// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_sort_by_group__8v0Zd {\n  display: flex;\n  flex-direction: column;\n  padding: 10px 0; }\n  .styles_sort_by_group_title__3mRVp > p {\n    margin: 0; }\n  .styles_sort_by_group_title__3mRVp {\n    margin-bottom: 10px; }\n  .styles_sort_by_group_radio__3dRAA {\n    display: flex;\n    flex-direction: row; }\n  .styles_sort_by_group_radio__3dRAA > div {\n    flex: 50% 1;\n    margin-right: 20px; }\n", ""]);
// Exports
exports.locals = {
	"sort_by_group": "styles_sort_by_group__8v0Zd",
	"sort_by_group_title": "styles_sort_by_group_title__3mRVp",
	"sort_by_group_radio": "styles_sort_by_group_radio__3dRAA"
};
module.exports = exports;
