import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from "react-router-dom";
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { selectIsLoading, selectAuthError, verifyEmail } from '../../store/user/userSlice';
import { showNotification } from '../../store/notification/notificationSlice';
import routes from '../../routes';
import { removeValueFromLocalStorage } from "../../helpers/auth";
import { NOTIFICATION_TYPES } from '../../constants';
import { IRouteParams } from '../../interfaces';
import SuspenseLoading from '../../components/SuspenseLoading/SuspenseLoading';
import SubmitButton from "../../components/Button";
import SuccessIcon from "../../assets_ash/icons/success_icon.svg";
import RejectIcon from '../../assets_ash/icons/reject_icon.svg'

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        fontSize: 28,
        fontWeight: 600,
        margin: '50px 0'
    },
    info: {
        fontSize: 18,
        fontWeight: 500,
        color: '#000',
    },
    authContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px 0',
        '& :last-child': {
            marginLeft: 5
        }
    },
    noAccountText: {
        fontSize: 18,
        color: '#000000'
    }
});

const VerificationResult: React.FC = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const isLoading = useSelector(selectIsLoading);
    const error = useSelector(selectAuthError);
    const { token } = useParams<IRouteParams>();

    const handleEmailVerification = async () => {
        try {
            await dispatch(verifyEmail(token));
            dispatch(showNotification({
                message: t('email_verify.status_notification.success'),
                type: NOTIFICATION_TYPES.SUCCESS
            }));
            removeValueFromLocalStorage('unVerifyEmail');
        } catch (e) {
            dispatch(showNotification({
                message: t('email_verify.status_notification.error'),
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    useEffect(() => {
        if (!token) {
            return;
        }

        handleEmailVerification();
    }, []);

    if (isLoading) {
        return (
            <SuspenseLoading/>
        );
    }

    return (
        <main className={classes.container}>
            <img
                src={error ? RejectIcon : SuccessIcon}
                alt='status icon'
            />

            <Typography className={classes.title}>
                {error ? t('email_verify.status_title.error') : t('email_verify.status_title.success')}
            </Typography>

            {error ? (
                <Typography className={classes.info}>
                    {t('email_verify.status_verify.error')}
                </Typography>
            ) : (
                <>
                    <Typography className={classes.info}>
                        {t('email_verify.status_verify.success')}
                    </Typography>
                    <Typography className={classes.info}>
                        {t('email_verify.status_verify.success_sub')}
                    </Typography>
                </>
            )}

            {!error && (
                <SubmitButton
                    type='submit'
                    disabled={isLoading}
                    onClick={() => history.push(routes.login.path)}
                >
                    {isLoading ? <CircularProgress size={25} color='inherit'/> : t('generics.login_button')}
                </SubmitButton>
            )}

            <section className={classes.authContainer}>
                <Typography className={classes.noAccountText} >
                    {t('generics.have_account')}
                </Typography>
                <Link
                    to={routes.login.path}
                    className={classes.noAccountText}
                    style={{color: '#007FDD'}}
                >
                    {t('generics.login_button')}
                </Link>
            </section>
        </main>
    )
};

export default VerificationResult;
