import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { logout, selectUserData } from '../../../store/user/userSlice';
import { showNotification } from "../../../store/notification/notificationSlice";
import { changeSidebarLanguage, getFirstLetters } from '../../../helpers';
import { extractSlugFromLocation } from "../../../helpers/queryHelpers";
import routes from "../../../routes";
import {
    makeStyles,
    createStyles,
    Typography,
    Theme,
    MenuItem,
    ListItemText,
} from '@material-ui/core';
import { NOTIFICATION_TYPES } from "../../../constants";
import LanguageChange from "../../../components/Header/LanguageChange";
import { StyledMenu } from "../../../components/Select";

const useStyles = makeStyles((theme: Theme) => createStyles({
    dashboardBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 1),
        backgroundColor: '#ffffff',
        paddingTop: '30px',
    },
    dashboardBarTitle: {
        fontWeight: 700,
        marginLeft: '15px',
    },
    divider: {
        marginLeft: '20px',
        marginRight: '20px',
        height: '1px',
        borderBottom: '1px solid #bbb',
    },
    mailIcon: {
        color: '#9b9b9b',
        fontSize: 40,
        fontWeight: 400,
    },
    avatarIcon: {
        width: '50px',
        borderRadius: "50%",
        backgroundColor: '#E5A6A6',
        padding: "10px",
        textAlign: 'center',
        fontSize: '20px',
        cursor: 'pointer'
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    emailText: {
        fontSize: '10px',
        margin: 0,
    },
    nameText: {
        fontSize: "16px",
        margin: 0,
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'row',
    },
}));

const DashboardBar = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();
    const currentLanguage = localStorage.getItem('lang') ?? 'hy';

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const userData = useSelector(selectUserData);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const handleLogout = async () => {
        try {
            dispatch(logout());
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    return (
        <div className={classes.dashboardBar}>
            <Typography
                variant="h4"
                component="h2"
                className={classes.dashboardBarTitle}
            >
                {location && changeSidebarLanguage(extractSlugFromLocation(location.pathname), currentLanguage)}
            </Typography>

            {!!userData && (
                <section className='d-flex align-items-center'>
                    <div className="d-flex align-items-center ml-5 mr-5">
                        <div className="avatar-icon-wrapper mr-3">
                            <div
                                className={classes.avatarIcon}
                                onClick={handleClick}
                            >
                                {getFirstLetters(userData.first_name, userData.last_name)}
                            </div>
                            <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem classes={{root: classes.menuItem}}>
                                    <p className={classes.nameText}>
                                        {`${userData?.first_name} ${userData?.last_name}`}
                                    </p>
                                    <p className={classes.emailText}>
                                        {userData?.email}
                                    </p>
                                </MenuItem>
                                <Link to={routes.userDocuments.path}>
                                    <MenuItem>
                                        <ListItemText
                                            primary={t('header.menu_list.my_uploads')}
                                        />
                                    </MenuItem>
                                </Link>
                                <MenuItem>
                                    <ListItemText
                                        primary={t('header.menu_list.logout')}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleLogout();
                                        }}
                                    />
                                </MenuItem>
                            </StyledMenu>
                        </div>
                        <div className={classes.userInfo}>
                            <div style={{marginRight: '10px'}}>
                                <span
                                    onClick={(e) => e.preventDefault()}
                                    className="text-black font-size-sm"
                                    title="...">
                                {t('document_review.welcome')}
                                </span>
                                <span className="text-black d-block font-size-md">
                                {`${userData.first_name} ${userData.last_name}`}
                            </span>
                            </div>
                            <LanguageChange/>
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
};

export default DashboardBar;
