import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { logout, selectIsAuthenticated } from "../../store/user/userSlice";
import { showNotification } from "../../store/notification/notificationSlice";
import { getToken } from "../../helpers/auth";
import routes from "../../routes";
import { NOTIFICATION_TYPES } from "../../constants";
import { Link as MUILink, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
    footer: {
        width: '100%',
        display: "flex",
        alignItems: 'center',
        paddingTop: 10,
        backgroundColor: "#436176",
        '@media(max-width: 1200px)' : {
            flexDirection: 'column',
        }
    },
    socialActions: {
        display: 'flex',
        flex: "10%",
        padding: '20px 50px',
        '@media(max-width: 1200px)' : {
            padding: '0 25px',
        }
    },
    icon: {
        fill: '#ffffff',
        margin: 10,
        '@media(max-width: 1200px)' : {
            margin: 5
        }
    },
    linksContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: "80%",
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 10,
        '@media(max-width: 1200px)' : {
            flexDirection: 'column',
            alignItems: 'center',
            margin: 5,
        }
    },
    linkContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 40px',
        '@media(max-width: 1200px)' : {
            padding: '0 10px',
        }
    },
    link: {
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 500,
        margin: 5,
        '@media(max-width: 1200px)' : {
            fontSize: 10,
        }
    },
    links: {
        display: "flex",
    },
    copyright: {
        marginRight: 10,
        alignSelf: 'flex-end',
        '@media(max-width: 1200px)' : {
            alignSelf: 'auto',
        }
    },
    copyrightText: {
        color: "#ffffff",
        fontSize: 14,
        fontWeight: 500,
        '@media(max-width: 1200px)' : {
            fontSize: 8
        }
    },
});

const Footer: React.FC = () => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const classes = useStyles();

    const isAuthenticated = useSelector(selectIsAuthenticated);

    const handleLogout = async () => {
        try {
            dispatch(logout());
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    return (
        <section className={classes.footer}>
            <section className={classes.socialActions}>
                <MUILink
                    href={'https://www.facebook.com/HomenetmenGlendaleArarat'}
                    color="inherit"
                    rel="noopener"
                    target="_blank"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 24 24"
                        className={classes.icon}
                    >
                        <path
                            id="iconmonstr-facebook-4"
                            d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm3,8H13.65c-.538,0-.65.221-.65.778V10h2l-.209,2H13v7H10V12H8V10h2V7.692C10,5.923,10.931,5,13.029,5H15Z"
                        />
                    </svg>
                </MUILink>
                <MUILink
                    href={'https://www.instagram.com/my_ararat/'}
                    color="inherit"
                    rel="noopener"
                    target="_blank"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 24 24"
                        className={classes.icon}
                    >
                        <path
                            id="iconmonstr-instagram-14"
                            d="M14.829,6.3c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04A2.63,2.63,0,0,0,6.3,9.172c-.033.738-.041.959-.041,2.828s.008,2.09.041,2.829a2.631,2.631,0,0,0,2.87,2.87c.737.033.959.041,2.828.041s2.091-.007,2.829-.041a2.631,2.631,0,0,0,2.87-2.87c.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828a2.632,2.632,0,0,0-2.87-2.87ZM12,15.6A3.595,3.595,0,1,1,15.6,12,3.595,3.595,0,0,1,12,15.6ZM15.737,9.1a.84.84,0,1,1,.84-.84A.84.84,0,0,1,15.737,9.1Zm-1.4,2.9A2.333,2.333,0,1,1,12,9.667,2.333,2.333,0,0,1,14.333,12ZM12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm6.958,14.886a3.864,3.864,0,0,1-4.071,4.072C14.14,18.992,13.9,19,12,19s-2.139-.008-2.886-.042a3.865,3.865,0,0,1-4.072-4.072C5.008,14.14,5,13.9,5,12s.008-2.139.042-2.886A3.863,3.863,0,0,1,9.114,5.043C9.861,5.008,10.1,5,12,5s2.14.008,2.887.043a3.863,3.863,0,0,1,4.071,4.071C18.992,9.861,19,10.1,19,12S18.992,14.14,18.958,14.886Z"
                        />
                    </svg>

                </MUILink>
            </section>

            <section className={classes.linksContainer}>
                <section className={classes.links}>
                    <section className={classes.linkContainer}>
                        <Link to={routes.landing.path}>
                            <Typography className={classes.link}>
                                {t('footer.home')}
                            </Typography>
                        </Link>
                        {isAuthenticated && getToken() ? (
                            <Typography
                                className={classes.link}
                                style={{cursor: "pointer"}}
                                onClick={() => handleLogout()}
                            >
                                {t('footer.logout')}
                            </Typography>
                        ) : (
                            <Link to={routes.login.path}>
                                <Typography className={classes.link}>
                                    {t('footer.login')}
                                </Typography>
                            </Link>
                        )}
                        <Link to={routes.home.path}>
                            <Typography className={classes.link}>
                                {t('footer.gallery')}
                            </Typography>
                        </Link>
                    </section>
                    <section className={classes.linkContainer}>
                        <Link to={routes.documentsUpload.path}>
                            <Typography className={classes.link}>
                                {t('footer.upload')}
                            </Typography>
                        </Link>
                        <Link to={routes.registration.path}>
                            <Typography className={classes.link}>
                                {t('footer.register')}
                            </Typography>
                        </Link>
                        <Link to={routes.aboutUs.path}>
                            <Typography className={classes.link}>
                                {t('footer.about_us')}
                            </Typography>
                        </Link>
                    </section>
                    <section className={classes.linkContainer}>
                        <Link to={routes.faq.path}>
                            <Typography className={classes.link}>
                                {t('footer.faq')}
                            </Typography>
                        </Link>
                        <Link to={routes.privacyPolicy.path}>
                            <Typography className={classes.link}>
                                {t('footer.privacy_policy')}
                            </Typography>
                        </Link>
                        <Link to={routes.termsAndConditions.path}>
                            <Typography className={classes.link}>
                                {t('footer.terms_and_conditions')}
                            </Typography>
                        </Link>
                    </section>
                </section>
                <section className={classes.copyright}>
                    <Typography className={classes.copyrightText}>
                        &#169; 2023 Parpetsi All Rights Reserved
                    </Typography>
                </section>
            </section>
        </section>
    )
};

export default Footer;