import React from 'react';
import { FILE_TYPES } from '../../../../constants';
import {
    matchFileType,
    matchFileTypeByExtension
} from '../../../../helpers';
import { Document, Page, pdfjs } from 'react-pdf';
import wordIcon from '../../../../assets_ash/images/word_image.svg'
import styles from '../Document/styles.module.scss';

// Sets the URL for the PDF.js worker script, which handles rendering PDF files in the background for better performance.
// This code uses a CDN to load the appropriate version of pdf.worker.js based on the current PDF.js version.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IDocumentPreview {
    filePath: string;
}

const DocumentPreview: React.FC<IDocumentPreview> = ({ filePath }) => {
    const fileType = matchFileType(matchFileTypeByExtension(filePath));

    if (fileType === FILE_TYPES.IMAGE) {
        return (
            <img src={filePath} alt="document" className={styles.image} />
        )
    } else if (fileType === FILE_TYPES.VIDEO) {
        return (
            <video
                key={filePath}
                className={styles.video}
            >
                <source src={filePath} type={matchFileTypeByExtension(filePath)} />
            </video>
        )
    } else if (fileType === FILE_TYPES.PDF) {
        return (
            <Document className={styles.card_pdf} file={filePath}>
                <Page pageNumber={1} />
            </Document>
        )
    } else if (fileType === FILE_TYPES.DOC) {
        return (
            <img src={wordIcon} alt="document" className={styles.image} />
        )
    } else {
        return <></>
    }
};

export default DocumentPreview;
