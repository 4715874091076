import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { 
    getUser, 
    logout, 
    setIsAuthenticated, 
    selectIsAuthenticated 
} from './store/user/userSlice';
import { showNotification } from './store/notification/notificationSlice';
import { getToken, removeToken } from './helpers/auth';
import { getInitialLocalization } from "./helpers/localizationHelpers";
import { TOKEN, NOTIFICATION_TYPES } from './constants';
import ScrollToTop from './utils/ScrollToTop';
import Layout from './Layout';

import './utils/Icons';
import './assets/base.scss';

const App = () => {
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const dispatch = useDispatch();

    const handleStorageChange = async (event: StorageEvent) => {
        if (event.key === TOKEN && !event.newValue) {
            dispatch(logout());
        }
    };

    const handleAuthentication = async () => {
        const token = getToken();

        if (token && !isAuthenticated) {
            await dispatch(setIsAuthenticated(true));
        }
    };

    const getUserData = async () => {
        try {
            await dispatch(getUser());
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
            removeToken();
        }
    };

    useEffect(() => {
        handleAuthentication();
    }, []);
    
    useEffect(() => {
        if (isAuthenticated) {
            getUserData();
        }
    }, [isAuthenticated]);

    // If user logs out/in in a different tab, reload in order to reflect the changes
    useEffect(() => {
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <BrowserRouter basename={getInitialLocalization()}>
            <ScrollToTop>
                <Layout />
            </ScrollToTop>
        </BrowserRouter>
    );
};

export default App;
