import React from 'react';
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { makeStyles, Typography } from "@material-ui/core";
import { changeBulkRevisionDocument } from "../../../store/documents/documentsSlice";
import { showNotification } from "../../../store/notification/notificationSlice";
import { formatRevisionDate } from "../../../helpers/dateTime";
import { changeStatusCurrentLanguage } from "../../../helpers";
import { calculateDocumentLength } from "../../../helpers/documentChangeHelpers";
import { NOTIFICATION_TYPES } from "../../../constants";
import { ChangeDocumentFiles, ChangeFile } from "../../../assets_ash/globalTypes";
import routes from "../../../routes";
import { IRevisionUser } from "../../../interfaces";
import RevisionTab from "./RevisionTab/index";
import RevisionTabWrapper from "./RevisionTabWrapper";

type ChangeBoard = {
    document_files: ChangeDocumentFiles[],
    document: ChangeFile[],
    currentDocument: ChangeDocumentFiles | undefined,
    user: IRevisionUser,
    id: string,
    created_at: string
}

const useStyles = makeStyles({
    versionsBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '30px 0 30px 200px'
    },
    versions: {
        display: 'flex',
        flexDirection: 'row',
        flex: '90%'
    },
    versionsTab: {
        flex: '50%',
    },
    oldVersionsTab: {
        flex: '50%',
        paddingLeft: '15px',
    },
    oldVersion: {
        '& :nth-child(1)': {
            marginRight: '10px',
            paddingRight: '15px',
            borderRight: '1px solid black'
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    versionsTitle: {
        fontWeight: 600,
    },
    userInfoTab: {
        flex: '10%'
    },
    approveAll: {
        color: '#007FDD',
        cursor: 'pointer'
    },
});

const ChangeBoard: React.FC<ChangeBoard> = ({document_files, document, currentDocument, user, id, created_at}) => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();

    const documentReview = useRouteMatch({
        path: [routes.documentChanges.path],
        exact: true,
        strict: true,
        sensitive: true,
    });

    const handleBulkChangeRevision = async (id: string, status: string) => {
        try {
            await dispatch(changeBulkRevisionDocument(id, status));
            dispatch(showNotification({
                message: `${t('document_review.notifications.all_files_action', { status : changeStatusCurrentLanguage(status, false)})}`,
                type: NOTIFICATION_TYPES.SUCCESS
            }));
            window.location.reload();
        } catch (e) {
            dispatch(showNotification({
                message: e.message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    return (
        <main>
            <section className={classes.versionsBar}>

                <section className={classes.versions}>

                    <section className={classes.versionsTab}>
                        <Typography
                            variant='h4'
                            component='h3'
                            className={classes.versionsTitle}
                        >
                            {t('document_review.actions.old_version')}
                        </Typography>
                    </section>

                    <section className={classes.oldVersionsTab}>
                        <Typography
                            variant='h4'
                            component='h3'
                            className={classes.versionsTitle}
                        >
                            {t('document_review.actions.new_version')} {`(${calculateDocumentLength(document, document_files, currentDocument)})`}
                        </Typography>

                        {/*{!documentReview && (*/}
                        {/*    <section className={classes.oldVersion}>*/}
                        {/*        <Typography*/}
                        {/*            variant='subtitle1'*/}
                        {/*            component='h3'*/}
                        {/*            className={classes.approveAll}*/}
                        {/*            onClick={(e: any) => {*/}
                        {/*                handleBulkChangeRevision(id, 'approve')*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            {t('document_review.actions.approve_all')}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography*/}
                        {/*            variant='subtitle1'*/}
                        {/*            component='h3'*/}
                        {/*            className={classes.approveAll}*/}
                        {/*            onClick={(e: any) => {*/}
                        {/*                handleBulkChangeRevision(id, 'decline')*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            {t('document_review.actions.decline_all')}*/}
                        {/*        </Typography>*/}
                        {/*    </section>*/}
                        {/*)}*/}

                    </section>

                </section>

                <section className={classes.userInfoTab}>
                    <Typography
                        variant='subtitle2'
                        component='h4'
                    >
                        {t('document_review.actions.edited_by')} {`${user.first_name} ${user.last_name}`}
                    </Typography>
                    <Typography
                        variant='subtitle2'
                        component='h4'
                    >
                        {formatRevisionDate(created_at)}
                    </Typography>
                </section>

            </section>
            <section>

                {!!document && document.map((doc: ChangeFile, index: number) => (
                    <RevisionTab key={index} field={doc.key} value={doc.value} old_value={doc.old_value} id={doc.id} status={doc.status}/>
                ))}

                <RevisionTabWrapper document_files={document_files} currentDocument={currentDocument}/>
            </section>
        </main>
    )
}

export default ChangeBoard