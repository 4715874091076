import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import routes from "../../routes";
import { selectAuthError } from "../../store/user/userSlice";
import { makeStyles, Typography } from "@material-ui/core";
import SubmitButton from "../../components/Button";
import SuccessIcon from '../../assets_ash/icons/success_icon.svg';
import RejectIcon from '../../assets_ash/icons/reject_icon.svg'

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        margin: '30px 0'
    },
    notificationTitle: {
        fontSize: 28,
        fontWeight: 700,
        margin: '10px 0'
    },
    notificationStatus: {
        fontSize: 18,
        textAlign: 'center',
        color: '#000000'
    }
});

const ResetSuccess: React.FC = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const history = useHistory();

    const error = useSelector(selectAuthError);

    const handleRedirectToLogin = () => history.push(routes.login.path);

    return (
        <main className={classes.container}>

            <img
                src={error ? RejectIcon : SuccessIcon}
                alt="success"
                className={classes.icon}
            />

            <Typography className={classes.notificationTitle}>
                {error ? t('reset_success.title_status.reject') : t('reset_success.title_status.success')}
            </Typography>

            <Typography className={classes.notificationStatus}>
                {error ? error : (t('reset_success.sub_status'))}
            </Typography>

            <SubmitButton
                name={t('generics.login_button')}
                type='button'
                onClick={handleRedirectToLogin}
            />
        </main>
    )
};

export default ResetSuccess;