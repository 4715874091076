import React from "react";
import { InputBase, Menu, MenuProps, Radio, withStyles } from "@material-ui/core";

export const CustomSelect = withStyles({
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#FFFFFF',
        border: '1px solid #D8D8D8',
        padding: '17px 14px',
        color: '#757575',
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
})(InputBase);

export const BlueRadio = withStyles({
    root: {
        color: '#007FDD',
        '&$checked': {
            color: '#007FDD',
        },
    },
    checked: {
        color: '#007FDD',
    },
})((props) => <Radio color="default" {...props} />);

export const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));