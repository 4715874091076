import React, { useMemo } from 'react';
import { FormikValues, FormikTouched, FormikErrors } from 'formik';
import { IFormikValues, IFormikDocument } from '../../../types';
import {  IGroup } from '../../../../../interfaces';
import { DATE_OPTIONS, FILE_TYPES } from '../../../../../constants';
import { useTranslation } from "react-i18next";
import { getYearsToNow } from "../../../../../helpers/dateTime";
import {
    matchFileType,
    matchFileTypeByExtension
} from '../../../../../helpers';
import {
    FormControl,
    FormControlLabel,
    Checkbox,
    InputLabel,
    Select,
    FormHelperText,
    MenuItem,
    TextField,
    makeStyles,
} from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import WarningModal from '../../../../../components/WarningModal';
import TextMaskInput from '../../TextMaskInput';
import DynamicTags from '../../../../../components/DynamicTags';
import { FileCopyOutlined } from '@material-ui/icons';
import { BeatLoader } from 'react-spinners';

import styles from '../../styles.module.scss';

interface IFormFields {
    values: FormikValues;
    groups: IGroup[];
    currentDocument: string;
    isSingleDocument: boolean;
    submitLoading: boolean;
    isGroupsLoading: boolean;
    touched: FormikTouched<IFormikValues>;
    errors: FormikErrors<IFormikValues>;
    isWarningOpen: boolean;
    setFieldValue: (field: string, value: any) => void;
    handleChange: () => void;
    onCopyToClipboard: (value: string | number) => void;
    combineOrSplitDocuments: () => void;
    approveWarning: () => void;
    declineWarning: () => void;

    // annotations: any;
    applyImageAnnotations: any;
    deleteImageAnnotation: any;
    onMouseOver: any;
    onMouseOut: any;
};

const useStyles = makeStyles({
    formInput: {
        width: '100%',
    },
    select: {
        width: '100%',
    },
    selectApproximate: {
        width: '100%',
    },
    chipContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        margin: 0,

        border: '1px solid #e0e0e0',
    },
    customChip: {
        backgroundColor: '#e0e0e0 !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        marginRight: '0.4rem',
        marginBottom: '0.4rem',
        fontSize: '1rem',
    },
});

const FormFields: React.FC<IFormFields> = ({
    values,
    groups,
    isSingleDocument,
    currentDocument,
    submitLoading,
    isGroupsLoading,
    touched,
    errors,
    isWarningOpen,
    setFieldValue,
    handleChange,
    onCopyToClipboard,
    combineOrSplitDocuments,
    approveWarning,
    declineWarning,
    applyImageAnnotations,
    deleteImageAnnotation,
    onMouseOver,
    onMouseOut,
}) => {
    const { t } = useTranslation('common');
    const classes = useStyles();

    const currentLanguage = localStorage.getItem('lang') ?? 'hy';

    const currenLanguagePeriod = useMemo(() => {
        return DATE_OPTIONS.period[currentLanguage as keyof typeof DATE_OPTIONS.period]
    }, [currentLanguage]);

    const approxDate = useMemo(getYearsToNow, []);

    return (
        <>
            {values.docs && values.docs.map((value: IFormikDocument, index: number) => {
                return (
                    <div key={index} style={{ display: value.name === currentDocument ? 'block' : 'none' }}>

                        <div className={styles.field_container}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isSingleDocument ? values.docs[0].approximateDate : value.approximateDate}
                                        onChange={(e) => setFieldValue(`docs[${isSingleDocument ? 0 : index}].approximateDate`, e.target.checked)}
                                        name={`docs[${isSingleDocument ? 0 : index}].approximateDate`}
                                    />
                                }
                                label={t('upload.second_form.fields.not_exact_date')}
                            />
                        </div>

                        {(isSingleDocument ? values.docs[0].approximateDate : value.approximateDate) ? (
                            <>
                                <div className={styles.approximate_date_field}>
                                    <FormControl
                                        className={classes.selectApproximate}
                                        variant='outlined'
                                        disabled={submitLoading
                                            || isSingleDocument ? values.docs[0].isDateRange : value.isDateRange}
                                    >
                                        <InputLabel id={`from-label-${isSingleDocument ? 0 : index}`}>{t('upload.second_form.fields.year_from')}</InputLabel>
                                        <Select
                                            id={`docs[${isSingleDocument ? 0 : index}].from`}
                                            name={`docs[${isSingleDocument ? 0 : index}].from`}
                                            labelId={`from-label-${isSingleDocument ? 0 : index}`}
                                            label={t('upload.second_form.fields.year_from')}
                                            value={isSingleDocument ? values.docs[0].from : value.from}
                                            onChange={(e) => {
                                                const newFromValue = e.target.value;
                                                if (newFromValue === '') {
                                                    setFieldValue(`docs[${isSingleDocument ? 0 : index}].to`, '');
                                                }
                                                setFieldValue(`docs[${isSingleDocument ? 0 : index}].from`, newFromValue);
                                            }}
                                            error={touched.docs?.[isSingleDocument ? 0 : index]?.from
                                                && Boolean((errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.from)}
                                        >
                                            <MenuItem value="">{t('upload.second_form.fields.none')}</MenuItem>
                                            {approxDate && approxDate.map((option, index) => {
                                                return (
                                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                                );
                                            })}

                                        </Select>
                                        <FormHelperText
                                            error={touched.docs?.[isSingleDocument ? 0 : index]?.from
                                                && Boolean((errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.from)}
                                        >
                                            {touched.docs?.[isSingleDocument ? 0 : index]?.from
                                                && (errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.from}
                                        </FormHelperText>
                                    </FormControl>

                                    <CopyToClipboard
                                        text={isSingleDocument ? values.docs[0].from : value.from}
                                        onCopy={() => onCopyToClipboard(isSingleDocument ? values.docs[0].from : value.from)}
                                    >
                                        <FileCopyOutlined className={styles.copy_to_clipboard} />
                                    </CopyToClipboard>

                                    <FormControl
                                        className={classes.selectApproximate}
                                        variant='outlined'
                                        disabled={submitLoading
                                            || (isSingleDocument ? values.docs[0].isDateRange : value.isDateRange)
                                            || (isSingleDocument ? !values.docs[0].from : !value.from)}
                                    >
                                        <InputLabel id={`to-label-${isSingleDocument ? 0 : index}`}>{t('upload.second_form.fields.year_to')}</InputLabel>
                                        <Select
                                            id={`docs[${isSingleDocument ? 0 : index}].to`}
                                            name={`docs[${isSingleDocument ? 0 : index}].to`}
                                            labelId={`to-label-${isSingleDocument ? 0 : index}`}
                                            label={t('upload.second_form.fields.year_to')}
                                            // DUDE
                                            value={isSingleDocument ? values.docs[0].to : value.to}
                                            onChange={handleChange}
                                            error={touched.docs?.[isSingleDocument ? 0 : index]?.to
                                                && Boolean((errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.to)}
                                        >
                                            <MenuItem value="">{t('upload.second_form.fields.none')}</MenuItem>
                                            {approxDate && approxDate.map((option, index) => {
                                                return (
                                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FormHelperText
                                            error={touched.docs?.[isSingleDocument ? 0 : index]?.to
                                                && Boolean((errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.to)}
                                        >
                                            {touched.docs?.[isSingleDocument ? 0 : index]?.to
                                                && (errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.to}
                                        </FormHelperText>
                                    </FormControl>

                                    <CopyToClipboard
                                        text={isSingleDocument ? values.docs[0].to : value.to}
                                        onCopy={() => onCopyToClipboard(isSingleDocument ? values.docs[0].to : value.to)}
                                    >
                                        <FileCopyOutlined className={styles.copy_to_clipboard} />
                                    </CopyToClipboard>
                                </div>

                                <div className={styles.approximate_date_field}>
                                    <FormControl
                                        className={classes.selectApproximate}
                                        variant='outlined'
                                        disabled={submitLoading
                                            || isSingleDocument ? values.docs[0].isDateRange : value.isDateRange}
                                    >
                                        <InputLabel id={`period-label-${isSingleDocument ? 0 : index}`}>{t('upload.second_form.fields.period')}</InputLabel>
                                        <Select
                                            id={`docs[${isSingleDocument ? 0 : index}].period`}
                                            name={`docs[${isSingleDocument ? 0 : index}].period`}
                                            labelId={`to-label-${isSingleDocument ? 0 : index}`}
                                            label={t('upload.second_form.fields.period')}
                                            value={isSingleDocument ? values.docs[0].period : value.period}
                                            onChange={handleChange}
                                            error={touched.docs?.[isSingleDocument ? 0 : index]?.period
                                                && Boolean((errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.period)}
                                        >
                                            <MenuItem value="">{t('upload.second_form.fields.none')}</MenuItem>
                                            {currenLanguagePeriod && currenLanguagePeriod.map((option, index) => {
                                                return (
                                                    <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FormHelperText
                                            error={touched.docs?.[isSingleDocument ? 0 : index]?.period
                                                && Boolean((errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.period)}
                                        >
                                            {touched.docs?.[isSingleDocument ? 0 : index]?.period
                                                && (errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.period}
                                        </FormHelperText>
                                    </FormControl>

                                    <CopyToClipboard
                                        text={isSingleDocument ? values.docs[0].period : value.period}
                                        onCopy={() => onCopyToClipboard(isSingleDocument ? values.docs[0].period : value.period)}
                                    >
                                        <FileCopyOutlined className={styles.copy_to_clipboard} />
                                    </CopyToClipboard>
                                </div>

                                <div className={styles.approximate_date_field}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isSingleDocument ? values.docs[0].isDateRange : value.isDateRange}
                                                onChange={(e) => {
                                                    setFieldValue(`docs[${isSingleDocument ? 0 : index}].isDateRange`, e.target.checked);
                                                    if (e.target.checked) {
                                                        setFieldValue(`docs[${isSingleDocument ? 0 : index}].from`, '');
                                                        setFieldValue(`docs[${isSingleDocument ? 0 : index}].to`, '');
                                                        setFieldValue(`docs[${isSingleDocument ? 0 : index}].period`, '');
                                                    } else {
                                                        setFieldValue(`docs[${isSingleDocument ? 0 : index}].dateFrom`, '');
                                                        setFieldValue(`docs[${isSingleDocument ? 0 : index}].dateTo`, '');
                                                    }
                                                }}
                                                name={`docs[${isSingleDocument ? 0 : index}].isDateRange`}
                                            />
                                        }
                                        label={t('upload.second_form.fields.date_range')}
                                    />
                                </div>

                                <div className={styles.approximate_date_field}>
                                    <TextField
                                        id="date-range-from"
                                        name={`docs[${isSingleDocument ? 0 : index}].dateFrom`}
                                        className={classes.formInput}
                                        label={t('upload.second_form.fields.date_from')}
                                        type="date"
                                        autoComplete="off"
                                        variant="outlined"
                                        disabled={submitLoading
                                            || isSingleDocument ? !values.docs[0].isDateRange : !value.isDateRange}
                                        value={isSingleDocument ? values.docs[0].dateFrom : value.dateFrom}
                                        onChange={handleChange}
                                        error={touched.docs?.[isSingleDocument ? 0 : index]?.dateFrom
                                            && Boolean((errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.dateFrom)}
                                        helperText={touched.docs?.[isSingleDocument ? 0 : index]?.dateFrom
                                            && (errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.dateFrom}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <CopyToClipboard
                                        text={isSingleDocument ? values.docs[0].dateFrom : value.dateFrom}
                                        onCopy={() => onCopyToClipboard(isSingleDocument ? values.docs[0].dateFrom : value.dateFrom)}
                                    >
                                        <FileCopyOutlined className={styles.copy_to_clipboard} />
                                    </CopyToClipboard>

                                    <TextField
                                        id="date-range-to"
                                        name={`docs[${isSingleDocument ? 0 : index}].dateTo`}
                                        className={classes.formInput}
                                        label={t('upload.second_form.fields.date_to')}
                                        type="date"
                                        autoComplete="off"
                                        variant="outlined"
                                        disabled={submitLoading
                                            || isSingleDocument ? !values.docs[0].isDateRange : !value.isDateRange || !Boolean(values.docs?.[isSingleDocument ? 0 : index]?.dateFrom)}
                                        value={isSingleDocument ? values.docs[0].dateTo : value.dateTo}
                                        onChange={handleChange}
                                        error={Boolean(values.docs?.[isSingleDocument ? 0 : index]?.dateFrom) && touched.docs?.[isSingleDocument ? 0 : index]?.dateTo
                                            && Boolean((errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.dateTo)}
                                        helperText={Boolean(values.docs?.[isSingleDocument ? 0 : index]?.dateFrom) && touched.docs?.[isSingleDocument ? 0 : index]?.dateTo
                                            && (errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.dateTo}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <CopyToClipboard
                                        text={isSingleDocument ? values.docs[0].dateTo : value.dateTo}
                                        onCopy={() => onCopyToClipboard(isSingleDocument ? values.docs[0].dateTo : value.dateTo)}
                                    >
                                        <FileCopyOutlined className={styles.copy_to_clipboard} />
                                    </CopyToClipboard>
                                </div>
                            </>
                        ) : (
                            <div className={styles.field_container}>
                                <TextField
                                    id="date"
                                    name={`docs[${isSingleDocument ? 0 : index}].date`}
                                    className={classes.formInput}
                                    label={t('upload.second_form.fields.date')}
                                    type="text"
                                    autoComplete="off"
                                    variant="outlined"
                                    disabled={submitLoading}
                                    value={isSingleDocument ? values.docs[0].date : value.date}
                                    onChange={handleChange}
                                    error={touched.docs?.[isSingleDocument ? 0 : index]?.date
                                        && Boolean((errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.date)}
                                    helperText={touched.docs?.[isSingleDocument ? 0 : index]?.date
                                        && (errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.date}
                                    InputProps={{
                                        inputComponent: TextMaskInput
                                    }}
                                />
                                <CopyToClipboard
                                    text={isSingleDocument ? values.docs[0].date : value.date}
                                    onCopy={() => onCopyToClipboard(isSingleDocument ? values.docs[0].date : value.date)}
                                >
                                    <FileCopyOutlined className={styles.copy_to_clipboard} />
                                </CopyToClipboard>
                            </div>
                        )}

                        <div className={styles.field_container}>
                            <TextField
                                id="place"
                                name={`docs[${isSingleDocument ? 0 : index}].place`}
                                className={classes.formInput}
                                label={t('upload.second_form.fields.location')}
                                type="text"
                                autoComplete="location"
                                disabled={submitLoading}
                                variant="outlined"
                                value={isSingleDocument ? values.docs[0].place : value.place}
                                onChange={handleChange}
                                error={touched.docs?.[isSingleDocument ? 0 : index]?.place
                                    && Boolean((errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.place)}
                                helperText={touched.docs?.[isSingleDocument ? 0 : index]?.place
                                    && (errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.place}
                            />
                            <CopyToClipboard
                                text={isSingleDocument ? values.docs[0].place : value.place}
                                onCopy={() => onCopyToClipboard(isSingleDocument ? values.docs[0].place : value.place)}
                            >
                                <FileCopyOutlined className={styles.copy_to_clipboard} />
                            </CopyToClipboard>
                        </div>

                        <div className={styles.field_container}>
                            <FormControl
                                className={classes.select}
                                variant='outlined'
                                disabled={submitLoading}
                            >
                                <InputLabel id={`group-label-${isSingleDocument ? 0 : index}`}>{t('upload.second_form.fields.group')}</InputLabel>
                                <Select
                                    id={`docs[${isSingleDocument ? 0 : index}].group`}
                                    name={`docs[${isSingleDocument ? 0 : index}].group`}
                                    labelId={`group-label-${isSingleDocument ? 0 : index}`}
                                    label={t('upload.second_form.fields.group')}
                                    value={isSingleDocument ? values.docs[0].group : value.group}
                                    onChange={handleChange}
                                    error={touched.docs?.[isSingleDocument ? 0 : index]?.group
                                        && Boolean((errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.group)}
                                >
                                    {isGroupsLoading && (
                                        <MenuItem>
                                            <BeatLoader />
                                        </MenuItem>
                                    )}
                                    {!isGroupsLoading && groups && groups.map(group => {
                                        return (
                                            <MenuItem key={group.name} value={group.id}>{group.name}</MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText
                                    error={touched.docs?.[isSingleDocument ? 0 : index]?.group
                                        && Boolean((errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.group)}
                                >
                                    {touched.docs?.[isSingleDocument ? 0 : index]?.group
                                        && (errors.docs?.[isSingleDocument ? 0 : index] as FormikErrors<IFormikDocument>)?.group}
                                </FormHelperText>
                            </FormControl>

                            <CopyToClipboard
                                text={isSingleDocument ? values.docs[0].group : value.group}
                                onCopy={() => onCopyToClipboard(isSingleDocument ? values.docs[0].group : value.group)}
                            >
                                <FileCopyOutlined className={styles.copy_to_clipboard} />
                            </CopyToClipboard>
                        </div>

                        <div className={styles.field_container}>
                            <TextField
                                id="occasion"
                                name={`docs[${isSingleDocument ? 0 : index}].occasion`}
                                className={classes.formInput}
                                label={t('upload.second_form.fields.occasion')}
                                type="text"
                                autoComplete="occasion"
                                variant="outlined"
                                disabled={submitLoading}
                                multiline
                                value={isSingleDocument ? values.docs[0].occasion : value.occasion}
                                onChange={handleChange}
                            />
                            <CopyToClipboard
                                text={isSingleDocument ? values.docs[0].occasion : value.occasion}
                                onCopy={() => onCopyToClipboard(isSingleDocument ? values.docs[0].occasion : value.occasion)}
                            >
                                <FileCopyOutlined className={styles.copy_to_clipboard} />
                            </CopyToClipboard>
                        </div>

                        {isSingleDocument && (
                            <h3 className={styles.form_title}>{t('upload.second_form.field_actions.sub_title')}</h3>
                        )}

                        <div className={styles.field_container}>
                            {matchFileType(matchFileTypeByExtension(value.name)) === FILE_TYPES.IMAGE ? (
                                <section className={styles.people_info_container}>
                                    <section className={styles.people_info_container_fields}>
                                        <DynamicTags
                                            index={index}
                                            faceTags={value.files[0].face_tags}
                                            onMouseOver={onMouseOver}
                                            onMouseOut={onMouseOut}
                                            deleteImageAnnotation={deleteImageAnnotation}
                                        />
                                        <CopyToClipboard
                                            text={value.files[0].people}
                                            // onCopy={() => onCopyToClipboard(value.files[0].people)}
                                        >
                                            <FileCopyOutlined className={styles.copy_to_clipboard}/>
                                        </CopyToClipboard>
                                    </section>
                                    <section className={styles.people_info_container_texts}>
                                        {t('upload.second_form.fields.people_info')}
                                    </section>
                                </section>
                            ) : (
                                <>
                                    <TextField
                                        id="people"
                                        name={`docs[${index}].files[0]people`}
                                        className={classes.formInput}
                                        label={t('upload.second_form.fields.people')}
                                        type="text"
                                        autoComplete="people-names"
                                        variant="outlined"
                                        disabled={submitLoading}
                                        value={value.files[0].people}
                                        onChange={handleChange}
                                    />
                                    <CopyToClipboard
                                        text={value.files[0].people}
                                        onCopy={() => onCopyToClipboard(value.files[0].people)}
                                    >
                                        <FileCopyOutlined className={styles.copy_to_clipboard} />
                                    </CopyToClipboard>
                                </>)}
                        </div>

                        <div className={styles.field_container}>
                            <TextField
                                id="information"
                                name={`docs[${index}].files[0].information`}
                                className={classes.formInput}
                                label={t('upload.second_form.fields.information')}
                                type="text"
                                autoComplete="information"
                                variant="outlined"
                                disabled={submitLoading}
                                multiline
                                value={value.files[0].information}
                                onChange={handleChange}
                            />
                            <CopyToClipboard
                                text={value.files[0].information}
                                onCopy={() => onCopyToClipboard(value.files[0].information)}
                            >
                                <FileCopyOutlined className={styles.copy_to_clipboard} />
                            </CopyToClipboard>
                        </div>

                        <div className={styles.field_container}>
                            <TextField
                                id="document_source"
                                name={`docs[${index}].files[0].document_source`}
                                className={classes.formInput}
                                label={t('upload.second_form.fields.document_source')}
                                type="text"
                                autoComplete="document_source"
                                variant="outlined"
                                disabled={submitLoading}
                                multiline
                                value={value.files[0].document_source}
                                onChange={handleChange}
                            />
                            <CopyToClipboard
                                text={value.files[0].document_source}
                                onCopy={() => onCopyToClipboard(value.files[0].document_source)}
                            >
                                <FileCopyOutlined className={styles.copy_to_clipboard} />
                            </CopyToClipboard>
                        </div>

                        <div className={styles.field_container}>
                            <TextField
                                id="narratives"
                                name={`docs[${index}].files[0].narratives`}
                                className={classes.formInput}
                                label={t('upload.second_form.fields.memory')}
                                type="text"
                                autoComplete="memory-or-narrative"
                                variant="outlined"
                                disabled={submitLoading}
                                multiline
                                value={value.files[0].narratives}
                                onChange={handleChange}
                            />
                            <CopyToClipboard
                                text={value.files[0].narratives}
                                onCopy={() => onCopyToClipboard(value.files[0].narratives)}
                            >
                                <FileCopyOutlined className={styles.copy_to_clipboard} />
                            </CopyToClipboard>
                        </div>
                    </div>
                )
            })}

            <FormControlLabel
                disabled={values.docs.length === 1}
                control={
                    <Checkbox
                        checked={isSingleDocument}
                        onChange={combineOrSplitDocuments}
                        name="isSingleDocument"
                    />
                }
                label={t('upload.second_form.fields.group_files')}
            />

            <WarningModal
                open={isWarningOpen}
                warningText={
                    isSingleDocument ?
                        t('upload.second_form.fields.singleDocumentPopup')
                        :
                        t('upload.second_form.fields.groupDocumentPopup')
                }
                setApprove={approveWarning}
                onCloseModal={declineWarning}
            />
        </>
    );
};

export default FormFields;
