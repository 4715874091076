import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import styles from './styles.module.scss';

interface ITableSkeleton {
    rowsCount: number;
};

const useStyles = makeStyles((theme: Theme) => ({
    avatar: {
        marginRight: '10px',
    },
    color: {
        backgroundColor: '#dddddd',
    },
}));

const TableSkeleton: React.FC<ITableSkeleton> = ({ rowsCount }) => {
    const classes = useStyles();
    const skeletonRows = Array.from({length: rowsCount}, (_, index) => index);
    
    return (
        <div className={styles.skeleton_table}>
            {skeletonRows.map(index => (
                <div key={index} className={styles.skeleton_row}>
                    <Skeleton className={clsx(classes.color, classes.avatar)} variant="circle" width={50} height={50} />
                    <Skeleton className={classes.color} variant="rect" width={'95%'} height={50} />
                </div>
            ))}
        </div>
    );
};

export default TableSkeleton;
