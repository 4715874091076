import React from 'react';
import { Typography } from '@material-ui/core';
import LogoIcon from '../../assets_ash/icons/main_icon.svg';

import styles from './styles.module.scss';

const Logo = () => {
    return (
        <div className={styles.logo}>
            <img
                src={LogoIcon}
                className={styles.logo_icon}
            />
        </div>
    )
};

export default Logo;
