// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_root__1hBos {\n  display: flex; }\n\n.styles_content__2Nm_z {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column; }\n\n.styles_divider__3HyQM {\n  margin-left: 20px;\n  margin-right: 20px;\n  height: 1px;\n  border-bottom: 1px solid #bbb; }\n", ""]);
// Exports
exports.locals = {
	"root": "styles_root__1hBos",
	"content": "styles_content__2Nm_z",
	"divider": "styles_divider__3HyQM"
};
module.exports = exports;
