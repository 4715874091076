import React from 'react';
import clsx from 'clsx';
import { Chip, makeStyles } from '@material-ui/core';
import { IAnnotation, TAnnotationData } from '../../interfaces';
import { useTranslation } from "react-i18next";

import styles from './styles.module.scss';

interface IDynamicTags {
    index: number; 
    faceTags: IAnnotation[];
    onMouseOver: (tagId: number) => void; 
    onMouseOut: (tagId: number) => void; 
    deleteImageAnnotation: (index: number, tagData: TAnnotationData) => void;
}

const useStyles = makeStyles({
    customChip: {
        backgroundColor: '#e0e0e0 !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        marginRight: '0.4rem',
        marginBottom: '0.4rem',
        fontSize: '1rem',
    },
});

const DynamicTags: React.FC<IDynamicTags> = ({ 
    index, 
    faceTags, 
    onMouseOver, 
    onMouseOut, 
    deleteImageAnnotation,
}) => {
    const classes = useStyles();
    const { t } = useTranslation('common');

    return (
        <div className={styles.chip_container}>
            <label
                className={clsx(styles.chip_container_label, {
                    [styles.active]: faceTags?.length
                })}
            >
                {t('upload.second_form.fields.people')}
            </label>
            {faceTags?.length ? (
                faceTags?.map((tag: IAnnotation) => (
                    <Chip
                        key={`${tag.data.name}${Math.random()}`}
                        className={classes.customChip}
                        label={tag.data.name}
                        onMouseOver={() => onMouseOver(tag.data.id)}
                        onMouseLeave={() => onMouseOut(tag.data.id)}
                        onDelete={() => {
                            deleteImageAnnotation(index, tag.data);
                        }}
                    />
                ))
            ) : (
                <span className={styles.chip_container_placeholder}>{t('upload.second_form.fields.faceTagPlaceholder')}</span>
            )}
        </div>
    );
};

export default DynamicTags;
