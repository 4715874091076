import axios from 'axios';
import { getToken, removeToken } from '../helpers/auth';
import { API_URL } from '../config/app';
import i18n from '../i18n/index'
import routes from "../routes";

const api = axios.create({
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
	},
});

api.interceptors.request.use((config) => {
	const token = getToken();
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
},
	(error) => {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response && error.response.status === 401) {
			// "Unauthenticated" exception handling
			removeToken();
		return Promise.reject(i18n.t(`common:error.un_auth`));
	}}
);

export default api;
