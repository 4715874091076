import React from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSelected } from "../../../../store/documents/documentsSlice";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";

const useStyles = makeStyles({
    selectButton : {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        borderRadius: 7,
        marginLeft: 15,
        padding: '20px 25px',
        '@media(max-width: 1200px)' : {
            margin: '10px 10px 0 0 '
        },
        '@media(max-width: 900px)' : {
            margin: '10px 0 0 0 '
        }
    },
    downloadIcon: {
        color: '#007FDD',
        fontSize: 20,
    },
    selectText: {
        fontSize: 16,
        marginLeft: 7
    }
});

const SelectDownload: React.FC = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();

    const setSelect = () => dispatch(setSelected(true));

    return (
        <Button
            variant="outlined"
            className={classes.selectButton}
            onClick={() => setSelect()}
        >
            <CloudDownload className={classes.downloadIcon}/>
            <Typography className={classes.selectText} >
                {t('header.download.select')}
            </Typography>
        </Button>
    )
}

export default SelectDownload;