import React, {
    useState,
    useEffect,
} from 'react';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { createDocuments, selectLoading } from '../../../../store/documents/documentsSlice';
import { showNotification } from '../../../../store/notification/notificationSlice';
import { NOTIFICATION_TYPES } from '../../../../constants';
import { isEmpty, isObject } from '../../../../helpers';
import { formatDate } from '../../../../helpers';
import routes from '../../../../routes';
import documentUploadSchema from '../../../../schemas/documentUploadSchema';
import FormActions from './FormActions';
import FieldActions from './FieldActions';
import FormFields from './FormFields';
import {
    initializeFormikValues, 
    updateFormikValues,
} from '../../helpers';

import styles from '../styles.module.scss';
import {useTranslation} from "react-i18next";

const DocumentUploadForm = ({
    documents,
    groups,
    isGroupsLoading,
    currentDocument,
    annotations,
    setFiles,
    setUploadedFiles,
    deleteUploadedFiles,
    setCurrentDocument,
    deletedDocumentKey,
    setDeletedDocumentKey,
    applyImageAnnotations,
    deleteImageAnnotation,
    onMouseOver,
    onMouseOut,
}) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const history = useHistory();
    const submitLoading = useSelector(selectLoading);
    const [copiedFormValues, setCopiedFormValues] = useState(null);

    const [isSingleDocument, setIsSingleDocument] = useState(false);
    const [isWarningOpen, setIsWarningOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            docs: initializeFormikValues(documents)
        },
        validationSchema: documentUploadSchema,
        onSubmit: async (values, { setSubmitting }) => {
            const { docs } = values;
            let updatedDocuments = [];

            const documents = docs.map(document => {
                const type = document.name.split('.').pop()?.toLowerCase() || '';
                if (document.approximateDate && !document.isDateRange) {
                    return {
                        name: document.name,
                        type: type,
                        approx_date: { from: document.from, to: document.to, period: document.period },
                        place: document.place,
                        group_id: document.group,
                        occasion: document.occasion,
                        files: document.files,
                    };
                } if (document.approximateDate && document.isDateRange) {
                    return {
                        name: document.name,
                        type: type,
                        date_range: { from: document.dateFrom, to: document.dateTo },
                        place: document.place,
                        group_id: document.group,
                        occasion: document.occasion,
                        files: document.files,
                    };
                } else {
                    const exactDate = document.date ? formatDate(document.date) : ''; // Format date to YYYY-MM-DD                   
                    return {
                        name: document.name,
                        type: type,
                        exact_date: exactDate,
                        place: document.place,
                        group_id: document.group,
                        occasion: document.occasion,
                        files: document.files,
                    };
                }
            });

            if (isSingleDocument) {
                updatedDocuments.push(documents[0]);
                let files = [];
                
                documents.forEach((document) => {
                    files = [...files, ...document.files]
                });
                updatedDocuments[0].files = [...files];
            } else {
                updatedDocuments = [...documents];
            }

            try {
                await dispatch(createDocuments(updatedDocuments));
                dispatch(showNotification({
                    message: t('upload.second_form.notifications.document_success'),
                    type: NOTIFICATION_TYPES.SUCCESS
                }));
                await closeAfterSuccess();
                history.push(`${routes.userDocuments.path}?page=1`);
            } catch (e) {
                dispatch(showNotification({
                    message: e.message,
                    type: NOTIFICATION_TYPES.ERROR
                }));
            }

            setSubmitting(false);
        }
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit
    } = formik;

    const combineOrSplitDocuments = () => {
        setIsWarningOpen(true);
    };

    const approveWarning = (e) => {
        setIsSingleDocument(!isSingleDocument);
        setIsWarningOpen(false);
    };

    const declineWarning = () => {
        setIsWarningOpen(false);
        setIsSingleDocument(false);
    };

    const onCopyToClipboard = (copiedValue) => {
        dispatch(showNotification({
            message: `${t('upload.second_form.notifications.copy_one_field', { value: copiedValue})}`,
            type: NOTIFICATION_TYPES.INFO
        }));
    };

    const copyAllFormFields = (values) => {
        const currentFields = values.find(value => value.name === currentDocument);
        setCopiedFormValues(currentFields);
        dispatch(showNotification({
            message: t('upload.second_form.notifications.copy_form'),
            type: NOTIFICATION_TYPES.INFO
        }));
    };

    const assignCopiedValuesToFields = () => {
        const currentIndex = values.docs.findIndex(doc => currentDocument === doc.name);
        const name = values.docs[currentIndex].name;
        const files = values.docs[currentIndex].files;
        let updatedFiles = [...files];
        
        updatedFiles[0] = {
            ...updatedFiles[0],
            document_source: copiedFormValues.files[0].document_source,
            information: copiedFormValues.files[0].information,
            narratives: copiedFormValues.files[0].narratives,
            occasion: copiedFormValues.files[0].occasion,
            people: copiedFormValues.files[0].people,
        };

        if (isSingleDocument) {
            setFieldValue(`docs[${currentIndex}]`, { ...values.docs[currentIndex], files: updatedFiles});
        } else {
            setFieldValue(`docs[${currentIndex}]`, { ...copiedFormValues, name, files: updatedFiles });
        }

        // setCopiedFormValues(null);
    };

    const setFocusToFormWithErrors = (formikValues, formikErrors) => {
        if (formikErrors && formikValues) {
            let index;

            for (let i = 0; i < errors?.docs?.length; i++) {
                if (formikErrors?.docs[i]) {
                    index = i;
                    break;
                }
            }
            const documentWithErrors = formikValues.docs[index].name;
            setCurrentDocument(documentWithErrors);
        }
    };

    const submitForm = async () => {
        if (isObject(errors) && !isEmpty(errors)) {
            setFocusToFormWithErrors(values, errors);
        }
        await handleSubmit();
    };

    const handleCancel = async () => {
        await deleteUploadedFiles();
        await setUploadedFiles([]);
        localStorage.removeItem('documents');
    };

    const closeAfterSuccess = async () => {
        await setUploadedFiles([]);
        await setFiles([]);
        localStorage.removeItem('documents');
    };

    useEffect(() => {
        // update documents if added or removed document
        const updatedFormikValues = updateFormikValues(values, documents, deletedDocumentKey);
        setFieldValue('docs', updatedFormikValues);
        if (deletedDocumentKey === currentDocument) {
            setCurrentDocument(updatedFormikValues[0].name);
        }
    }, [documents]);

    useEffect(() => {
        if (isSingleDocument) {
            // If a single document is selected, assign the data 
            // of the current document to the document with index 0
            const selectedDoc = values.docs.find(doc => doc.name === currentDocument);
            const updatedValues = values.docs.map((doc, index) => {
                if (index === 0) {
                    return {
                        ...doc,
                        date: selectedDoc.date,
                        place: selectedDoc.place,
                        group: selectedDoc.group,
                        approximateDate: selectedDoc.approximateDate,
                        from: selectedDoc.from,
                        to: selectedDoc.to,
                        period: selectedDoc.period,
                    };
                }
                return doc;
            });
            setFieldValue('docs', updatedValues);
        }
    }, [isSingleDocument]);

    useEffect(() => {
        // Updating annotations in formik values
        if (annotations.length) {
            values.docs.forEach((document, documentIndex) => {
                let updatedFiles = [...values.docs[documentIndex].files];
                updatedFiles[0].face_tags = annotations[documentIndex];
                setFieldValue(`docs[${documentIndex}]`, { ...values.docs[documentIndex], files: updatedFiles});
            })
        }
    }, [annotations]);

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <FormActions
                submitLoading={submitLoading}
                handleCancel={handleCancel}
                submitForm={submitForm}
            />

            <FieldActions
                values={values}
                copiedFormValues={copiedFormValues}
                setCopiedFormValues={setCopiedFormValues}
                copyAllFormFields={copyAllFormFields}
                assignCopiedValuesToFields={assignCopiedValuesToFields}
            />

            {currentDocument ? (
                <FormFields
                    values={values}
                    groups={groups}
                    isSingleDocument={isSingleDocument}
                    currentDocument={currentDocument}
                    submitLoading={submitLoading}
                    isGroupsLoading={isGroupsLoading}
                    touched={touched}
                    errors={errors}
                    isWarningOpen={isWarningOpen}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    onCopyToClipboard={onCopyToClipboard}
                    combineOrSplitDocuments={combineOrSplitDocuments}
                    approveWarning={approveWarning}
                    declineWarning={declineWarning}
                    applyImageAnnotations={applyImageAnnotations}
                    deleteImageAnnotation={deleteImageAnnotation}
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                />
            ) : (
                <></>
            )}
        </form>
    )
};

export default DocumentUploadForm;
