import React, { useState } from 'react';
import { FILE_TYPES } from '../../../constants';
import {
    matchFileType,
    matchFileTypeByExtension,
} from '../../../helpers';
import { makeStyles } from '@material-ui/core';
import {
    PictureAsPdf,
    Description
} from '@material-ui/icons';
import { activeAnnotationComparator } from '../../../helpers/annotationHelpers';
import ImageAnnotations from '../../ImageAnnotations';
import { TAnnotationId } from '../../../interfaces';
import { Document, Page, pdfjs } from 'react-pdf';
import styles from '../styles.module.scss';

// Sets the URL for the PDF.js worker script, which handles rendering PDF files in the background for better performance.
// This code uses a CDN to load the appropriate version of pdf.worker.js based on the current PDF.js version.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IFile {
    file_path: string;
    id: string;
    face_tags: any;
};

interface IMediaItem {
    file: IFile;
    handlePreviewOpen: () => void;
    activeAnnotations: TAnnotationId[];
};

const useStyles = makeStyles({
    mediaIcon: {
        fontSize: '100px',
    },
});

const MediaItem: React.FC<IMediaItem> = ({ file, handlePreviewOpen, activeAnnotations }) => {
    const classes = useStyles();
 
    if (matchFileType(matchFileTypeByExtension(file.file_path)) === FILE_TYPES.IMAGE) {
        return (
            <div
                className={styles.media_wrapper}
                onClick={handlePreviewOpen}
            >
                <ImageAnnotations
                    annotations={file.face_tags}
                    img={file.file_path}
                    applyImageAnnotations={null}
                    deleteImageAnnotation={null}
                    mode='view'
                    documentIndex={null}
                    activeAnnotations={activeAnnotations}
                    activeAnnotationComparator={activeAnnotationComparator}
                />
            </div>
        )
    } else if (matchFileType(matchFileTypeByExtension(file.file_path)) === FILE_TYPES.VIDEO) {
        return (
            <div
                className={styles.media_wrapper}
            >
                <video
                    key={file.file_path}
                    className={styles.media_item_video}
                    onClick={handlePreviewOpen}
                >
                    <source src={file.file_path} type={matchFileTypeByExtension(file.file_path)} />
                </video>
            </div>
        );
    } else if (matchFileType(matchFileTypeByExtension(file.file_path)) === FILE_TYPES.PDF) {
        return (
            <div
                className={`${styles.media_wrapper} ${styles.document_wrapper}`}
                onClick={handlePreviewOpen}
            >
                <Document file={file.file_path}>
                    <Page pageNumber={1} />
                </Document>
            </div>
        )
    } else if (matchFileType(matchFileTypeByExtension(file.file_path)) === FILE_TYPES.DOC) {
        return (
            <div
                className={`${styles.media_wrapper} ${styles.document_wrapper}`}
                onClick={handlePreviewOpen}
            >
                <Description
                    className={classes.mediaIcon}
                />
            </div>
        )
    } else {
        return <></>
    }
};

export default MediaItem;
