import React, {useEffect, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useDropzone} from 'react-dropzone';
import {showNotification} from '../../../store/notification/notificationSlice';
import {matchFileType} from "../../../helpers";
import {NOTIFICATION_TYPES} from '../../../constants';
import i18n from "../../../i18n";
import {UploadContext} from '../UploadProvider';

const useFileUploader = () => {
    const {
        files,
        setFiles,
        uploadedFiles,
        setUploadedFiles,
        loading,
        setLoading,
        progress,
        formId,
        setFormId,
        handleUpload,
        deleteUploadedFiles,
    } = useContext(UploadContext);

    const dispatch = useDispatch();

    const getFileMaxSize = (file) => {
        const isVideo = file.type.startsWith('video/');

        let maxFileSize = 20 * 1024 * 1024;
        if (isVideo) {
            maxFileSize = 250 * 1024 * 1024;
        }

        return file.size >= maxFileSize;
    }

    const handleFileDrop = async (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            if (getFileMaxSize(file)) {
                dispatch(showNotification({
                    message: i18n.t('common:notifications.file_size_max_image'),
                    type: NOTIFICATION_TYPES.ERROR
                }));
            }

            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;

                img.onload = () => {
                    const width = img.width;
                    const height = img.height;

                    const modifiedFile = Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        width: width,
                        height: height,
                    });

                    // Modified file with added dimentions
                    // console.log('Modified file:', modifiedFile);
                };
            };

            reader.readAsDataURL(file);
        });

        const selectedFiles = acceptedFiles
            .filter(file => !getFileMaxSize(file))
            .map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );

        await setFiles([...files, ...selectedFiles]);
    };

    const {
        isDragActive,
        isDragAccept,
        isDragReject,
        open,
        getRootProps,
        getInputProps,
    } = useDropzone({
        accept:
            'image/*, video/*, application/pdf, application/msword,' +
            '.doc, .docx, .pdf, .xls, .xlsx, application/msword, application/vnd.ms-excel,' +
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        onDrop: handleFileDrop,
    });

    useEffect(() => {
        // checking if upload maked from second upload form
        if (files && files.length && formId && formId === 2) {
            try {
                handleUpload();
            } catch (e) {
                dispatch(
                    showNotification({
                        message: e.message,
                        type: NOTIFICATION_TYPES.ERROR,
                    })
                );
                setLoading(false);
            }
        }
    }, [files, formId]);

    useEffect(() => {
        localStorage.setItem('documents', JSON.stringify(uploadedFiles));

        return () => {
            localStorage.removeItem('documents');
        }
    }, [uploadedFiles]);

    return {
        files,
        uploadedFiles,
        progress,
        formId,
        setFormId,
        setFiles,
        setUploadedFiles,
        loading,
        setLoading,
        handleUpload,
        deleteUploadedFiles,
        isDragActive,
        isDragAccept,
        isDragReject,
        open,
        getRootProps,
        getInputProps,
    };
}

export default useFileUploader;
