import React, { useState, useEffect } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { 
	selectNotificationMessage, 
	selectShowNotification, 
	selectNotificationType, 
	closeNotification,
	TNotificationType
} from '../../store/notification/notificationSlice';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';

const Notification = () => {
	const [open, setOpen] = useState(false);

	const message = useSelector(selectNotificationMessage);
	const showNotification = useSelector(selectShowNotification);
	const notificationType: TNotificationType = useSelector(selectNotificationType);

	const dispatch: AppDispatch = useDispatch();

	const handleCloseNotification = () => {
		dispatch(closeNotification());
	};

	useEffect(() => {
		setOpen(showNotification);
	}, [showNotification]);

	return (
		<Snackbar
			open={open}
			autoHideDuration={5000}
			onClose={handleCloseNotification}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<Alert
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						size="small"
						onClick={() => {
							setOpen(false);
						}}
					>
						<CloseIcon fontSize="inherit" />
					</IconButton>
				}
				className="mb-1 mt-1"
				color={notificationType}
			>
				{message}
			</Alert>
		</Snackbar>
	);
}

export default Notification;
