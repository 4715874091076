import React from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {Link, Redirect} from "react-router-dom";
import { logout, selectUserData, selectUserRole } from "../../../store/user/userSlice";
import { showNotification } from "../../../store/notification/notificationSlice";
import routes from "../../../routes";
import { NOTIFICATION_TYPES } from "../../../constants";
import { UserRoles } from "../../../assets_ash/globalTypes";
import {
    ListItemText,
    makeStyles,
    MenuItem,
} from "@material-ui/core";
import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import { StyledMenu } from "../../Select";
import UserIcon from "../../../assets_ash/images/user_icon.svg";

const useStyles = makeStyles({
    userContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0 5px',
        borderLeft: '1px solid #D8D8D8',
        borderRight: '1px solid #D8D8D8',
        padding: '0 10px 0 20px',
        cursor: "pointer",
        '&>img': {
            width: '50px'
        }
    },
    svgIcon: {
        '& path': {
            color: '#007FDD',
        },
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    emailText: {
        fontSize: '10px',
        margin: 0,
    },
    nameText: {
        fontSize: "16px",
        margin: 0
    }
})

const User = () => {
    const {t} = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const user = useSelector(selectUserData);
    const userRole = useSelector(selectUserRole);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleLogout = async () => {
        try {
            dispatch(logout());
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    return (
        <>
            <div
                className={classes.userContainer}
                onClick={handleClick}
            >
                <img src={UserIcon} alt="user"/>
                <KeyboardArrowDownOutlined className={classes.svgIcon}/>
            </div>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {!!user && (
                    <MenuItem classes={{root: classes.menuItem}}>
                        <p className={classes.nameText}>
                            {`${user?.first_name} ${user?.last_name}`}
                        </p>
                        <p className={classes.emailText}>
                            {user?.email}
                        </p>
                    </MenuItem>
                )}
                <Link to={routes.userDocuments.path}>
                    <MenuItem>
                        <ListItemText primary={t('header.menu_list.my_uploads')}/>
                    </MenuItem>
                </Link>
                {(userRole === UserRoles.ADMIN || userRole === UserRoles.GROUP_OWNER) && (
                    <Link to={routes.adminDashboard.path}>
                        <MenuItem>
                            <ListItemText primary={t('header.menu_list.admin')}/>
                        </MenuItem>
                    </Link>
                )}
                <MenuItem>
                    <ListItemText
                        primary={t('header.menu_list.logout')}
                        onClick={(e) => {
                            e.preventDefault();
                            handleLogout();
                        }}
                    />
                </MenuItem>
            </StyledMenu>
        </>
    )
};

export default User;