import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
    getUsersByRole,
    selectUsersByRole,
    selectLoadingUsersByRole,
    TUserRole,
} from '../../../store/admin/adminSlice';
import {
    getGroupsList,
    createGroup,
    updateGroup,
    selectGroupsActionLoading
} from '../../../store/groups/groupSlice';
import { IGroupOwner } from '../../../interfaces';
import { showNotification } from '../../../store/notification/notificationSlice';
import { 
    ACTION_TYPES, 
    NOTIFICATION_TYPES, 
    USER_ROLES 
} from '../../../constants';
import createGroupSchema from '../../../schemas/createGroupSchema';
import { BeatLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    FormControl,
    InputLabel,
    Select,
    FormControlLabel,
    IconButton,
    TextField,
    Typography,
    MenuItem,
    FormHelperText,
    CircularProgress,
} from '@material-ui/core';
import { ListAltOutlined } from '@material-ui/icons';
import { CancelRounded } from '@material-ui/icons';

import styles from './styles.module.scss';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        borderRadius: 20,
    },
    title: {
        fontSize: '30px',
        fontWeight: 700,
        color: '#000000',
        textAlign: 'center',
        marginLeft: '15px',
    },
    content: {
        minWidth: '500px',
        maxWidth: '800px',
        padding: '20px 60px',
        borderRadius: '70px',
    },
    formComponent: {
        marginBottom: '15px',
    },
    checkbox: {
        color: '#4191ff',
    },
    checkboxLabel: {
        color: '#000000',
    },
    addMoreOwnersButton: {
        fontWeight: 600,
    },
    fromToInput: {
        maxWidth: '170px',
    },
    icon: {
        fontSize: '35px',
        color: '#ffffff',
    },
    cancelButton: {
        marginRight: '10px',
        width: '100%',
    },
    createButton: {
        width: '100%',
    },
    removeOwnerButton: {
        margin: 0,
        padding: 0,
        marginTop: '10px',
        marginLeft: '10px',
    },
    formControl: {
        width: '93%',
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    helperText: {
        marginBottom: '15px',
    }
}));

interface ICreateGroup {
    open: boolean;
    actionType: string;
    group?: any;
    handleClose: () => void;
};

interface IFormValues {
    groupName: string;
    withOwners: boolean;
    owners: IGroupOwner[];
    details: string;
}

const CreateGroup: React.FC<ICreateGroup> = ({
    open,
    actionType,
    handleClose,
    group = null,
}) => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();
    const isUsersLoading = useSelector(selectLoadingUsersByRole);
    const isGroupsActionLoading = useSelector(selectGroupsActionLoading);
    const users = useSelector(selectUsersByRole);
    
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const submitButton = actionType === ACTION_TYPES.CREATE ? ACTION_TYPES.CREATE : ACTION_TYPES.UPDATE;

    const formik = useFormik<IFormValues>({
        initialValues: {
            groupName: '',
            withOwners: false,
            owners: [{ user_id: '', from: 0, to: 0 }],
            details: '',
        },
        validationSchema: createGroupSchema,
        onSubmit: async ({ groupName, owners, withOwners }) => {
            if (!withOwners) {
                owners = [];
            }
            
            try {
                if (actionType === ACTION_TYPES.CREATE) {
                    await dispatch(createGroup(groupName, owners));
                    dispatch(showNotification({
                        message: 'Group successfully created',
                        type: NOTIFICATION_TYPES.SUCCESS
                    }));    
                } else if (actionType === ACTION_TYPES.UPDATE) {
                    await dispatch(updateGroup(groupName, group.id, owners));
                    dispatch(showNotification({
                        message: 'Group successfully updated',
                        type: NOTIFICATION_TYPES.SUCCESS
                    }));
                }
                handleReset();
                dispatch(getGroupsList(1, true));
            } catch (e) {
                dispatch(showNotification({
                    message: (e as Error).message,
                    type: NOTIFICATION_TYPES.ERROR
                }));
            }
        },
    });

    const handleAddOwner = () => {
        formik.setFieldValue(
            'owners', 
            [...formik.values.owners, { user_id: '', from: 0, to: 0 }]
        );
    };

    const handleRemoveOwner = (indexToRemove: number) => {
        formik.setFieldValue(
            'owners',
            formik.values.owners.filter((_, index) => index !== indexToRemove)
        );
    };

    const handleReset = () => {
        formik.resetForm();
        handleClose();
    };
    
    useEffect(() => {
        formik.resetForm();
    }, [actionType]);

    useEffect(() => {
        if (group && actionType === ACTION_TYPES.UPDATE) {
            formik.setValues({
                groupName: group.name,
                withOwners: Boolean(group.owners.length),
                owners: group.owners,
                details: '',
            });
        }
    }, [group, open, actionType]);

    useEffect(() => {
        if (dropdownOpen) {
            try {
                dispatch(getUsersByRole(USER_ROLES.GROUP_OWNER as TUserRole));
            } catch (e) {
                dispatch(showNotification({
                    message: (e as Error).message,
                    type: NOTIFICATION_TYPES.ERROR
                }));
            }
        }
    }, [dropdownOpen]);

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.dialogPaper }}
            >
                <div className={styles.dialog_content}>
                    <section className={styles.main_icon}>
                        <ListAltOutlined className={clsx(classes.icon, 'color-neutral-second')} />
                    </section>
                    <Typography className={classes.title}>
                        {actionType === ACTION_TYPES.CREATE ? t('admin.group_table.create_group.create_button') : t('admin.group_table.create_group.update_button')}
                    </Typography>
                </div>

                <form onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                }}>
                    <DialogContent className={classes.content}>
                        <TextField
                            id="groupName"
                            name="groupName"
                            className={classes.formComponent}
                            label={t('admin.group_table.create_group.fields.group_name')}
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled={isGroupsActionLoading}
                            value={formik.values.groupName}
                            onChange={formik.handleChange}
                            error={formik.touched.groupName && Boolean(formik.errors.groupName)}
                            helperText={formik.touched.groupName && formik.errors.groupName}
                        />

                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <FormControlLabel
                                className={classes.checkboxLabel}
                                control={
                                    <Checkbox
                                        className={classes.checkbox}
                                        checked={formik.values.withOwners}
                                        onChange={formik.handleChange}
                                        name="withOwners"
                                        color="secondary"
                                        disabled={isGroupsActionLoading}
                                    />
                                }
                                label={t('admin.group_table.create_group.fields.group_owner')}
                            />

                            <Button
                                color="secondary"
                                disabled={!formik.values.withOwners || isGroupsActionLoading}
                                onClick={handleAddOwner}
                                type="button"
                            >
                                <Typography className={classes.addMoreOwnersButton}>{t('admin.group_table.create_group.fields.more_owner')}</Typography>
                            </Button>
                        </div>

                        {formik.values.withOwners &&
                            formik.values.owners.map((owner, index) => (
                                <div key={index} className="d-flex flex-column align-items-center justify-content-between">
                                    <div className={styles.owners}>
                                        <FormControl
                                            size="small"
                                            variant="outlined"
                                            className={classes.formControl}
                                        >
                                            <InputLabel
                                                htmlFor="filled-age-native-simple"
                                            >
                                                {t('admin.group_table.create_group.fields.owner_name')}
                                            </InputLabel>
                                            <Select
                                                labelId={`owner-user_id-${index}`}
                                                id={`owner-user_id-${index}`}
                                                name={`owners[${index}].user_id`}
                                                label={t('admin.group_table.create_group.fields.owner_name')}
                                                variant="outlined"
                                                value={owner.user_id}
                                                onOpen={() => setDropdownOpen(true)}
                                                onClose={() => setDropdownOpen(false)}
                                                onChange={formik.handleChange}
                                                inputProps={{
                                                    id: `owner-user_id-${index}`,
                                                    name: `owners[${index}].user_id`,
                                                }}
                                                disabled={isGroupsActionLoading}
                                                error={formik.touched.owners?.[index]?.user_id
                                                    && Boolean((formik.errors.owners?.[index] as IGroupOwner)?.user_id)}

                                            >
                                                {actionType === ACTION_TYPES.UPDATE && owner.name && !dropdownOpen && (
                                                    <MenuItem key={owner.name} value={owner.user_id}>
                                                        {owner.name}
                                                    </MenuItem>
                                                )}

                                                {!isUsersLoading && users && users.map(user => (
                                                    <MenuItem key={user.id} value={user.id}>
                                                        {`${user.first_name} ${user.last_name}`}
                                                    </MenuItem>
                                                ))}

                                                <MenuItem
                                                    className={classes.loader}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        return;
                                                    }}
                                                >
                                                    {isUsersLoading && <BeatLoader color={'#4191ff'} />}
                                                </MenuItem>
                                            </Select>
                                            <FormHelperText
                                                className={classes.helperText}
                                                error={formik.touched.owners?.[index]?.user_id
                                                    && Boolean((formik.errors.owners?.[index] as IGroupOwner)?.user_id)}
                                            >
                                                {formik.touched.owners?.[index]?.user_id
                                                    && (formik.errors.owners?.[index] as IGroupOwner)?.user_id}
                                            </FormHelperText>
                                        </FormControl>

                                        <IconButton
                                            aria-label="remove owner"
                                            className={classes.removeOwnerButton}
                                            disabled={isGroupsActionLoading}
                                            onClick={() => handleRemoveOwner(index)}
                                        >
                                            <CancelRounded />
                                        </IconButton>
                                    </div>

                                    <div className="d-flex align-items-start justify-content-between mb-3 w-100">
                                        <TextField
                                            id={`owner-from-${index}`}
                                            name={`owners[${index}].from`}
                                            type="number"
                                            className={classes.fromToInput}
                                            label={t('admin.group_table.create_group.fields.year_from')}
                                            variant="outlined"
                                            size="small"
                                            value={owner.from}
                                            disabled={isGroupsActionLoading}
                                            onChange={formik.handleChange}
                                            error={formik.touched.owners?.[index]?.from
                                                && Boolean((formik.errors.owners?.[index] as IGroupOwner)?.from)}
                                            helperText={formik.touched.owners?.[index]?.from
                                                && (formik.errors.owners?.[index] as IGroupOwner)?.from}
                                        />

                                        <span className={styles.field_divider}>&#8722;</span>

                                        <TextField
                                            id={`owner-to-${index}`}
                                            name={`owners[${index}].to`}
                                            type="number"
                                            className={classes.fromToInput}
                                            label={t('admin.group_table.create_group.fields.year_to')}
                                            variant="outlined"
                                            size="small"
                                            value={owner.to}
                                            disabled={isGroupsActionLoading}
                                            onChange={formik.handleChange}
                                            error={formik.touched.owners?.[index]?.to
                                                && Boolean((formik.errors.owners?.[index] as IGroupOwner)?.to)}
                                            helperText={formik.touched.owners?.[index]?.to
                                                && (formik.errors.owners?.[index] as IGroupOwner)?.to}
                                        />
                                    </div>
                                </div>
                            ))}

                        <TextField
                            id="details"
                            name="details"
                            className={classes.formComponent}
                            disabled={isGroupsActionLoading}
                            label={t('admin.group_table.create_group.fields.details')}
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={formik.values.details}
                            onChange={formik.handleChange}
                        />
                    </DialogContent>
                    <div className={styles.dialog_actions}>
                        <Button
                            className={classes.cancelButton}
                            disabled={isGroupsActionLoading}
                            color="secondary"
                            variant="outlined"
                            type="button"
                            onClick={handleReset}
                        >
                            {t('generics.cancel')}
                        </Button>
                        <Button
                            className={classes.createButton}
                            disabled={isGroupsActionLoading}
                            color="secondary"
                            variant="contained"
                            type="submit"
                        >
                            {isGroupsActionLoading ? <CircularProgress size={25} color='inherit' /> : t(`generics.${submitButton}`)}
                        </Button>
                    </div>

                </form>
            </Dialog>
        </div>
    );
}

export default CreateGroup;
