// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_sort_by_group___SGuq {\n  display: flex;\n  flex-direction: column;\n  padding: 10px 0; }\n  .styles_sort_by_group_title__3oVJ1 p {\n    margin: 0; }\n  .styles_sort_by_group_title__3oVJ1 {\n    margin-bottom: 10px; }\n  .styles_sort_by_group_radio__100wc {\n    display: flex;\n    flex-direction: row; }\n  .styles_sort_by_group_radio__100wc > div {\n    flex: 50% 1;\n    margin-right: 20px; }\n\n.styles_sort_arrows__2NTyg {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-evenly;\n  align-items: center; }\n  .styles_sort_arrows_container__282vE {\n    display: flex;\n    flex-direction: column; }\n    .styles_sort_arrows_container__282vE img {\n      width: 12px; }\n", ""]);
// Exports
exports.locals = {
	"sort_by_group": "styles_sort_by_group___SGuq",
	"sort_by_group_title": "styles_sort_by_group_title__3oVJ1",
	"sort_by_group_radio": "styles_sort_by_group_radio__100wc",
	"sort_arrows": "styles_sort_arrows__2NTyg",
	"sort_arrows_container": "styles_sort_arrows_container__282vE"
};
module.exports = exports;
