import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { requestToEdit, selectLoading } from "../../../store/documents/documentsSlice";
import { getNoAuthGroupList, selectGroupsLoading, selectNoAuthGroups } from "../../../store/groups/groupSlice";
import { showNotification } from "../../../store/notification/notificationSlice";
import { DATE_OPTIONS, NOTIFICATION_TYPES } from "../../../constants";
import { matchFileType, matchFileTypeByExtension } from '../../../helpers';
import { FILE_TYPES } from '../../../constants';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    makeStyles,
    MenuItem,
    Select,
    TextField, Typography
} from "@material-ui/core";
import moment from 'moment';
import DynamicTags from '../../../components/DynamicTags';
import { comparePropsObjects } from "../../../helpers/objectHelpers";
import { getYearsToNow } from "../../../helpers/dateTime";
import routes from "../../../routes";
import { IAnnotation, PerFile, TAnnotationData, TAnnotationId } from "../../../interfaces";
import { CompareDocument, RevisionDocument, RevisionFile } from "../../../assets_ash/globalTypes";
import User from "../../../components/Header/User";
import LanguageChange from "../../../components/Header/LanguageChange";
import TextMaskInput from "../../DocumentsUpload/UploadFormSecond/TextMaskInput";
import { Close, Done } from "@material-ui/icons";

const useStyles = makeStyles({
    sidebar: {
        width: '30%',
        boxShadow: '-2px 0px 10px #00000029',
        padding: '27px 17px'
    },
    form: {
        width: '100%',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    actionButton: {
        marginRight: 15,
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: '25px 0 20px',
    },
    title: {
        fontWeight: 700,
        marginRight: '50px',
    },
    formInput: {
        width: '100%',
    },
    select: {
        width: '100%',
    },
    fieldContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        marginBottom: '30px'
    },
    twoFiledContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        margin: '20px 0 30px',
        justifyContent: 'space-between',
        '& div': {
            flex: '50%',
            maxWidth: 220
        },
    },
    twoFieldSelect: {
        flex: '50%',
    },
    twoFieldDatePicker: {
        flex: '50%',
    },
    icon: {
        fill : '#ffffff'
    }
});

type Sidebar = {
    document: any,
    currentFile: PerFile | undefined,
    annotations: IAnnotation[][],
    deleteImageAnnotation: (documentIndex: number, data: TAnnotationData) => void,
    onMouseOver: (id: TAnnotationId) => void,
    onMouseOut: () => void,
}

type Exact = 'exact' | 'notExact' | ''
type DateRange = 'dateRange' | 'notDateRange'

const useDocumentMapperForFormik = (document: RevisionDocument) => {

    const documentFiles: RevisionFile[] = document?.files?.reduce((result: RevisionFile[], file: RevisionFile) => {
        result.push({
            id: file?.id ?? '',
            file_path: file?.file_path ?? '',
            information: file?.information ?? '',
            narratives: file?.narratives ?? '',
            people: file?.people ?? '',
            document_source: file.document_source ?? '',
            face_tags: file.face_tags ?? [],
        });
        return result;
    }, []);

    return useMemo(() => (
        document && {
            id: document.id,
            name: document.name ?? '',
            date: document.date && (document.approx_date || document.date_range) ? '' : moment(document.date, "YYYY-MM-DD").format("MM/DD/YYYY"),
            approx_date: document.approx_date ?? {from: '', to: '', period: ''},
            date_range: document.date_range ?? {from: '', to: ''},
            place: document.place ?? '',
            group_id: document.group_id,
            people: document.people ?? '',
            privacy_level: document.privacy_level ?? '',
            created_at: document.created_at,
            updated_at: document.updated_at,
            occasion: document.occasion ?? '',
            files: documentFiles,
        }
    ), [document])
};

const Sidebar: React.FC<Sidebar> = ({
                                        document,
                                        currentFile,
                                        annotations,
                                        deleteImageAnnotation,
                                        onMouseOver,
                                        onMouseOut
                                    }) => {
    const {t} = useTranslation('common');
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const doc = useDocumentMapperForFormik(document);

    const submitLoading = useSelector(selectLoading);
    const isGroupsLoading = useSelector(selectGroupsLoading);
    const groups = useSelector(selectNoAuthGroups);

    const [isExact, setIsExact] = useState<Exact>('exact');
    const [isDateRange, setIsDateRange] = useState<DateRange>('notDateRange');

    const approxDate = useMemo(getYearsToNow, []);

    const currentLanguage = localStorage.getItem('lang') ?? 'hy'

    const currenLanguagePeriod = useMemo(() => {
        return DATE_OPTIONS.period[currentLanguage as keyof typeof DATE_OPTIONS.period]
    }, [currentLanguage])

    const getGroups = async () => {
        try {
            await dispatch(getNoAuthGroupList(1));
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    const handleSubmitChanges = async (id: string, changes: CompareDocument) => {
        try {
            await dispatch(requestToEdit(id, changes));
            dispatch(showNotification({
                message: `${t('notifications.suggest_edit')}`,
                type: NOTIFICATION_TYPES.SUCCESS
            }));
        } catch (e) {
            dispatch(showNotification({
                message: e.message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    const formik = useFormik({
        initialValues: doc,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSubmitChanges(doc.id, comparePropsObjects(doc, values));
            history.push(routes.home.path);
        }
    });

    const {
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        errors,
        touched
    } = formik

    const findObjectIndex = (array: RevisionFile[], searchObject: RevisionFile) => array.findIndex((obj: RevisionFile) => JSON.stringify(obj) === JSON.stringify(searchObject));

    const handleChangeExact = () => {
        setIsExact("exact");
        setIsDateRange('notDateRange')
    }

    const handleCancel = () => history.push(routes.home.path);

    useEffect(() => {
        getGroups();
    }, []);

    useEffect(() => {
        if (!!doc) {
            if(isDateRange === 'dateRange') {
                setFieldValue('approx_date', {from: doc.approx_date.from, to: doc.approx_date.to, period: doc.approx_date.period })
            } else if (isDateRange === 'notDateRange') {
                setFieldValue('date_range', {from: doc.date_range.from, to: doc.date_range.to })
            }
        }
    }, [isDateRange]);

    useEffect(() => {
        // Updating annotations in formik values
        if (annotations.length) {
            let updatedFiles = [...values.files];

            values.files.forEach((file, fileIndex) => {
                updatedFiles[fileIndex].face_tags = annotations[fileIndex];
                setFieldValue(`files`, updatedFiles);
            })
        }
    }, [annotations]);

    return (
        <section className={classes.sidebar}>
            <form className={classes.form}>
                <section className={classes.actions}>
                    <Button
                        className={classes.actionButton}
                        variant="outlined"
                        size='large'
                        disabled={submitLoading}
                        onClick={handleCancel}
                    >
                        <Close color={"error"}/>
                    </Button>
                    <Button
                        className={classes.actionButton}
                        variant='contained'
                        size='large'
                        color="secondary"
                        disabled={submitLoading}
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        {submitLoading ? <CircularProgress size={20} color='inherit'/> : <Done className={classes.icon}/>}
                    </Button>

                    <User/>
                    <LanguageChange/>
                </section>

                <section className={classes.titleContainer}>
                    <h3 className={classes.title}>{t('suggest_edit.title')}</h3>
                </section>

                {!!values && (
                    <>
                        <FormControlLabel
                            control={
                                <Checkbox onChange={() => {
                                    isExact === 'notExact' ? handleChangeExact() : setIsExact('notExact')
                                }}/>
                            }
                            label={t('suggest_edit.fields.not_exact_date')}
                        />
                        {isExact === 'exact' && (
                            <div className={classes.fieldContainer}>
                                <TextField
                                    id="date"
                                    name="date"
                                    className={classes.formInput}
                                    label={t('suggest_edit.fields.date')}
                                    type="text"
                                    autoComplete="off"
                                    variant="outlined"
                                    disabled={submitLoading}
                                    value={values.date}
                                    onChange={handleChange}
                                    error={touched.date && Boolean(errors.date)}
                                    helperText={touched.date && errors.date}
                                    InputProps={{
                                        inputComponent: TextMaskInput
                                    }}
                                />
                            </div>
                        )}

                        {isExact === 'notExact' && (
                            <>
                                <section className={classes.twoFiledContainer}>
                                    <FormControl
                                        className={classes.select}
                                        variant='outlined'
                                        disabled={isDateRange === 'dateRange'}
                                    >
                                        <Select
                                            name={`approx_date.from`}
                                            value={values.approx_date.from}
                                            onChange={handleChange}
                                        >
                                            {!!approxDate && approxDate.map(date => <MenuItem key={date} value={date}>{date}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        className={classes.select}
                                        variant='outlined'
                                        disabled={isDateRange === 'dateRange'}
                                    >
                                        <Select
                                            name='approx_date.to'
                                            value={values.approx_date.to}
                                            onChange={handleChange}
                                        >
                                            {!!approxDate && approxDate.map(date => <MenuItem key={date} value={date}>{date}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </section>

                                <section className={classes.fieldContainer}>
                                    <FormControl
                                        className={classes.select}
                                        variant='outlined'
                                        disabled={isDateRange === 'dateRange'}
                                    >
                                        <Select
                                            name='approx_date.period'
                                            value={values.approx_date.period}
                                            onChange={handleChange}
                                        >
                                            {currenLanguagePeriod && currenLanguagePeriod.map(period => <MenuItem
                                                key={period.name} value={period.value}>{period.name}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </section>

                                <FormControlLabel
                                    control={
                                        <Checkbox onChange={() => {
                                            isExact === 'notExact' && isDateRange === 'notDateRange' ? setIsDateRange('dateRange') : setIsDateRange('notDateRange')
                                        }}/>
                                    }
                                    label={t('suggest_edit.fields.date_range')}
                                />

                                {isExact === 'notExact' && isDateRange === 'dateRange' && (
                                    <>
                                        <section className={classes.twoFiledContainer}>
                                            <TextField
                                                id="date-range-from"
                                                name='date_range.from'
                                                value={values.date_range.from}
                                                className={classes.twoFieldDatePicker}
                                                label={t('suggest_edit.fields.date_from')}
                                                type="date"
                                                autoComplete="off"
                                                variant="outlined"
                                                onChange={handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />

                                            <TextField
                                                id="date-range-to"
                                                name='date_range.to'
                                                value={values.date_range.to}
                                                className={classes.twoFieldDatePicker}
                                                label={t('suggest_edit.fields.date_to')}
                                                type="date"
                                                autoComplete="off"
                                                variant="outlined"
                                                onChange={handleChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </section>
                                    </>
                                )}
                            </>
                        )}


                        <div className={classes.fieldContainer}>
                            <TextField
                                id="place"
                                className={classes.formInput}
                                label={t('suggest_edit.fields.location')}
                                type="text"
                                autoComplete="off"
                                variant="outlined"
                                disabled={submitLoading}
                                value={values.place}
                                onChange={handleChange}
                                error={touched.place && Boolean(errors.place)}
                                helperText={touched.place && errors.place}
                            />
                        </div>
                        <div className={classes.fieldContainer}>
                            <FormControl
                                className={classes.select}
                                variant='outlined'
                                disabled={submitLoading}
                            >
                                <Select
                                    name='group_id'
                                    value={values.group_id ?? ''}
                                    onChange={handleChange}
                                    error={touched.group_id && Boolean(errors.group_id)}
                                >
                                    {isGroupsLoading && (
                                        <MenuItem>
                                            <BeatLoader/>
                                        </MenuItem>
                                    )}
                                    {!isGroupsLoading && groups && groups.map(group => {
                                        return (
                                            <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText
                                    error={touched.group_id && Boolean(errors.group_id)}
                                >
                                    {touched.group_id && errors.group_id}
                                </FormHelperText>
                            </FormControl>
                        </div>

                        <div className={classes.fieldContainer}>
                            <TextField
                                id="occasion"
                                className={classes.formInput}
                                label={t('suggest_edit.fields.occasion')}
                                type="text"
                                variant="outlined"
                                disabled={submitLoading}
                                value={values.occasion}
                                onChange={handleChange}
                            />
                        </div>

                        {values?.files.filter((file: RevisionFile) => file.id === currentFile?.id).map((file: RevisionFile, _index, array) => {
                            const index = findObjectIndex(values.files, array[0]);
                            return (
                                <section key={index}>
                                    <h3 style={{
                                        fontWeight: 700,
                                        marginBottom: '1rem'
                                    }}>{t('suggest_edit.sub_title')}</h3>
                                    <div className={classes.fieldContainer}>
                                        {matchFileType(matchFileTypeByExtension(file.file_path)) === FILE_TYPES.IMAGE ? (
                                            <section>
                                                <DynamicTags
                                                    index={index}
                                                    faceTags={file.face_tags}
                                                    onMouseOver={onMouseOver}
                                                    onMouseOut={onMouseOut}
                                                    deleteImageAnnotation={deleteImageAnnotation}
                                                />
                                                <Typography style={{marginTop: 5}}>
                                                    {t('upload.second_form.fields.people_info')}
                                                </Typography>
                                            </section>
                                        ) : (
                                            <TextField
                                                id="people"
                                                name={`files[${index}].people`}
                                                className={classes.formInput}
                                                label={t('suggest_edit.fields.people')}
                                                type="text"
                                                autoComplete="off"
                                                variant="outlined"
                                                disabled={submitLoading}
                                                value={file.people}
                                                onChange={handleChange}
                                            />
                                        )}

                                    </div>
                                    <div className={classes.fieldContainer}>
                                        <TextField
                                            id="information"
                                            name={`files[${index}].information`}
                                            className={classes.formInput}
                                            label={t('suggest_edit.fields.information')}
                                            type="text"
                                            autoComplete="off"
                                            variant="outlined"
                                            disabled={submitLoading}
                                            value={file.information}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className={classes.fieldContainer}>
                                        <TextField
                                            id="document_source"
                                            name={`files[${index}].document_source`}
                                            className={classes.formInput}
                                            label={t('suggest_edit.fields.document_source')}
                                            type="text"
                                            autoComplete="off"
                                            variant="outlined"
                                            disabled={submitLoading}
                                            value={file.document_source}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className={classes.fieldContainer}>
                                        <TextField
                                            id="narratives"
                                            name={`files[${index}].narratives`}
                                            className={classes.formInput}
                                            label={t('suggest_edit.fields.memory')}
                                            type="text"
                                            autoComplete="off"
                                            variant="outlined"
                                            disabled={submitLoading}
                                            value={file.narratives}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </section>
                            )
                        })}
                    </>
                )}
            </form>
        </section>
    )
};

export default Sidebar;