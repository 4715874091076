import { 
    createSlice, 
    PayloadAction 
} from '@reduxjs/toolkit';
import { 
    AppDispatch, 
    AppThunk, 
    RootState 
} from '..';
import api from '../../api';

interface IFilesUpload {
    files: any,
    loading: boolean,
    error: string | null,
}

const initialState: IFilesUpload = {
    files: [],
    loading: false,
    error: null,
};

const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        setFiles: (state, action: PayloadAction<any>) => {
            state.files = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            // state.isAuthenticated = action.payload;
        },
    },
});

export const {
    setFiles,
    setLoading,
    setError,
    // setIsAuthenticated,
} = uploadSlice.actions;

export const selectIsLoading = (state: RootState) => state.upload.loading;
// export const selectIsAuthenticated = (state: RootState) => state.user.isAuthenticated;
export const selectFiles = (state: RootState) => state.upload.files;
// export const selectUserData = (state: RootState) => state.user.userData;

export default uploadSlice;