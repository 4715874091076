
export const downloadCurrentFile = (file: any) => {
    const link = document.createElement('a');
    link.href = file.file_path;
    link.setAttribute('download', '');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const buildQueryString = (params: any) => {
    const queryParamKeys = Object.keys(params);
    return queryParamKeys
        .filter(paramKey => (paramKey !== 'id') && (paramKey !== 'page'))
        .map(paramKey => `${paramKey}=${params[paramKey]}`)
        .join('&');
};

export const getCurrentItemIndex = (items: any[], currentItemId: string) => {
    return items.findIndex((item => item.id === currentItemId));
};

export const getListItemIndex = (items: any[], itemsPerPage: number, pageNumber: number, itemId: string) => {
    const firstItemIndex = (pageNumber - 1) * itemsPerPage;
    const currentItemIndex = getCurrentItemIndex(items, itemId);

    if (pageNumber === 1) {
        return currentItemIndex;
    } else {
        return firstItemIndex + currentItemIndex;
    }
};

export const getCurrentPage = (itemIndex: number, perPage: number) => {
    return Math.ceil(itemIndex / perPage); // вычисление номера страницы
};
