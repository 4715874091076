import React, { Component, useEffect, useState } from "react";
import Annotation from "react-image-annotation";
import { RectangleSelector } from "react-image-annotation/lib/selectors";
import Box from './Box';
import { HighlightOff } from "@material-ui/icons";

import styles from './styles.module.scss';

const ImageAnnotations = ({
    annotations,
    img,
    applyImageAnnotations,
    deleteImageAnnotation,
    mode = 'edit',
    documentIndex,
    activeAnnotations,
    activeAnnotationComparator,
}) => {
    const [annotation, setAnnotation] = useState({});

    const onSubmit = annotation => {
        if (!annotation.data) {
            return;
        }
        const { geometry, data } = annotation;

        setAnnotation({});
        applyImageAnnotations(documentIndex, geometry, data);
    };

    function renderSelector({ annotation, active }) {
        /* drag and drop selector */
        const { geometry } = annotation;
        if (!geometry || mode === 'view') { 
            return null;
        }

        return (
            <Box
                geometry={geometry}
                style={{
                    background: "rgba(255, 255, 255, 0.5)",
                    border: "solid 1px #ffffff",
                }}
            >
            </Box>
        );
    }

    function renderHighlight({ annotation, active }) {
        // Showing annotation select box

        const { geometry } = annotation;
        if (!geometry) return null;

        function getRenderHighlightBoxStyles(mode, active) {
            let borderStyle;
            let boxShadowStyle;

            if (mode === 'edit') {
                borderStyle = active ? "solid 1px yellow" : "solid 1px white";
            } else if (mode === 'view') {
                borderStyle = active ? "solid 1px white" : "none";
            }

            boxShadowStyle = active ? "0 0 20px 20px rgba(255, 255, 255, 0.3) inset" : "none";

            return {
                border: borderStyle,
                boxShadow: boxShadowStyle,
            }
        }

        return (
            <Box
                key={annotation.data.id}
                geometry={geometry}
                style={getRenderHighlightBoxStyles(mode, active)}
            >
            </Box>
        );
    }

    function renderContent({ annotation }) {
        // Showing annotation with data
        const { geometry, data } = annotation;

        const deleteAnnotation = () => {
            deleteImageAnnotation(documentIndex, data);
        };

        const labelTopStyle = (mode === 'edit') ? `${geometry.y + geometry.height}%` : `${geometry.y + geometry.height + 1}%`

        return (
            <div
                key={annotation.data.id}
                style={{
                    background: "rgba(0, 0 ,0, 0.7)",
                    color: "#ffffff",
                    padding: 10,
                    position: "absolute",
                    fontSize: 14,
                    borderRadius: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: 'box-shadow: 5px 5px 9px -5px rgba(0,0,0,0.1)',
                    left: `${geometry.x}%`,
                    top: labelTopStyle,
                }}
            >
                <span className={styles.annotation_data}>
                    {annotation.data && annotation.data.name}
                </span>
                {mode === 'edit' ? <HighlightOff className={styles.delete_icon} onClick={deleteAnnotation} /> : <></>}
            </div>
        );
    }

    function renderEditor(props) {
        // Annotation input editor
        const { geometry } = props.annotation;
        
        if (!geometry || mode === 'view') {
            return null;
        }

        return (
            <div
                style={{
                    background: "rgba(0, 0 ,0, 0.7)",
                    borderRadius: 3,
                    padding: "10px",
                    position: "absolute",
                    boxShadow: 'box-shadow: 5px 5px 9px -5px rgba(0,0,0,0.1)',
                    zIndex: 10,
                    left: `${geometry.x}%`,
                    top: `${geometry.y + geometry.height}%`
                }}
            >
                <input
                    type="text"
                    className={styles.edit_input}
                    autoComplete="off"
                    onChange={e => { 
                        props.onChange({
                            ...props.annotation,
                            data: {
                                ...props.annotation.data,
                                text: e.target.value
                            }
                        })
                    }}
                />
                <button
                    className={styles.edit_button}
                    onClick={props.onSubmit}
                >
                    Add tag
                </button>
            </div>
        );
    }

    function renderOverlay() {
        if (mode === 'view') {
            return;
        }
        
        return (
            <div
                style={{
                    background: "rgba(0, 0, 0, 0.3)",
                    color: "white",
                    padding: 5,
                    pointerEvents: "none",
                    position: "absolute",
                    top: 5,
                    left: 5
                }}
            >
            </div>
        );
    }


    return (
        <Annotation
            src={img}
            alt={img}
            annotations={mode === 'edit' ? (annotations[documentIndex] || []) : annotations}
            type={RectangleSelector.TYPE}
            value={annotation}
            onChange={value => setAnnotation(value)}
            onSubmit={onSubmit}
            allowTouch
            renderSelector={renderSelector}
            activeAnnotations={activeAnnotations}
            activeAnnotationComparator={activeAnnotationComparator}
            renderHighlight={renderHighlight}
            renderContent={renderContent}
            renderEditor={renderEditor}
            renderOverlay={renderOverlay}
        />
    );
};

export default ImageAnnotations;
