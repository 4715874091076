// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_media_container__2izUj {\n  width: 100%;\n  border-radius: 20px;\n  background-color: #F5F5F5;\n  border: 1px solid #D8D8D8;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 15px; }\n  .styles_media_container_selected__jdFek {\n    border: 2px solid #007FDD; }\n  .styles_media_container__2izUj .styles_media_icon__22obC {\n    font-size: 15em;\n    color: #c0bfc2; }\n  .styles_media_container__2izUj .styles_media__3Zsq- {\n    display: block;\n    height: 100%; }\n  .styles_media_container__2izUj .styles_video__21VY4 {\n    width: 100%; }\n  .styles_media_container__2izUj .styles_pdf__2ihzv {\n    max-width: 300px;\n    display: flex;\n    justify-content: center;\n    align-items: center; }\n    @media screen and (max-width: 1400px) {\n      .styles_media_container__2izUj .styles_pdf__2ihzv {\n        transform: scale(0.85); } }\n", ""]);
// Exports
exports.locals = {
	"media_container": "styles_media_container__2izUj",
	"media_container_selected": "styles_media_container_selected__jdFek",
	"media_icon": "styles_media_icon__22obC",
	"media": "styles_media__3Zsq-",
	"video": "styles_video__21VY4",
	"pdf": "styles_pdf__2ihzv"
};
module.exports = exports;
