import React, { ReactNode } from 'react';
import { makeStyles } from "@material-ui/core";

type ButtonType = "button" | "submit" | "reset";

type SubmitButton = {
    name?: string;
    type: ButtonType;
    disabled?: boolean;
    children?: ReactNode;
    onClick?: () => void;
}

const useStyles = makeStyles({
    button: {
        width: 426,
        height: 48,
        background: '#007FDD 0 0 no-repeat padding-box',
        color: '#ffffff',
        border: "none",
        borderRadius: 7,
        fontSize: 18,
        marginTop: 25,
        '@media (max-width: 800px)' : {
            width: 330,
            fontSize: 16,
            marginTop: 20,
        },
        '@media (max-height: 800px)' : {
            width: 330,
            fontSize: 16,
            marginTop: 20,
        },
    }
});

const SubmitButton: React.FC<SubmitButton> = ({
    name, 
    type, 
    disabled, 
    children ,
    onClick
}) => {
    const classes = useStyles();

    return (
        <button
            type={type}
            name={name}
            className={classes.button}
            disabled={disabled}
            onClick={onClick}
        >
            {children ? children : name}
        </button>
    );
};

export default SubmitButton;