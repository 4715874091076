import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AWS from 'aws-sdk';
import JSZip from 'jszip';
import { AxiosError } from "axios";
import api from '../../api';
import routes from "../../routes";
import { IDocument, IError, IPerDocument, IRevision, IRevisionDocuments } from '../../interfaces';
import { AppDispatch, AppThunk, RootState } from '..';
import { NOTIFICATION_MESSAGES } from "../../constants";
import { CompareDocument, TSort, TTab } from "../../assets_ash/globalTypes";
import { areAllValuesEmpty } from "../../helpers";
import { API_URL } from "../../config/app";

interface IInitialState {
    adminDocuments: IDocument[],
    documents: IDocument[],
    document: IPerDocument | null,
    loading: boolean,
    loadingSingleDocument: boolean,
    loadingRequest: boolean,
    actionLoading: boolean,
    error: IError | null,
    total: number | null,
    perPage: number | null,
    lastPage: number | null,
    notificationCount: number | null,
    revisionNotificationCount: number | null
    search: string | null,
    tabs: TTab | null,
    dateErrors: Record<string, string>,
    revisionDocuments: IRevisionDocuments[],
    revisionDocument: IRevision | null,
    singleDocumentByPageLoading: boolean,
    downloadLoading: boolean,
    selected: boolean,
    selectedDocuments: string[],
    tabStatistics: Record<string, number> | null,
    widgetStatistics: Record<string, number> | null,
    filesCount: number,
    downloadedFilesCount: number,
}

const initialState: IInitialState = {
    adminDocuments: [],
    documents: [],
    document: null,
    loading: false,
    loadingSingleDocument: false,
    loadingRequest: false,
    actionLoading: false,
    error: null,
    total: null,
    perPage: null,
    lastPage: null,
    notificationCount: null,
    revisionNotificationCount: null,
    search: null,
    tabs: null,
    dateErrors: {},
    singleDocumentByPageLoading: false,
    revisionDocuments: [],
    revisionDocument: null,
    downloadLoading: false,
    selected: false,
    selectedDocuments: [],
    tabStatistics: null,
    widgetStatistics: null,
    filesCount: 0,
    downloadedFilesCount: 0,
};

const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setLoadingSingleDocument: (state, action: PayloadAction<boolean>) => {
            state.loadingSingleDocument = action.payload;
        },
        setLoadingRequest: (state, action: PayloadAction<boolean>) => {
            state.loadingRequest = action.payload;
        },
        setError: (state, action: PayloadAction<IError | null>) => {
            state.error = action.payload;
        },
        setPaginationData: (state, action: PayloadAction<any>) => {
            state.total = action.payload.total;
            state.perPage = action.payload.per_page;
        },
        setActionLoading: (state, action: PayloadAction<boolean>) => {
            state.actionLoading = action.payload;
        },
        setAdminDocuments: (state, action: PayloadAction<IDocument[]>) => {
            state.adminDocuments = action.payload;
        },
        setDocument: (state, action: PayloadAction<IPerDocument>) => {
            state.document = action.payload;
        },
        setDocuments: (state, action: PayloadAction<IDocument[]>) => {
            state.documents = action.payload;
        },
        setNotificationCountCount: (state, action: PayloadAction<number | null>) => {
            state.notificationCount = action.payload;
        },
        setSearch: (state, action: PayloadAction<IInitialState['search']>) => {
            state.search = action.payload;
            if (action.payload) {
                state.tabs = null;
            }
        },
        setTabs: (state, action: PayloadAction<IInitialState['tabs']>) => {
            state.tabs = action.payload;
            if (action.payload) {
              state.search = null;
            }
        },
        setDateErrors: (state, action: PayloadAction<Record<string, string>>) => {
            state.dateErrors = action.payload;
        },
        setSingleDocumentByPageLoading: (state, action: PayloadAction<boolean>) => {
            state.singleDocumentByPageLoading = action.payload;
        },
        setRevisionDocuments: (state, action: PayloadAction<IRevisionDocuments[]>) => {
            state.revisionDocuments = action.payload;
        },
        setRevisionDocument: (state, action: PayloadAction<IRevision | null>) => {
            state.revisionDocument = action.payload;
        },
        setRevisionNotificationCount: (state, action: PayloadAction<number | null>) => {
            state.revisionNotificationCount = action.payload;
        },
        setDownloadLoading: (state, action: PayloadAction<boolean>) => {
            state.downloadLoading = action.payload;
        },
        setSelected: (state, action: PayloadAction<boolean>) => {
            state.selected = action.payload;
        },
        setSelectedDocuments: (state, action: PayloadAction<string>) => {
            state.selectedDocuments.push(action.payload);
        },
        removeSelectedDocument: (state, action: PayloadAction<string>) => {
            state.selectedDocuments = state.selectedDocuments.filter(item => item !== action.payload);
        },
        clearSelectedDocuments: (state, action: PayloadAction<any[]>) => {
            state.selectedDocuments = action.payload;
        },
        setTabStatistics: (state, action: PayloadAction<Record<string, number>>) => {
            state.tabStatistics = action.payload;
        },
        setWidgetStatistics: (state, action: PayloadAction<Record<string, number>>) => {
            state.widgetStatistics = action.payload;
        },
        setFilesCount: (state, action: PayloadAction<number>) => {
            state.filesCount = action.payload;
        },
        setDownloadedFilesCount: (state, action: PayloadAction<number>) => {
            state.downloadedFilesCount = action.payload;
        },
    }
});

export const {
    setLoading,
    setLoadingSingleDocument,
    setLoadingRequest,
    setError,
    setPaginationData,
    setActionLoading,
    setAdminDocuments,
    setDocument,
    setDocuments,
    setNotificationCountCount,
    setSearch,
    setTabs,
    setDateErrors,
    setSingleDocumentByPageLoading,
    setRevisionDocuments,
    setRevisionDocument,
    setRevisionNotificationCount,
    setDownloadLoading,
    setSelected,
    setSelectedDocuments,
    removeSelectedDocument,
    clearSelectedDocuments,
    setTabStatistics,
    setWidgetStatistics,
    setDownloadedFilesCount,
    setFilesCount,
} = documentSlice.actions;

export const getAdminDocumentsList = (
    page: number | null | undefined,
    queryParam?: string,
): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {

        const response = await api.get(`/admin/documents/list?per_page=10&page=${page}${queryParam ? `&${queryParam}` : ''}`);

        dispatch(setLoading(false));
        dispatch(setError(null));
        dispatch(setAdminDocuments(response.data.data));
        dispatch(setPaginationData(response.data.meta));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const getUserDocumentsList = (page: number | null | undefined, queryParam?: string, perPage: number = 18): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.get(`/documents/list?per_page=${perPage}&page=${page}${queryParam ? `&${queryParam}` : ''}`);

        dispatch(setLoading(false));
        dispatch(setError(null));
        dispatch(setDocuments(response.data.data));
        dispatch(setPaginationData(response.data.meta));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
}

export const getDocumentById = (documentId: string, isAdmin: boolean = false): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoadingSingleDocument(true));

    try {
        const response = await api.get(`${isAdmin ? '/admin' : ''}/documents/${documentId}`)

        dispatch(setLoadingSingleDocument(false));
        dispatch(setError(null));
        dispatch(setDocument(response.data.data))

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoadingSingleDocument(false));

        throw new Error(errorMessage);
    }
};

export const documentApprove = (documentId: string, privacy_level: string): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoadingRequest(true));

    try {
        const response = await api.put(`/admin/documents/${documentId}/approve`, { privacy_level })

        dispatch(setLoadingRequest(false));
        dispatch(setError(null));

        return response;
    } catch (error) {
        console.log(error.response)
        const errorMessage =
            (error as AxiosError)?.response?.data || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoadingRequest(false));

        throw new Error(errorMessage);
    }
};

export const documentDecline = (documentId: string): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoadingRequest(true));

    try {
        const response = await api.put(`/admin/documents/${documentId}/decline`)

        dispatch(setLoadingRequest(false));
        dispatch(setError(null));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoadingRequest(false));

        throw new Error(errorMessage);
    }
};

export const getNotificationCount = (): AppThunk => async (dispatch: AppDispatch) => {
    try {
        const response = await api.get(`/admin/documents/list?per_page=-1&status=pending`);

        dispatch(setNotificationCountCount(response.data.meta.total));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const getDocumentChangesNotificationCount = (): AppThunk => async (dispatch: AppDispatch) => {
    try {
        const response = await api.get('/admin/document-revisions/list?per_page=-1&status=pending');

        dispatch(setRevisionNotificationCount(response.data.meta.total));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
}

export const createDocuments = (docs: any): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        await api.post('/documents', { docs });

        localStorage.removeItem('documents');

        dispatch(setLoading(false));
        dispatch(setError(null));
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setLoading(false));
        dispatch(setError(errorMessage));

        throw new Error(errorMessage);
    }
};

export const requestToEdit = (id: string, changes: CompareDocument): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = api.post(`documents/${id}/change`, changes);

        dispatch(setLoading(false));
        dispatch(setError(null));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setLoading(false));
        dispatch(setError(errorMessage));

        throw new Error(errorMessage);
    }
};

export const getUserDocuments = (
    page: number | null | undefined,
    queryParams?: string,
): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.get(`/users/documents/list?&page=${page}${queryParams ? `&${queryParams}` : ''}`);

        dispatch(setLoading(false));
        dispatch(setError(null));
        dispatch(setDocuments(response.data.data))
        dispatch(setPaginationData(response.data.meta));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const getSingleDocumentByPage = (
    path: string,
    documentCommonIndex: number,
    additionalQueryString: string,
    sort: TSort,
): AppThunk => async (dispatch: AppDispatch) => {
    let resource;

    if (path === routes.home.path) {
        resource = '/documents/list';
    } else if (path === routes.adminDashboardDocuments.path) {
        resource = '/admin/documents/list';
    } else if (path === routes.userDocuments.path) {
        resource = '/users/documents/list';
    }

    const sortQuery = {
        ...(!areAllValuesEmpty(sort) && { sort_by: sort.name, sort_dir: sort.sortDir }),
    }

    const sortParam = new URLSearchParams(sortQuery as Record<string, string>).toString();

    dispatch(setSingleDocumentByPageLoading(true));

    try {
        const response = await api.get(
            `${resource}?per_page=${1}&page=${documentCommonIndex}` +
            `${additionalQueryString ? `&${additionalQueryString}` : ''}` +
            `${sortParam ? `&${sortParam}` : ''}`
        );
        dispatch(setSingleDocumentByPageLoading(false));

        return response.data.data;

    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setSingleDocumentByPageLoading(false));
        throw new Error(errorMessage);
    }
};

export const getDocumentRevisionsList = (
    page: number | null | undefined,
    queryParam?: string
): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.get(`admin/document-revisions/list?per_page=10&page=${page}${queryParam ? `&${queryParam}` : ''}`);

        dispatch(setLoading(false));
        dispatch(setError(null));
        dispatch(setRevisionDocuments(response.data.data));
        dispatch(setPaginationData(response.data.meta));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const getRevisionDocumentListById = (page: number | null | undefined, query?: string, documentId?: string | null | number | undefined): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));
    try {

        const id = documentId ? typeof documentId !== undefined : null;

        const response = await api.get(`admin/document-revisions/list?per_page=10&page=${page}${query ? `&${query}` : ''}${id ? `&document_id=${documentId}` : ''}`);

        dispatch(setLoading(false));
        dispatch(setError(null));
        dispatch(setRevisionDocuments(response.data.data));
        dispatch(setPaginationData(response.data.meta));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
}

export const getRevisionsDocument = (id: string): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.get(`/admin/document-revisions/${id}`);

        dispatch(setLoading(false));
        dispatch(setError(null));
        dispatch(setRevisionDocument(response.data.data));

        return response.data.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setLoading(false));
        dispatch(setError(errorMessage));

        throw new Error(errorMessage);
    }
};

export const changeRevisionDocument = (file_id: string, status: string): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.put(`/admin/document-revisions/change/${file_id}/${status}`);

        dispatch(setLoading(false));
        dispatch(setError(null));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setLoading(false));
        dispatch(setError(errorMessage));

        throw new Error(errorMessage);

    }
};

export const changeBulkRevisionDocument = (document_id: string, status: string): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.put(`/admin/document-revisions/${document_id}/${status}`);

        dispatch(setLoading(false));
        dispatch(setError(null));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setLoading(false));
        dispatch(setError(errorMessage));

        throw new Error(errorMessage);

    }
};

export const downloadDocumentFiles = (files: any): AppThunk => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setDownloadLoading(true));

    try {
        const credentialsResponse = await api.get(`/upload-credentials`);

        const s3 = new AWS.S3({
            credentials: {
                accessKeyId: credentialsResponse.data.access_key,
                secretAccessKey: credentialsResponse.data.secret_key,
                sessionToken: credentialsResponse.data.session_token,
            },
        });

        const zip = new JSZip();
        const downloadPromises = [];

        for (const url of files) {
            const fileUrl = decodeURIComponent(url);
            const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            const params = {
                Bucket: credentialsResponse.data.bucket,
                Key: fileName,
            };

            const signedUrl = await s3.getSignedUrlPromise('getObject', params);

            downloadPromises.push(
                new Promise((resolve: any, reject: any) => {
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', signedUrl);
                    xhr.responseType = 'arraybuffer';

                    // tarcking downloaded files
                    xhr.onloadend = () => {
                        if (xhr.status === 200) {
                            zip.file(fileName, xhr.response);
                            
                            // incrementing downloaded files count
                            const state = getState();
                            dispatch(setDownloadedFilesCount(state.documents.downloadedFilesCount + 1));

                            resolve();
                        } else {
                            reject(new Error('Failed to download file'));
                        }
                    };

                    xhr.onerror = () => {
                        reject(new Error('Failed to download file'));
                    };

                    xhr.send();
                })
            );
        }

        await Promise.all(downloadPromises);

        const zipBlob = await zip.generateAsync({ type: 'blob' });

        // Creating link to ZIP archive
        const downloadUrl = URL.createObjectURL(zipBlob);

        const downloadLink = document.createElement('a');
        downloadLink.href = downloadUrl;
        downloadLink.download = 'documents.zip';

        downloadLink.click();

        URL.revokeObjectURL(downloadUrl);

        dispatch(setDownloadLoading(false));
        dispatch(setError(null));
        dispatch(clearSelectedDocuments([]));
        dispatch(setSelected(false));
        dispatch(setDownloadedFilesCount(0));
        dispatch(setFilesCount(0));
    } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;
        dispatch(setError(errorMessage));
        dispatch(setDownloadLoading(false));
        dispatch(setDownloadedFilesCount(0));
        dispatch(setFilesCount(0));
        throw new Error(errorMessage);
    }
};

export const getDocumentFilePaths = (documentIds: string[]): AppThunk => async (dispatch: AppDispatch) => {
    try {
        const response = await api.post('/download', { documentIds });
        const filesUrls = response?.data?.fileUrls;
        
        dispatch(setFilesCount(filesUrls.length));

        return filesUrls;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setDownloadLoading(false));

        throw new Error(errorMessage);
    }
};

export const getWindgetNotificatios = (): AppThunk => async (dispatch: AppDispatch) => {
    try {
        const response = await api.get('admin/dashboard/statistics');

        dispatch(setWidgetStatistics(response.data.data));

        return response
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setDownloadLoading(false));

        throw new Error(errorMessage);
    }
}

export const getTabNotifications = (): AppThunk => async (dispatch: AppDispatch) => {
    try {
        const response = await api.get('homepage/statistics');

        dispatch(setTabStatistics(response.data.data));

        return response
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setDownloadLoading(false));

        throw new Error(errorMessage);
    }
}

export const deleteDocumentViewFile = (id: string, key: string): AppThunk => async (dispatch: AppDispatch) => {
    setLoading(true);

    try {
        const response = await api.delete(`/admin/document-files/${id}`);

        if(response.status !== 204) {
            return;
        }
        const awsResponse = await api.get(`${API_URL}/upload-credentials`);

        const s3 = new AWS.S3({
            accessKeyId: awsResponse.data.access_key,
            secretAccessKey: awsResponse.data.secret_key,
            sessionToken: awsResponse.data.session_token,
        });

        dispatch(setLoading(false));
        dispatch(setError(null));

        try {
            const params = {
                Bucket: awsResponse.data.bucket,
                Delete: {
                    Objects: [
                        { Key: key }
                    ]
                }
            };

            const deleteProgress = s3.deleteObjects(params);

            await deleteProgress.promise();
        } catch (error) {
            setLoading(false);
            throw new Error(error);
        }

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setLoading(false));
        dispatch(setError(errorMessage));

        throw new Error(errorMessage);
    }
};

export const editByAdmin = (id: string, changes: CompareDocument): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = api.post(`/admin/document-revisions/${id}/change`, changes);

        dispatch(setLoading(false));
        dispatch(setError(null));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setLoading(false));
        dispatch(setError(errorMessage));

        throw new Error(errorMessage);
    }
};

export const selectAdminDocuments = (state: RootState) => state.documents.adminDocuments;
export const selectDocuments = (state: RootState) => state.documents.documents;
export const selectDocument = (state: RootState) => state.documents.document;
export const selectLoading = (state: RootState) => state.documents.loading;
export const selectLoadingSingleDocument = (state: RootState) => state.documents.loadingSingleDocument;
export const selectLoadingRequest = (state: RootState) => state.documents.loadingRequest;
export const selectTotal = (state: RootState) => state.documents.total;
export const selectPerPage = (state: RootState) => state.documents.perPage;
export const selectLastPage = (state: RootState) => state.documents.lastPage;
export const selectNotificationCount = (state: RootState) => state.documents.notificationCount;
export const selectSearch = (state: RootState) => state.documents.search;
export const selectTabs = (state: RootState) => state.documents.tabs;
export const selectErrors = (state: RootState) => state.documents.dateErrors;
export const selectSingleDocumentByPageLoading = (state: RootState) => state.documents.singleDocumentByPageLoading;
export const selectRevisionDocuments = (state: RootState) => state.documents.revisionDocuments;
export const selectRevisionDocument = (state: RootState) => state.documents.revisionDocument;
export const selectRevisionDocumentNotification = (state: RootState) => state.documents.revisionNotificationCount;
export const selectDownloadLoading = (state: RootState) => state.documents.downloadLoading;
export const selectSelected = (state: RootState) => state.documents.selected;
export const selectSelectedDocuments = (state: RootState) => state.documents.selectedDocuments;
export const selectWidgetNotifications = (state: RootState) => state.documents.widgetStatistics;
export const selectTabsNotifications = (state: RootState) => state.documents.tabStatistics;
export const selectFilesCount = (state: RootState) => state.documents.filesCount;
export const selectDownloadedFilesCount = (state: RootState) => state.documents.downloadedFilesCount;

export default documentSlice;


