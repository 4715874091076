import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import { Box, makeStyles, MenuItem, Select } from "@material-ui/core";
import { changeLanguageQuery, currentLanguage } from "../../../helpers/localizationHelpers";

type LanguageChangeConfig = {
    flagCode: FlagIconCode;
    onClick: () => void;
    text: string;
}

const useStyles = makeStyles((theme) => ({
    languageChange: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& span': {
            marginLeft: 8,
            fontSize: 16,
        },
    },
    flagIcon: {
        borderRadius: '50%',
        marginRight: 8,
        width: 30,
        height: 30,
    },
    select: {
        position: 'relative',
        padding: '5px',
        color: '#757575',
        '&:focus' : {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
        '& .MuiSelect-icon' : {
            display: 'none',
        },
        '&:before, &:after' : {
            borderBottom: 'none',
        },
        '& .MuiInputBase-root' : {
            paddingBottom: theme.spacing(1),
        },
        '& .MuiInput-underline' : {
            borderBottom: 'none !important'
        }
    },
}));

const LanguageChange: React.FC = () => {
    const classes = useStyles();
    const currentLng = localStorage.getItem('lang') ?? 'hy'

    const { i18n } = useTranslation('common');

    const languageChanges: Record<string, LanguageChangeConfig> = {
        'hy': {
            flagCode: 'AM',
            onClick: () => changeLanguage(currentLanguage.en),
            text: 'Հայ',
        },
        'en': {
            flagCode: 'US',
            onClick: () => changeLanguage(currentLanguage.hy),
            text: 'Eng',
        },
    };

    const changeLanguage = (lng: string): void => {
        localStorage.setItem('lang', lng);
        i18n.changeLanguage(lng);
        changeLanguageQuery();
    };

    const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
        const selectedLanguage = event.target.value as string;
        languageChanges[selectedLanguage].onClick();
    };

    useEffect(() => {
        const currentLng = localStorage.getItem('lang') ?? 'hy';
        i18n.changeLanguage(currentLng as string)
    }, []);

    return (
        <Box>
            <Select
                id="language-select"
                value={currentLng}
                onChange={(e: any) => handleChange(e)}
                className={`${classes.languageChange} `}
                classes={{
                    root: classes.select,
                }}
                disableUnderline
            >
                {Object.keys(languageChanges).map((key) => {
                    const { flagCode, text } = languageChanges[key];
                    return (
                        <MenuItem key={key} value={key}>
                            <FlagIcon code={flagCode} size={28} className={classes.flagIcon}/>
                            <span>{text}</span>
                        </MenuItem>
                    );
                })}
            </Select>
        </Box>
    )
};

export default LanguageChange;