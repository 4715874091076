import * as Yup from 'yup';
import i18n from '../i18n';

const loginSchema = Yup.object({
    email: Yup
        .string()
        .email(i18n.t('common:login.validation.email_valid'))
        .required(i18n.t('common:login.validation.email_required')),
    password: Yup
        .string()
        .min(6, i18n.t('common:login.validation.password_min'))
        .required(i18n.t('common:login.validation.password_required')),
})

export default loginSchema;