// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_chip_container__3YmDt {\n  display: flex;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  margin: 0;\n  border: 1px solid #c0bfc2;\n  padding: 16px 14px;\n  width: 100%;\n  border-radius: 0.2rem;\n  color: rgba(0, 0, 0, 0.54);\n  position: relative;\n  font-size: 1rem;\n  font-family: 'Montserrat', \"sans-serif\"; }\n  .styles_chip_container_label__2P_PR {\n    position: absolute;\n    top: 0;\n    left: 0;\n    transform: translate(22%, 64%);\n    background-color: #ffffff;\n    padding: 0;\n    margin: 0;\n    transition: border-color 0.3s ease, transform 0.3s ease, padding 0.3s ease; }\n    .styles_chip_container_label__2P_PR.styles_active__Zblt2 {\n      transform: translate(0, -50%) scale(0.75);\n      padding: 0 0.5rem 0 0.2rem;\n      color: #3c44b1; }\n  .styles_chip_container_placeholder__3tlr4 {\n    font-size: 0.85rem;\n    color: transparent;\n    transition: color 0.3s ease; }\n  .styles_chip_container__3YmDt:hover, .styles_chip_container__3YmDt:focus {\n    border-color: #3c44b1; }\n    .styles_chip_container__3YmDt:hover .styles_chip_container_label__2P_PR, .styles_chip_container__3YmDt:focus .styles_chip_container_label__2P_PR {\n      transform: translate(0, -50%) scale(0.75);\n      padding: 0 0.5rem 0 0.2rem;\n      color: #3c44b1; }\n    .styles_chip_container__3YmDt:hover .styles_chip_container_placeholder__3tlr4, .styles_chip_container__3YmDt:focus .styles_chip_container_placeholder__3tlr4 {\n      color: rgba(0, 0, 0, 0.54); }\n", ""]);
// Exports
exports.locals = {
	"chip_container": "styles_chip_container__3YmDt",
	"chip_container_label": "styles_chip_container_label__2P_PR",
	"active": "styles_active__Zblt2",
	"chip_container_placeholder": "styles_chip_container_placeholder__3tlr4"
};
module.exports = exports;
