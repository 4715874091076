import React from 'react';
import { CancelRounded } from '@material-ui/icons';

import styles from '../styles.module.scss';

interface IThumb {
    onRemove: () => void;
};

const Thumb: React.FC<IThumb> = ({ onRemove, children }) => {
    return (
        <div
            className={styles.thumb}
            onClick={onRemove}
        >
            <CancelRounded className={styles.delete_icon} />
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
};

export default Thumb;
