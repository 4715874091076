import routes from "../routes";
import {extractPathSegment} from "./auth";
import {capitalizeAllWords} from "./index";
import {localizeRouth} from "./localizationHelpers";

const currentRoutes: Record<string, keyof typeof routes> = {
    "/": "home",
    "/en": "home",
    "gallery": "home",
    "my-documents": "userDocuments",
    "admin-dashboard": "adminDashboardDocuments",
}

export const getDocumentViewPath = (pathname: string) => routes[currentRoutes[getQueryPath(pathname)]].path;

export const getQueryPath = (pathname: string) => (pathname !== '/' && pathname !== '/en') ? pathname.split('/')[1] : '/';

export const extractSlugFromLocation = (pathname: string) => {
    const slug = extractPathSegment(pathname, -1);

    // Checking if the slug is uuid
    if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(slug)) {
        return capitalizeAllWords(extractPathSegment(pathname, -2));
    } else if (slug.split('-')?.length > 1) {
        return `${capitalizeAllWords(slug.split('-')[0])} ${capitalizeAllWords(slug.split('-')[1])}`
    } else {
        return capitalizeAllWords(slug);
    }
};

export const queryDestruction = (query: any, search: any): Record<string, string | number> => {
    const { type, group, created_at_from, created_at_to, my_uploads } = query;

    return {
        ...(!!my_uploads && {my_uploads}),
        ...((!!created_at_from && !!created_at_to) && {created_at_from, created_at_to}),
        ...(!!group && {group}),
        ...(!!type && {type}),
        ...(!!search && {s: search}),
    }
};

