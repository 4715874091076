import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

type BackStep = {
    route: {
        path: string
    };
    text: string;
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center'
    },
    arrowIcon: {
        color: "#007FDD"
    },
    text: {
        color: '#000000',
        fontSize: 18,
        marginLeft: 5
    }
});

const BackStep: React.FC<BackStep> = ({route, text}) => {
    const classes = useStyles();

    return (
        <Link
            to={`${route.path}`}
            className={classes.container}
        >
            <ArrowBack className={classes.arrowIcon}/>
            <Typography className={classes.text}>
                {text}
            </Typography>
        </Link>
    )
};

export default BackStep;