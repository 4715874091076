import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { showNotification } from "../../../store/notification/notificationSlice";
import { getReports, selectReports } from "../../../store/admin/adminSlice";
import { NOTIFICATION_TYPES } from "../../../constants";
import ReportRows from "./ReportRow";

const useStyles = makeStyles({
    container: {
        padding: '20px'
    },
    date: {
        fontSize: '30px',
        fontWeight: 700,
        padding: '10px'
    },
});

const Reports: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const reports = useSelector(selectReports);

    const getAdminReports = async () => {
        try {
            await dispatch(getReports());
        } catch (e) {
            dispatch(showNotification({
                message: e.message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    useEffect(() => {
        getAdminReports();
    }, []);

    return (
        <section className={classes.container}>
            <ReportRows reports={reports}/>
        </section>
    )
}

export default Reports;