import React, { ReactNode } from 'react';
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../../../helpers";
import { makeStyles } from "@material-ui/core";

type ReportRow = {
    reports: Record<string, any>[] | null
}

const useStyles = makeStyles({
    reports: {
        '& :last-child': {
            border: "none"
        }
    },
    reportRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '15px 10px',
        borderBottom: "1px solid #DEDEDE"
    },
    reportValueRow: {
        display: 'flex',
        flexDirection: 'row',
        padding: '5px 10px',
        margin: "0 0 0 50px",
        '& :last-child': {
            marginLeft: '10px'
        }
    },
    reportKey: {
        fontSize: "18px",
        fontWeight: 600
    },
    reportValue: {
        fontSize: "16px"
    },
    reportSubKey : {
        fontSize: "16px",
        fontWeight: 500
    }
});

const ReportRows: React.FC<ReportRow> = ({reports}) => {
    const { t } = useTranslation('common');
    const classes = useStyles();

    const reportKeys = ['photo', 'document', 'video', 'total', 'unknown']

    return (
        <section className={classes.reports}>
            {!!reports && Object.entries(reports).map(([key, value], index) => (
                <section key={index} className={classes.reportRow}>
                    <section className={classes.reportKey}>
                        {t(`report.report_keys.${key}`)}
                    </section>

                    {isEmpty(value) && (
                        <section className={classes.reportValueRow}>
                            <section className={classes.reportSubKey}>
                                {t(`report.report_values.total`)}
                            </section>
                            <section className={classes.reportValue}>
                                {value.lenght ?? 0}
                            </section>
                        </section>
                    )}
                    {Object.entries(value as object).map(([key, value], index) => (
                        <section key={index} className={classes.reportValueRow}>
                            <section className={classes.reportSubKey}>
                                {reportKeys.includes(key) ? t(`report.report_values.${key}`) : key}
                            </section>
                            <section className={classes.reportValue}>
                                {value as ReactNode}
                            </section>
                        </section>
                    ))}
                </section>
            ))}
        </section>
    )
};

export default ReportRows;