import * as Yup from 'yup'
import i18n from '../i18n';
import {IsMember} from "../assets_ash/globalTypes";

const registerSchema = Yup.object().shape({
    first_name: Yup
        .string()
        .required(i18n.t('common:register.validation.first_name')),
    last_name: Yup
        .string()
        .required(i18n.t('common:register.validation.last_name')),
    email: Yup
        .string()
        .email(i18n.t('common:register.validation.email_invalid'))
        .required(i18n.t('common:register.validation.email')),
    password: Yup
        .string()
        .min(6, i18n.t('common:register.validation.password_min'))
        .required(i18n.t('common:register.validation.password')),
    confirm_password: Yup
        .string()
        .min(6, i18n.t('common:register.validation.confirm_password_min'))
        .required(i18n.t('common:register.validation.confirm_password') )
        .oneOf(
            [Yup.ref('password')],
            i18n.t('common:register.validation.passwords_equal')
        ),
    date_of_birth: Yup
        .string()
        .required(i18n.t('common:register.validation.dob_required'))
        .matches(
            /^(19\d\d|20[0-9][0-9])-([0]\d|1[0-2])-([0-2]\d|3[01])$/,
            i18n.t('common:register.validation.dob_range')
        )
        .when(['isMember', 'date_from'], {
            is: (isMember, date_from) =>
                isMember === 'Yes' && !!date_from,
            then: Yup
                .string()
                .test('is-not-greater-than-from', i18n.t('common:register.validation.dob_date_from'), function (value: any) {
                    const from = this.parent.date_from;
                    return value.split('-')[0] < from;
                }),
        }),
    isMember: Yup
        .string()
        .notRequired()
        .nullable()
        .oneOf([IsMember.Yes, IsMember.No, null]),
    country: Yup
        .string()
        .required(i18n.t('common:register.validation.country')),
    date_to: Yup
        .number()
        .notRequired()
        .when(['isMember', 'date_from'], {
            is: (isMember, date_from) =>
                isMember === 'Yes' && !!date_from,
            then: Yup
                .number()
                .test('is-greater-than-from', i18n.t('common:register.validation.date_to'), function (value: any) {
                    const from = this.parent.date_from;
                    return value >= from;
            }),
        })
});

export default registerSchema;


