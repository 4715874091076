import React from 'react';
import { DisableEnum, TDisable } from "../../../assets_ash/globalTypes";
import { makeStyles } from "@material-ui/core";

type TableSortArrow = {
    setSort: (sortObject: Record<string, any>) => void,
    sort: Record<string, Record<string, string>>
    disable: TDisable,
    disableStatus: Record<string, DisableEnum>
}

const useStyles = makeStyles({
    arrowDisable: {
        filter: 'grayscale(100%)',
        opacity: 0.5,
        pointerEvents: 'none',
    },
    sortArrowContainer: {
        display: 'flex',
        flexDirection: 'column',
    }
})

const TableSortArrows: React.FC<TableSortArrow> = ({sort, setSort, disable,disableStatus}) => {
    const classes = useStyles();

    return (
        <section className={classes.sortArrowContainer}>
            <img
                src={'https://cdn-icons-png.flaticon.com/512/25/25330.png'}
                className={disable === disableStatus.desc ? classes.arrowDisable : ""}
                onClick={(e) => { setSort(sort.desc) }}
            />
            <img
                src={'https://cdn-icons-png.flaticon.com/512/25/25224.png'}
                className={disable === disableStatus.asc ? classes.arrowDisable : ""}
                onClick={(e) => { setSort(sort.asc) }}
            />
        </section>
    )
};

export default TableSortArrows;