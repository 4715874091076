import React from 'react';
import { 
    Box,
    Typography,
    CircularProgress, 
} from '@material-ui/core';

const CircularProgressWithLabel = ({ value, circleSize, fontSize, labelColor }) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress 
                variant="determinate" 
                value={value} 
                size={circleSize} 
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography 
                    variant="caption" 
                    component="div" 
                    color="textSecondary" 
                    style={{color: labelColor, fontSize: fontSize}}
                >
                    {`${Math.round(value)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

export default CircularProgressWithLabel;
