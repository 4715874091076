import React from 'react';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    grid: {
        margin: '20px 0',
        padding: '0',
        left: "50%",
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 300px)',
        gridAutoRows: '10px',
        justifyContent: 'center'
    }
})

const DocumentsWrapper: React.FC = ({children}) => {
    const classes = useStyles();

    return (
        <div className={classes.grid}>
            {children}
        </div>
    )
};

export default DocumentsWrapper;