// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_dashboard__1CTMm {\n  max-width: 1600px;\n  width: 100%;\n  margin: 0 auto; }\n", ""]);
// Exports
exports.locals = {
	"dashboard": "styles_dashboard__1CTMm"
};
module.exports = exports;
