import * as Yup from 'yup';
import i18n from '../i18n';

const emailSchema = Yup.object({
    email: Yup
        .string()
        .email(i18n.t('common:forgot_password.validation.email_valid'))
        .required(i18n.t('common:forgot_password.validation.email_required')),
})

export default emailSchema;