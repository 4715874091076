import React from 'react';
import { makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

type SkeletonGrid = {
    cells: number
}

const useStyles = makeStyles({
    skeletonGridCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        gridRowEnd: 'span 20',
        margin: '5px',
        padding: 0,
        borderRadius: 22,
        overflow: 'hidden',
        cursor: 'pointer',
    }
})

const SkeletonGrid: React.FC<SkeletonGrid> = ({cells}) => {
    const classes = useStyles();

    const cellsArray = Array.from({length: cells}, (_, index) => index);

    return (
        <>
            {cellsArray.map((index) => (
                <Skeleton
                    key={index}
                    className={classes.skeletonGridCell}
                    animation="wave"
                    variant="rect"
                    width={290}
                    height={190}/>
            ))}
        </>
    )
}

export default SkeletonGrid;