import React from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles, Typography } from "@material-ui/core";
import Logo from "../../../components/Logo";
import LanguageChange from "../../../components/Header/LanguageChange";
import Widgets from "./Widgets";
import background from '../../../assets_ash/images/landingBackground.png'
import companyLogo from '../../../assets_ash/images/company_logo.svg'

const useStyles = makeStyles({
    main: {
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100vh',
        minHeight: '800px',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '15px 20px',
        alignItems: 'center'
    },
    card: {
        maxWidth: 1000,
        height: 'auto',
        backgroundColor: '#fff',
        borderRadius: '12px',
        textAlign: 'center',
        position: 'relative',
        margin: 'auto',
        padding: 30,
        '@media(max-width: 1200px)' : {
            maxWidth: 500
        },
        '@media(max-width: 800px)' : {
            maxWidth: 500
        },
        '@media(max-width: 400px)' : {
            maxWidth: 400
        },
        '@media(max-height: 600px)' : {
            maxWidth: 500
        }
    },
    titleContainer: {
        textAlign: 'center',
        marginTop: '60px',
    },
    infoContainer: {
        textAlign: 'center',
    },
    title: {
        fontSize: 28,
        fontWeight: 600,
        '@media(max-width: 1200px)' : {
            fontSize: 20
        },
        '@media(max-width: 800px)' : {
            fontSize: 16
        },
        '@media(max-width: 400px)' : {
            fontSize: 14
        },
        '@media(max-height: 600px)' : {
            fontSize: 16
        }
    },
    info: {
        fontSize: 16,
        fontWeight: 400,
        marginBottom: '10px',
        '@media(max-width: 1200px)' : {
            fontSize: 14
        },
        '@media(max-width: 800px)' : {
            fontSize: 12
        },
        '@media(max-width: 400px)' : {
            fontSize: 10
        },
        '@media(max-height: 600px)' : {
            fontSize: 10
        }
    },
    logoContainer: {
        '& > img' : {
            position: 'absolute',
            zIndex: 1,
            top: -150,
            left: 0,
            right: 0,
            margin: 'auto',
            width: 200,
            '@media(max-width: 1200px)' : {
                width: 150,
                top: -100
            },
            '@media(max-width: 800px)' : {
                width: 100,
                top: -70
            },
            '@media(max-width: 400px)' : {
                width: 70,
                top: -50
            },
            '@media(max-height: 600px)' : {
                width: 100,
                top: -70
            }
        },
    }
});

const Landing: React.FC = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();

    return (
        <section className={classes.main}>
            <section className={classes.header}>
                <Logo/>

                <LanguageChange/>
            </section>
            <section className={classes.card}>
                <section className={classes.logoContainer}>
                    <img src={companyLogo} alt=""/>
                </section>
                <section className={classes.titleContainer}>
                    <Typography className={classes.title}>
                        {t('landing.title')}
                    </Typography>
                </section>
                <section className={classes.infoContainer}>
                    <Typography className={classes.info}>
                        {t('landing.text1')}
                    </Typography>
                    <Typography className={classes.info}>
                        {t('landing.text2')}
                    </Typography>
                </section>
                <Widgets />
            </section>
        </section>
    )
};

export default Landing;