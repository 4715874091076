import React, {useEffect, useMemo} from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectIsAuthenticated } from "../../../../store/user/userSlice";
import { getToken } from "../../../../helpers/auth";
import { LANDING_PAGE_WIDGETS } from "../../../../constants";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
    widgetContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: 10,
        '@media(max-width: 1200px)': {
           padding: 5
        },
    },
    widget: {
        width: '210px',
        height: '80px',
        borderRadius: '14px',
        backgroundColor: '#007FDD',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10,
        '@media(max-width: 1200px)': {
            width: 150,
            height: 50
        },
        '@media(max-width: 800px)' : {
            width: 110,
            height: 40
        },
        '@media(max-width: 400px)' : {
            width: 120,
            height: 50
        }
    },
    widgetText: {
        fontSize: 20,
        fontWeight: 500,
        color: '#fff',
        marginLeft: 10,
        '@media(max-width: 1200px)': {
            fontSize: 14
        },
        '@media(max-width: 800px)': {
            fontSize: 10
        },
        '@media(max-width: 400px)' : {
            fontSize: 14
        }
    },
    icon: {
        width: 35,
        '@media(max-width: 1200px)': {
            width: 25,
        },
        '@media(max-width: 800px)': {
            width: 15,
        },
        '@media(max-width: 400px)' : {
            width: 15,
        }
    }
});

const Widgets: React.FC = () => {
    const classes = useStyles();

    const isAuthenticated = useSelector(selectIsAuthenticated);
    const token = getToken();

    const filteredWidget = useMemo(() => {
        return LANDING_PAGE_WIDGETS.filter(widget => {
            const { isAuth } = widget;
            return isAuthenticated && token ? isAuth === false : true;
        });
    }, [isAuthenticated, token]);

    return (
        <section className={classes.widgetContainer}>
            {filteredWidget && filteredWidget.map((card, key) => (
                <Link to={card.link} key={key}>
                    <section className={classes.widget}>
                        <img src={card.icon} alt="" className={classes.icon}/>
                        <Typography className={classes.widgetText}>
                            {card.text}
                        </Typography>
                    </section>
                </Link>
            ))}
        </section>
    )
};

export default Widgets;