import React, { useState } from 'react';
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { matchFileType, matchFileTypeByExtension } from "../../../helpers";
import { FILE_TYPES } from "../../../constants";
import routes from "../../../routes";
import { ChangeDocumentFiles } from "../../../assets_ash/globalTypes";
import { Button, makeStyles } from "@material-ui/core";
import ContentViewer from "../../../components/ContentViewer";
import { Description, PictureAsPdf, Videocam, ZoomOutMap } from "@material-ui/icons";
import Logo from '../../../assets/icons/logo.svg';

import styles from './styles.module.scss';

type Sidebar = {
    document_files: ChangeDocumentFiles[],
    current: any,
    setCurrent: any
}

const useStyles = makeStyles({
    thumbButton: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    },
});

const Sidebar: React.FC<Sidebar> = ({document_files, current, setCurrent}) => {
    const classes = useStyles();
    const history = useHistory();

    const [isOpen, setOpen] = useState(false);
    const [filePreview, setFilePreview] = useState('');
    const [fileType, setFileType] = useState('');

    const handleOpen = (imageUrl: string, type: string) => {
        setOpen(true);
        setFilePreview(imageUrl);
        setFileType(type);
    };

    const handleClose = () => {
        setOpen(false);
        setFilePreview('');
        setFileType('');
        history.push(routes.documentChangeReview.path);
    };

    return (
        <>
            <ContentViewer
                filePreview={filePreview}
                fileType={fileType}
                open={isOpen}
                currentFile={filePreview}
                handleClose={handleClose}
            />

            <section className={styles.file_bar}>
                <section className={styles.logo_container}>
                    <Link to={routes.home.path}>
                        <img src={Logo} alt="logo" className={styles.logo}/>
                    </Link>
                </section>

                <section className={styles.thumbs_bar}>
                    {document_files?.length !== 0 && !!document_files && typeof current !== undefined ?
                        ((Object
                            .values(document_files)
                            .flatMap((file: any) => file ? file : [])
                            .map((file, index) => (
                                <div key={index}>
                                <Button
                                    className={classes.thumbButton}
                                    disableRipple
                                    disableElevation
                                    onClick={() => setCurrent(file)}
                                >
                                    <div className={clsx(styles.thumb_container, {
                                        [styles.thumb_container_selected]: file.file_path === current?.file_path
                                    })}>
                                        {matchFileType(matchFileTypeByExtension(file?.file_path)) === FILE_TYPES.IMAGE && (
                                            <img className={styles.thumb} src={file.file_path}/>
                                        )}
                                        {matchFileType(matchFileTypeByExtension(file?.file_path)) === FILE_TYPES.VIDEO &&
                                            <Videocam className={styles.thumb_icon}/>
                                        }
                                        {matchFileType(matchFileTypeByExtension(file?.file_path)) === FILE_TYPES.PDF &&
                                            <PictureAsPdf className={styles.thumb_icon}/>
                                        }
                                        {matchFileType(matchFileTypeByExtension(file?.file_path)) === FILE_TYPES.DOC &&
                                            <Description className={styles.thumb_icon}/>
                                        }
                                        <div className={styles.full_screen}>
                                            <ZoomOutMap
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOpen(file.file_path, matchFileTypeByExtension(file.file_path));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Button>
                            </div>
                            ))))
                        : (!document_files?.length && typeof current !== undefined
                            ?  (
                                <div>
                                    <Button
                                        className={classes.thumbButton}
                                        disableRipple
                                        disableElevation
                                        onClick={() => setCurrent(current)}
                                    >
                                        <div className={styles.thumb_container_selected}>
                                            {matchFileType(matchFileTypeByExtension(current?.file_path)) === FILE_TYPES.IMAGE && (
                                                <img className={styles.thumb} src={current.file_path}/>
                                            )}
                                            {matchFileType(matchFileTypeByExtension(current?.file_path)) === FILE_TYPES.VIDEO &&
                                                <Videocam className={styles.thumb_icon}/>
                                            }
                                            {matchFileType(matchFileTypeByExtension(current?.file_path)) === FILE_TYPES.PDF &&
                                                <PictureAsPdf className={styles.thumb_icon}/>
                                            }
                                            {matchFileType(matchFileTypeByExtension(current?.file_path)) === FILE_TYPES.DOC &&
                                                <Description className={styles.thumb_icon}/>
                                            }
                                            <div className={styles.full_screen}>
                                                <ZoomOutMap
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleOpen(current?.file_path, matchFileTypeByExtension(current?.file_path));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                            ) : (
                                <></>
                            ))}
                </section>
            </section>
        </>
    )
};

export default Sidebar;