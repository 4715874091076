import React from 'react';
import SearchBar from '../../../../components/SearchBar';
import Logo from '../../../../assets/icons/logo.svg';
import { Link } from 'react-router-dom';
import routes from '../../../../routes';

import styles from '../styles.module.scss';

const UploadBar = () => {
    return (
        <div className={styles.upload_bar}>
            <section className={styles.logo}>
                <Link to={routes.home.path}>
                    <img src={Logo} alt="parpetsi" />
                </Link>
            </section>
            <SearchBar fullwidth={true} />
        </div>
    );
};

export default UploadBar;