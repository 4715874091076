// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_dialog_content__282Gl {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 20px auto 30px; }\n\n.styles_main_icon__1QZRN {\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  padding: 10px;\n  background-color: #777777;\n  display: flex;\n  justify-content: center;\n  align-items: center; }\n\n.styles_dialog_actions__1xJy7 {\n  display: flex;\n  justify-content: center;\n  margin: 10px auto 40px;\n  width: 400px; }\n  .styles_dialog_actions__1xJy7:first-child {\n    margin-right: 10px; }\n\n.styles_owners__3mV8W {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  width: 100%; }\n\n.styles_field_divider__1WS94 {\n  margin-top: 10px; }\n", ""]);
// Exports
exports.locals = {
	"dialog_content": "styles_dialog_content__282Gl",
	"main_icon": "styles_main_icon__1QZRN",
	"dialog_actions": "styles_dialog_actions__1xJy7",
	"owners": "styles_owners__3mV8W",
	"field_divider": "styles_field_divider__1WS94"
};
module.exports = exports;
