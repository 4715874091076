import { IUploadedFile, IFormikValues } from "../types";

export const mapDocumentsToFormikValues = (documents: IUploadedFile[]) => {
    const docs = documents.map(document => {
        return {
            name: document.Key,
            date: '',
            place: '',
            group: '',
            approximateDate: false,
            from: '',
            to: '',
            period: '',
            isDateRange: false,
            dateFrom: '',
            dateTo: '',
            occasion: '',
            files: [
                {
                    file_path: document.Location,
                    information: '',
                    narratives: '',
                    document_source: '',
                    people: '',
                    face_tags: [],
                },
            ],
        };
    });

    return docs;
};

export const initializeFormikValues = (documents: IUploadedFile[]) => {
    return mapDocumentsToFormikValues(documents);
};

export const updateFormikValues = (values: IFormikValues, documents: IUploadedFile[], deletedDocumentKey: string) => {
    const documentsList = mapDocumentsToFormikValues(documents);
    let uniqueNewDocuments;
    let updatedFormikValues;

    // case if added new document
    if (!deletedDocumentKey) {
        uniqueNewDocuments = documentsList.filter((newFile) => {
            return !values.docs.some((existingFile) => existingFile.name === newFile.name);
        });        
        updatedFormikValues = [...values.docs, ...uniqueNewDocuments];
    
    // case if document deleted
    } else if (deletedDocumentKey) {
        updatedFormikValues = values.docs.filter(doc => doc.name !== deletedDocumentKey);
    }

    return updatedFormikValues;
};
