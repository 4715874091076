import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';
import {matchFileType} from '../../../helpers';
import {FILE_TYPES, NOTIFICATION_TYPES} from '../../../constants';
import {showNotification} from '../../../store/notification/notificationSlice';
import useFileUploader from '../FileUploader';
import CircularProgressWithLabel from '../../../components/CircularProgressWithLabel';
import routes from '../../../routes';
import {
    Link,
    useHistory
} from 'react-router-dom';
import {
    Button,
    makeStyles,
} from '@material-ui/core';
import Thumb from './Thumb';
import UploadIcon from '../../../assets_ash/icons/icon_upload.svg';
import { Description } from '@material-ui/icons';
import { PictureAsPdf } from '@material-ui/icons';
import { Videocam } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';

import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
    actionButton: {
        maxWidth: '170px',
        width: '100%',
    },
    actionButtonSecond: {
        marginLeft: '15px',
    },
    actionButtonLoading: {
        height: '50px',
    }
});

function UploadFormFirst(props) {
    const {t} = useTranslation('common');
    const {
        files,
        uploadedFiles,
        progress,
        setFormId,
        setFiles,
        loading,
        setLoading,
        handleUpload,
        isDragActive,
        isDragAccept,
        isDragReject,
        open,
        getRootProps,
        getInputProps,
    } = useFileUploader();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const storedDocuments = JSON.parse(localStorage.getItem('documents'));

    if (storedDocuments && storedDocuments.length) {
        history.push('/documents-upload/upload-form-second');
    }

    const removeFile = (key) => {
        const updatedFiles = files.filter(file => file.name !== key);
        setFiles(updatedFiles);
    };

    const uploadFiles = async () => {
        try {
            await handleUpload();
        } catch (e) {
            dispatch(showNotification({
                message: e.message,
                type: NOTIFICATION_TYPES.ERROR
            }));
            setLoading(false);
        }
    };

    useEffect(() => {
        if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length) {
            history.push('/documents-upload/upload-form-second');
        }
    }, [uploadedFiles]);

    return (
        <section className={styles.container}>
            <div
                {...getRootProps({className: !isDragActive ? styles.dropdown_box : styles.dropdown_box_active})}
                onMouseDown={() => setFormId(1)}
            >
                <input {...getInputProps()} disabled={loading}/>
                <div className={styles.icon_container}>
                    {loading ? (
                        <CircularProgressWithLabel
                            circleSize={72}
                            value={progress}
                            fontSize={'18px'}
                            labelColor={'black'}
                        />
                    ) : (
                        <img src={UploadIcon} className={styles.icon} alt="upload"/>
                    )}
                </div>

                {isDragReject ?
                    (
                        <p className={styles.drag_reject}>{t('upload.first_form.dnd.dnd_format_reject')}</p>
                    ) : (
                        <p className={styles.title}>
                            {loading ? (
                                <span className={styles.home_link}>{t('upload.first_form.dnd.upload_loading')}</span>
                            ) : (
                                <>
                                    <span>{t('upload.first_form.dnd.dnd_access')}</span>
                                    <span className={styles.home_link}>{t('upload.first_form.dnd.dnd_browse')}</span>
                                </>
                            )}

                        </p>
                    )
                }

                <div className={styles.possible_files}>
                    <p className={styles.possible_files_item}>
                        <span className={styles.possible_files_decoration}></span>
                        <span className={styles.possible_files_name}>{t('upload.first_form.dnd.image_formats')} (png, jpg, gif)</span>
                    </p>
                    <p className={styles.possible_files_item}>
                        <span className={styles.possible_files_decoration}></span>
                        <span className={styles.possible_files_name}>{t('upload.first_form.dnd.files_formats')} (pdf, word, excel)</span>
                    </p>
                    <p className={styles.possible_files_item}>
                        <span className={styles.possible_files_decoration}></span>
                        <span
                            className={styles.possible_files_name}>{t('upload.first_form.dnd.videos_formats')} (mp4)</span>
                    </p>
                </div>
            </div>

            {files && !!files.length && (
                <div className={styles.upload_info}>
                    {t('upload.first_form.dnd.selected_count', { count : files.length })}
                </div>
            )}

            <section className={styles.thumbs}>
                {files && !!files.length &&
                    files.map((file, index) => {
                        if (matchFileType(file.type) === FILE_TYPES.IMAGE) {
                            return (
                                <Thumb
                                    key={index}
                                    onRemove={() => removeFile(file.name)}
                                >
                                    <img src={file.preview} alt={file.name}/>
                                </Thumb>
                            )
                        } else if (matchFileType(file.type) === FILE_TYPES.PDF) {
                            return (
                                <Thumb
                                    key={index}
                                    onRemove={() => removeFile(file.name)}
                                >
                                    <PictureAsPdf className={styles.pdf_icon}/>
                                </Thumb>
                            )
                        } else if (matchFileType(file.type) === FILE_TYPES.DOC) {
                            return (
                                <Thumb
                                    key={index}
                                    onRemove={() => removeFile(file.name)}
                                >
                                    <Description className={styles.pdf_icon}/>
                                </Thumb>
                            )
                        } else if (matchFileType(file.type) === FILE_TYPES.VIDEO) {
                            return (
                                <Thumb
                                    key={index}
                                    onRemove={() => removeFile(file.name)}
                                >
                                    <Videocam className={styles.pdf_icon}/>
                                </Thumb>
                            )
                        }
                    })
                }
            </section>

            <div className={styles.actions}>
                <Button
                    size='large'
                    variant='outlined'
                    color='secondary'
                    className={clsx(classes.actionButton, classes.actionButtonSecond, {
                        [classes.actionButtonLoading]: loading
                    })}
                    disabled={(files && !files.length) || (loading ? true : false)}
                    onClick={uploadFiles}
                >
                    {loading ? <CircularProgress size={20} color='inherit'/> : t('generics.next')}
                </Button>
            </div>
        </section>
    );
};

export default UploadFormFirst;
