import React from 'react';
import clsx from 'clsx';
import { Chip, makeStyles } from '@material-ui/core';
import { IAnnotation, TAnnotationData } from '../../interfaces';

import styles from './styles.module.scss';

interface IStaticTags {
    faceTags: IAnnotation[];
    onMouseOver: (tagId: number) => void;
    onMouseOut: (tagId: number) => void;
}

const useStyles = makeStyles({
    customChip: {
        backgroundColor: '#e0e0e0 !important',
        color: 'rgba(0, 0, 0, 0.87) !important',
        marginRight: '0.4rem',
        marginBottom: '0.4rem',
        fontSize: '0.85rem',
        cursor: 'pointer',
    },
});

const StaticTags: React.FC<IStaticTags> = ({
    faceTags,
    onMouseOver,
    onMouseOut,
}) => {
    const classes = useStyles();
    return (
        <div className={styles.chip_container}>
            <label
                className={clsx(styles.chip_container_label, {
                    [styles.active]: faceTags?.length
                })}
            >
            </label>
            {faceTags?.length && (
                faceTags?.map((tag: IAnnotation) => (
                    <Chip
                        key={`${tag.data.name}${Math.random()}`}
                        className={classes.customChip}
                        label={tag.data.name}
                        onMouseOver={() => onMouseOver(tag.data.id)}
                        onMouseLeave={() => onMouseOut(tag.data.id)}
                    />
                )))}
        </div>
    );
};

export default StaticTags;
