import React from 'react';
import {
    Modal,
    makeStyles
} from '@material-ui/core';
import FileViewer from 'react-file-viewer';
import { FILE_TYPES } from '../../constants';
import { matchFileType } from '../../helpers';
import Lightbox from '../Lightbox';

const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    pdf: {
        width: '100%',
        height: '100%',
        textAlign: 'center',
    }
});

const ContentViewer = ({ filePreview, fileType, open, currentFile, handleClose }) => {
    const classes = useStyles();

    const renderContent = () => {
        if (matchFileType(fileType) === FILE_TYPES.IMAGE) {
            return (
                <Lightbox 
                    image={filePreview} 
                    title="Image Title" 
                    currentFile={currentFile} 
                    onClose={handleClose} 
                />
               
            );
        } else if (matchFileType(fileType) === FILE_TYPES.VIDEO) {
            return (
                <video controls style={{maxWidth: "80vw"}}>
                    <source src={filePreview} type={fileType} />
                </video>
            );
        } else if (matchFileType(fileType) === FILE_TYPES.PDF) {
            return (
                <div className={classes.pdf} onClick={handleClose}>
                    <FileViewer fileType="pdf" filePath={filePreview} />
                </div>
            );
        } else if (matchFileType(fileType) === FILE_TYPES.DOC) {
            return (
                <FileViewer fileType="docx" filePath={filePreview} />
            );
        }

        return null;
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            className={classes.modal}
        >
            <>{renderContent()}</>
        </Modal>
    );
};

export default ContentViewer;
