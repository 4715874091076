import React from 'react';
import clsx from 'clsx';
import { THelperClass } from '../../../interfaces';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import styles from './styles.module.scss';

interface IStatisticCard {
    bgColor: THelperClass,
    dataColor: THelperClass,
    statisticCount: string | number,
    statisticTitle: string,
    icon: React.ReactNode,
};

const useStyles = makeStyles({
    count: {
        fontSize: '30px',
        fontWeight: 700,
    },
    title: {
        fontSize: '16px',
        textAlign: 'end',
    },
});

const StatisticCard: React.FC<IStatisticCard> = ({
    bgColor,
    dataColor,
    statisticCount,
    statisticTitle,
    icon,
}) => {
    const classes = useStyles();

    return (
        <div className={clsx(styles.card, bgColor)}>
            <section className={styles.data_info}>
                {icon}
                <Typography className={clsx(classes.count, dataColor)}>
                    {statisticCount}
                </Typography>
            </section>
            <Typography className={classes.title}>
                {statisticTitle}
            </Typography>
        </div>
    );
}

export default StatisticCard;
