import React from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles, Typography } from "@material-ui/core";
import background from "../../../assets_ash/images/faq.png";

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexDirection: 'column',
    },
    viewImage: {
        flex: '10%',
        '& > img': {
            width: '100%',
        }
    },
    viewInfo: {
        flex: '90%',
        padding: '25px 200px 0'
    },
    title: {
        fontSize: 48,
        fontWeight: 400,
        marginBottom: '15px'
    },
    infoText: {
        fontSize: 16,
        color: '#000000',
        margin: '10px 0 5px',
    },
    subTitle: {
        fontSize: 36,
        fontWeight: 400,
    }
});

const AboutUs: React.FC = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();

    return (
        <section className={classes.main}>
            <section className={classes.viewImage}>
                <img src={background} alt="backgroundImage"/>
            </section>
            <section className={classes.viewInfo}>
                <Typography className={classes.title}>
                    {t('about_us.title')}
                </Typography>

                <Typography className={classes.infoText}>
                    The Armenian General Athletic Union and Scouts, Homenetmen, was founded in
                    1918 in Istanbul, Turkey. Homenetmen is predominantly athletic and scouting
                    organization which over the years has branched out into many regions throughout
                    the world. The organization has its bylaws which govern all its activities.
                    Homenetmen Glendale “Ararat” Chapter, was founded on December 15, 1978 and
                    currently is the largest of the 19 Chapters in Western United States Region. At its
                    inception, as dictated by the requirements of the time, “Ararat” Chapter
                    simultaneously founded Athletic and scouting Divisions. Later on Fundraising,
                    Cultural and Programs Divisions were established. The Chapter’s Executive Board is
                    elected by delegates from all Divisions for a one-year term and oversees the
                    activities of all Divisions. In addition to its many volunteers, the Executive Director
                    and a small staff manage the Center and help run the various programs and
                    administrative duties.
                </Typography>

                <Typography className={classes.subTitle}>
                    {t('about_us.sub_title1')}
                </Typography>

                <Typography className={classes.infoText}>
                    In 1975 the Armenian community in Glendale, California began to grow. In 1978,
                    several individuals organized and initiated a scouting program in Glendale. This
                    group of scouts first began their activities with Homenetmen Los Angeles Chapter,
                    and in the fall of 1978 began to conduct their separate scouting activities.
                    Meanwhile, a group of young Armenian athletes came together in Glendale and
                    formed a soccer team that held regular practices. Later, the scouting group and the
                    soccer team were brought together to form Homenetmen Glendale Chapter. Our
                    Chapter’s initial meeting took place on December 15, 1978, at the Armenian Center
                    in Glendale, where the Chapter’s first Executive Board was elected.
                </Typography>

                <Typography className={classes.subTitle}>
                    {t('about_us.sub_title2')}
                </Typography>

                <Typography className={classes.infoText}>
                    Homenetmen’s mission is to prepare exemplary and law-abiding citizens by
                    providing physical and health education, endowing the mind and soul of the
                    members with the finest spirit of sportsmanship and developing an understanding
                    of responsibility and honor as they strive for individual and collective excellence.
                </Typography>
            </section>
        </section>
    )
};

export default AboutUs;