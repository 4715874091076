import React, { useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { selectUserRole } from "../../../store/user/userSlice";
import { DOCUMENT_DRAWER_WIDTH, SIDEBAR_TABS } from '../../../constants';
import routes from '../../../routes';
import { changeSidebarLanguage } from "../../../helpers";
import {
    Drawer,
    makeStyles,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme
} from '@material-ui/core';
import NotificationBadge from "../../../components/NotificationBadge";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Logo from '../../../assets/icons/logo.svg';

const useStyles = makeStyles((theme: Theme) => ({
        drawer: {
            width: DOCUMENT_DRAWER_WIDTH,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            height: '100vh',
        },
        drawerOpen: {
            width: DOCUMENT_DRAWER_WIDTH,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        listOpened: {
            marginTop: '25px',
            marginLeft: '25px',
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        listClosed: {
            marginTop: '25px',
            marginLeft: '10px',
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            backgroundColor: '#ffffff',
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        paper: {
            position: 'relative',
            textAlign: 'center',
        },
        logoContainer: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        logo: {
            height: '35px',
        },
        disappeared: {
            marginRight: '100px',
        },
        listItemSelected: {
            backgroundColor: '#f4f5fd',
            color: '#505050',
            '&:hover' : {
                backgroundColor: '#f4f5fd',
                color: '#505050',
                textDecoration : "none"
            }
        }
    }));

const Sidebar = () => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(true);
    const history = useHistory();
    const currentLanguage = localStorage.getItem('lang' ?? 'hy');

    const userRole = useSelector(selectUserRole);

    const toggleDrawer = () => setOpen(!open);

    const handleNavigation = (path: string): void => history.push('/admin-dashboard' + path);

    const areIconSvg = (path: any): string | boolean => path?.icon ? false : path?.split('/')[3]?.split('.')[2] === 'svg';

    const currentLanguageSidebar = useMemo(() => {
        return SIDEBAR_TABS
            .filter((listItem) => listItem.roles.includes(userRole as string))
            .map((listItem) => {
            const listItemText = changeSidebarLanguage(listItem.text, currentLanguage as string);
            return {
                ...listItem,
                listItemText,
            };
        });
    }, [currentLanguage, userRole])

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx(classes.paper, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar}>
                <div className={clsx(classes.logoContainer, {
                    [classes.disappeared]: !open,
                })}>
                    <Link to={routes.home.path}>
                        <img src={Logo} className={classes.logo} alt="parpetsi" />
                    </Link>
                </div>
                <IconButton onClick={toggleDrawer}>
                    {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>

            <List className={clsx({
                [classes.listOpened]: open,
                [classes.listClosed]: !open,
            })}>
                {currentLanguageSidebar && currentLanguageSidebar.map((listItem) => {
                    return (
                        <ListItem
                            key={listItem.text}
                            button
                            onClick={(e) => handleNavigation(listItem.path)}
                            className={history.location.pathname.split('/')[2] === listItem.path.split('/')[1].split('?')[0] ? classes.listItemSelected : ""}
                        >
                            <ListItemIcon>
                                {areIconSvg(listItem.icon) ? (
                                    <img src={`${listItem.icon}`} alt="document_change" style={{ width: '14px' }}/>
                                ) : (
                                    <FontAwesomeIcon icon={listItem.icon as IconProp}/>
                                )}
                            </ListItemIcon>

                            <ListItemText primary={listItem.listItemText} />
                            {listItem.notification ? (<NotificationBadge type={listItem?.notificationType}/>) : (<></>)}
                        </ListItem>
                    )
                })}
            </List>
        </Drawer>
    );
};

export default Sidebar;
