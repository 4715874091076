import React from 'react';
import {
    Modal,
    Paper,
    Button,
    makeStyles,
} from '@material-ui/core';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        minWidth: '400px',
        maxWidth: '600px', 
        padding: '3rem',
    },
    warningText: {
        width: '100%',
        textAlign: 'center',
        marginBottom: '4rem',
        fontSize: '1.2rem',
    },
    actions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    actionBtn: {
        marginLeft: '1.5rem',
    },
});

interface IWarningModal {
    open: boolean;
    warningText: string;
    setApprove: () => void;
    onCloseModal: () => void;
};

const WarningModal: React.FC<IWarningModal> = ({
    open,
    warningText,
    setApprove,
    onCloseModal
}) => {
    const { t } = useTranslation('common');
    const classes = useStyles();

    return (
        <Modal
            open={open}
            className={classes.modal}
        >
            <Paper
                className={classes.paper}
            >
                <p className={classes.warningText}>{warningText}</p>
                
                <div className={classes.actions}>
                    <Button
                        color='secondary'
                        variant='contained'
                        onClick={setApprove}
                    >
                        <span>{t('upload.second_form.fields.popupContinue')}</span>
                    </Button>
                    <Button
                        color='secondary'
                        variant='outlined'
                        className={classes.actionBtn}
                        onClick={onCloseModal}
                    >
                        <span>{t('upload.second_form.fields.popupCancel')}</span>
                    </Button>
                </div>
            </Paper>
        </Modal>
    );
};

export default WarningModal;
