import React from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles, Typography } from "@material-ui/core";
import background from "../../../assets_ash/images/faq.png";

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexDirection: 'column',
    },
    viewImage: {
        flex: '10%',
        '& > img': {
            width: '100%',
        }
    },
    viewInfo: {
        flex: '90%',
        padding: '25px 200px 0'
    },
    title: {
        fontSize: 48,
        fontWeight: 400,
        marginBottom: '5px'
    },
    subTitle : {
        fontSize: 16,
        fontWeight: 400,
        color: '#707070',
        marginBottom: '15px'
    },
    infoText: {
        fontSize: 16,
        color: '#000000',
        '&:last-child': {
            marginTop: 10
        }
    }
});

const TermsAndConditions: React.FC = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();

    return (
        <section className={classes.main}>
            <section className={classes.viewImage}>
                <img src={background} alt="backgroundImage"/>
            </section>
            <section className={classes.viewInfo}>
                <Typography className={classes.title}>
                    {t('terms_and_conditions.title')}
                </Typography>
                <Typography className={classes.subTitle}>
                    {t('terms_and_conditions.sub_title')}
                </Typography>

                <Typography className={classes.infoText}>
                    These terms and conditions (the “Terms and Conditions” hereafter) govern and apply to anyone accessing or using the HOMENETMEN GLENDALE ARARAT ARCHIVING website located at www.xxxxx.yy, and any associated websites, mobile sites or applications, products, software and other services (collectively, the “Services”). These Terms and Conditions apply to any use of the Services.
                </Typography>
                <Typography className={classes.infoText}>
                    The Services are owned and operated by HOMENETMEN GLENDALE ARARAT and its affiliates, and/or franchisors. While using the Services, please be aware that your access to and use of the Services and any information or documents offered by HOMENETMEN GLENDALE ARARAT are subject to these Terms and Conditions. If you choose to continue to use or access the Services after having the opportunity to read these Terms and Conditions, you are indicating your acceptance of the Terms and Conditions. If you do not agree with any part of these Terms and Conditions or HOMENETMEN GLENDALE ARARAT’s Privacy Policy, please do not use the Services. HOMENETMEN GLENDALE ARARAT eserves the right to modify these Terms and Conditions at any time without notice, and such changes shall be deemed effective immediately upon posting of the modified Terms and Conditions. You should regularly review these Terms and Conditions to ensure that you are aware of, understand and accept any changes made. Your continued use of the Services following the posting of changes to these Terms and Conditions will indicate your acceptance of those changes.
                </Typography>
            </section>
        </section>
    )
};

export default TermsAndConditions;