import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    clearSelectedDocuments,
    downloadDocumentFiles,
    getDocumentFilePaths,
    selectDownloadLoading,
    selectSelectedDocuments,
    setSelected,
    selectFilesCount,
    selectDownloadedFilesCount,
} from "../../../../store/documents/documentsSlice";
import {showNotification} from "../../../../store/notification/notificationSlice";
import {NOTIFICATION_TYPES} from "../../../../constants";
import {Button, CircularProgress, makeStyles, Typography} from "@material-ui/core";
import {BeatLoader} from "react-spinners";

const useStyles = makeStyles({
    button: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        borderRadius: 7,
        marginLeft: 15,
        padding: '20px 25px',
        '@media(max-width: 900px)' : {
            margin: '10px 5px 0 0 '
        }
    },
    buttonText: {
        fontSize: 16,
        color: '#007FDD',
    },
    count: {
        fontSize: 16,
        marginRight: 10
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }
});

const DownloadButton: React.FC = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();

    const selectedDocuments = useSelector(selectSelectedDocuments);
    const downloadLoading = useSelector(selectDownloadLoading);
    const filesCount = useSelector(selectFilesCount);
    const downloadedFilesCount = useSelector(selectDownloadedFilesCount);

    const setSelect = async () => {
        await dispatch(setSelected(false))
        await dispatch(clearSelectedDocuments([]))
    };

    const handleDownloadDocuments = async () => {
        try {
            if (selectedDocuments?.length === 0) {
                await dispatch(showNotification({
                    message: t('header.download.documents_count_zero'),
                    type: NOTIFICATION_TYPES.ERROR
                }));
            } else {
                const filePaths = await dispatch(getDocumentFilePaths(selectedDocuments));
                await dispatch(downloadDocumentFiles(filePaths));
                await dispatch(showNotification({
                    message: t('header.download.download_success'),
                    type: NOTIFICATION_TYPES.SUCCESS
                }));
            }
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    return (
        <section className={classes.buttonContainer}>
            <Button
                variant="outlined"
                className={classes.button}
                onClick={(e) => {
                    e.preventDefault();
                    handleDownloadDocuments()
                }}
                disabled={downloadLoading}
            >
                {downloadLoading ? (
                    <>
                        <Typography className={classes.count}>
                            {`${downloadedFilesCount} / ${filesCount}`}
                        </Typography>
                        <CircularProgress size={20} color="inherit" />
                    </>
                ) : (
                    <>
                        <Typography className={classes.count}>
                            {`${selectedDocuments?.length}/10`}
                        </Typography>
                        <Typography className={classes.buttonText}>
                            {t('header.download.download')}
                        </Typography>
                    </>
                )}
            </Button>
            {!downloadLoading && (
                <Button
                    variant="outlined"
                    className={classes.button}
                    onClick={() => setSelect()}
                    disabled={downloadLoading}
                >
                    <Typography className={classes.buttonText}>
                        {t('header.download.cancel')}
                    </Typography>
                </Button>
            )}
        </section>
    )
}

export default DownloadButton;