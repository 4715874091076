import React from 'react';
import { useSelector } from "react-redux";
import { selectSelected } from "../../../store/documents/documentsSlice";
import SelectDownload from "./SelectDownload";
import DownloadButton from "./Download";

const DownloadFiles: React.FC = () => {

    const selected = useSelector(selectSelected);

    return (
        <>
            {selected ? (
                <DownloadButton />
            ) : (
                <SelectDownload />
            )}
        </>
    )
};

export default DownloadFiles;