import * as Yup from 'yup'
import i18n from '../i18n';

const documentUploadSchema = Yup.object({
    docs: Yup.array().of(
        Yup.object().shape({
            date: Yup.string().when('approximateDate', {
                is: true,
                then: Yup.string(),
                otherwise: Yup.string()
                    .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/, i18n.t('common:upload.second_form.validation.date_format')),
            }),
            place: Yup.string(),
            group: Yup.string(),
            approximateDate: Yup.boolean(),
            from: Yup.number().when('approximateDate', {
                is: (approximateDate, isDateRange) => approximateDate && !isDateRange,
                then: Yup.number(),
                otherwise: Yup.number(),
            }),
            to: Yup.number().when(['approximateDate', 'isDateRange', 'from'], (approximateDate, isDateRange, from, schema) => {
                return approximateDate && !isDateRange
                    ? Yup.number().when('from', (from, schema) => {
                        return schema.test('is-greater-than-from', i18n.t('common:upload.second_form.validation.date_to_min_from'), (value: number) => {
                            if (!from) {
                                return true;
                            }
                            return value > parseInt(from);
                        });
                    })
                    : Yup.number();
            }),
            period: Yup.string().when('approximateDate', {
                is: true,
                then: Yup.string(),
                otherwise: Yup.string(),
            }),
            dateFrom: Yup.string().when(['approximateDate', 'isDateRange'], {
                is: (approximateDate, isDateRange) => approximateDate && isDateRange,
                then: Yup.string()
                    .nullable()
                    .test('is-greater-than-1900', i18n.t('common:upload.second_form.validation.date_min'), (value: any) => {
                        if (!value) {
                            return true;
                        }
                        const dateFrom = new Date(value);
                        const minDate = new Date('01/01/1900');
                        return dateFrom >= minDate;
                    })
                    .when(['dateTo', 'dateFrom'], (dateTo, dateFrom, schema) => {
                        if (!dateTo && !dateFrom) {
                            return schema;
                        }
                        return schema.test('is-less-than-dateTo', i18n.t('common:upload.second_form.validation.date_to_min_from'), (value: string) => {
                            if (!dateTo) {
                                return true;
                            }
                            const dateFrom = new Date(value);
                            const notFormattedDateTo = new Date(dateTo);
                            return dateFrom < notFormattedDateTo;
                        });
                    }),
                otherwise: Yup.string(),
            }),
            dateTo: Yup.string().when(['approximateDate', 'isDateRange'], {
                is: (approximateDate, isDateRange) => approximateDate && isDateRange,
                then: Yup.string()
                    .nullable()
                    .test('is-greater-than-1900', i18n.t('common:upload.second_form.validation.date_min'), (value: any) => {
                        if (!value) {
                            return true;
                        }
                        const dateTo = new Date(value);
                        const minDate = new Date('01/01/1900');
                        return dateTo >= minDate;
                    })
                    .when(['dateFrom', 'dateTo'], (dateFrom, dateTo, schema) => {
                        if (!dateFrom && !dateTo) {
                            return schema;
                        }
                        return schema.test('is-greater-than-dateFrom', i18n.t('common:upload.second_form.validation.date_to_min_from'), (value: string) => {
                            if (!dateFrom) {
                                return true;
                            }
                            const notFormattedDateFrom = new Date(dateFrom);
                            const dateTo = new Date(value);
                            return dateTo > notFormattedDateFrom;
                        });
                    }),
                otherwise: Yup.string(),
            }),
            occasion: Yup.string(),
        })
    ),
});

export default documentUploadSchema;
