import React, { ReactElement } from 'react';
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { changeRevisionDocument } from "../../../../store/documents/documentsSlice";
import { showNotification } from "../../../../store/notification/notificationSlice";
import {
    changeFiledLanguage,
    changeStatusCurrentLanguage
} from "../../../../helpers";
import routes from "../../../../routes";
import { formatDate, parseJSONIfValid, formatValue } from "../../../../helpers/documentChangeHelpers";
import { NOTIFICATION_TYPES } from "../../../../constants";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { CancelOutlined, CheckCircle, Close, Done, SettingsOutlined } from "@material-ui/icons";
import ActionsPopover from "../../../AdminDashboard/ActionsPopover";

type RevisionTab = {
    field: string,
    value: string,
    old_value: string,
    id: string,
    status: string
}

const useStyles = makeStyles({
    tab: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: "10px"
    },
    field: {
        display: "flex",
        justifyContent: 'flex-start',
        flex: '10%',
        padding: '10px'
    },
    values: {
        display: 'flex',
        flexDirection: 'row',
        flex: "80%",
    },
    value: {
        display: "flex",
        justifyContent: 'flex-start',
        padding: '10px',
        borderBottom: '1px solid black',
        flex: '50%'
    },
    button: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        borderBottom: '1px solid #DEDEDE',
        padding: '10px'
    },
    icons: {
        marginRight: '10px',
        width: '20px',
    },
    iconText: {
        fontSize: '15px',
        color: "#707070"
    },
    valueStatus: {
        display: "flex",
        justifyContent: 'space-between',
        padding: '10px',
        borderBottom: '1px solid black',
        flex: '50%'
    }
})

const RevisionTab: React.FC<RevisionTab> = ({field, value, old_value, id,status}) => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();

    const documentReview = useRouteMatch({
        path: [routes.documentChanges.path],
        exact: true,
        strict: true,
        sensitive: true,
    });

    const handleChangeRevision = async (id: string, status: string) => {
        try {
            await dispatch(changeRevisionDocument(id, status));
            dispatch(showNotification({
                message: `${t('document_review.notifications.single_file_action', { status : changeStatusCurrentLanguage(status, true)})}`,
                type: NOTIFICATION_TYPES.SUCCESS
            }));
            window.location.reload();
        } catch (e) {
            dispatch(showNotification({
                message: e.message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    const statusesIcons: Record<string, ReactElement | React.ReactFragment> = {
        'approved': <Done color={"secondary"}/>,
        'declined': <Close color={"error"}/>,
        'pending': <></>,
    };

    return (
        <section className={classes.tab}>
            <section className={classes.field}>
                <Typography
                    variant='body1'
                    component='span'
                >
                    {changeFiledLanguage(field)}
                </Typography>
            </section>
            <section className={classes.values}>
                <section className={classes.value}>
                    <Typography
                        variant='body1'
                        component='span'
                    >
                        {parseJSONIfValid(old_value) && field !== 'face_tags' ? formatDate(old_value) : formatValue(field, old_value)}
                    </Typography>
                </section>
                <section className={classes.valueStatus}>
                    <Typography
                        variant='body1'
                        component='span'
                    >
                        {parseJSONIfValid(value) && field !== 'face_tags' ? formatDate(value) : formatValue(field, value)}
                    </Typography>
                    <Typography
                        variant='body1'
                        component='span'
                    >
                        {statusesIcons[status]}
                    </Typography>
                </section>
            </section>

            {!documentReview && (
                <section>
                    <ActionsPopover
                        isColumn={true}
                        icon={<SettingsOutlined style={{color: '#8D8D8D'}}/>}
                    >
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleChangeRevision(id, 'approve');
                            }}
                            color="secondary"
                            className={classes.button}
                        >
                            <CheckCircle
                                className={classes.icons}
                                style={{color: '#007FDD'}}
                            />
                            <Typography className={classes.iconText}>
                                {t('document_review.actions.approve')}
                            </Typography>
                        </Button>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleChangeRevision(id, 'decline');
                            }}
                            color="secondary"
                            className={classes.button}
                            style={{border: 'none'}}
                        >
                            <CancelOutlined
                                className={classes.icons}
                                style={{color: '#E63510'}}/>
                            <Typography
                                className={classes.iconText}
                                style={{color: '#E63510'}}
                            >
                                {t('document_review.actions.decline')}
                            </Typography>
                        </Button>
                    </ActionsPopover>
                </section>
            )}

        </section>
    )
};

export default RevisionTab;