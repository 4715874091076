// Annotation types
import { 
    IAnnotation,
    TAnnotationGeometry,
    TAnnotationData,
    TAnnotationId,
} from '../interfaces';

export const applyAnnotations = (
    documentIndex: number, 
    geometry: TAnnotationGeometry, 
    data: TAnnotationData, 
    annotations: IAnnotation[][], 
    setAnnotations: (updatedAnnotations: IAnnotation[][]) => void
    ) => {
    const annotationsData: any = {};

    // Changing name of the default 'text' property in data object
    for (let prop in data) {
        if (prop === 'text') {
            annotationsData['name'] = data[prop];
        } else {
            annotationsData[prop] = data[prop as keyof TAnnotationData];
        }
    }

    // Updating current file (document) annoations
    const currentFileAnnotations = annotations[documentIndex];
    const updatedCurrentFileAnnotations = [...currentFileAnnotations, { geometry, data: { ...annotationsData, id: Math.random() }, name: data.text }]
    
    // Updating all existed annotations
    const updatedAllFilesAnnotations: any = [...annotations];
    updatedAllFilesAnnotations[documentIndex] = updatedCurrentFileAnnotations;
    
    setAnnotations(updatedAllFilesAnnotations);
};

export const deleteAnnotation = (documentIndex: number, data: TAnnotationData, annotations: IAnnotation[][], setAnnotations: (updatedAnnotations: IAnnotation[][]) => void) => {
    const updatedCurrentDocumentAnnotations = [...annotations[documentIndex].filter((annot) => annot.data.id !== data.id)];
    const updatedAnnotations = [...annotations];
    updatedAnnotations[documentIndex] = updatedCurrentDocumentAnnotations;

    setAnnotations(updatedAnnotations);
};

export const activeAnnotationComparator = (a: IAnnotation, b: TAnnotationId) => {
    return a.data.id === b;
};
