import React, { useEffect, useMemo, useState } from 'react';
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    makeStyles,
    MenuItem,
    Popover,
    RadioGroup,
    Select,
    Typography,
} from "@material-ui/core";
import { FilterListOutlined } from "@material-ui/icons";
import { DATE_OPTIONS, NOTIFICATION_TYPES, Options } from "../../../constants";
import { COLORS } from "../../../constants/styles";
import { selectIsAuthenticated, selectIsLoading } from "../../../store/user/userSlice";
import { showNotification } from "../../../store/notification/notificationSlice";
import {
    getNoAuthGroupList,
    selectGroupsLoading,
    selectNoAuthGroups
} from "../../../store/groups/groupSlice";
import { filterValidationSchema } from "../../../schemas/filterSchmea";
import { RadioEnum, Filter, TRadio } from "../../../assets_ash/globalTypes";
import { getToken } from "../../../helpers/auth";
import { getYearsToNow } from "../../../helpers/dateTime";
import { BlueRadio, CustomSelect } from "../../Select";
import styles from "./styles.module.scss";

const useStyles = makeStyles({
    filterIcon: {
        fontSize: '20px',
        marginRight: '5px'
    },
    filterButton: {
        display: 'flex',
        alignItems: 'center',
        height: '50px',
        borderRadius: '7px',
        marginLeft: '15px',
        padding: '20px 25px',
        '@media(max-width: 1200px)' : {
            marginTop: 10,
            marginLeft: 0
        }
    },
    actionButton: {
        border: 'none',
        backgroundColor: 'transparent',
        marginRight: 0,
    },
    formGroup: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        width: '300px'
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'column',
    },
    submitButton: {
        width: "250px",
        marginBottom: '10px',
        '& .MuiButton-label': {
            fontSize: '18px',
        },
        '&.MuiButton-containedPrimary': {
            backgroundColor: '#007FDD',
        },
        '&.MuiButton-containedPrimary:hover': {
            backgroundColor: '#007FDD',
        },
    },
    cancelButton: {
        width: "250px",
        border: 'none',
        borderRadius: '8px',
        textAlign: 'center',
        '& .MuiButton-label': {
            fontSize: '18px',
            color: "#007FDD",
        },
    },
    selectIcon: {
        color: COLORS.PURE_BLUE,
        marginRight: '10px'
    },
    clearAll : {
        width: "250px",
        border: 'none',
        borderRadius: '8px',
        textAlign: 'center',
        '& .MuiButton-label': {
            fontSize: '18px',
            color: "#007FDD",
        },
    }
});

const FilterModal: React.FC<Filter> = ({handleFilter}) => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();

    const isLoading = useSelector(selectIsLoading);
    const groups = useSelector(selectNoAuthGroups);
    const isGroupsLoading = useSelector(selectGroupsLoading);
    const isAuth = useSelector(selectIsAuthenticated);

    const currentLanguage = localStorage.getItem('lang') ?? 'hy'

    const currentLanguageMonths = useMemo(() => {
        return DATE_OPTIONS.months[currentLanguage as keyof Options];
    }, [currentLanguage]);

    const currenLanguageLastUploads = useMemo(() => {
        return DATE_OPTIONS.last[currentLanguage as keyof Options]
    }, [currentLanguage]);

    const approxDate = useMemo(getYearsToNow, []);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [sortType, setSortType] = useState<TRadio>('');

    const [lastUploads, setLastUploads] = useState<string>('');
    const [myUploads, setMyUploads] = useState<string>('');
    const [group, setGroup] = useState<string>('');

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const formik = useFormik({
        initialValues: {
            fromYear: '',
            fromMonth: '',
            toYear: '',
            toMonth: '',
        },
        validationSchema: sortType === 'date' ? filterValidationSchema : null,
        onSubmit: (values) => {
            handleFilter(values, lastUploads, myUploads, group);
        },
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        resetForm,
        setValues
    } = formik;

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const clearForm = (): void => {
        setValues({fromYear: '', fromMonth: '', toYear: '', toMonth: ''}, true)
        resetForm();
        setLastUploads('');
        setGroup('');
        setMyUploads('');
        setSortType('');
    };

    const getGroups = async () => {
        try {
            await dispatch(getNoAuthGroupList(1));
        } catch (e) {
            dispatch(showNotification({
                message: e.message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    useEffect(() => {
        if (sortType !== RadioEnum.BY_DATE && !!open) {
            setValues({fromYear: '', fromMonth: '', toYear: '', toMonth: ''}, true)
        }
        if (sortType !== RadioEnum.BY_LAST) {
            setLastUploads('');
        }
        if (sortType !== RadioEnum.BY_GROUP) {
            setGroup('');
        }
        if(sortType !== RadioEnum.BY_ME) {
            setMyUploads('');
        }
        if (sortType === RadioEnum.BY_GROUP) {
            getGroups();
        }
    }, [sortType]);

    return (
        <>
            <Button
                aria-describedby={id}
                variant="outlined"
                className={classes.filterButton}
                onClick={handleClick}
            >
                <FilterListOutlined
                    className={classes.filterIcon}
                    color="secondary"
                />
                <Typography>
                    {t('header.filters.button')}
                </Typography>
            </Button>
            <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <FormControl
                    component="fieldset"
                    className={classes.formGroup}>

                    {isAuth && getToken() && (
                        <RadioGroup
                            aria-label="myUploads"
                            name="myUploads"
                            className={classes.radioGroup}
                            value={sortType}
                            onChange={(e)=> {
                                setSortType(e.target.value as RadioEnum)
                                setMyUploads('me')
                            }}
                        >
                            <FormControlLabel
                                value={RadioEnum.BY_ME}
                                control={<BlueRadio/>}
                                label={t('header.filters.my_uploads')}
                            />
                        </RadioGroup>
                    )}

                    <RadioGroup
                        aria-label="answer"
                        name="lastUploads"
                        className={classes.radioGroup}
                        value={sortType}
                        onChange={(e) => {
                            setSortType(e.target.value as RadioEnum)
                        }}
                    >
                        <FormControlLabel
                            value={RadioEnum.BY_LAST}
                            control={<BlueRadio/>}
                            label={t('header.filters.last_uploads')}
                        />
                    </RadioGroup>
                    {sortType === RadioEnum.BY_LAST && (
                        <section className={styles.sort_by_group}>
                            <div className={styles.sort_by_group_title}>
                                <p>{t('header.filters.last_uploads')}</p>
                            </div>
                            <FormControl>
                                <Select
                                    name='lastUploads'
                                    value={lastUploads}
                                    onChange={(e) => setLastUploads(e.target?.value as string)}
                                    input={<CustomSelect/>}
                                    classes={{icon: classes.selectIcon}}
                                >
                                    {currenLanguageLastUploads && currenLanguageLastUploads.map((option: string) => (
                                        <MenuItem
                                            value={option}
                                            key={option}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </section>
                    )}

                    <RadioGroup
                        aria-label="answer"
                        name="answer"
                        className={classes.radioGroup}
                        value={sortType}
                        onChange={(e) => {
                            setSortType(e.target.value as RadioEnum)
                        }}
                    >
                        <FormControlLabel
                            value={RadioEnum.BY_GROUP}
                            control={<BlueRadio/>}
                            label={t('header.filters.by_group')}
                        />
                    </RadioGroup>
                    {sortType === RadioEnum.BY_GROUP && (
                        <section className={styles.sort_by_group}>
                            <div className={styles.sort_by_group_title}>
                                <p>{t('header.filters.group')}</p>
                            </div>
                            <FormControl>
                                <Select
                                    name='group'
                                    value={group}
                                    onChange={(e) => setGroup(e.target.value as string)}
                                    input={<CustomSelect/>}
                                    classes={{icon: classes.selectIcon}}
                                >
                                    {isGroupsLoading && (
                                        <MenuItem>
                                            <BeatLoader/>
                                        </MenuItem>
                                    )}
                                    {!isGroupsLoading && groups && groups.map(group => {
                                        return (
                                            <MenuItem
                                                key={group.name}
                                                value={group.id}
                                            >
                                                {group.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </section>
                    )}

                    <RadioGroup
                        aria-label="answer"
                        name="answer"
                        className={classes.radioGroup}
                        value={sortType}
                        onChange={(e) => {
                            setSortType(e.target.value as RadioEnum);
                        }}
                    >
                        <FormControlLabel
                            value={RadioEnum.BY_DATE}
                            control={<BlueRadio/>}
                            label={t('header.filters.by_date_range')}
                        />
                    </RadioGroup>
                    {sortType === RadioEnum.BY_DATE && (
                        <form onSubmit={handleSubmit}>
                            <section className={styles.sort_by_group}>
                                <div className={styles.sort_by_group_title}>
                                    <p>{t('header.filters.date_from')}</p>
                                </div>
                                <div className={styles.sort_by_group_radio}>
                                    <FormControl>
                                        <Select
                                            name='fromYear'
                                            value={values.fromYear}
                                            onChange={handleChange}
                                            error={!!errors.fromYear && touched.fromYear}
                                            input={<CustomSelect/>}
                                            classes={{icon: classes.selectIcon}}
                                            fullWidth
                                            displayEmpty
                                        >
                                            {approxDate && approxDate.map((option: any) => (
                                                <MenuItem
                                                    value={option}
                                                    key={option}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.fromYear && touched.fromYear && (
                                            <FormHelperText error>{errors.fromYear}</FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl>
                                        <Select
                                            name='fromMonth'
                                            value={values.fromMonth}
                                            onChange={handleChange}
                                            error={!!errors.fromMonth && touched.fromMonth}
                                            input={<CustomSelect/>}
                                            classes={{icon: classes.selectIcon}}
                                            fullWidth
                                            displayEmpty
                                        >
                                            {currentLanguageMonths && currentLanguageMonths?.map((option: any) => (
                                                <MenuItem
                                                    value={option}
                                                    key={option}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.fromMonth && touched.fromMonth && (
                                            <FormHelperText error>{errors.fromMonth}</FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </section>
                            <section className={styles.sort_by_group}>
                                <div className={styles.sort_by_group_title}>
                                    <p>{t('header.filters.date_to')}</p>
                                </div>
                                <div className={styles.sort_by_group_radio}>
                                    <FormControl>
                                        <Select
                                            name='toYear'
                                            value={values.toYear}
                                            onChange={handleChange}
                                            error={!!errors.toYear && touched.toYear}
                                            input={<CustomSelect/>}
                                            classes={{icon: classes.selectIcon}}
                                            fullWidth
                                            displayEmpty
                                        >
                                            {approxDate && approxDate.map((option: any) => (
                                                <MenuItem
                                                    value={option}
                                                    key={option}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.toYear && touched.toYear && (
                                            <FormHelperText error>{errors.toYear}</FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl>
                                        <Select
                                            name='toMonth'
                                            value={values.toMonth}
                                            onChange={handleChange}
                                            error={!!errors.toMonth && touched.toMonth}
                                            input={<CustomSelect/>}
                                            classes={{icon: classes.selectIcon}}
                                            fullWidth
                                            displayEmpty
                                        >
                                            {currentLanguageMonths && currentLanguageMonths?.map((option: any) => (
                                                <MenuItem
                                                    value={option}
                                                    key={option}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.toMonth && touched.toMonth && (
                                            <FormHelperText error>{errors.toMonth}</FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </section>
                        </form>
                    )}

                    <Button
                        variant='contained'
                        color='primary'
                        className={classes.submitButton}
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        {isLoading ? <CircularProgress size={25} color='inherit'/> : t('header.filters.filter_button')}
                    </Button>
                    <Button
                        variant='outlined'
                        className={classes.cancelButton}
                        onClick={(e) => {
                            e.preventDefault();
                            handleClose();
                        }}
                    >
                        {t('header.filters.close')}
                    </Button>
                    <Button
                        className={classes.clearAll}
                        onClick={(e) => {
                            e.preventDefault();
                            clearForm();
                        }}
                    >
                        {t('header.filters.clear')}
                    </Button>
                </FormControl>
            </Popover>
        </>
    )
};

export default FilterModal;