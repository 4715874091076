import * as Yup from 'yup';
import i18n from '../i18n';

const createGroupSchema = Yup.object({
    groupName: Yup.string().required(i18n.t('common:admin.group_table.validations.group_name_required')),
    withOwners: Yup.boolean(),
    owners: Yup.array().when('withOwners', {
        is: true,
        then: Yup.array().of(
            Yup.object().shape({
                user_id: Yup.string().required(i18n.t('common:admin.group_table.validations.group_owner_name_required')),
                from: Yup.number()
                    .required(i18n.t('common:admin.group_table.validations.year_from_required'))
                    .min(1800, i18n.t('common:admin.group_table.validations.date_min')),
                to: Yup.number()
                    .required(i18n.t('common:admin.group_table.validations.year_to_required'))
                    .test('is-greater-than-from', i18n.t('common:admin.group_table.validations.year_to_greater_from'), function (value: any) {
                        const from = this.parent.from;
                        return value > from;
                    }),
            })
        ).required(i18n.t('common:admin.group_table.validations.at_least_one_owner')),
        otherwise: Yup.array().notRequired(),
    }),
});

export default createGroupSchema;
