import React, { ReactElement } from 'react';
import {
    Popover,
    Button,
    Card,
    Typography,
    makeStyles,
    createStyles,
    Theme
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionButton: {
            border: 'none',
            backgroundColor: 'transparent',
            marginRight: 0,
        },
        card: {
            display: 'flex',
            flexDirection: 'column',
            padding: '10px'
        }
    }),
);

interface IActionPopover {
    isColumn?: boolean
    icon?: ReactElement
}

const ActionsPopover: React.FC<IActionPopover> = ({ isColumn, icon, children }) => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Button
                aria-describedby={id}
                variant="outlined"
                className={classes.actionButton}
                onClick={handleClick}
            >
                <Typography>
                    {icon ? icon : t('table_columns.more')}
                    {open ? <KeyboardArrowUp color="secondary" /> : <KeyboardArrowDown color="secondary" />}
                </Typography>
            </Button>
            <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Card className={isColumn ?  classes.card : ''}>
                    {children}
                </Card>
            </Popover>
        </div>
    );
};

export default ActionsPopover;
