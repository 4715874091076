import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { selectLoading } from "../../../store/documents/documentsSlice";
import { getYearsToNow } from "../../../helpers/dateTime";
import { DATE_OPTIONS, Options } from "../../../constants";
import { filterValidationSchema } from "../../../schemas/filterSchmea";
import { FilterListOutlined } from "@material-ui/icons";
import { COLORS } from "../../../constants/styles";
import {
    Button, CircularProgress,
    createStyles, FormControl,
    FormControlLabel, FormHelperText,
    makeStyles, MenuItem,
    Popover, RadioGroup,
    Select, Theme,
    Typography,
} from "@material-ui/core";
import { RadioEnum, TRadio, TStatus} from "../../../assets_ash/globalTypes";
import styles from "../../AdminDashboard/DocumentsTable/styles.module.scss";
import { BlueRadio, CustomSelect } from '../../../components/Select';

const useStyles = makeStyles((theme: Theme) => createStyles({
    filterIcon: {
        fontSize: '20px',
    },
    filterButton: {
        display: 'flex',
        alignItems: 'center',
        height: '50px',
        borderRadius: '7px',
        marginLeft: '15px',
        padding: '20px 25px'
    },
    formGroup: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        width: '300px'
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'column',
    },
    selectIcon: {
        color: COLORS.PURE_BLUE,
        marginRight: '10px'
    },
    submitButton: {
        width: "250px",
        marginBottom: '10px',
        '& .MuiButton-label': {
            fontSize: '18px',
        },
        '&.MuiButton-containedPrimary': {
            backgroundColor: '#007FDD',
        },
        '&.MuiButton-containedPrimary:hover': {
            backgroundColor: '#007FDD',
        },
    },
    cancelButton: {
        width: "250px",
        border: 'none',
        borderRadius: '8px',
        '& .MuiButton-label': {
            fontSize: '18px',
            color: "#007FDD",
        },
    },
}));

type Filter = {
    getDocuments: any;
}

const Filter: React.FC<Filter> = ({getDocuments}) => {
    const classes = useStyles();
    const { t } = useTranslation('common');

    const isLoading = useSelector(selectLoading);

    const currentLanguage = localStorage.getItem('lang') ?? 'hy'

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [filterType, setFilterType] = useState<TRadio>('');
    const [status, setStatus] = useState<TStatus>('');
    const [lastUpload, setLastUpload] = useState<string>('');

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setAnchorEl(null)
    };

    const currentLanguageLastUploads = useMemo(() => {
        return DATE_OPTIONS.last[currentLanguage as keyof typeof DATE_OPTIONS.last];
    }, [currentLanguage]);

    const currentLanguageStatus = useMemo(() => {
        return DATE_OPTIONS.document_statues[currentLanguage as keyof typeof DATE_OPTIONS.document_statues];
    }, [currentLanguage])

    const currentLanguageMonths = useMemo(() => {
        return DATE_OPTIONS.months[currentLanguage as keyof Options];
    }, [currentLanguage]);

    const approxDate = useMemo(getYearsToNow, []);

    const formik = useFormik({
        initialValues: {
            fromYear: '',
            fromMonth: '',
            toYear: '',
            toMonth: '',
        },
        validationSchema: filterType === 'date' ? filterValidationSchema : null,
        onSubmit: (values) => {
            getDocuments(values, lastUpload, status)
        },
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setValues
    } = formik;

    useEffect(() => {
        if (filterType !== RadioEnum.BY_DATE) {
            setValues({fromYear: '', fromMonth: '', toYear: '', toMonth: ''}, true)
        }
        if (filterType !== RadioEnum.BY_LAST) {
            setLastUpload('');
        }
        if (filterType !== RadioEnum.BY_STATUS) {
            setStatus('');
        }
    }, [filterType]);

    return (
        <div>
            <Button
                aria-describedby={id}
                variant="outlined"
                className={classes.filterButton}
                onClick={handleClick}
            >
                <FilterListOutlined
                    className={classes.filterIcon}
                    color="secondary"
                />
                <Typography>
                    {t('header.filters.button')}
                </Typography>
            </Button>
            <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <FormControl
                    component="fieldset"
                    className={classes.formGroup}
                >
                    <RadioGroup
                        aria-label="answer"
                        name="answer"
                        className={classes.radioGroup}
                        value={filterType}
                        onChange={(e) => {
                            setFilterType(e.target.value as RadioEnum);
                        }}
                    >
                        <FormControlLabel
                            value={RadioEnum.BY_LAST}
                            control={<BlueRadio/>}
                            label={t('header.filters.last_uploads')}
                        />
                    </RadioGroup>

                    {filterType === RadioEnum.BY_LAST && (
                        <section className={styles.sort_by_group}>
                            <div className={styles.sort_by_group_title}>
                                <p>{t('header.filters.last_uploads')}</p>
                            </div>
                            <Select
                                name='lastUpload'
                                value={lastUpload}
                                onChange={(e) => setLastUpload(e.target.value as string)}
                                input={<CustomSelect/>}
                                classes={{icon: classes.selectIcon}}
                            >
                                {currentLanguageLastUploads && currentLanguageLastUploads.map((option: string) => (
                                    <MenuItem
                                        value={option}
                                        key={option}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </section>
                    )}

                    <RadioGroup
                        aria-label="answer"
                        name="answer"
                        className={classes.radioGroup}
                        value={filterType}
                        onChange={(e) => {
                            setFilterType(e.target.value as RadioEnum)
                        }}
                    >
                        <FormControlLabel
                            value={RadioEnum.BY_STATUS}
                            control={<BlueRadio/>}
                            label={t('header.filters.by_status')}
                        />
                    </RadioGroup>

                    {filterType === RadioEnum.BY_STATUS && (
                        <section className={styles.sort_by_group}>
                            <div className={styles.sort_by_group_title}>
                                <p>{t('header.filters.by_status')}</p>
                            </div>
                            <Select
                                value={status}
                                input={<CustomSelect/>}
                                onChange={(e: any) => setStatus(e.target?.value)}
                                classes={{icon: classes.selectIcon}}
                            >
                                {currentLanguageStatus && currentLanguageStatus.map((per_status) => {
                                    return (
                                        <MenuItem
                                            key={per_status.status}
                                            value={per_status.status}
                                        >
                                            {per_status.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </section>
                    )}

                    <RadioGroup
                        aria-label="answer"
                        name="answer"
                        className={classes.radioGroup}
                        value={filterType}
                        onChange={(e) => {
                            setFilterType(e.target.value as RadioEnum);
                        }}
                    >
                        <FormControlLabel
                            value={RadioEnum.BY_DATE}
                            control={<BlueRadio/>}
                            label={t('header.filters.by_date_range')}
                        />
                    </RadioGroup>

                    {filterType === RadioEnum.BY_DATE && (
                        <form onSubmit={formik.handleSubmit}>
                            <section className={styles.sort_by_group}>
                                <div className={styles.sort_by_group_title}>
                                    <p>{t('header.filters.date_from')}</p>
                                </div>
                                <div className={styles.sort_by_group_radio}>
                                    <FormControl>
                                        <Select
                                            name='fromYear'
                                            value={values.fromYear}
                                            onChange={handleChange}
                                            error={!!errors.fromYear && touched.fromYear}
                                            input={<CustomSelect/>}
                                            classes={{icon: classes.selectIcon}}
                                            fullWidth
                                            displayEmpty
                                        >
                                            {approxDate && approxDate.map((option: any) => (
                                                <MenuItem
                                                    value={option}
                                                    key={option}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.fromYear && touched.fromYear && (
                                            <FormHelperText error>{errors.fromYear}</FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl>
                                        <Select
                                            name='fromMonth'
                                            value={values.fromMonth}
                                            onChange={handleChange}
                                            error={!!errors.fromMonth && touched.fromMonth}
                                            input={<CustomSelect/>}
                                            classes={{icon: classes.selectIcon}}
                                            fullWidth
                                            displayEmpty
                                        >
                                            {currentLanguageMonths && currentLanguageMonths.map((option: any) => (
                                                <MenuItem
                                                    value={option}
                                                    key={option}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.fromMonth && touched.fromMonth && (
                                            <FormHelperText
                                                error>{errors.fromMonth}</FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </section>
                            <section className={styles.sort_by_group}>
                                <div className={styles.sort_by_group_title}>
                                    <p>{t('header.filters.date_to')}</p>
                                </div>
                                <div className={styles.sort_by_group_radio}>
                                    <FormControl>
                                        <Select
                                            name='toYear'
                                            value={values.toYear}
                                            onChange={handleChange}
                                            error={!!errors.toYear && touched.toYear}
                                            input={<CustomSelect/>}
                                            classes={{icon: classes.selectIcon}}
                                            fullWidth
                                            displayEmpty
                                        >
                                            {approxDate && approxDate.map((option: any) => (
                                                <MenuItem
                                                    value={option}
                                                    key={option}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.toYear && touched.toYear && (
                                            <FormHelperText error>{errors.toYear}</FormHelperText>
                                        )}
                                    </FormControl>

                                    <FormControl>
                                        <Select
                                            name='toMonth'
                                            value={values.toMonth}
                                            onChange={handleChange}
                                            error={!!errors.toMonth && touched.toMonth}
                                            input={<CustomSelect/>}
                                            classes={{icon: classes.selectIcon}}
                                            fullWidth
                                            displayEmpty
                                        >
                                            {currentLanguageMonths && currentLanguageMonths.map((option: any) => (
                                                <MenuItem
                                                    value={option}
                                                    key={option}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.toMonth && touched.toMonth && (
                                            <FormHelperText error>{errors.toMonth}</FormHelperText>
                                        )}
                                    </FormControl>
                                </div>
                            </section>
                        </form>
                    )}

                    <Button
                        variant='contained'
                        color='primary'
                        className={classes.submitButton}
                        onClick={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        {isLoading ? <CircularProgress size={25} color='inherit'/> : t('header.filters.filter_button')}
                    </Button>

                    <Button
                        variant='outlined'
                        className={classes.cancelButton}
                        onClick={(e) => {
                            e.preventDefault();
                            setFilterType('');
                            handleClose(e);
                        }}
                    >
                        {t('header.filters.close')}
                    </Button>

                </FormControl>
            </Popover>

        </div>
    )
};

export default Filter