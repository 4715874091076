export enum currentLanguage {
    en = 'en',
    hy = 'hy'
}

export const LocalizationSettings = {
    defaultLanguage: 'en',
    routerPrefix: '/:locale(en|hy)?',
    appliedLanguage: ['hy', 'en'],
}

export const localizeRouth = (path: string): string => `${LocalizationSettings.routerPrefix}${path}`;

export const changeLanguageQuery = (): void => {
    const currentLng = localStorage.getItem('lang');

    const languageActions: Record<string, () => void> = {
        'hy': () => {
            const url = new URL(window.location.href);
            url.pathname = url.pathname.replace('/hy', '/en');
            window.location.replace(url.href);
        },
        'en': () => {
            const url = new URL(window.location.href);
            url.pathname = url.pathname.replace('/en', '/hy');
            window.location.replace(url.href);
        },
    };

    const handler = languageActions[currentLng as currentLanguage];
    if (handler) {
        handler()
    }
};

export const getInitialLocalization = (): string => {
    const prefix = window.location.pathname.split('/')[1];
    const locationPath = getLocationPathName(prefix);
    if (locationPath) {
        return locationPath;
    }
    if (prefix === '') {
        localStorage.setItem('lang', LocalizationSettings.defaultLanguage);
        window.history.replaceState(null, '', '/en');
        return 'en';
    }

    localStorage.setItem('lang', LocalizationSettings.defaultLanguage);

    return '';
};

const getLocationPathName = (prefix: string) => {
    if (LocalizationSettings.appliedLanguage.includes(prefix)) {
        localStorage.setItem('lang', prefix);

        return prefix;
    }
};

export const getLanguage = (): string => {
    const prefix = window.location.pathname.split('/')[1];
    const locationPath = getLocationPathName(prefix);
    if (locationPath) {
        return locationPath;
    } else {
        localStorage.setItem('lang', LocalizationSettings.defaultLanguage);
    }

    return window.location.pathname === '/'
        ? LocalizationSettings.defaultLanguage
        : localStorage.getItem('lang')!;
};
