import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import { IError, IUser } from '../../interfaces';
import { AppDispatch, AppThunk, RootState } from '..';
import { AxiosError } from "axios";
import { NOTIFICATION_MESSAGES } from "../../constants";

export type TUserRole = 'basic' | 'group_owner' | 'private_viewer' | 'admin' | null;
export type TPerPage = '-1' | number | null;

interface IInitialState {
    users: IUser[];
    user: IUser | null;
    usersByRole: IUser[] | null;
    loading: boolean;
    loadingUsersByRole: boolean;
    error: IError | null;
    total: number | null;
    perPage: number | null;
    reports: Record<string, string>[] | null
}

const initialState: IInitialState = {
    users: [],
    user: null,
    usersByRole: null,
    loading: false,
    loadingUsersByRole: false,
    error: null,
    total: null,
    perPage: null,
    reports: null
}

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setLoadingUsersByRole: (state, action: any) => {
            state.loadingUsersByRole = action.payload;
        },
        setError: (state, action: PayloadAction<IError | null>) => {
            state.error = action.payload;
        },
        setUsers: (state, action: PayloadAction<IUser[]>) => {
            state.users = action.payload;
        },
        setUser: (state, action: PayloadAction<IUser>) => {
            state.user = action.payload;
        },
        setUsersByRole: (state, action: PayloadAction<IUser[]>) => {
            state.usersByRole = action.payload;
        },
        setPaginationData: (state, action: PayloadAction<any>) => {
            state.total = action.payload.total;
            state.perPage = action.payload.per_page;
        },
        setReports: (state, action: PayloadAction<Record<string, string>[]>) => {
            state.reports = action.payload;
        }
    }
});

export const {
    setLoading,
    setError,
    setUsers,
    setUser,
    setPaginationData,
    setUsersByRole,
    setLoadingUsersByRole,
    setReports
} = adminSlice.actions;

export const getUsersLists = (
    page: number = 1,
    ): AppThunk => async (dispatch: AppDispatch) => {
    
    dispatch(setLoading(true));
    
    try {
        const response = await api.get(`/admin/users?page=${page}`);

        dispatch(setLoading(false));
        dispatch(setError(null));
        dispatch(setUsers(response.data.data));
        dispatch(setPaginationData(response.data.meta));

        return response.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const getUsersByRole = (
    role: TUserRole = null,
    ): AppThunk => async (dispatch: AppDispatch) => {
    
    dispatch(setLoadingUsersByRole(true));
    
    try {
        const response = await api.get(`/admin/users?role=${role}&per_page=-1`);

        dispatch(setLoadingUsersByRole(false));
        dispatch(setError(null));
        dispatch(setUsersByRole(response.data.data));

        return response.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoadingUsersByRole(false));

        throw new Error(errorMessage);
    }
};

export const getUserById = (id: string): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.get(`/admin/users/${id}`);

        dispatch(setLoading(false));
        dispatch(setError(null));
        dispatch(setUser(response.data));

        return response.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const assignRole = (
    userId: string,
    roleId: string
): AppThunk => async (dispatch: AppDispatch) => {
    try {
        const response = await api.put(`/admin/users/${userId}`, {role: roleId});

        dispatch(setLoading(false));
        dispatch(setError(null));

        return response.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const deleteUser = (id: number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.delete(`/admin/users/${id}`);

        dispatch(setLoading(false));
        dispatch(setError(null));

        return response.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const createUser = (): AppThunk => async (dispatch: AppDispatch) => {
    try {
        const response = await api.post(`/admin/users/`);

        dispatch(setLoading(false));
        dispatch(setError(null));

        return response.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
}

export const disableUser = (id: number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = api.put(`/admin/users/${id}`, { status: 'disabled' });

        dispatch(setLoading(false));
        dispatch(setError(null));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
}

export const getReports = (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.get('/admin/reports');

        dispatch(setReports(response.data.data));
        dispatch(setLoading(false));
        dispatch(setError(null));

        return response.data.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
}

export const changeUserStatus = (userId: number, status: string): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = api.put(`/admin/users/${userId}`, { role: status });

        dispatch(setLoading(false));
        dispatch(setError(null));

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
}

export const selectUsers = (state: RootState) => state.admin.users;
export const selectUser = (state: RootState) => state.admin.user;
export const selectUsersByRole = (state: RootState) => state.admin.usersByRole;
export const selectLoading = (state: RootState) => state.admin.loading;
export const selectLoadingUsersByRole = (state: RootState) => state.admin.loadingUsersByRole;
export const selectError = (state: RootState) => state.admin.error;
export const selectTotal = (state: RootState) => state.admin.total;
export const selectPerPage = (state: RootState) => state.admin.perPage;

export const selectReports = (state: RootState) => state.admin.reports;

export default adminSlice;
