import {
  Action,
  AnyAction,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import themeOptionsSlice from './themeOptions/themeOptionsSlice';
import userSlice from './user/userSlice';
import notificationSlice from './notification/notificationSlice';
import groupSlice from "./groups/groupSlice";
import adminSlice from "./admin/adminSlice";
import uploadSlice from './upload/uploadSlice';
import documentSlice from "./documents/documentsSlice";
import documentViewSlice from './documentView/documentViewSlice';

const store = configureStore({
  reducer: {
    ThemeOptions: themeOptionsSlice.reducer,
    user: userSlice.reducer,
    notification: notificationSlice.reducer,
    group: groupSlice.reducer,
    admin: adminSlice.reducer,
    documents: documentSlice.reducer,
    upload: uploadSlice.reducer,
    documentView: documentViewSlice.reducer,
},
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
export type MapDispatch = ThunkDispatch<RootState, void, AnyAction>;
