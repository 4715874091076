import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../styles.module.scss';

interface ISliderArrowPrevious {
    currentSlideIndex: number;
    setCurrentSlideIndex: any;
    currentDocument: any;
}

const SliderArrowPrevious: React.FC<ISliderArrowPrevious> = ({ currentSlideIndex, setCurrentSlideIndex, currentDocument }) => {
    const handleClick = () => {
        if (currentSlideIndex === 0) {
            setCurrentSlideIndex(currentDocument.files.length - 1);
        } else {
            setCurrentSlideIndex((prevIndex: number) => prevIndex - 1);
        }
    };

    return (
        <div 
            className={styles.media_slider_arrow_previous} 
            onClick={handleClick}
        >
            <FontAwesomeIcon icon={['fas', 'chevron-left']} />
        </div>
    );
};

export default SliderArrowPrevious;
