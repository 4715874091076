import React, {Fragment, useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { parse } from "query-string";
import { selectIsAuthenticated } from "../../store/user/userSlice";
import { selectSearch, setSearch } from "../../store/documents/documentsSlice";
import { getToken } from "../../helpers/auth";
import { NO_BACK_MATCHES, NO_SEARCH_MATCHES } from "../../constants";
import {
    Button,
    InputAdornment,
    makeStyles,
    TextField,
    Theme
} from "@material-ui/core";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import Close from '@material-ui/icons/Close'
import routes from "../../routes";
import BackStep from "../BackToHome/BackStep";
import LanguageChange from "./LanguageChange";
import User from "./User";
import LogoIcon from "../../assets_ash/icons/main_icon.svg";

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        '@media(max-width: 1200px)': {
            padding: 10,
        }
    },
    searchBar: {
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media(max-width: 700px)': {
            flexDirection: "column",
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
        }
    },
    logo: {
        flex: "5%",
        '& > a > img': {
            width: 150,
            order: 1,
            '@media(max-width: 1200px)': {
                width: 120,
                height: 40
            }
        }
    },
    searchField: {
        display: 'flex',
        flex: '100%',
        order: 2,
        '& .MuiOutlinedInput-adornedEnd': {
            padding: 0
        },
        '@media(max-width: 700px)': {
            width: '100%',
            order: 3,
        }
    },
    searchActions: {
        display: 'flex',
        alignItems: 'center',
        order: 3,
        '@media(max-width: 700px)': {
            order: 2,
            margin: '10px 0'
        }
    },
    textField: {
        margin: '0 10px 0 25px',
        flex: '73%',
        borderRadius: 10,
        backgroundColor: '#F5F5F5',
        '& .MuiSvgIcon-root': {
            margin: '5px',
            color: '#707070',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '@media(max-width: 900px)': {
            margin: '10px 0',
        }
    },
    searchIcon: {
        fill: "#ffffff",
        width: 50,
        height: 50,
        backgroundColor: '#92A3BC',
        borderRadius: 10,
        padding: 10,
        margin: 5
    },
    submitButton: {
        display: 'flex',
        flex: "15%",
        flexDirection: 'row',
        justifyContent: 'flex-end',
        '@media(max-width: 700px)': {
            justifyContent: 'flex-start',
        }
    },
    uploadButton: {
        width: "120px",
        padding: '15px',
        marginRight: '5px',
        '& .MuiButton-label': {
            fontSize: '18px',
        },
        '&.MuiButton-containedPrimary': {
            backgroundColor: '#007FDD',
        },
        '&.MuiButton-containedPrimary:hover': {
            backgroundColor: '#007FDD',
        },
    },
    loginButton: {
        width: "100px",
        padding: '15px',
        margin: '0 15px',
        border: 'none',
        borderRadius: '0',
        borderLeft: '1px solid #D8D8D8',
        borderRight: '1px solid #D8D8D8',
        '& .MuiButton-label': {
            fontSize: '18px',
            color: "#007FDD",
        },
    },
    backStep: {
        margin: '30px 0'
    },
}));

const Header: React.FC = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [searchQuery, setSearchQuery] = useState<string | null>(null);

    const query = parse(history.location.search);

    const isAuthenticated = useSelector(selectIsAuthenticated);
    const search = useSelector(selectSearch);

    const noBack = useRouteMatch({
        path: NO_BACK_MATCHES,
        exact: true,
        strict: true,
        sensitive: true,
    });
    const noSearch = useRouteMatch({
        path: NO_SEARCH_MATCHES,
        exact: true,
        strict: true,
        sensitive: true,
    });
    const searchDisabled = useRouteMatch({
        path: [routes.home.path],
        exact: true,
        strict: true,
        sensitive: true,
    });

    const handleClickSearch = () => {
        localStorage.setItem('searchResult', searchQuery as string)
        dispatch(setSearch(searchQuery))
    };
    
    const removeLocalStorageUnnecessaryValues = () => {
        localStorage.removeItem('searchResult');
        localStorage.removeItem('page');
    }

    useEffect(() => {
        if(searchQuery === null) {
            setSearchQuery('');
            dispatch(setSearch(''))
        }
    }, [searchQuery]);

    useEffect(() => {
        if(search === null) {
            setSearchQuery('');
        }
    }, [search])

    useEffect(() => {
        if(query?.s) {
            setSearchQuery(query.s as string);
            dispatch(setSearch(query.s as string))
        }
    }, []);

    return (
        <section className={classes.header}>
            <section className={classes.searchBar}>
                <section className={classes.logo}>
                    <Link to={routes.landing.path} onClick={() => removeLocalStorageUnnecessaryValues()}>
                        <img src={LogoIcon} alt='logo'/>
                    </Link>
                </section>
                {!noSearch?.isExact ? (
                    <>
                        <section className={classes.searchField}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                disabled={!searchDisabled?.isExact}
                                className={classes.textField}
                                value={searchQuery || ''}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (!!searchQuery) {
                                            handleClickSearch();
                                        }
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <>
                                            <InputAdornment
                                                position="end"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSearchQuery(null);
                                                    localStorage.removeItem('searchResult')
                                                }}
                                            >
                                                {searchQuery?.length ? <Close style={{cursor: 'pointer'}}/> : <></>}
                                            </InputAdornment>
                                            <SearchTwoToneIcon
                                                className={classes.searchIcon}
                                                style={{cursor: 'pointer'}}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleClickSearch();
                                                }}
                                            />
                                        </>
                                    )
                                }}
                            />
                        </section>
                        <section className={classes.searchActions}>
                            <section className={classes.submitButton}>
                                <Link to={routes.documentsUpload.path}>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        className={classes.uploadButton}
                                    >
                                        {t('generics.upload')}
                                    </Button>
                                </Link>
                                {(isAuthenticated || getToken()) ?
                                    (
                                        <User/>
                                    ) : (
                                        <Link to={routes.login.path}>
                                            <Button
                                                variant='outlined'
                                                className={classes.loginButton}
                                            >
                                                {t('generics.login_button')}
                                            </Button>
                                        </Link>
                                    )
                                }
                            </section>
                            <section >
                                <LanguageChange/>
                            </section>
                        </section>
                    </>
                ) : (
                    <LanguageChange/>
                )}
            </section>
            {!noBack?.isExact && (
                <section className={classes.backStep}>
                    <BackStep
                        route={routes.landing}
                        text={t('generics.back_home')}
                    />
                </section>
            )}
        </section>
    )
};

export default Header;