import React, { useState } from 'react';
import clsx from "clsx";
import { Button, Card, Collapse } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

type Accordion = {
    title: string,
    text: string
}

const Accordion: React.FC<Accordion> = ({title, text}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="accordion mb-spacing-6">
            <Card className={clsx('card-box', {'panel-open': isOpen})}>
                <Card>
                    <div className="card-header">
                        <div className="panel-title">
                            <div className="accordion-toggle">
                                <Button
                                    variant="text"
                                    size="large"
                                    className="btn-link d-flex align-items-center justify-content-between btn-transition-none"
                                    onClick={toggle}>
                                    <span>{title}</span>
                                    <FontAwesomeIcon
                                        style={{color: '#007FDD'}}
                                        icon={faAngleUp}
                                        className="font-size-xl accordion-icon"
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Collapse in={isOpen}>
                        <div className="p-5">
                            {text}
                        </div>
                    </Collapse>
                </Card>
            </Card>
        </div>
    )
}

export default Accordion;