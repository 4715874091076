import { ChangeDocumentFiles, ChangeFile } from "../assets_ash/globalTypes";
import { DATE_OPTIONS } from "../constants";

export  const calculateDocumentLength = (document: ChangeFile[], document_files: ChangeDocumentFiles[], currentDocument: any) => {
    const documentLength = document && document.length !== undefined ? document.length : 0;
    let documentsFileChangeLength;
    if(!!document_files) {
        const documentFilesLength = Object
            .values(document_files)
            .flatMap((file: ChangeDocumentFiles) => file.changes ? file.changes : [])
        documentsFileChangeLength = documentFilesLength && documentFilesLength.length !== undefined ? documentFilesLength.length : 0;
    } else {
        documentsFileChangeLength = 0
    }

    return documentLength + documentsFileChangeLength;
}

export const formatDate = (value: string): string => {
    const currentLanguage = localStorage.getItem('lang') ?? 'hy'

    if(!!value && typeof value !== undefined) {
        const date: any = Object.entries(JSON.parse(value))[0][1];

        if(typeof date === 'string') {
            return date;
        }

        const matchingNames = DATE_OPTIONS.period[currentLanguage as keyof typeof DATE_OPTIONS.period]
            .filter((period: any) => period.value === date?.period)
            .map((period: any) => period.name);

        return `${date?.from} - ${date?.to} ${matchingNames}`
    }
    return ''
}

export const parseJSONIfValid = (property: any) => {
    try {
        const parsedJSON = JSON.parse(property);
        
        if (typeof parsedJSON === 'object' && parsedJSON !== null) {
            return parsedJSON;
        }
    } catch (error) {
        return false
    }

    return property;
}

export const formatValue = (field: any, value: any) => {
    if (field === 'face_tags' && value && parseJSONIfValid(value)) {
        const faceTagsData = JSON.parse(value);
        
        if (Array.isArray(faceTagsData.face_tags)) {
            const tagNamesArray = faceTagsData.face_tags.map((current: any) => current.name);
            return tagNamesArray.join(',');
        }
    }
    return value;
}
