// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_search_bar__RKch- {\n  width: 100%; }\n", ""]);
// Exports
exports.locals = {
	"search_bar": "styles_search_bar__RKch-"
};
module.exports = exports;
