import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    removeSelectedDocument,
    selectSelected,
    selectSelectedDocuments,
    setSelectedDocuments
} from "../../../../store/documents/documentsSlice";
import { showNotification } from "../../../../store/notification/notificationSlice";
import { truncateString } from "../../../../helpers";
import { IDocument } from "../../../../interfaces";
import { Checkbox, makeStyles } from "@material-ui/core";
import { NOTIFICATION_TYPES } from "../../../../constants";
import documentCount from '../../../../assets_ash/icons/document_count.svg'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import DocumentPreview from '../DocumentPreview';

import styles from './styles.module.scss'

type Document = {
    document: IDocument;
    currentPage: number;
    handleOpenDocument: (documentId: string) => void;
}

const useStyles = makeStyles({
    selectIcon: {
        width: 50,
        height: 50
    },
    selectedIcon: {
        width: 50,
        height: 50,
        color: '#007FDD',
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        border: '0.2px solid #707070'
    },
    checkboxIcon: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        backgroundColor: '#FFFFFF',
        color: '#007FDD',
        border: '0.2px solid #707070'
    }
})

const Document: React.FC<Document> = ({ document, currentPage, handleOpenDocument }) => {
    const { t } = useTranslation('common')
    const { 
        id, 
        file_path, 
        place, 
        group, 
        date, 
        files_count, 
    } = document;
    
    const classes = useStyles();
    const dispatch = useDispatch();

    const selected = useSelector(selectSelected);
    const selectedDocuments = useSelector(selectSelectedDocuments) ?? [];

    const setSelectDocument = async (id: string) => {
        if (selectedDocuments.find(documentId => documentId === id)) {
            await dispatch(removeSelectedDocument(id))
        } else {
            if(selectedDocuments?.length === 10) {
                await dispatch(showNotification({
                    message: t('header.download.download_limit'),
                    type: NOTIFICATION_TYPES.ERROR,
                }))
            } else {
                await dispatch(setSelectedDocuments(id))
            }
        }
    }; 

    const isDocumentChecked = (id: string) => selectedDocuments.find(documentId => documentId === id);

    return (
        <section
            className={styles.documents}
            onClick={(e) => {
                e.preventDefault();
                handleOpenDocument(document.id);
            }}
        >
            <DocumentPreview filePath={file_path} />

            <section className={styles.description}>
                <span className={styles.description_first}>
                    {group}
                </span>
                <span className={styles.description_second}>
                    {place ? truncateString(place, 40) : 'not place'} {date ? `/ ${truncateString(date, 60)}` : '/ not date'}
                </span>
            </section>
            {files_count > 1 && (
                <section className={styles.documents_count}>
                    <img src={documentCount} alt="" className={styles.document_icon}/>
                    <span>{files_count ? files_count > 10 ? '9+' : files_count : ''}</span>
                </section>
            )}
            {selected && (
                <section className={styles.checkbox}>
                    <Checkbox
                        onClick={(e) => {
                            e.stopPropagation();
                            setSelectDocument(id);
                        }}
                        checked={!!isDocumentChecked(id)}
                        icon={<CircleUnchecked className={classes.checkboxIcon} />}
                        checkedIcon={<CircleCheckedFilled className={classes.selectedIcon}/>}
                    />
                </section>
            )}
        </section>
    )
};

export default Document;
