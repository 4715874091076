import { AsYouType } from 'libphonenumber-js';
import moment from 'moment';
import numberFormat from 'format-number';
import humps from 'humps';
import hyLocale from 'date-fns/locale/hy';
import enLocale from 'date-fns/locale/en-US';
import {
    LANGUAGES,
    DEFAULT_DATE_FORMAT,
    MATERIAL_UI_DATE_FORMAT,
    ISO_9601_DATE_FORMAT,
    DEFAULT_DATE_TIME_FORMAT,
    DEFAULT_PAGE_SIZE,
    LANG,
    FILE_TYPES,
    DOC_FILE_TYPES,
    DOCUMENTS_TYPES,
    USER_ROLES,
} from '../constants';
import { v4 as uuidv4 } from 'uuid';

export const reformatPhoneNumber = (phoneNumber: string): string => {
    if (!phoneNumber) {
        return '';
    }

    return new AsYouType('AM').input(phoneNumber);
};

export const reformatDate = (
    date: string,
    dateFormat: string,
    formatedDateFormat: string = DEFAULT_DATE_FORMAT
): string => moment(date, dateFormat).format(formatedDateFormat);

export const reformatClosedDate = (date: string): string => moment(date, ISO_9601_DATE_FORMAT).format(DEFAULT_DATE_TIME_FORMAT);

export const reformatNumber = (
    num: number | string | null,
    fixedPoint: number | null = null
): string => {
    if (num == null) {
        return '';
    }

    let n = +num;

    if (fixedPoint) {
        n = +n.toFixed(fixedPoint);
    }

    return numberFormat()(n);
};

export const getISO9601Date = (date: string): string => moment(date, MATERIAL_UI_DATE_FORMAT).utc().format(ISO_9601_DATE_FORMAT);

export const roundUpNumber = (num: number | string | null): number => {
    if (!num) {
        return NaN;
    }

    return Math.ceil(+num);
};

export const reformatNumberWithCeil = (num: number | string | null): string => reformatNumber(roundUpNumber(num));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const camelizeKeys = (data: object | object[]): any => humps.camelizeKeys(data);

export const decamelize = (data: string): string => humps.decamelize(data);

export const camelize = (data: string): string => humps.camelize(data);

export const decamelizeKeys = (data: object | object[]): object => humps.decamelizeKeys(data);

export function sliceItemsFunc<T>(items: T[], step: number): T[] {
    return [...items].slice(0, DEFAULT_PAGE_SIZE * step);
}
export const showMoreIsActive = (itemCount: number, step: number): boolean => step < Math.ceil(itemCount / DEFAULT_PAGE_SIZE);

export const urlParamsToObject = (params: string[]): object =>
    Object.assign(
        {},
        ...params.map((param) => JSON.parse(`{"${param.replace(/=/g, '":"')}"}`))
    );

export const getActiveLocale = (): Locale => localStorage.getItem(LANG) === LANGUAGES.EN ? enLocale : hyLocale;

export const getClipBoardContent = (id: string): string => document.getElementById(id)?.innerText as string;

export const slicePhoneNumberPlus = (phoneNumber = ''): string => phoneNumber.replace('+', '');

export const capitalizeStringFirstLetter = (str: string): string => {
    return str.split('_')?.length > 1 ? `${str.split('_')[0].charAt(0).toUpperCase() + str.split('_')[0].slice(1)} ${str.split('_')[1]}` : str.charAt(0).toUpperCase() + str.slice(1);
}

export const changeStatusCurrentLanguage = (status: string, singleDocument: boolean): string => {
    const currentLanguage = localStorage.getItem('lang') ?? 'hy';
    const STATUSES = {
        en: {
            approve: 'approved',
            decline: 'declined'
        },
        hy: {
            approve: singleDocument ? 'հաստատված է' : 'հաստատված են',
            decline: singleDocument ? 'մերժված է' : 'մերժված են'
        }
    };

    const currentStatusLanguage = STATUSES[currentLanguage as keyof typeof STATUSES];

    return capitalizeStringFirstLetter(currentStatusLanguage[status as keyof typeof currentStatusLanguage]);
}

export const changePrivacyLevelCurrentLanguage = (privacy_level: string): string => {
    const currentLanguage = localStorage.getItem('lang') ?? 'hy';

    const PRIVACY_LEVELS = {
        en: {
            public: 'public',
            registered: 'registered',
            private: 'private'
        },
        hy: {
            public: 'հանրային',
            registered: 'գրանցված',
            private: 'մասնավոր'
        }
    }

    if(!privacy_level) {
        return '';
    }

    const currentPrivacyLevelLanguage = PRIVACY_LEVELS[currentLanguage as keyof typeof PRIVACY_LEVELS];

    return capitalizeStringFirstLetter(currentPrivacyLevelLanguage[privacy_level as keyof typeof currentPrivacyLevelLanguage]);
}

export const capitalizeFiledName = (filed: string): string => filed.split('_')?.length > 1 ? `${filed.split('_')[0].charAt(0).toUpperCase() + filed.split('_')[0].slice(1)}` : filed.charAt(0).toUpperCase() + filed.slice(1);

export const changeFiledLanguage = (filed:string): string => {
    const currentLanguage = localStorage.getItem('lang') ?? 'hy';
    const FIELDS = {
        en: {
            "date" : "Date",
            "place" : "Location",
            "group_id" : "Group",
            "occasion" : "Occasion",
            "people" : "People",
            "information" : "Information",
            "document_source" : "Document source",
            "narratives" : "Memories and narratives",
            "face_tags" : "Face tags",
        },
        hy: {
            "date" : "Տարեթիվ",
            "place" : "Տեղադրություն",
            "group_id" : "Խումբ",
            "occasion" : "Առիթ",
            "people" : "Մարդիկ",
            "information" : "Ինֆորմացիա",
            "document_source" : "Փաստաթղթի աղբյուրը",
            "narratives" : "Հիշողություններ կամ պատմություն",
            "face_tags" : "Դեմքի պիտակներ",
        }
    };

    const currentStatusLanguage = FIELDS[currentLanguage as keyof typeof FIELDS];

    return capitalizeFiledName(currentStatusLanguage[filed as keyof typeof currentStatusLanguage]);
}

export const capitalizeAllWords = (str: string) => {
    const words = str.split(' ');
    const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords.join(' ');
}

export const getTotalPageCount = (totalElements: number, perPage: number) => {
    return Math.ceil(totalElements / perPage)
};

export const getFileExtension = (url: string) => url?.substring(url.lastIndexOf('.') + 1);

export const findMatchingFileType = (file_path: string, or?: boolean) => {
    const currentLanguage = localStorage.getItem('lang') ?? 'hy';
    for (const fileType of DOCUMENTS_TYPES[currentLanguage as keyof typeof DOCUMENTS_TYPES]) {
        if (fileType.formats.includes(getFileExtension(file_path))) {
            return or ? fileType.key : fileType.name;
        }
    }
    return undefined;
};

export const matchFileType = (fileType: string) => {
    if (/^image\//.test(fileType)) {
        return FILE_TYPES.IMAGE;
    } else if (/^video\//.test(fileType)) {
        return FILE_TYPES.VIDEO;
    } else if (fileType === 'application/pdf') {
        return FILE_TYPES.PDF;
    } else if (DOC_FILE_TYPES.includes(fileType)) {
        return FILE_TYPES.DOC;
    }
}

export const matchFileTypeByExtension = (filename: string): string => {
    const extension = filename?.split('.').pop()?.toLowerCase() || '';

    const fileTypeMap: Record<string, string> = {
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'png': 'image/png',
        'gif': 'image/gif',
        'bmp': 'image/bmp',
        'svg': 'image/svg+xml',
        'webp': 'image/webp',
        'mp4': 'video/mp4',
        'mov': 'video/quicktime',
        'avi': 'video/x-msvideo',
        'wmv': 'video/x-ms-wmv',
        'flv': 'video/x-flv',
        'webm': 'video/webm',
        'pdf': 'application/pdf',
        'doc': 'application/msword',
        'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'xls': 'application/vnd.ms-excel',
        'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };

    const mimeType = fileTypeMap[extension.toLowerCase()];

    if (mimeType) {
        return mimeType;
    }

    return '';
}

export const isEmpty = (obj: any) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}

export const areAllValuesEmpty = (object: any): boolean => Object.values(object).every(value => value === '');

export const areAllValuesUndefined = (object: any): boolean => Object.values(object).every(value => value === undefined);

export const isObject = (value: any) => typeof value === 'object' && value !== null;

export const truncateString = (str: string, maxLength: number): string => str.length <= maxLength ? str : (`${str.slice(0, maxLength - 3)}...`);

export const parseQueryStringPageNumber = (currentUrl: string): number => {
    const queryString = currentUrl.split('?')[1];

    if (queryString) {
        const searchParams = new URLSearchParams(queryString);
        return parseInt(searchParams.get('page') as string);
    }

    return 1;
}

export const extractUserRoleFromUrl = (url: string) => {
    const regex = /admin-dashboard/;

    if (regex.test(url)) {
        return USER_ROLES.ADMIN;
    }
    return USER_ROLES.BASIC;
}

export const formatDate = (date: string) => {
    // Function to format the date in YYYY-MM-DD format (from MM/DD/YYYY or MM-DD-YYYY)
    const parts = date.split(/[/-]/);
    const formattedDate = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
    return formattedDate;
};

export const extractUserCredentials = (
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    confirm_password: string,
    date_of_birth: string,
    street: string,
    state_province: string,
    country: string,
    date_from: number | string,
    date_to: number | string,
    group: Record<string, any>[],
    otherGroup: string,
    city: string
): Record<string, any> => {

    const newGroup = group.map((group: any) => ({
        group_id: group?.id,
        ...(!!otherGroup && group?.name === 'Other' && { name: otherGroup }),
        from: date_from,
        to: date_to,
    }))

    return {
        email,
        password,
        first_name,
        last_name,
        password_confirmation: confirm_password,
        dob: date_of_birth,
        address: {
            street,
            state: state_province,
            country,
            city
        },
        group: newGroup
    }
};

export const getFirstLetters = (str1: string, str2: string): string => str1.charAt(0) + str2.charAt(0);

export const changeSidebarLanguage = (str: string, currentLanguage?: string): string => {
    if(typeof currentLanguage === 'undefined') {
       return '';
    }

    const TABS = {
        en: {
            Overview: "Overview",
            Users: "All Users",
            Documents: "Documents",
            'Documents Change': "Changes",
            Groups: "Groups",
            Reports: "Reports"
        },
        hy: {
            Overview: "Ընդհանուր",
            Users: "Օգտարերը",
            Documents: "Դոկումենտ",
            'Documents Change': "Փոփոխություն",
            Groups: "Խմբեր",
            Reports: "Զեկույցներ"
        }
    }

    const currentTab = TABS[currentLanguage as keyof typeof TABS];

    if (currentTab && currentTab.hasOwnProperty(str)) {
        return currentTab[str as keyof typeof currentTab];
    }

    return ''
}

export const extractFileName = (filePath: string) => {
    return filePath.split('/').pop();
};

export const convertFileName = (fileName: string) => {
    const fileExtension = fileName.split('.').pop();
    const uuid = uuidv4();
    return `${uuid}.${fileExtension}`;
};
