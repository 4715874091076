import React from 'react';
import { TextField, InputAdornment, makeStyles } from '@material-ui/core';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import styles from './styles.module.scss';

type TSearchBar = {
    fullwidth?: boolean;
}

const useStyles = makeStyles({
    textField: {
        borderRadius: '6px',
        backgroundColor: '#F5F5F5',
        padding: '5px',
        '& .MuiSvgIcon-root': {
            marginRight: '5px',
            color: '#707070',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
    },
})

const SearchBar: React.FC<TSearchBar> = ({ fullwidth = false }) => {
    const classes = useStyles();

    return (
        <div className={styles.search_bar}>
            <TextField
                variant="outlined"
                fullWidth={fullwidth}
                className={classes.textField}
                disabled
                size="small"
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position="end"
                        >
                            <SearchTwoToneIcon/>
                        </InputAdornment>
                    )
                }}
            />
        </div>
    );
};

export default SearchBar;
