import moment from "moment";
import { DATE_OPTIONS } from "../constants";

export const formatDate = (date: string) => {
    return moment(date).format('MM/DD/YYYY');
};

export const getLastWeekAndMonthDates = (lastUpload: string) => {
    const today = moment().startOf('day');

    const dateRanges: Record<string, Record<string, string | number>> = {
        'Last week': { from: 1, unit: 'week' },
        'Անցած շաբաթ': { from: 1, unit: 'week' },
        'Last month': { from: 30, unit: 'days' },
        'Անցած ամիս': { from: 30, unit: 'days' },
    };

    if (lastUpload in dateRanges) {
        const { from, unit } = dateRanges[lastUpload as keyof typeof dateRanges];
        const createdAtFrom = today.clone().subtract(from as  moment.DurationInputArg1, unit as moment.unitOfTime.DurationConstructor | undefined ).format('YYYY-MM-DD');
        const createdAtTo = today.clone().format('YYYY-MM-DD');

        return {
            createdAtFrom,
            createdAtTo
        };
    }
};


const getMonthNameFromLocalStorage = (month: string): string | null => {
    const currentLang = localStorage.getItem('lang');
    const { months } = DATE_OPTIONS;

    if (months[currentLang as keyof typeof months]) {
        const index = months[currentLang as keyof typeof months].indexOf(month);

        if (index !== -1) {
            if (currentLang === 'hy') {
                return months['en'][index];
            } else {
                return month;
            }
        }
    }

    return null;
}

export function formatDateRange(
    createdAtFromYear: string,
    createdAtFromMonth: string,
    createdAtToYear: string,
    createdAtToMonth: string
): { createdAtFrom: string; createdAtTo: string } {
    const createdAtFrom = moment(`${createdAtFromYear}-${getMonthNameFromLocalStorage(createdAtFromMonth)}-01`).format('YYYY-MM-DD');
    const createdAtTo = moment(`${createdAtToYear}-${getMonthNameFromLocalStorage(createdAtToMonth)}-01`).format('YYYY-MM-DD');

    return {
        createdAtFrom,
        createdAtTo,
    };
}

export const getYearsToNow = (): string[] => {
    const currentYear = new Date().getFullYear();
    const startYear = 1900;

    return Array.from({ length: currentYear - startYear + 1 }, (_, index) => (startYear + index).toString());
}

export const formatRevisionDate = (created_at: string): string => {
    const date = new Date(created_at);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}`;
}