import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { parse } from "query-string";
import { selectTabs, setTabs } from "../../../../store/documents/documentsSlice";
import { TTab, Tab as TabType } from "../../../../assets_ash/globalTypes";
import { makeStyles, Typography } from "@material-ui/core";

type Tab = {
    name: string,
    fileCount: any,
    translate: string
}

const useStyles = makeStyles({
    tab: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 15px',
        fontSize: '18px',
        borderRadius: '7px',
        marginRight: '10px',
        height: '50px',
        border: '1px solid #D8D8D8',
        cursor: "pointer",
        '&:hover': {
            backgroundColor: '#E2EDFC',
            border: '1px solid #E2EDFC',
            color: '#007FDD'
        },
        '@media(max-width: 1200px)': {
            margin: '10px 10px 0 0',
        }
    },
    value: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 15px',
        fontSize: '18px',
        borderRadius: '7px',
        border: '1px solid #E2EDFC',
        height: '50px',
        marginRight: '10px',
        backgroundColor: '#E2EDFC',
        color: '#007FDD',
        cursor: "pointer",
        '@media(max-width: 1200px)': {
            margin: '10px 10px 0 0',
        }
    },
    text: {
        fontWeight: 600,
        marginLeft: '5px'
    }
});

const FileTab: React.FC<Tab> = ({name, fileCount, translate}) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const query = parse(history.location.search);

    const tab = useSelector(selectTabs);

    const handleChoseTab = (): void => {
        if (tab == TabType[name as keyof typeof TabType]) {
            dispatch(setTabs('' as TTab));
        } else {
            dispatch(setTabs(name as TTab));
        }
    };

    useEffect(() => {
        if (query?.type) {
            dispatch(setTabs(query.type as TTab));
        }
    }, [])

    return (
        <section
            className={tab === TabType[name as keyof typeof TabType] ? classes.value : classes.tab}
            onClick={() => handleChoseTab()}
        >
            {t(`header.tabs.${translate}`)}
            <Typography className={classes.text}>
                {fileCount ? fileCount[name] : 0}
            </Typography>
        </section>
    )
};

export default FileTab;