/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
import _ from 'lodash';
import {decamelize} from '.';
import {CompareDocument, DocumentFileRevision, DocumentRevision} from "../assets_ash/globalTypes";
import moment from "moment";

export const pickWithDefaults = (o: any, picks: any): Object => {
    const keys = Object.keys(picks);
    const obj = o;

    return keys.reduce((acc, key: string) => {
        const value = obj[key];
        let defaultVal = picks[key];

        if (_.isPlainObject(defaultVal)) {
            defaultVal = pickWithDefaults(value, picks[key]);
        }

        return Object.assign(acc, {
            [key]: value || defaultVal,
        });
    }, {});
};

export const setObjectValue = <T extends object, U extends keyof T>(
    obj: T,
    key: U,
    value: any
) => {
    obj[key] = value;
};

export const getObjectValue = <T extends object, U extends keyof T>(
    obj: T,
    key: U
) => obj[key];

export const convertToFormData = (obj: object) => Object.entries(obj).reduce((formData, [key, value]) => {
    formData.append(decamelize(key), value);
    return formData;
}, new FormData());

export const compareObjects = (obj1: Record<string, any>, obj2: Record<string, any>): Record<string, any>[] => {
    return Object.entries(obj1)
        .filter(([key, value]) => key !== "files" && obj2.hasOwnProperty(key) && value !== obj2[key])
        .map(([key, value]) => ({key, value}));
}

export const hasOnlyOneKeyOrEmpty = (object: Record<string, any>, key: string): boolean => {
    const keys = Object.keys(object);

    return !!keys?.length || (keys.length === 1 && keys[0] === key);
};

export const areAllPropsEmptyString = (obj: Record<string, any>) => {
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop) && obj[prop] !== '') {
            return false;
        }
    }
    return true;
}

function areObjectsEqual<T>(obj1: T, obj2: T): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = obj1[key as keyof typeof obj1];
        const val2 = obj2[key as keyof typeof obj2];

        if (typeof val1 === 'object' && typeof val2 === 'object') {
            if (!areObjectsEqual(val1, val2)) {
                return false;
            }
        } else if (val1 !== val2) {
            return false;
        }
    }

    return true;
}

export const comparePropsObjects = (oldProps: any, newProps: any): CompareDocument => {
    const changedValues = {} as CompareDocument;

    const documentKeys = [
        "id",
        "name",
        "date",
        "place",
        "group_id",
        "people",
        "privacy_level",
        "document_date",
        "created_at",
        "updated_at",
        "occasion",
        'date_range',
        'approx_date',
        'face_tags',
    ];

    const documentChanges = documentKeys
        .filter((key) => {
            if (key === 'date_range') {
                return !areObjectsEqual(newProps[key], oldProps[key])
            }
            if (key === 'approx_date') {
               return !areObjectsEqual(newProps[key], oldProps[key])
            }
            return oldProps[key] !== newProps[key];
        })
        .map(key => {
            if (key === 'date') {
                const formattedDate = moment(newProps[key], 'MM/DD/YYYY').format('YYYY-MM-DD');
                return {key: 'exact_date', value: formattedDate};
            } else {
                return {key: key, value: newProps[key]};
            }
        })

    if (documentChanges.length > 0) {
        changedValues.document = documentChanges;
    }

    const oldFiles: Record<string, string>[] = oldProps.files || [];
    const newFiles: Record<string, string>[] = newProps.files || [];

    const documentFiles: DocumentFileRevision[] = newFiles.reduce((result: DocumentFileRevision[], newFile, index) => {
        const oldFile = oldFiles[index];

        const changes: DocumentRevision[] = Object.keys(newFile)
            .filter(key => newFile.hasOwnProperty(key) && (!oldFile || JSON.stringify(oldFile[key]) !== JSON.stringify(newFile[key])))
            .map(key => ({key: key, value: newFile[key]}));

        if (changes.length > 0) {
            result.push({
                file_id: newFile.id,
                changes: changes
            });
        }

        return result;
    }, []);

    if (documentFiles.length > 0) {
        changedValues.document_files = documentFiles;
    }

    return changedValues;
};

