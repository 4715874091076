import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import clsx from 'clsx';
import { BeatLoader } from 'react-spinners';
import {
    selectUsers,
    selectLoading,
    getUsersLists,
    selectTotal,
    selectPerPage,
    disableUser,
    changeUserStatus,
} from '../../../store/admin/adminSlice';
import { formatDate } from '../../../helpers/dateTime';
import { showNotification } from '../../../store/notification/notificationSlice';
import { UserRoles } from "../../../assets_ash/globalTypes";
import { NOTIFICATION_TYPES, USER_ROLES } from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    createStyles,
    makeStyles,
    Theme,
    Table,
    Card,
    CardContent,
    Button,
    Typography
} from '@material-ui/core';
import ElementsPagination from '../../../components/ElementsPagination';
import ActionsPopover from '../ActionsPopover';
import TableSkeleton from '../DataTable/TableSkeleton';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { AccountCircleOutlined } from '@material-ui/icons';
import GroupOwnerBadge from "../../../components/GroupOwnerBadge";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            height: '100%',
            marginBottom: 0,
        },
        tableHeader: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginBottom: '20px',
        },
        tableTitleContainer: {
            fontSize: '30px',
            fontWeight: 700,
            color: '#000000',
            display: 'flex',
            justifyContent: 'space-between',
        },
        tableTitle: {
            marginRight: '15px',
        },
        totalInfo: {
            fontWeight: 'normal',
        },
        heading: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '15px',
        },
        iconContainer: {
            width: '50px',
            height: '50px',
            backgroundColor: '#777777',
            borderRadius: '50%',
            padding: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '15px',
        },
        usersIcon: {
            color: '#ffffff',
            fontSize: '25px',
        },
        createButton: {
            marginRight: '130px',
        },
        actions: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        filterIcon: {
            fontSize: '18px',
        },
        filterButton: {
            marginLeft: '15px',
            display: 'flex',
            alignItems: 'center',
            height: '40px',
        },
        cardContent: {
            minHeight: '500px',
        },
    }),
);

const UsersTable = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const [currentPage, setCurrentPage] = useState<number | null>(null);
    const [totalPages, setTotalPages] = useState<number | null>(null);
    const [update, setUpdated] = useState<boolean>(false);

    const users = useSelector(selectUsers);
    const isLoading = useSelector(selectLoading);
    const total = useSelector(selectTotal);
    const perPage = useSelector(selectPerPage);

    const dispatch = useDispatch();

    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const getUsersPerPage = async (page: number) => {
        try {
            await dispatch(getUsersLists(page));
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    // a function that updates the page query parameter in the URL
    const setPage = (pageNum: number) => {
        queryParams.set("page", String(pageNum));
        history.push({
            pathname: location.pathname,
            search: queryParams.toString(),
        });
    };

    const handleChangeUserStatus = async (userId: number) => {
        try {
            await dispatch(changeUserStatus(userId, USER_ROLES.PRIVATE_VIEWER));
            dispatch(showNotification({
                message: t('admin.user_table.user_status_success'),
                type: NOTIFICATION_TYPES.SUCCESS
            }));
            setUpdated(true);
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    const handleDeleteUser = async (userId: number) => {
        try {
            await dispatch(disableUser(userId));
            dispatch(showNotification({
                message: t('admin.user_table.user_disable_success'),
                type: NOTIFICATION_TYPES.SUCCESS
            }));
            setUpdated(true);
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    useEffect(() => {
        // set the current page to the value of the "page" query parameter
        const initPage = parseInt(queryParams.get("page") ?? "1", 10);
        setCurrentPage(initPage);
    }, []);

    useEffect(() => {
        // updating the list of users on pagination
        if (currentPage) {
            getUsersPerPage(currentPage);
            setPage(currentPage);
        }
    }, [currentPage]);

    useEffect(() => {
        if (total && perPage) {
            setTotalPages(Math.ceil(total / perPage));
        }
    }, [total, perPage]);

    useEffect(() => {
        if (currentPage) {
            getUsersPerPage(currentPage);
            setPage(currentPage);
        }
        return () => {
            setUpdated(false);
        }
    }, [update])

    return (
        <Card className={clsx(classes.card, 'card-box mb-spacing-6-x2 bl-0 br-0 shadow-none')}>
            <CardContent className={classes.cardContent}>
                <div className="table-responsive-md">
                    <section className={classes.tableHeader}>
                        <section className={classes.heading}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className={classes.iconContainer}>
                                    <FontAwesomeIcon icon={faUsers} className={classes.usersIcon} />
                                </div>
                                <Typography className={classes.tableTitleContainer}>
                                    <span className={classes.tableTitle}>{t('admin.user_table.title')}</span>
                                    {!isLoading && total && <span className={classes.totalInfo}>{` (${total})`}</span>}
                                </Typography>
                                {isLoading && <BeatLoader />}
                            </div>
                        </section>
                    </section>

                    {isLoading ? (
                        <TableSkeleton rowsCount={10} />
                    ) : (
                        <Table className="table table-borderless table-hover text-nowrap mb-5">
                            <thead>
                                <tr>
                                    <th className="text-left">{t('table_columns.name')}</th>
                                    <th className="text-center">{t('table_columns.created_at')}</th>
                                    <th className="text-center">{t('table_columns.verified')}</th>
                                    <th className="text-center">{t('table_columns.current_status')}</th>
                                    <th className="text-center">{t('table_columns.action')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-icon-wrapper mr-3">
                                                        <div className="avatar-icon">
                                                            <AccountCircleOutlined
                                                                style={{
                                                                    fontSize: '40px',
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <a
                                                            href="#/"
                                                            onClick={(e) => e.preventDefault()}
                                                            className="font-weight-bold text-black"
                                                            title="...">
                                                            {`${user.first_name} ${user.last_name}`}
                                                        </a>
                                                        <span className="text-black-50 d-block">
                                                            {user.role ? <GroupOwnerBadge /> : ''}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">{formatDate(user.created_at)}</td>
                                            <td className="text-center">
                                                {user.verified ? (
                                                    <div className="badge badge-neutral-warning text-success px-4">
                                                        {t('admin.user_table.verified_statuses.verified')}
                                                    </div>
                                                ) : (
                                                    <div className="badge badge-neutral-warning text-warning px-4">
                                                        {t('admin.user_table.verified_statuses.disabled')}
                                                    </div>
                                                )}
                                            </td>
                                            <td className="text-center">
                                                {user.status === 'approved' ? (
                                                    <div className="badge badge-neutral-warning text-success px-4">
                                                        {t('statuses.approved')}
                                                    </div>
                                                ) : (
                                                    <div className="badge badge-neutral-warning text-warning px-4">
                                                        {t('statuses.declined')}
                                                    </div>
                                                )}
                                            </td>
                                            <td className="text-center">
                                                <ActionsPopover isColumn={true}>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDeleteUser(user.id);
                                                        }}
                                                        color="secondary"
                                                    >
                                                        <Typography>{t('admin.user_table.disable_action')}</Typography>
                                                    </Button>
                                                    {user.role !== UserRoles.PRIVATE_VIEWER && (
                                                        <Button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleChangeUserStatus(user.id);
                                                            }}
                                                            color="secondary"
                                                        >
                                                            <Typography>{t('admin.user_table.user_to_private')}</Typography>
                                                        </Button>
                                                    )}
                                                </ActionsPopover>
                                            </td>
                                        </tr>
                                    )})}
                            </tbody>
                        </Table>
                    )}

                    {totalPages && (totalPages > 1) &&
                        <ElementsPagination
                            page={currentPage || 10}
                            total={totalPages}
                            isLoading={isLoading}
                            setPage={setCurrentPage}
                        />
                    }
                </div>
            </CardContent>
        </Card>
    );
}

export default UsersTable;
