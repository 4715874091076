import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import queryString, { stringify } from 'query-string';
import clsx from "clsx";
import {
    getUserDocuments,
    selectDocuments,
    selectLoading,
    selectPerPage,
    selectTotal
} from "../../store/documents/documentsSlice";
import { NOTIFICATION_TYPES, SORT_CREDENTIALS, SORT_ENUMS } from "../../constants";
import { CurrentStatus, DateRange, TDisable } from "../../assets_ash/globalTypes";
import { ISort } from "../../interfaces";
import { showNotification } from "../../store/notification/notificationSlice";
import { formatDate, formatDateRange, getLastWeekAndMonthDates } from "../../helpers/dateTime";
import { areAllValuesEmpty, areAllValuesUndefined, findMatchingFileType } from "../../helpers";
import {
    Button, Card,
    CardContent, createStyles,
    makeStyles, Table,
    Theme, Typography,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { RemoveRedEyeOutlined, SettingsOutlined } from "@material-ui/icons";
import TableSkeleton from "../AdminDashboard/DataTable/TableSkeleton";
import ActionsPopover from "../AdminDashboard/ActionsPopover";
import DocumentImage from "../../components/DocumentImage";
import DocumentView from '../../components/DocumentView';
import Filter from './Filter/index'
import TableSortArrows from "../../components/Admin/TableSortArrows";
import NoDataNotification from "../../components/NoDataNotification";

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        height: '100%',
        marginBottom: 0,
    },
    cardContent: {
        minHeight: '500px',
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginBottom: '20px',
    },
    heading: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: '15px',
    },
    button: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        borderBottom: '1px solid #DEDEDE',
        padding: '10px'
    },
    icons: {
        marginRight: '10px',
        width: '20px'
    },
    iconText: {
        fontSize: '15px',
        color: "#707070"
    },
    sortArrows: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        '& img': {
            width: '12px'
        }
    },
}));

const UserUploads: React.FC = () => {
    const classes = useStyles();

    const [totalPages, setTotalPages] = useState<number | null>(null);
    const [sortTypes, setSortTypes] = useState<ISort>();
    const [isDocumentViewOpen, setIsDocumentViewOpen] = useState<boolean>(false);

    const isLoading = useSelector(selectLoading);
    const total = useSelector(selectTotal);
    const perPage = useSelector(selectPerPage);
    const documents = useSelector(selectDocuments);

    const [query, setQuery] = useQueryParams({
        id: StringParam,
        page: NumberParam,
        status: StringParam,
        created_at_from: StringParam,
        created_at_to: StringParam,
        group: StringParam,
        sort_dir: StringParam,
        sort_by: StringParam
    });

    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const location = useLocation();

    const getUserDocumentList = async (
        page: number | null | undefined,
        query?: string,
    ) => {
        try {
            await dispatch(getUserDocuments(page, query))
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    const handleFilter = async (values: Record<string, string>, lastUploads: string, status: string) => {
        const { fromYear, fromMonth, toYear, toMonth } = values;
        let createDate: DateRange | undefined;

        if (!!lastUploads) {
            createDate = getLastWeekAndMonthDates(lastUploads);
        } else if (!areAllValuesEmpty(values)) {
            createDate = formatDateRange(fromYear, fromMonth, toYear, toMonth);
        }

        const userStatus = status ? status : undefined;

        setQuery({
            id: undefined,
            page: 1,
            status: userStatus,
            created_at_from: createDate?.createdAtFrom,
            created_at_to: createDate?.createdAtTo,
            sort_dir: undefined,
            sort_by: undefined
        }, 'push')

    }

    const handleSort = (sortObject: Record<string, string | any>): void => {
        const { name, sortDir, disable } = sortObject;

        setSortTypes({
            name,
            sortDir,
            disable
        })
        setQuery({
            ...query,
            page: 1,
            sort_dir: sortDir,
            sort_by: name
        }, 'replace')
    }

    const handleOpenDocument = (documentId: string): void => {
        setQuery({
            ...query,
            id: documentId,
        }, 'replace');
    };

    const destructureObject = (query: Record<string, any>): Record<string, any> => {
        const { status, created_at_from, created_at_to, sort_dir, sort_by } = query;

        return {
            ...(!!status && { status }),
            ...(!!created_at_from && !!created_at_to && { created_at_from, created_at_to }),
            ...(!!sort_dir && !!sort_by && { sort_dir, sort_by })
        };
    };

    useEffect(() => {
        const queryParams = queryString.parse(location.search);
        if (queryParams.id) {
            setIsDocumentViewOpen(true);
        }
    }, [location]);

    useEffect(() => {
        if (!areAllValuesUndefined(query) && !query.id) {
            getUserDocumentList(query.page, stringify(destructureObject(query)));
        }
    }, [query]);

    useEffect(() => {
        if (total && perPage) {
            setTotalPages(Math.ceil(total / perPage));
        }
    }, [total, perPage]);

    return (
        <>
            <Card className={clsx(classes.card, 'card-box mb-spacing-6-x2 bl-0 br-0 shadow-none')}>
                <CardContent className={classes.cardContent}>
                    <div className="table-responsive-md">
                        <section className={classes.tableHeader}>
                            <div className={classes.heading}>
                                <Filter getDocuments={handleFilter} />
                            </div>
                        </section>

                        {isLoading ? (
                            <TableSkeleton rowsCount={10}/>
                        ) : (
                            <Table className="table table-borderless table-hover text-nowrap mb-5">
                                <thead>
                                <tr>
                                    <th className="text-left">
                                        {t('table_columns.documents')}
                                    </th>
                                    <th className="text-center">
                                        <div className={classes.sortArrows}>
                                            {t('table_columns.created_at')}
                                            <TableSortArrows
                                                setSort={handleSort}
                                                sort={SORT_CREDENTIALS.created_at}
                                                disable={sortTypes?.disable as TDisable}
                                                disableStatus={SORT_ENUMS.created_at}
                                            />
                                        </div>
                                    </th>
                                    <th className="text-center">
                                        <div className={classes.sortArrows}>
                                            {t('table_columns.updated_at')}
                                            <TableSortArrows
                                                setSort={handleSort}
                                                sort={SORT_CREDENTIALS.updated_at}
                                                disable={sortTypes?.disable as TDisable}
                                                disableStatus={SORT_ENUMS.updated_at}
                                            />
                                        </div>
                                    </th>
                                    <th className="text-center">
                                        <div className={classes.sortArrows}>
                                            {t('table_columns.group')}
                                        </div>
                                    </th>
                                    <th className="text-center">
                                        <div className={classes.sortArrows}>
                                            {t('table_columns.type')}
                                            <TableSortArrows
                                                setSort={handleSort}
                                                sort={SORT_CREDENTIALS.type}
                                                disable={sortTypes?.disable as TDisable}
                                                disableStatus={SORT_ENUMS.type}
                                            />
                                        </div>
                                    </th>
                                    <th className="text-center">
                                        <div className={classes.sortArrows}>
                                            {t('table_columns.current_status')}
                                            <TableSortArrows
                                                setSort={handleSort}
                                                sort={SORT_CREDENTIALS.status}
                                                disable={sortTypes?.disable as TDisable}
                                                disableStatus={SORT_ENUMS.status}
                                            />
                                        </div>
                                    </th>
                                    <th className="text-center">
                                        {t('table_columns.action')}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {documents && documents.map((document, index) => {
                                    return (
                                        <tr key={index} onClick={(e) => {
                                            e.stopPropagation();
                                            handleOpenDocument(document.id);
                                        }}>
                                            <td>
                                                <DocumentImage
                                                    filePath={document.file_path}
                                                    isNew={document.is_new}
                                                />
                                            </td>
                                            <td className="text-center">
                                                {formatDate(document.created_at)}
                                            </td>
                                            <td className="text-center">
                                                {formatDate(document.updated_at)}
                                            </td>
                                            <td className="text-center">
                                                {document.group}
                                            </td>
                                            <td className="text-center">
                                                {findMatchingFileType(document.file_path)}
                                            </td>
                                            <td className="text-center">
                                                {document.status === CurrentStatus.Approved ? (
                                                    <div className="badge  px-4" style={{
                                                        backgroundColor: '#E9F7F4',
                                                        color: '#2EB191',
                                                        borderRadius: '15px'
                                                    }}>
                                                        {t('statuses.approved')}
                                                    </div>
                                                ) : document.status === CurrentStatus.Declined ? (
                                                    <div className="badge px-4" style={{
                                                        backgroundColor: '#FBEDED',
                                                        color: '#D2514F',
                                                        borderRadius: '15px'
                                                    }}>
                                                        {t('statuses.declined')}
                                                    </div>
                                                ) : (
                                                    <div className="badge px-4" style={{
                                                        backgroundColor: '#FBEDED',
                                                        color: '#D2514F',
                                                        borderRadius: '15px'
                                                    }}>
                                                        {t('statuses.pending')}
                                                    </div>
                                                )}
                                            </td>
                                            <td className="text-center">
                                                <ActionsPopover
                                                    isColumn={true}
                                                    icon={<SettingsOutlined style={{color: '#8D8D8D'}}/>}
                                                >
                                                    <Button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpenDocument(document.id);
                                                        }}
                                                        color="secondary"
                                                        className={classes.button}
                                                    >
                                                        <RemoveRedEyeOutlined
                                                            className={classes.icons}
                                                            style={{color: '#707070'}}
                                                        />
                                                        <Typography className={classes.iconText}>
                                                            {t('table_columns.view_file')}
                                                        </Typography>
                                                    </Button>
                                                </ActionsPopover>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </Table>
                        )}

                        {!documents?.length && !isLoading && (
                            <NoDataNotification text={'not_data'} />
                        )}

                        {!!documents?.length && totalPages && (totalPages > 1) &&
                            <div className="d-flex align-items-center justify-content-center flex-wrap">
                                <Pagination
                                    count={totalPages}
                                    variant="outlined"
                                    shape="rounded"
                                    page={query.page as number ?? 1}
                                    onChange={(event, value) => {
                                        setQuery({
                                            ...query,
                                            page: value
                                        }, 'replace')
                                    }}
                                    disabled={isLoading}
                                />
                            </div>
                        }
                    </div>
                </CardContent>
            </Card>

            {
                documents.length || queryString.parse(location.search).id ? (
                    <DocumentView
                        isDocumentViewOpen={isDocumentViewOpen}
                        documents={documents}
                        setIsDocumentViewOpen={setIsDocumentViewOpen}
                        perPage={perPage}
                        total={total}
                        sort={sortTypes}
                        getDocumentsList={getUserDocumentList}
                    />
                ) : (
                    <></>
                )
            }
        </>
    )
}

export default UserUploads;