import i18n from 'i18next';
import { getLanguage } from "../helpers/localizationHelpers";
import common_en from './translations/en/common.json';
import common_hy from './translations/hy/common.json';


i18n.init({
    lng: getLanguage(),
    resources: {
        en: {
            common: common_en
        },
        hy: {
            common: common_hy
        }
    },

    fallbackLng: 'en',
    debug: false,

    interpolation: {
        escapeValue: false,
    },

});

export default i18n;
