import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { localizeRouth } from "../../helpers/localizationHelpers";
import routes from '../../routes';
import ProtectedRoute from '../../components/ProtectedRoute';
import UploadFormFirst from './UploadFormFirst';
import UploadFormSecond from './UploadFormSecond';
import UploadProvider from './UploadProvider';

const DocumentsUpload = () => {
    return (
        <>
            <UploadProvider>
                <Switch>
                    <Redirect exact from={routes.documentsUpload.path} to={localizeRouth(routes.documentsUploadFormFirst.path)} />
                    <ProtectedRoute
                        exact
                        path={localizeRouth(routes.documentsUploadFormFirst.path)}
                        component={UploadFormFirst}
                    />
                    <ProtectedRoute
                        exact
                        path={localizeRouth(routes.documentsUploadFormSecond.path)}
                        component={UploadFormSecond}
                    />
                </Switch>
            </UploadProvider>
        </>
    );
};

export default DocumentsUpload;
