import React from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

type TableActions = {
    status: string
}

const useStyles = makeStyles({
    approvedStatus : {
        backgroundColor: '#E9F7F4',
        color: '#2EB191',
        borderRadius: '15px'
    },
    declinedStatus : {
        backgroundColor: '#FBEDED',
        color: '#D2514F',
        borderRadius: '15px'
    },
    pendingStatus : {
        backgroundColor: '#FBEDED',
        color: '#D2514F',
        borderRadius: '15px'
    },
    doneStatus : {
        backgroundColor: '#E9F7F4',
        color: '#2EB191',
        borderRadius: '15px'
    },
    badge: {
        display: 'inline-block',
        padding: '0.25em 0.4em',
        fontSize: '75%',
        fontWeight: 700,
        lineHeight: 1,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'baseline',
    },
    px4: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
    },
});

const TableActions: React.FC<TableActions> = ({status}) => {
    const classes = useStyles();
    const { t } = useTranslation('common');

    const actions: Record<string, React.ReactElement> = {
        approved :
            <div className={`${classes.badge} ${classes.px4} ${classes.approvedStatus}`} >
                {t('statuses.approved')}
            </div>,
        declined :
            <div className={`${classes.badge} ${classes.px4} ${classes.declinedStatus}`}>
                {t('statuses.declined')}
            </div>,
        pending :
            <div className={`${classes.badge} ${classes.px4} ${classes.pendingStatus}`}>
                {t('statuses.pending')}
            </div>,
        done :
            <div className={`${classes.badge} ${classes.px4} ${classes.doneStatus}`}>
                {t('statuses.done')}
            </div>,
    };

    return (
        <>
            {actions[status]}
        </>
    )
};

export default TableActions;