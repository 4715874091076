import React, {
    createContext,
    useReducer
} from 'react';
import api from '../../../api';
import { API_URL } from '../../../config/app';
import { showNotification } from '../../../store/notification/notificationSlice';
import { NOTIFICATION_TYPES } from '../../../constants';
import AWS from 'aws-sdk';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { convertFileName } from '../../../helpers';

export const UploadContext = createContext();

const initialState = {
    files: [],
    uploadedFiles: JSON.parse(localStorage.getItem('documents')) || [],
    loading: false,
    error: null,
    progress: 0,
    formId: null,
};

const uploadReducer = (state, action) => {
    switch (action.type) {
        case 'SET_FILES':
            return {
                ...state,
                files: action.payload,
            };
        case 'SET_UPLOADED_FILES':
            return {
                ...state,
                uploadedFiles: action.payload,
            };
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.payload,
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        case 'SET_PROGRESS':
            return {
                ...state,
                progress: action.payload,
            };
        case 'SET_FORM_ID':
            return {
                ...state,
                formId: action.payload,
            };
        default:
            return state;
    }
};

const UploadProvider = ({ children }) => {
    const reduxDispatch = useDispatch();
    const { t } = useTranslation('common');
    const [state, dispatch] = useReducer(uploadReducer, initialState);

    const setFiles = (files) => {
        dispatch({ type: 'SET_FILES', payload: files });
    };

    const setUploadedFiles = (uploadedFiles) => {
        dispatch({ type: 'SET_UPLOADED_FILES', payload: uploadedFiles });
    };

    const setLoading = (isLoading) => {
        dispatch({ type: 'SET_LOADING', payload: isLoading });
    };

    const setError = (error) => {
        dispatch({ type: 'SET_ERROR', payload: error });
    };

    const setProgress = (progress) => {
        dispatch({ type: 'SET_PROGRESS', payload: progress });
    };

    const setFormId = (formId) => {
        dispatch({ type: 'SET_FORM_ID', payload: formId });
    };

    const handleUpload = async () => {
        setLoading(true);

        try {
            const response = await api.get(`${API_URL}/upload-credentials`);

            if (response.status === 200) {
                await UploadFilesToAWS(
                    response.data.access_key,
                    response.data.secret_key,
                    response.data.session_token,
                    response.data.bucket,
                    response.data.region
                );

                reduxDispatch(showNotification({
                    message: t('upload.first_form.notifications.files_success_upload'),
                    type: NOTIFICATION_TYPES.SUCCESS
                }));
            }

            setLoading(false);
            setProgress(0);
            setFiles([]);
            setFormId(null);
        } catch (e) {
            reduxDispatch(showNotification({
                message: e.message,
                type: NOTIFICATION_TYPES.ERROR
            }));
            setLoading(false);
            setProgress(0);
        }
    };

    const UploadFilesToAWS = async (
        access_key,
        secret_key,
        session_token,
        bucket,
        region
    ) => {
        const { files, uploadedFiles } = state;

        if (files.length) {
            const s3 = new AWS.S3({
                accessKeyId: access_key,
                secretAccessKey: secret_key,
                sessionToken: session_token,
            });

            try {
                const uploadPromises = files.map((file) => {
                    const params = {
                        Bucket: bucket,
                        Key: convertFileName(file.name),
                        Body: file,
                    };

                    return s3.upload(params)
                        .on('httpUploadProgress', (progress) => {
                            const { loaded, total } = progress;
                            const percentProgress = Math.round((loaded / total) * 100);
                            setProgress(percentProgress)
                        })
                        .promise();
                });

                const responses = await Promise.all(uploadPromises);

                setUploadedFiles([...uploadedFiles, ...responses]);
            } catch (error) {
                throw new Error(error)
            }
        }
    };

    const deleteUploadedFiles = async () => {
        const { uploadedFiles } = state;

        setLoading(true);

        try {
            const response = await api.get(`${API_URL}/upload-credentials`);

            const s3 = new AWS.S3({
                accessKeyId: response.data.access_key,
                secretAccessKey: response.data.secret_key,
                sessionToken: response.data.session_token,
            });

            try {
                const params = {
                    Bucket: response.data.bucket,
                    Delete: {
                        Objects: uploadedFiles.map((file) => ({ Key: file.Key })),
                    },
                };

                // Tracking deleting progress
                const deleteProgress = s3.deleteObjects(params).on('httpUploadProgress', (progress) => {
                    const { loaded, total } = progress;
                    const percentProgress = Math.round((loaded / total) * 100);
                    setProgress(percentProgress);
                });

                await deleteProgress.promise();
            } catch (error) {
                setLoading(false);
                setProgress(0);
                throw new Error(error);
            }

            setLoading(false);
            setProgress(0);
            setFiles([]);
            setUploadedFiles([]);
            setFormId(null);
            localStorage.removeItem('documents');
        } catch (e) {
            reduxDispatch(showNotification({
                message: e.message,
                type: NOTIFICATION_TYPES.ERROR
            }));
            setLoading(false);
            setProgress(0);
        }
    };

    return (
        <UploadContext.Provider
            value={{
                files: state.files,
                uploadedFiles: state.uploadedFiles,
                loading: state.loading,
                error: state.error,
                progress: state.progress,
                formId: state.formId,
                setFiles,
                setUploadedFiles,
                setLoading,
                setError,
                setProgress,
                setFormId,
                handleUpload,
                UploadFilesToAWS,
                deleteUploadedFiles,
            }}
        >
            {children}
        </UploadContext.Provider>
    );
};

export default UploadProvider;
