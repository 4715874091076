// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_badge_container__2gVEE {\n  width: 30px;\n  background-color: #E63500;\n  font-size: 14px;\n  color: #FFFFFF;\n  text-align: center;\n  border-radius: 10px;\n  padding: 1px 0; }\n", ""]);
// Exports
exports.locals = {
	"badge_container": "styles_badge_container__2gVEE"
};
module.exports = exports;
