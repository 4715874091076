// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_skeleton_table__1KE-H .styles_skeleton_row__1jg2S {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 20px; }\n", ""]);
// Exports
exports.locals = {
	"skeleton_table": "styles_skeleton_table__1KE-H",
	"skeleton_row": "styles_skeleton_row__1jg2S"
};
module.exports = exports;
