import React from 'react';
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory} from 'react-router-dom';
import { forgotPassword, selectIsLoading } from '../../store/user/userSlice';
import { showNotification } from '../../store/notification/notificationSlice';
import { NOTIFICATION_TYPES } from '../../constants';
import emailSchema from "../../schemas/emailSchema";
import routes from '../../routes';
import { CircularProgress, makeStyles, TextField, Typography } from "@material-ui/core";
import SubmitButton from "../../components/Button";
import KeyIcon from '../../assets_ash/icons/key_icon.svg'

const useStyles = makeStyles({
    container: {
        margin: '30px auto',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    keyIcon: {
        margin: '30px 0'
    },
    title: {
        textAlign: 'center',
        fontSize: 28,
        fontWeight: 500,
        color: '#000000',
        marginBottom: 20
    },
    subTitle: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 500,
        color: '#000000',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: 'center',
        maxWidth: '500px',
        margin: '30px auto'
    },
    field: {
        width: '100%',
        margin: '10px 0'
    }
});

const ForgotPassword = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const isLoading = useSelector(selectIsLoading);
    const dispatch = useDispatch();
    const history = useHistory();

    const submitForgotPassword = async (email: string) => {
        try {
            await dispatch(forgotPassword(email));
            dispatch(showNotification({
                type: NOTIFICATION_TYPES.INFO,
                message: t('notifications.success')
            }))
            history.push(routes.sendEmail.path);
        } catch (e) {
            dispatch(showNotification({
                message: t(`notifications.user_not_found`),
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: emailSchema,
        onSubmit: (values) => {
            submitForgotPassword(values.email)
        }
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit
    } = formik;

    return (
        <main className={classes.container}>

            <section className={classes.titleContainer}>
                <img
                    src={KeyIcon}
                    alt='key icon'
                    className={classes.keyIcon}
                />

                <Typography className={classes.title}>
                    {t('forgot_password.title')}
                </Typography>

                <Typography className={classes.subTitle}>
                    {t('forgot_password.sub_title')}
                </Typography>
            </section>

            <form
                onSubmit={handleSubmit}
                className={classes.form}
            >
                <TextField
                    id="email"
                    name="email"
                    className={classes.field}
                    label={t('forgot_password.fields.email')}
                    type="text"
                    autoComplete="off"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                />

                <SubmitButton
                    type='submit'
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={25} color='inherit'/> : t('generics.reset_password')}
                </SubmitButton>
            </form>

        </main>
    )
}

export default ForgotPassword;