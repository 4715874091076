import React from 'react';
import { makeStyles } from "@material-ui/core";
import { findMatchingFileType } from "../../helpers";
import videoImage from '../../assets_ash/images/video_image.svg';
import pdfImage from '../../assets_ash/images/pdf_image.svg';
import wordImage from '../../assets_ash/images/word_image.svg';

interface IDocumentImage {
    filePath: string,
    isNew?: string
}

const useStyles = makeStyles({
    documentContainer: {
        position: 'relative',
        overflow: 'hidden',
        borderTopLeftRadius: '8px'
    },
    newBadge: {
        position: 'absolute',
        top: -5,
        left: -25,
        padding: 10,
        color: '#FFF',
        fontSize: 12,
        transform: 'rotate(-45deg)',
        width: 70,
        height: 30,
        background: '#328EFA',
        textAlign: 'center',
    },
    image: {
        width: '75px',
        borderRadius: '8px'
    },
});

const DocumentImage: React.FC<IDocumentImage> = ({filePath, isNew}) => {
    const classes = useStyles();
    const fileType = findMatchingFileType(filePath, true);
    const documentType = fileType === 'Word' ? wordImage : fileType === 'Video' ? videoImage : fileType === 'PDF' ? pdfImage : filePath

    return (
        <div className={classes.documentContainer} >
            <img
                src={documentType}
                alt="Document"
                className={classes.image}
            />
            {isNew && (
                <div className={classes.newBadge} >
                    <span>New</span>
                </div>
            )}
        </div>
    )
};

export default DocumentImage;