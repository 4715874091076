import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { stringify } from "query-string";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import {
    getRevisionDocumentListById,
    selectLoading,
    selectPerPage, selectRevisionDocuments,
    selectTotal
} from "../../../store/documents/documentsSlice";
import { showNotification } from "../../../store/notification/notificationSlice";
import { NOTIFICATION_TYPES, SORT_CREDENTIALS, SORT_ENUMS } from "../../../constants";
import { formatDate } from "../../../helpers/dateTime";
import { changePrivacyLevelCurrentLanguage, findMatchingFileType } from "../../../helpers";
import { TDisable } from "../../../assets_ash/globalTypes";
import routes from "../../../routes";
import {
    Button,
    Card,
    CardContent,
    createStyles,
    makeStyles,
    Table,
    Theme, Typography,
} from "@material-ui/core";
import { AttachFile, SettingsOutlined } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import TableSkeleton from "../DataTable/TableSkeleton";
import ActionsPopover from "../ActionsPopover";
import DocumentImage from "../../../components/DocumentImage";
import TableSortArrows from "../../../components/Admin/TableSortArrows";
import TableActions from "../../../components/Admin/TableActions";
import NoDataNotification from "../../../components/NoDataNotification";

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        height: '100%',
        marginBottom: 0,
    },
    cardContent: {
        minHeight: '500px',
    },
    button: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        borderBottom: '1px solid #DEDEDE',
        padding: '10px'
    },
    icons: {
        marginRight: '10px',
        width: '20px'
    },
    iconText: {
        fontSize: '15px',
        color: "#707070"
    },
    sortArrows: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        '& img': {
            width: '12px'
        }
    },
}))

const DocumentChangesViewList: React.FC = () => {
    const classes = useStyles();
    const [totalPages, setTotalPages] = useState<number | null>(null);
    const [sortTypes, setSortTypes] = useState<Record<string, string>>();

    const isLoading = useSelector(selectLoading);
    const total = useSelector(selectTotal);
    const perPage = useSelector(selectPerPage);
    const documents = useSelector(selectRevisionDocuments);

    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const history = useHistory();

    const [query, setQuery] = useQueryParams({
        id: StringParam,
        page: NumberParam,
        sort_dir: StringParam,
        sort_by: StringParam
    });

    const handleOpenChanges = (documentId: string) => history.push(`${routes.documentChanges.path}?id=${documentId}`);

    const getCurrentDocumentRevisionList = async (
        page: number | null | undefined,
        query?: string,
        documentId?: string | null | number | undefined
    ) => {
        try {
            await dispatch(getRevisionDocumentListById(page, query, documentId));
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    const destructureObject = (query: Record<string, any>): Record<string, any> => {
        const {sort_dir, sort_by} = query;

        return {
            ...(!!sort_dir && !!sort_by && {sort_dir, sort_by}),
        };
    };

    const handleSort = (sortObject: Record<string, string | any>): void => {
        const {name, sortDir, disable} = sortObject;

        setSortTypes({
            name,
            sortDir,
            disable
        });
        setQuery({
            ...query,
            page: 1,
            sort_dir: sortDir,
            sort_by: name
        }, 'replace')
    }

    useEffect(() => {
        getCurrentDocumentRevisionList(query.page, stringify(destructureObject(query)), query?.id);
    }, [query])

    useEffect(() => {
        if (total && perPage) {
            setTotalPages(Math.ceil(total / perPage));
        }
    }, [total, perPage]);

    return (
        <>
            <Card className={clsx(classes.card, 'card-box mb-spacing-6-x2 bl-0 br-0 shadow-none')}>
                <CardContent className={classes.cardContent}>
                    {isLoading ? (
                        <TableSkeleton rowsCount={10}/>
                    ) : (
                        <Table className="table table-borderless table-hover text-nowrap mb-5">
                            <thead>
                                <tr>
                                <th className="text-left">
                                    {t('table_columns.documents')}
                                </th>
                                <th className="text-center">
                                    <div className={classes.sortArrows}>
                                        {t('table_columns.created_at')}
                                        <TableSortArrows
                                            setSort={handleSort}
                                            sort={SORT_CREDENTIALS.created_at}
                                            disable={sortTypes?.disable as TDisable}
                                            disableStatus={SORT_ENUMS.created_at}
                                        />
                                    </div>
                                </th>
                                <th className="text-center">
                                    <div className={classes.sortArrows}>
                                        {t('table_columns.updated_at')}
                                        <TableSortArrows
                                            setSort={handleSort}
                                            sort={SORT_CREDENTIALS.updated_at}
                                            disable={sortTypes?.disable as TDisable}
                                            disableStatus={SORT_ENUMS.updated_at}
                                        />
                                    </div>
                                </th>
                                <th className="text-center">
                                    <div className={classes.sortArrows}>
                                        {t('table_columns.group')}
                                    </div>
                                </th>
                                <th className="text-center">
                                    <div className={classes.sortArrows}>
                                        {t('table_columns.type')}
                                        <TableSortArrows
                                            setSort={handleSort}
                                            sort={SORT_CREDENTIALS.type}
                                            disable={sortTypes?.disable as TDisable}
                                            disableStatus={SORT_ENUMS.type}
                                        />
                                    </div>
                                </th>
                                <th className="text-center">
                                    <div className={classes.sortArrows}>
                                        {t('table_columns.current_status')}
                                        <TableSortArrows
                                            setSort={handleSort}
                                            sort={SORT_CREDENTIALS.status}
                                            disable={sortTypes?.disable as TDisable}
                                            disableStatus={SORT_ENUMS.status}
                                        />
                                    </div>
                                </th>
                                <th className="text-center">
                                    <div className={classes.sortArrows}>
                                        {t('table_columns.privacy_level')}
                                        <TableSortArrows
                                            setSort={handleSort}
                                            sort={SORT_CREDENTIALS.privacy_level}
                                            disable={sortTypes?.disable as TDisable}
                                            disableStatus={SORT_ENUMS.privacy_level}
                                        />
                                    </div>
                                </th>
                                <th className="text-center">
                                    {t('table_columns.action')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {documents && documents.map((document: any, index) => (
                                <tr key={index}>
                                    <td>
                                        <DocumentImage
                                            filePath={document.thumbnail}
                                            isNew={document.status}
                                        />
                                    </td>
                                    <td className="text-center">
                                        {formatDate(document.created_at)}
                                    </td>
                                    <td className="text-center">
                                        {formatDate(document.updated_at)}
                                    </td>
                                    <td className="text-center">
                                        {document.document.group}
                                    </td>
                                    <td className="text-center">
                                        {findMatchingFileType(document.document.type)}
                                    </td>
                                    <td className="text-center">
                                        <TableActions status={document.status}/>

                                    </td>
                                    <td className='text-center'>
                                        {changePrivacyLevelCurrentLanguage(document.document.privacy_level)}
                                    </td>
                                    <td className="text-center">
                                        <ActionsPopover
                                            isColumn={true}
                                            icon={<SettingsOutlined style={{color: '#8D8D8D'}}/>}
                                        >
                                            <Button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOpenChanges(document.id);
                                                }}
                                                color="secondary"
                                                className={classes.button}
                                            >
                                                <AttachFile
                                                    className={classes.icons}
                                                    style={{color: '#707070'}}
                                                />
                                                <Typography className={classes.iconText}>
                                                    {t('table_columns.document_changes')}
                                                </Typography>
                                            </Button>
                                        </ActionsPopover>
                                    </td>
                                </tr>))}
                            </tbody>
                        </Table>
                    )}

                    {!documents?.length && !isLoading && (
                        <NoDataNotification text={'not_data'}/>
                    )}

                    {!!documents?.length && totalPages && (totalPages > 1) &&
                        <div className="d-flex align-items-center justify-content-center flex-wrap">
                            <Pagination
                                count={totalPages}
                                variant="outlined"
                                shape="rounded"
                                page={query.page as number ?? 1}
                                onChange={(event, value) => {
                                    setQuery({
                                        ...query,
                                        page: value
                                    }, 'replace')
                                }}
                                disabled={isLoading}
                            />
                        </div>
                    }

                </CardContent>
            </Card>
        </>
    )
};

export default DocumentChangesViewList;