import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, selectIsAuthenticated, selectIsLoading } from "../../store/user/userSlice";
import { showNotification } from '../../store/notification/notificationSlice';
import { NOTIFICATION_TYPES } from '../../constants';
import routes from "../../routes";
import loginSchema from "../../schemas/loginSchema";
import { Typography, CircularProgress, TextField, makeStyles, InputAdornment } from "@material-ui/core";
import SubmitButton from "../../components/Button";
import BackStep from "../../components/BackToHome/BackStep";
import CompanyLogo from '../../assets_ash/images/company_logo.svg';
import backgroundImage from '../../assets_ash/images/landingBackground.png';
import LogoIcon from "../../assets_ash/icons/main_icon.svg";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles({
    background: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '100vh',
        minHeight: '800px',
        display: 'flex',
        alignItems: 'center',
        margin: "0 auto"
    },
    modal: {
        maxWidth: 600,
        maxHeight: 700,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: '#fff',
        padding: '0 35px',
        borderRadius: 16,
        '@media(max-width: 1200px)' : {
            maxWidth: 500,
            maxHeight: 600,
            marginBottom: 10,
            padding: '0 25px',
        },
        '@media(max-width: 800px)' : {
            maxWidth: 450,
            maxHeight: 550,
            marginBottom: 10,
            padding: '0 15px',
        },
        '@media(max-height: 700px)' : {
            maxWidth: 450,
            maxHeight: 600,
            marginBottom: 10,
            padding: '0 20px',
        },
    },
    modalLinks: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '35px 0',
        '@media(max-width: 1200px)' : {
            padding: '20px 0 10px',
        },
        '@media(max-width: 800px)' : {
            padding: '20px 0 10px',
        },
        '@media(max-height: 700px)' : {
            padding: '10px 0',
        },
    },
    modalLogo: {
        display: 'block',
        width: 150,
        '@media(max-width: 1200px)' : {
            width: 120
        },
        '@media(max-width: 800px)' : {
            width: 100
        },
        '@media(max-height: 700px)' : {
            width: 100
        },
    },
    companyLogo: {
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0',
        '& > img': {
            width: 140
        },
        '@media(max-width: 1200px)' : {
            padding: '5px 0',
            '& > img': {
                width: 90
            },
        },
        '@media(max-width: 800px)' : {
            padding: '5px 0',
            '& > img': {
                width: 70
            },
        },
        '@media(max-height: 700px)' : {
            padding: '5px 0',
            '& > img': {
                width: 80
            },
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "flex-start",
        alignItems: 'center',
        width: '100%',
        marginBottom: 30,
        '@media(max-width: 1200px)' : {
            marginBottom: 20,
        },
        '@media(max-width: 800px)' : {
            marginBottom: 10,
        },
        '@media(max-height: 700px)' : {
            marginBottom: 0,
        },
    },
    field: {
        width: '100%',
        margin: '10px 0',
    },
    authActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    noAccount: {
        display: 'flex',
        '& :last-child': {
            marginLeft: 5
        }
    },
    recoveryPassword: {
        marginBottom: 10,
        fontSize: 18,
        '@media(max-width: 1200px)' : {
            fontSize: 16,
        },
        '@media(max-width: 800px)' : {
            fontSize: 14,
        },
        '@media(max-height: 700px)' : {
            fontSize: 14,
        },
    },
    noAccountText: {
        fontSize: 17,
        fontWeight: 500,
        '@media(max-width: 1200px)' : {
            fontSize: 16,
        },
        '@media(max-width: 800px)' : {
            fontSize: 14,
        },
        '@media(max-height: 700px)' : {
            fontSize: 14,
        },
    },
    copyrightText: {
        fontSize: 14,
        margin: '10px 0 15px',
        '@media(max-width: 1200px)' : {
            fontSize: 12,
        },
        '@media(max-width: 800px)' : {
            fontSize: 10,
        },
        '@media(max-height: 700px)' : {
            fontSize: 10,
        },
    }
});

const Login: React.FC = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();
    const redirectPath = localStorage.getItem('redirectPath');

    const isLoading = useSelector(selectIsLoading);
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const handleLogin = async (email: string, password: string) => {
        try {
            await dispatch(login(email, password));
        } catch (e) {
            dispatch(showNotification({
                message: t('notifications.login_incorrect'),
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: loginSchema,
        onSubmit: (values) => {
            handleLogin(values.email, values.password)
        }
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit
    } = formik;


    useEffect(() => {
        return () => {
            localStorage.removeItem('redirectPath');
        }
    }, [])

    if (isAuthenticated) {
        if (redirectPath) {
            return <Redirect to={redirectPath} />
        }
        return <Redirect to={routes.home.path} />
    }

    return (
        <main className={classes.background}>
            <section className={classes.modal}>
                <section className={classes.modalLinks}>
                    <img
                        src={LogoIcon}
                        className={classes.modalLogo}
                        alt='parpetsi logo'
                    />
                    <BackStep
                        route={routes.landing}
                        text={t('generics.back_home')}
                    />
                </section>

                <section className={classes.companyLogo}>
                    <img src={CompanyLogo} alt='company logo'/>
                </section>

                <form
                    onSubmit={handleSubmit}
                    className={classes.form}
                >
                    <TextField
                        id="email"
                        name="email"
                        className={classes.field}
                        label={t('login.fields.email')}
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        value={values.email}
                        onChange={handleChange}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <TextField
                        id="password"
                        name="password"
                        className={classes.field}
                        label={t('login.fields.password')}
                        type={showPassword ? 'text' : "password"}
                        autoComplete="off"
                        variant="outlined"
                        value={values.password}
                        onChange={handleChange}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    onClick={togglePasswordVisibility}
                                    style={{cursor: 'pointer'}}
                                >
                                    {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                </InputAdornment>
                            )
                        }}
                    />

                    <SubmitButton
                        type='submit'
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size={25} color='inherit'/> : t('generics.login_button')}
                    </SubmitButton>
                </form>

                <section className={classes.authActions}>
                    <Link
                        to={routes.forgotPassword.path}
                        className={classes.recoveryPassword}
                        style={{color: '#007FDD'}}
                    >
                        {t('login.links.reset_password')}
                    </Link>

                    <section className={classes.noAccount}>
                        <Typography className={classes.noAccountText}>
                            {t('login.links.sign_up')}
                        </Typography>
                        <Link
                            to={routes.registration.path}
                            className={classes.noAccountText}
                            style={{color: '#007FDD'}}
                        >
                            {t('login.links.sign_up_button')}
                        </Link>
                    </section>
                    <Typography className={classes.copyrightText}>
                        Copyright Parpetsi &#169; 2023
                    </Typography>
                </section>
            </section>
        </main>
    )
};

export default Login;
