import React, { useState, useEffect, useRef } from 'react';
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import { matchFileType, matchFileTypeByExtension } from "../../../helpers";
import { FILE_TYPES } from "../../../constants";
import { Button, makeStyles } from "@material-ui/core";
import { Description, PictureAsPdf, Videocam } from "@material-ui/icons";
import ImageViewer from "../../../components/ContentViewer";
import ImageAnnotations from '../../../components/ImageAnnotations';
import DocumentMedia from '../../../components/DocumentMedia';
import { 
    applyAnnotations, 
    deleteAnnotation, 
    activeAnnotationComparator, 
} from '../../../helpers/annotationHelpers';
import { extractFileName } from '../../../helpers';
import { 
    TAnnotationData, 
    TAnnotationGeometry,
    IAnnotation,
    TAnnotationId,
    IDocumentFile,
} from '../../../interfaces';

import styles from './styles.module.scss';

type EditBoard = {
    document?: any,
    currentFile?: any,
    annotations: IAnnotation[][],
    activeAnnotations: TAnnotationId[],
    setCurrentFile?: any,
    applyImageAnnotations: (documentIndex: number, geometry: TAnnotationGeometry, data: TAnnotationData) => void,
    deleteImageAnnotation: (documentIndex: number, data: TAnnotationData) => void,
}

const useStyles = makeStyles({
    fileContainer: {
        display: 'flex',
        width: '100%',
    },
    fileBar: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '20%',
        marginTop: '20px'
    },
    thumbBar: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0 20px',
    },
    thumbButton: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    },
    thumb: {
        display: 'block',
        height: '100%'
    },
    thumbIcon: {
        fontSize: '50px',
        color: '#c0bfc2'
    },
    mediaContainer: {
        width: '100%',
        borderRadius: '20px',
        backgroundColor: '#F5F5F5',
        border: '1px solid #D8D8D8',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '15px',
    },
    media: {
        display: 'block',
        height: '100%',
    },
    mediaIcon: {
        fontSize: '50px',
        color: '#c0bfc2',
    },
    mediaBar: {
        width: '80%'
    },
    mediaBarContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    scrollBarDocuments: {
        maxHeight: '900px',
        padding: '15px'
    }
});

const EditBoard: React.FC<EditBoard> = ({
    document, 
    currentFile, 
    annotations,
    activeAnnotations,
    setCurrentFile,
    applyImageAnnotations,
    deleteImageAnnotation,
}) => {
    const classes = useStyles();
    const fileRef = useRef<any>(null);

    const [isOpen, setOpen] = useState(false);
    const [filePreview, setFilePreview] = useState('');
    const [fileType, setFileType] = useState('');

    const handleSelectFile = (fileName: string) => {
        const selectedFile = document?.files.find((file: IDocumentFile) => extractFileName(file.file_path) === fileName);
        setCurrentFile(selectedFile);
    };

    const media = !!document && document?.files.map((file: any, index: number) => {
        const fileName = extractFileName(file.file_path) as string;
        let selectedFileName: string = fileName;
        
        if (currentFile) {
            selectedFileName = extractFileName(currentFile?.file_path) as string;
        }

        return (
            <DocumentMedia 
                key={index}
                index={index}
                fileName={fileName}
                filePath={file.file_path}
                currentDocument={selectedFileName}
                documentRef={fileRef}
                annotations={annotations}
                activeAnnotations={activeAnnotations}
                applyImageAnnotations={applyImageAnnotations}
                deleteImageAnnotation={deleteImageAnnotation}
                handleSelectDocument={handleSelectFile}
                activeAnnotationComparator={activeAnnotationComparator}
            />
        );
    });


    useEffect(() => {
        if (currentFile && fileRef.current) {
            fileRef.current.scrollIntoView(true);
        }
    }, [currentFile]);
   
    return (
        <>
            <section className={classes.fileContainer}>
                <section className={classes.fileBar}>
                    <section className={classes.thumbBar}>
                        {!!document?.files && document?.files?.map((file: any, index: number) => (
                            <section key={index}>
                                <Button
                                    className={classes.thumbButton}
                                    disableRipple
                                    disableElevation
                                    onClick={() => setCurrentFile(file)}
                                >
                                    <section className={clsx(styles.thumb_container, {
                                        [styles.thumb_container_selected]: file.id === currentFile?.id
                                    })}>
                                        {matchFileType(matchFileTypeByExtension(file.file_path)) === FILE_TYPES.IMAGE &&
                                            <img className={classes.thumb} src={file.file_path}/>}
                                        
                                        {matchFileType(matchFileTypeByExtension(file.file_path)) === FILE_TYPES.VIDEO &&
                                            <Videocam className={classes.thumbIcon}/>}
                                            
                                        {matchFileType(matchFileTypeByExtension(file.file_path)) === FILE_TYPES.PDF &&
                                            <PictureAsPdf className={classes.thumbIcon}/>}
                                        
                                        {matchFileType(matchFileTypeByExtension(file.file_path)) === FILE_TYPES.DOC &&
                                            <Description className={classes.thumbIcon}/>}
                                    </section>
                                </Button>
                            </section>
                        ))}
                    </section>
                </section>
                <section className={classes.mediaBar}>
                    <PerfectScrollbar
                        options={{wheelPropagation: true}}
                        className={classes.scrollBarDocuments}
                    >
                        <div className={classes.mediaBarContent}>
                            {media}
                        </div>
                    </PerfectScrollbar>
                </section>
            </section>
        </>
    )
};

export default EditBoard;