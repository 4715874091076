import React from 'react';
import { Group } from '@material-ui/icons'
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    icon: {
        color: '#732C5C',
        width: '30px'
    },
    badge: {
        backgroundColor: '#F1E9EE',
        borderRadius: 9,
        textAlign: 'center',
        padding: '1px 0',
        width: '130px'
    },
    text: {
        color: '#732C5C',
        fontSize: "12px"
    }
})

const GroupOwnerBadge: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.badge}>
            <Group className={classes.icon}/>
            <span className={classes.text}>
                Group Owner
            </span>
        </div>
    )
};

export default GroupOwnerBadge;