import React from 'react';
import { 
    Button, 
    CircularProgress, 
    makeStyles 
} from '@material-ui/core';
import User from '../../../../../components/Header/User';
import LanguageChange from '../../../../../components/Header/LanguageChange';

import styles from '../../styles.module.scss';
import {useTranslation} from "react-i18next";

interface IFormActions {
    submitLoading: boolean; 
    handleCancel: () => void;
    submitForm: () => void;
};

const useStyles = makeStyles({
    actionBtn: {
        marginRight: 15,
    },
});

const FormActions: React.FC<IFormActions> = ({ submitLoading, handleCancel, submitForm }) => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    
    return (
        <section className={styles.actions}>
            <Button
                className={classes.actionBtn}
                variant="outlined"
                size='large'
                disabled={submitLoading}
                onClick={handleCancel}
            >
                {t('generics.cancel')}
            </Button>
            <Button
                className={classes.actionBtn}
                type="submit"
                variant='contained'
                size='large'
                color="secondary"
                disabled={submitLoading}
                onClick={submitForm}
            >
                {submitLoading ? <CircularProgress size={20} color='inherit' /> : t('generics.submit')}
            </Button>

            <div className={styles.divider}></div>

            <User />
        </section>
    );
};

export default FormActions;
