import React, { Suspense } from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from './theme';
import SuspenseLoading from './components/SuspenseLoading';

// Pages
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import SendEmail from './pages/SendEmail';
import VerificationResult from './pages/VerificationResult';
import ResetSuccess from "./pages/SuccessReset";
import AdminDashboard from './pages/AdminDashboard';
import UserUploads from "./pages/UserUploads";
import DocumentsUpload from './pages/DocumentsUpload';
import DocumentsEdit from "./pages/DocumentsEdit";
import DocumentsChange from "./pages/AdminDashboard/DocumentsChange";
import DocumentChangeReview from "./pages/ChangeReview";
import DocumentChanges from "./pages/DocumentChangesView/DocumentChanges";
import DocumentChangesViewList from "./pages/AdminDashboard/DocumentChangesViewList";
import DocumentEditAdmin from "./pages/DocumentEditAdmin";
import FAQ from "./pages/Static/FAQ";
import TermsAndConditions from "./pages/Static/TermsAndConditions";
import PrivacyPolicy from "./pages/Static/PrivacyPolicy";
import AboutUs from "./pages/Static/AboutUs";
import Landing from "./pages/Static/Landing";

// Temporary page
import HomePage from './pages/Home';
// Components
import ProtectedRoute from './components/ProtectedRoute';
import Header from './components/Header/Header'
import Notification from './components/Notification/Notification';
import Footer from "./components/Footer";

// Routes
import routes from './routes';
import { localizeRouth } from "./helpers/localizationHelpers";
import { NO_FOOTER_MATCH, NO_HEADER_MATCHES } from "./constants";

const Layout: React.FC = () => {
    const headerMatch = useRouteMatch({
        path: NO_HEADER_MATCHES,
        exact: true,
        strict: true,
        sensitive: true,
    });
    const footerMatch = useRouteMatch({
        path: NO_FOOTER_MATCH,
        exact: true,
        strict: true,
        sensitive: true
    });

    return (
        <ThemeProvider theme={MuiTheme}>
            <QueryParamProvider adapter={ReactRouter5Adapter}>
                <AnimatePresence>
                    <Suspense fallback={<SuspenseLoading/>}>
                        <Notification/>
                        <section style={{minHeight: '100vh'}}>
                            {!headerMatch?.isExact && <Header/>}
                            <Switch>
                                <Route
                                    exact
                                    path={localizeRouth(routes.landing.path)}
                                    component={Landing}
                                />
                                <Route
                                    path={localizeRouth(routes.home.path)}
                                    component={HomePage}
                                />
                                <Route
                                    path={localizeRouth(routes.registration.path)}
                                    component={Register}
                                />
                                <Route
                                    exact
                                    path={localizeRouth(routes.sendEmail.path)}
                                    component={SendEmail}
                                />
                                <Route
                                    path={localizeRouth(routes.emailVerificationSuccess.path)}
                                    component={VerificationResult}
                                />
                                <Route
                                    path={localizeRouth(routes.login.path)}
                                    component={Login}
                                />
                                <Route
                                    path={localizeRouth(routes.forgotPassword.path)}
                                    component={ForgotPassword}
                                />
                                <Route
                                    path={localizeRouth(routes.resetPassword.path)}
                                    component={ResetPassword}
                                />
                                <Route
                                    path={localizeRouth(routes.resetPasswordSuccess.path)}
                                    component={ResetSuccess}
                                />
                                <Route
                                    path={localizeRouth(routes.faq.path)}
                                    component={FAQ}
                                />
                                <Route
                                    path={localizeRouth(routes.termsAndConditions.path)}
                                    component={TermsAndConditions}
                                />
                                <Route
                                    path={localizeRouth(routes.privacyPolicy.path)}
                                    component={PrivacyPolicy}
                                />
                                <Route
                                    path={localizeRouth(routes.aboutUs.path)}
                                    component={AboutUs}
                                />
                                <ProtectedRoute
                                    path={localizeRouth(routes.adminDashboard.path)}
                                    component={AdminDashboard}
                                />
                                <ProtectedRoute
                                    path={localizeRouth(routes.documentsUpload.path)}
                                    component={DocumentsUpload}
                                />
                                <ProtectedRoute
                                    path={localizeRouth(routes.documentsEdit.path)}
                                    component={DocumentsEdit}
                                />
                                <ProtectedRoute
                                    path={localizeRouth(routes.userDocuments.path)}
                                    component={UserUploads}
                                />
                                <ProtectedRoute
                                    path={localizeRouth(routes.documentsChange.path)}
                                    component={DocumentsChange}
                                />
                                <ProtectedRoute
                                    path={localizeRouth(routes.documentChangeReview.path)}
                                    component={DocumentChangeReview}
                                />
                                <ProtectedRoute
                                    path={localizeRouth(routes.documentChanges.path)}
                                    component={DocumentChanges}
                                />
                                <ProtectedRoute
                                    path={localizeRouth(routes.documentChangesList.path)}
                                    component={DocumentChangesViewList}
                                />
                                <ProtectedRoute
                                    path={localizeRouth(routes.documentEditAdmin.path)}
                                    component={DocumentEditAdmin}
                                />
                            </Switch>
                        </section>
                        {!footerMatch?.isExact && <Footer/>}
                    </Suspense>
                </AnimatePresence>
            </QueryParamProvider>
        </ThemeProvider>
    );
};

export default Layout;
