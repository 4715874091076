import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { NOTIFICATION_TYPES } from '../../constants';

export type TNotificationType = 'success' | 'info' | 'warning' | 'error';

type TNotificationPayload = {
    type: TNotificationType;
    message: string;
};

interface INotification {
    show: boolean;
    type: TNotificationType;
    message: string;
};

const initialState: INotification = {
    show: false,
    type: NOTIFICATION_TYPES.INFO,
    message: '',
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        showNotification: (state, action: PayloadAction<TNotificationPayload>) => {
            state.show = true;
            state.type = action.payload.type;
            state.message = action.payload.message;
        },
        closeNotification: (state) => {
            state.show = false;
            state.type = 'info';
            state.message = '';
        },
    },
});

export const {
    showNotification,
    closeNotification,
} = notificationSlice.actions;

export const selectShowNotification = (state: RootState) => state.notification.show;
export const selectNotificationMessage = (state: RootState) => state.notification.message;
export const selectNotificationType = (state: RootState) => state.notification.type;

export default notificationSlice;
