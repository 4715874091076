import React, { useState, useEffect, memo } from 'react';
import { useLocation, useHistory } from 'react-router';
import {
    useSelector,
    useDispatch,
} from 'react-redux';
import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import {
    selectUserRole,
} from '../../store/documentView/documentViewSlice';
import {
    getDocumentById,
    getAdminDocumentsList,
    getSingleDocumentByPage,
    documentApprove,
    documentDecline,
    selectDocument,
    setDocument,
    selectLoadingSingleDocument,
    selectSingleDocumentByPageLoading,
    downloadDocumentFiles,
    selectDownloadLoading,
    deleteDocumentViewFile,
} from '../../store/documents/documentsSlice';
import { selectIsAuthenticated } from '../../store/user/userSlice';
import { showNotification } from '../../store/notification/notificationSlice';
import {
    matchFileTypeByExtension,
    parseQueryStringPageNumber,
    extractUserRoleFromUrl, matchFileType,
} from '../../helpers';
import { getDocumentViewPath } from "../../helpers/queryHelpers";
import {
    downloadCurrentFile,
    buildQueryString,
    getListItemIndex,
    getCurrentPage,
} from '../../helpers/documentViewHelpers';
import {
    USER_ROLES,
    NOTIFICATION_TYPES,
    DOCUMENT_PRIVACY_LEVELS,
} from '../../constants';
import queryString from 'query-string';
import routes from '../../routes';
import ContentViewer from '../ContentViewer';
import MediaItem from './MediaItem';
import {
    Modal,
    makeStyles,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem, Typography,
} from '@material-ui/core';
import DocumentCount from '../../assets_ash/icons/documents_count_black.svg'
import DocumentDataFields from './DocumentDataFields';
import { MoonLoader } from 'react-spinners';
import { CircularProgress } from '@material-ui/core';
import SliderArrowNext from './DocumentInnerSlider/SliderArrowNext';
import SliderArrowPrevious from './DocumentInnerSlider/SliderArrowPrevious';
import { CloudDownload } from '@material-ui/icons';
import { Close } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowForwardIosTwoTone, ArrowBackIosTwoTone } from '@material-ui/icons';
import ZipIcon from '../../assets/icons/zip_icon.svg';
import DeleteIcon from "@material-ui/icons/Delete";

import styles from './styles.module.scss';

const useStyles = makeStyles({
    actionButton: {
        border: '1px solid #D8D8D8',
        borderRadius: '0.5rem',
        width: '100%',
        marginBottom: '1rem',
        minWidth: '170px',
        display: 'flex',
        alignItems: 'center',
        '@media (min-width: 1100px)': {
            marginRight: '2rem',
            marginBottom: 0,
            width: '40%',
        },
    },
    icon: {
        color: '#007FDD',
        fontSize: '18px',
    },
    formControl: {
        width: '100%',
        marginBottom: '2rem',
        '@media (min-width: 1100px)': {
            marginRight: '1.125rem',
            marginBottom: 0,
        },
    },
    adminActionBtn: {
        minWidth: '200px',
        width: '100%',
        paddingTop: '1.2rem',
        paddingBottom: '1.2rem',
        marginBottom: '2rem',
        '@media (min-width: 1100px)': {
            marginRight: '1.125rem',
            marginBottom: 0,
            width: '50%',
        },
    },
    declineBtn: {
        color: 'red',
    },
    closeBtn: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    editBtn: {
        border: '1px solid #D8D8D8',
    },
    mediaIcon: {
        fontSize: '100px',
    },
    carouselIcon: {
        fontSize: '50px',
        color: '#ffffff',
    },
    disabledCarouselIcon: {
        color: 'transparent',
    },
    docInfo: {
        fontSize: 16,
        color: '#757575'
    },
    docInfoContainer: {
        marginTop: 5
    }
});

const DocumentView = ({
    isDocumentViewOpen,
    setIsDocumentViewOpen,
    documents,
    perPage,
    total,
    getDocumentsList,
    sort = {
        name: '',
        sortDir: '',
        disable: ''
    },
}) => {
    const [documentId, setDocumentId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [additionalQueryString, setAdditionalQueryString] = useState('');
    const [currentFile, setCurrentFile] = useState(null);
    const [filePreview, setFilePreview] = useState('');
    const [fileType, setFileType] = useState('');
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [privacyLevel, setPrivacyLevel] = useState(DOCUMENT_PRIVACY_LEVELS.PUBLIC);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [currentFileDeleted, setCurrentFileDeleted] = useState(false);

    // using this index for sliding documents in carousel
    const [documentCommonIndex, setDocumentCommonIndex] = useState(0);

    const [documentApproving, setDocumentApproving] = useState(false);
    const [documentDeclining, setDocumentDeclining] = useState(false);
    const [activeAnnotations, setActiveAnnotations] = useState([]);

    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const currentDocument = useSelector(selectDocument);
    const isDocumentLoading = useSelector(selectLoadingSingleDocument);
    const nextOrPreviousDocumentLoading = useSelector(selectSingleDocumentByPageLoading);
    const userRole = useSelector(selectUserRole) || extractUserRoleFromUrl(location.pathname);
    const isFilesDownloading = useSelector(selectDownloadLoading);
    const isAuth = useSelector(selectIsAuthenticated);

    const queryParams = queryString.parse(location.search);

    const getDocument = async () => {
        try {
            dispatch(getDocumentById(documentId, userRole === USER_ROLES.ADMIN));
        } catch (e) {
            dispatch(showNotification({
                message: e.message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    const openFilePreview = () => {
        setIsPreviewOpen(true);
        setFilePreview(currentFile.file_path);
        setFileType(matchFileTypeByExtension(currentFile.file_path));
    };

    const closeFilePreview = () => {
        setIsPreviewOpen(false);
        setFilePreview('');
        setFileType('');
    };

    const handleChangePrivacyLevel = (event) => {
        setPrivacyLevel(event.target.value);
    };

    const handleApproveDocument = async () => {
        const currentUrl = location.pathname;
        const pageNumber = parseQueryStringPageNumber(currentUrl);

        setDocumentApproving(true);

        try {
            await dispatch(documentApprove(documentId, privacyLevel));
            setDocumentApproving(false);
            dispatch(showNotification({
                message: t(`notifications.sdv.document_approved`),
                type: NOTIFICATION_TYPES.SUCCESS
            }));
            dispatch(getAdminDocumentsList(pageNumber));
        } catch (e) {
            dispatch(showNotification({
                message: `${t(`notifications.sdv.${e.message}`)}`,
                type: NOTIFICATION_TYPES.ERROR
            }));
            setDocumentApproving(false);
        }
    };

    const handleDeclineDocument = async () => {
        const currentUrl = location.pathname;
        const pageNumber = parseQueryStringPageNumber(currentUrl);

        setDocumentDeclining(true);

        try {
            await dispatch(documentDecline(documentId));
            setDocumentDeclining(false);
            dispatch(showNotification({
                message: t(`notifications.sdv.document_declined`),
                type: NOTIFICATION_TYPES.SUCCESS
            }));
            dispatch(getAdminDocumentsList(pageNumber));
        } catch (e) {
            dispatch(showNotification({
                message: `${t(`notifications.sdv.${e.message}`)}`,
                type: NOTIFICATION_TYPES.ERROR
            }));
            setDocumentDeclining(false);
        }
    };

    const downloadFile = (currentFile) => {
        if (!isAuth) {
            history.push(routes.login.path);
        } else {
            downloadCurrentFile(currentFile);
        }

    };

    const downloadFiles = async () => {
        if (!isAuth) {
            history.push(routes.login.path);
        } else {

            const files = currentDocument.files.map(file => file.file_path);
            try {
                await dispatch(downloadDocumentFiles(files));
                dispatch(showNotification({
                    message: t('header.download.files_success'),
                    type: NOTIFICATION_TYPES.SUCCESS
                }));
            } catch (e) {
                dispatch(showNotification({
                    message: e.message,
                    type: NOTIFICATION_TYPES.ERROR
                }));
            }
        }
    };

    const slideDocument = async (direction) => {
        const path = getDocumentViewPath(history.location.pathname);
        const nextDocumentIndex = documentCommonIndex + 1;
        const prevDocumentIndex = documentCommonIndex - 1;
        const document = await dispatch(getSingleDocumentByPage(
            path,
            direction === 'right' ? nextDocumentIndex : prevDocumentIndex,
            additionalQueryString,
            sort,
        ));

        if (document.length) {
            if (direction === 'right') {
                setDocumentCommonIndex(prevIndex => prevIndex + 1);
            } else if (direction === 'left') {
                setDocumentCommonIndex(prevIndex => prevIndex - 1);
            }

            history.push(
                `${getDocumentViewPath(history.location.pathname)}?page=${currentPage}` +
                `${additionalQueryString ? `&${additionalQueryString}` : ''}` +
                `&id=${document[0].id}`
            );
        }
    };

    const closeDocumentView = () => {
        dispatch(setDocument(null));
        setIsDocumentViewOpen(false);
        setDocumentId(null);
        setCurrentFile(null);
        setDocumentCommonIndex(0);
        setCurrentSlideIndex(0);
        history.push(
            `${getDocumentViewPath(history.location.pathname)}?page=${getCurrentPage(documentCommonIndex, perPage)}` +
            `${additionalQueryString ? `&${additionalQueryString}` : ''}`
        );
    };

    const onMouseOver = (id) => {
        if (!activeAnnotations.length) {
            setActiveAnnotations([id]);
        }
    };

    const onMouseOut = () => {
        setActiveAnnotations([]);
    };

    const deleteCurrentFile = async (id, key) => {
        try {
            await dispatch(deleteDocumentViewFile(id, key));
        } catch (e) {
            dispatch(showNotification({
                message: e.message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    useEffect(() => {
        setDocumentId(queryParams.id);
        setCurrentPage(queryParams.page);
        setAdditionalQueryString(buildQueryString(queryParams));

        if (!documents.length) {
            getDocumentsList(queryParams.page, additionalQueryString);
        }
    }, [queryParams.id, queryParams.page]);

    useEffect(() => {
        if (isDocumentViewOpen && documentId) {
            getDocument();
        }
    }, [isDocumentViewOpen, documentId]);

    useEffect(() => {
        if (documents.length) {
            if (currentDocument) {
                setCurrentFile(currentDocument.files[0]);
            }
            if (!documentCommonIndex && currentDocument) {
                const documentIndexFromList = getListItemIndex(documents, perPage, currentPage, documentId);
                setDocumentCommonIndex(documentIndexFromList + 1);
            }
        }
    }, [documents, currentDocument]);

    useEffect(() => {
        if (currentDocument) {
            setCurrentFile(currentDocument.files[currentSlideIndex]);
        }
    }, [currentSlideIndex]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowLeft' && documentCommonIndex > 1) {
                slideDocument('left');
            } else if (event.key === 'ArrowRight' && documentCommonIndex < total) {
                slideDocument('right');
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [documentCommonIndex]);

    useEffect(() => {
        if(currentFileDeleted) {
            getDocument();
            setCurrentFileDeleted(false);
            dispatch(showNotification({
                message: t('notifications.file_success_delete'),
                type: NOTIFICATION_TYPES.SUCCESS
            }));
        }
    }, [currentFileDeleted])

    return (
        <>
            <Modal
                open={isDocumentViewOpen}
                onClose={closeDocumentView}
                className={styles.modal}
            >
                <>
                    {(isDocumentLoading) && (
                        <div className={styles.loading_modal}>
                            <MoonLoader color={'white'} />
                        </div>
                    )}

                    {!isDocumentLoading && currentDocument &&
                        (
                            <div className={styles.modal_layout}>
                                {documents.length ? (
                                    <div className={styles.carousel_btn}>
                                        <button
                                            className={styles.slider_button}
                                            onClick={() => slideDocument('left')}
                                            disabled={isDocumentLoading || nextOrPreviousDocumentLoading || (documentCommonIndex === 1)}
                                        >
                                            <ArrowBackIosTwoTone
                                                className={clsx(classes.carouselIcon, {
                                                    [classes.disabledCarouselIcon]: isDocumentLoading || nextOrPreviousDocumentLoading || (documentCommonIndex === 1)
                                                })}
                                                disabled={isDocumentLoading || nextOrPreviousDocumentLoading || (documentCommonIndex === 1)}
                                            />
                                        </button>
                                    </div>
                                ) : (
                                    <></>
                                )}

                                <div className={styles.modal_content}>
                                    <Button
                                        className={classes.closeBtn}
                                        onClick={closeDocumentView}
                                        disabled={documentApproving || documentDeclining}
                                    >
                                        <Close className={styles.closeIcon} />
                                    </Button>

                                    <div className={styles.content_wrapper}>
                                        <section className={styles.document_media}>
                                            {currentDocument.files.length > 1 && <SliderArrowPrevious
                                                currentSlideIndex={currentSlideIndex}
                                                setCurrentSlideIndex={setCurrentSlideIndex}
                                                currentDocument={currentDocument}
                                            />}
                                            {!!currentFile && (
                                                <MediaItem 
                                                    file={currentFile}
                                                    activeAnnotations={activeAnnotations} 
                                                    handlePreviewOpen={openFilePreview} 
                                                />)}
                                            
                                            {currentDocument.files.length > 1 && <SliderArrowNext
                                                currentSlideIndex={currentSlideIndex}
                                                setCurrentSlideIndex={setCurrentSlideIndex}
                                                currentDocument={currentDocument}
                                            />}
                                            {location.pathname === routes.adminDashboardDocuments.path && currentDocument.files.length > 1 && (
                                                <section
                                                    className={styles.delete_current_file}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const currentFileKey = currentFile.file_path.split('/').pop();
                                                        setCurrentFileDeleted(true);
                                                        deleteCurrentFile(currentFile.id, currentFileKey);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                    <Typography >
                                                        {t('sdv.action_buttons.delete_file')}
                                                    </Typography>
                                                </section>
                                            )}
                                        </section>
                                        {matchFileType(matchFileTypeByExtension(currentDocument.name)) === 'application/msword' && (
                                            <section className={classes.docInfoContainer}>
                                                <Typography className={classes.docInfo}>
                                                    {t('sdv.action_buttons.doc_open')}
                                                </Typography>
                                            </section>
                                        )}
                                        <section className={styles.document_actions}>
                                            {userRole === USER_ROLES.ADMIN ? (
                                                <div className={styles.admin_actions}>
                                                    <FormControl className={classes.formControl} variant='outlined'>
                                                        <InputLabel id="privacy">{t('sdv.privacy_level.title')}</InputLabel>
                                                        <Select
                                                            labelId="privacy"
                                                            id="privacy"
                                                            label={t('sdv.privacy_level.title')}
                                                            value={privacyLevel}
                                                            onChange={handleChangePrivacyLevel}
                                                        >
                                                            <MenuItem
                                                                value={DOCUMENT_PRIVACY_LEVELS.PUBLIC}>{t('sdv.privacy_level.public')}</MenuItem>
                                                            <MenuItem
                                                                value={DOCUMENT_PRIVACY_LEVELS.PRIVATE}>{t('sdv.privacy_level.private')}</MenuItem>
                                                            <MenuItem
                                                                value={DOCUMENT_PRIVACY_LEVELS.REGISTERED}>{t('sdv.privacy_level.registered')}</MenuItem>
                                                        </Select>
                                                    </FormControl>

                                                    <Button
                                                        variant='contained'
                                                        color='secondary'
                                                        className={classes.adminActionBtn}
                                                        disabled={documentApproving || documentDeclining}
                                                        onClick={handleApproveDocument}
                                                    >
                                                        {documentApproving ?
                                                            <CircularProgress size={20} color='inherit' /> : t('sdv.action_buttons.approve')}
                                                    </Button>

                                                    <Button
                                                        variant='outlined'
                                                        className={`${classes.adminActionBtn} ${classes.declineBtn}`}
                                                        disabled={documentApproving || documentDeclining}
                                                        onClick={handleDeclineDocument}
                                                    >
                                                        {documentDeclining ?
                                                            <CircularProgress size={20} color='inherit' /> : t('sdv.action_buttons.decline')}
                                                    </Button>
                                                    
                                                    <Button
                                                        className={`${classes.adminActionBtn} ${classes.editBtn}`}
                                                        onClick={(e) => {
                                                            history.push(`/${routes.documentEditAdmin.name}/${currentDocument.id}`)
                                                        }}
                                                    >
                                                        <FontAwesomeIcon color={"#007FDD"} icon={['far', 'edit']} />
                                                        <span className={styles.button_label}>{t('sdv.action_buttons.edit')}</span>
                                                    </Button>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className={styles.user_actions}>
                                                        <Button
                                                            className={classes.actionButton}
                                                            onClick={(e) => {
                                                                history.push(`/${routes.documentsEdit.name}/${currentDocument.id}`)
                                                            }}
                                                        >
                                                            <FontAwesomeIcon color={"#007FDD"} icon={['far', 'edit']} />
                                                            <span className={styles.button_label}>{t('sdv.action_buttons.suggest_edit')}</span>
                                                        </Button>

                                                        <Button
                                                            className={classes.actionButton}
                                                            onClick={() => downloadFile(currentFile)}
                                                        >
                                                            <CloudDownload className={classes.icon} />
                                                            <span className={styles.button_label}>{t('sdv.action_buttons.download')}</span>
                                                        </Button>

                                                        {currentDocument.files.length > 1 &&
                                                            (<Button
                                                                className={classes.actionButton}
                                                                disabled={isFilesDownloading}
                                                                onClick={downloadFiles}
                                                            >
                                                                {isFilesDownloading ? (
                                                                    <CircularProgress size={20} color="inherit" />
                                                                ) : (
                                                                    <>
                                                                        <img src={ZipIcon} />
                                                                        <span className={styles.button_label}>{t('sdv.action_buttons.download_all')}</span>
                                                                    </>
                                                                )}
                                                            </Button>)}

                                                        {currentDocument.files.length > 1 && (
                                                            <div className={styles.document_count}>
                                                                <img src={DocumentCount} alt='documents' className={styles.document_count_icon} />
                                                                <span>{currentSlideIndex + 1} of {currentDocument.files.length}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </section>

                                    </div>

                                    <DocumentDataFields
                                        currentDocument={currentDocument}
                                        currentFile={currentFile}
                                        activeAnnotations={activeAnnotations}
                                        onMouseOver={onMouseOver}
                                        onMouseOut={onMouseOut}
                                    />
                                </div>

                                {documents.length ? (
                                    <div className={styles.carousel_btn}>
                                        <button
                                            className={styles.slider_button}
                                            onClick={() => slideDocument('right')}
                                            disabled={isDocumentLoading || nextOrPreviousDocumentLoading || (documentCommonIndex === total)}
                                        >
                                            <ArrowForwardIosTwoTone
                                                className={clsx(classes.carouselIcon, {
                                                    [classes.disabledCarouselIcon]: isDocumentLoading || nextOrPreviousDocumentLoading || (documentCommonIndex === total)
                                                })}
                                                disabled={isDocumentLoading || nextOrPreviousDocumentLoading || (documentCommonIndex === total)}
                                            />
                                        </button>
                                    </div>
                                ) : (
                                    <></>
                                )}

                            </div>
                        )}
                </>
            </Modal>

            <ContentViewer
                filePreview={filePreview}
                fileType={fileType}
                open={isPreviewOpen}
                currentFile={currentFile}
                handleClose={closeFilePreview}
            />
        </>

    );
}

export default memo(DocumentView, (prevProps, nextProps) => {
    // Compare the relevant props to determine if the component should be re-rendered
    return (
        prevProps.isDocumentViewOpen === nextProps.isDocumentViewOpen &&
        prevProps.documents === nextProps.documents &&
        prevProps.setIsDocumentViewOpen === nextProps.setIsDocumentViewOpen &&
        prevProps.perPage === nextProps.perPage &&
        prevProps.total === nextProps.total &&
        prevProps.getDocumentsList === nextProps.getDocumentsList
    );
});
