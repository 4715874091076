import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getTabNotifications, selectTabsNotifications } from "../../../../store/documents/documentsSlice";
import { selectIsAuthenticated } from "../../../../store/user/userSlice";
import { showNotification } from "../../../../store/notification/notificationSlice";
import { getToken } from "../../../../helpers/auth";
import { Tabs as HeaderTab } from '../../../../assets_ash/globalTypes'
import { NOTIFICATION_TYPES } from "../../../../constants";
import { makeStyles } from "@material-ui/core";
import FilterModal from "../../FilterModal";
import DownloadFiles from "../../Download";
import FileTab from "../Tab";

const useStyles = makeStyles({
    tabContainer: {
        display: 'flex',
        alignItems: 'baseline',
        '@media(max-width: 900px)' : {
            flexWrap: 'wrap'
        },
    },
    prePostHeader: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'space-between',
        '@media(max-width: 900px)' : {
            flexDirection: 'column',
            justifyContent: 'flex-start',
        }
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '@media(max-width: 1200px)' : {
            alignItems: 'flex-end',
        },
        '@media(max-width: 900px)' : {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    }
});

const Tabs: React.FC<HeaderTab> = ({handleFilter}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const isAuthenticated = useSelector(selectIsAuthenticated);
    const count = useSelector(selectTabsNotifications);

    const tabs: Record<string, any>[] = [
        {
            name: 'photo',
            translate: 'photo',
            count: count?.photo,
        },
        {
            name: 'video',
            translate: 'videos',
            count: count?.video,
        },
        {
            name: 'document',
            translate: 'documents',
            count: count?.document,
        },
    ]

    const getDocumentsCounts = async () => {
        try {
            await dispatch(getTabNotifications());
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    useEffect(() => {
        getDocumentsCounts();
    }, [])

    return (
        <section className={classes.prePostHeader}>
            <section className={classes.tabContainer}>
                {tabs && tabs.map((tab, key) => (
                    <FileTab
                        key={key}
                        name={tab.name}
                        fileCount={count}
                        translate={tab.translate}
                    />
                ))}
            </section>
            <section className={classes.actionContainer}>
                {isAuthenticated && getToken() && (
                    <DownloadFiles/>
                )}
                <FilterModal handleFilter={handleFilter}/>
            </section>
        </section>
    )
};

export default Tabs;