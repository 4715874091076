// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_thumb_container__3MnNz {\n  width: 120px;\n  height: 120px;\n  border-radius: 30px;\n  background-color: #F5F5F5;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 30px;\n  border: 1px solid #D8D8D8;\n  position: relative; }\n  .styles_thumb_container_selected__1o3Dy {\n    border: 3px solid #007FDD; }\n", ""]);
// Exports
exports.locals = {
	"thumb_container": "styles_thumb_container__3MnNz",
	"thumb_container_selected": "styles_thumb_container_selected__1o3Dy"
};
module.exports = exports;
