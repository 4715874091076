import * as Yup from 'yup'
import i18n from '../i18n';

const resetPassSchema = Yup.object({
    email: Yup
        .string()
        .email(i18n.t('common:reset_password.validation.email_valid'))
        .required(i18n.t('common:reset_password.validation.email_required')),
    password: Yup
        .string()
        .min(6, i18n.t('common:reset_password.validation.password_min'))
        .required(i18n.t('common:reset_password.validation.password')),
    confirm_password: Yup
        .string()
        .min(6, i18n.t('common:reset_password.validation.confirm_password_min'))
        .required(i18n.t('common:reset_password.validation.confirm_password'))
        .oneOf(
            [Yup.ref('password')],
            i18n.t('common:reset_password.validation.passwords_equal')
        )
});

export default resetPassSchema;


