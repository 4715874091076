import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../styles.module.scss';

interface ISliderArrowNext {
    currentSlideIndex: number;
    setCurrentSlideIndex: any;
    currentDocument: any;
}

const SliderArrowNext: React.FC<ISliderArrowNext> = ({ currentSlideIndex, setCurrentSlideIndex, currentDocument }) => {
    const handleClick = () => {
        if (currentSlideIndex === currentDocument.files.length - 1) {
            setCurrentSlideIndex(0);
        } else {
            setCurrentSlideIndex((prevIndex: number) => prevIndex + 1);
        }
    };

    return (
        <div 
            className={styles.media_slider_arrow_next}
            onClick={handleClick}
        >
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </div>
    );
};

export default SliderArrowNext;
