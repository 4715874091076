import React, {
    useState,
    useEffect,
    useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import { showNotification } from '../../../store/notification/notificationSlice';
import useFileUploader from '../FileUploader';
import { NOTIFICATION_TYPES, FILE_TYPES } from '../../../constants';
import { matchFileType, matchFileTypeByExtension } from '../../../helpers';
import routes from '../../../routes';
import {
    Modal,
    Button,
    makeStyles,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AddTwoTone } from '@material-ui/icons';
import DocumentUploadForm from './DocumentUploadForm';
import UploadBar from './UploadBar';
import CircularProgressWithLabel from '../../../components/CircularProgressWithLabel';
import {
    getNoAuthGroupList,
    selectNoAuthGroups,
    selectGroupsLoading,
} from '../../../store/groups/groupSlice';
import DocumentMedia from '../../../components/DocumentMedia';
import { 
    applyAnnotations, 
    deleteAnnotation,
    activeAnnotationComparator,
} from '../../../helpers/annotationHelpers';
import { 
    Videocam, 
    PictureAsPdf, 
    Description, 
    Delete 
} from '@material-ui/icons';

import styles from './styles.module.scss';

const useStyles = makeStyles({
    thumbButton: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
        },
    },
    plusIcon: {
        fontSize: 25,
    },
    formInput: {
        maxWidth: 370,
        width: '100%',
        marginBottom: 30,
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    loadingModal: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const UploadFormSecond = () => {
    const { t } = useTranslation('common');
    let {
        files,
        uploadedFiles,
        progress,
        formId,
        setFormId,
        setUploadedFiles,
        setFiles,
        loading,
        handleUpload,
        deleteUploadedFiles,
        isDragActive,
        isDragAccept,
        isDragReject,
        open,
        getRootProps,
        getInputProps,
    } = useFileUploader();

    const classes = useStyles();
    const [currentDocument, setCurrentDocument] = useState('');
    const [deletedDocumentKey, setDeletedDocumentKey] = useState(null);
    const [annotations, setAnnotations] = useState([]);

    const [activeAnnotations, setActiveAnnotations] = useState([]);

    const documentRef = useRef(null);
    const thumbsBarRef = useRef(null);
    const groups = useSelector(selectNoAuthGroups);
    const isGroupsLoading = useSelector(selectGroupsLoading);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSelectDocument = (documentName) => {
        setCurrentDocument(documentName);
    };

    const getGroups = async () => {
        try {
            await dispatch(getNoAuthGroupList(1));
        } catch (e) {
            dispatch(showNotification({
                message: e.message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    const handleDelete = (documentName, documentIndex) => {
        const updatedDocumentsList = uploadedFiles.filter(file => file.Key !== documentName);
        const updatedAnnotations = annotations.filter((annotation, index) => index !== documentIndex);

        setUploadedFiles(updatedDocumentsList);
        setDeletedDocumentKey(documentName);
        setAnnotations(updatedAnnotations);
    };

    const applyImageAnnotations = (documentIndex, geometry, data) => {
        applyAnnotations(documentIndex, geometry, data, annotations, setAnnotations);
    };

    const deleteImageAnnotation = (documentIndex, data) => {
        deleteAnnotation(documentIndex, data, annotations, setAnnotations);
    };

    const onMouseOver = (id) => {
        if (!activeAnnotations.length) {
            setActiveAnnotations([id]);
        }
    };

    const onMouseOut = () => {
        setActiveAnnotations([]);
    };

    useEffect(() => {
        // initial set for selecting thumb with current document in ui
        if (uploadedFiles.length && !currentDocument) {
            uploadedFiles.length && setCurrentDocument(uploadedFiles[0].Key);

            // Init annotations
            if (!annotations.length) {
                const annotationsArray = Array.from({ length: uploadedFiles.length }, () => []);
                setAnnotations(annotationsArray);
            }

        } else if (!uploadedFiles.length && !JSON.parse(localStorage.getItem('documents'))?.length) {
            localStorage.removeItem('documents');
            history.push(routes.documentsUploadFormFirst.path);
        } else {
            // Adding empty arrays for annotations for new uploaded files 
            const annotationsArray = Array.from({ length: uploadedFiles.length }, () => []);
            annotations.forEach((annotation, index) => {
                annotationsArray[index] = annotation;
            });
            setAnnotations(annotationsArray);
        }
    }, [uploadedFiles]);

    useEffect(() => {
        // scrolling to selected document
        documentRef && documentRef.current && documentRef.current.scrollIntoView(true);
    }, [currentDocument])

    useEffect(() => {
        // display this data in dropdow field for selecting group
        getGroups();
    }, []);

    const media = !!uploadedFiles.length && currentDocument && uploadedFiles.map((file, index) => {
        return (
            <DocumentMedia 
                key={index}
                index={index}
                fileName={file.Key}
                filePath={file.Location}
                currentDocument={currentDocument}
                documentRef={documentRef}
                annotations={annotations}
                activeAnnotations={activeAnnotations}
                applyImageAnnotations={applyImageAnnotations}
                deleteImageAnnotation={deleteImageAnnotation}
                handleSelectDocument={handleSelectDocument}
                activeAnnotationComparator={activeAnnotationComparator}
            />
        );
    });

    return (
        <>
            <Modal
                open={loading}
                className={classes.modal}
            >
                <>
                    {loading && (
                        <CircularProgressWithLabel
                            circleSize={100}
                            value={progress}
                            fontSize={'25px'}
                            labelColor={'white'}
                        />
                    )}
                </>
            </Modal>

            {!!uploadedFiles && (<div className={styles.container}>
                <div className={styles.layout_outer}>
                    <UploadBar />
                    <div className={styles.layout_inner}>
                        <section className={styles.upload_files_bar} ref={thumbsBarRef}>

                            <div className={styles.file_uploader} {...getRootProps()} onMouseDown={() => setFormId(2)}>
                                <input {...getInputProps()} />
                                <AddTwoTone className={styles.file_uploader_icon} />
                                <span className={styles.file_uploader_label}>{t('generics.upload')}</span>
                            </div>

                            <div className={styles.thumbs_bar}>
                                {!!uploadedFiles.length && currentDocument && uploadedFiles.map((file, index) => {
                                    return (
                                        <div key={index}>
                                            <Button
                                                className={classes.thumbButton}
                                                disableRipple
                                                disableElevation
                                                onClick={() => handleSelectDocument(file.Key)}
                                            >
                                                <div className={clsx(styles.thumb_container, {
                                                    [styles.thumb_container_selected]: file.Key === currentDocument
                                                })}>
                                                    {matchFileType(matchFileTypeByExtension(file.Key)) === FILE_TYPES.IMAGE &&
                                                        <img className={styles.thumb} src={file.Location} />}
                                                    
                                                    {matchFileType(matchFileTypeByExtension(file.Key)) === FILE_TYPES.VIDEO &&
                                                        <Videocam className={styles.thumb_icon} />}
                                                    
                                                    {matchFileType(matchFileTypeByExtension(file.Key)) === FILE_TYPES.PDF &&
                                                        <PictureAsPdf className={styles.thumb_icon} />}
                                                    
                                                    {matchFileType(matchFileTypeByExtension(file.Key)) === FILE_TYPES.DOC &&
                                                        <Description className={styles.thumb_icon} />}

                                                    <Delete
                                                        style={{ fontSize: '50px' }}
                                                        className={styles.delete_btn}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDelete(file.Key, index);
                                                        }}
                                                    />
                                                </div>
                                            </Button>
                                        </div>
                                    )
                                })}
                            </div>
                        </section>

                        <section className={styles.media_bar}>
                            <PerfectScrollbar
                                options={{ wheelPropagation: true }}
                                className={styles.scrollbar_documents}
                                style={{ 
                                    maxHeight: thumbsBarRef?.current ? `${thumbsBarRef.current.getBoundingClientRect().height}px` : '100vh' 
                                }}
                            >
                                <div className={styles.media_bar_content}>
                                    {media}
                                </div>
                            </PerfectScrollbar>
                        </section>
                    </div>
                </div>
                <div className={styles.sidebar}>
                    <section className={styles.form}>
                        {currentDocument && groups && uploadedFiles && uploadedFiles.length &&
                            <DocumentUploadForm
                                documents={uploadedFiles}
                                groups={groups}
                                isGroupsLoading={isGroupsLoading}
                                currentDocument={currentDocument}
                                annotations={annotations}
                                deletedDocumentKey={deletedDocumentKey}
                                setFiles={setFiles}
                                setUploadedFiles={setUploadedFiles}
                                deleteUploadedFiles={deleteUploadedFiles}
                                setCurrentDocument={setCurrentDocument}
                                setDeletedDocumentKey={setDeletedDocumentKey}
                                applyImageAnnotations={applyImageAnnotations}
                                deleteImageAnnotation={deleteImageAnnotation}
                                onMouseOver={onMouseOver}
                                onMouseOut={onMouseOut}
                            />
                        }
                    </section>
                </div>
            </div>)}

        </>
    );
};

export default UploadFormSecond;
