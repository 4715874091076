import React from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { emailResend, forgotPassword, selectIsAuthenticated, selectIsLoading } from '../../store/user/userSlice';
import { showNotification } from "../../store/notification/notificationSlice";
import routes from '../../routes';
import { getValueFromLocalStorage } from "../../helpers/auth";
import { NOTIFICATION_TYPES } from "../../constants";
import EmailIcon from "../../assets_ash/icons/email_icon.svg";
import SubmitButton from "../../components/Button";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    emailIcon: {
        margin: '30px 0'
    },
    title: {
        textAlign: 'center',
        fontSize: 28,
        fontWeight: 500,
        color: '#000000',
        marginBottom: 20
    },
    subTitle: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 500,
        color: '#000000',
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    linkAction: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '25px 0',
        '& :last-child': {
            marginLeft: 5
        }
    },
    linkText: {
        fontSize: 20,
        fontWeight: 500
    },
    authActionContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& :last-child': {
            marginLeft: 5
        }
    },
    authText: {
        fontSize: 18,
        fontWeight: 500
    }
});

const SendEmail: React.FC = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();

    const isLoading = useSelector(selectIsLoading);
    const isAuthenticated = useSelector(selectIsAuthenticated);

    const unVerifyEmail = getValueFromLocalStorage('unVerifyEmail') ?? getValueFromLocalStorage('verifyEmail');

    const openEmailApp = () => {
        window.location.href = `mailto:`;
    };

    const handleResendEmail = async () => {
        const verifyEmail = getValueFromLocalStorage('verifyEmail');
        const unVerifyEmail = getValueFromLocalStorage('unVerifyEmail');

        try {
            if (unVerifyEmail) {
                await dispatch(emailResend(unVerifyEmail as string))
            } else if (verifyEmail) {
                await dispatch(forgotPassword(verifyEmail as string));
            }
            dispatch(showNotification({
                message: t('send_email.notification.success'),
                type: NOTIFICATION_TYPES.SUCCESS
            }));
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    if (isAuthenticated) {
        return (
            <Redirect to={routes.home.path}/>
        )
    }

    return (
        <main className={classes.container}>
            <section className={classes.titleContainer}>
                <img
                    src={EmailIcon}
                    alt='email icon'
                    className={classes.emailIcon}
                />

                <Typography className={classes.title}>
                    {t('send_email.title')}
                </Typography>

                <Typography className={classes.subTitle}>
                    {t('send_email.sub_title')} {unVerifyEmail}
                </Typography>
            </section>

            <section className={classes.actionContainer}>
                <SubmitButton
                    type='submit'
                    disabled={isLoading}
                    onClick={openEmailApp}
                >
                    {isLoading ? <CircularProgress size={25} color='inherit'/> : t('send_email.email_button')}
                </SubmitButton>

                <section className={classes.linkAction}>
                    <Typography className={classes.linkText}>
                        {t('send_email.resend_text')}
                    </Typography>

                    <Link
                        to={'#'}
                        onClick={handleResendEmail}
                        style={{color: '#007FDD'}}
                    >
                        <Typography className={classes.linkText}>
                            {t('send_email.resend_link')}
                        </Typography>
                    </Link>
                </section>

                <section className={classes.authActionContainer}>
                    <Typography className={classes.authText}>
                        {t('generics.have_account')}
                    </Typography>

                    <Link
                        to={routes.login.path}
                        className={classes.authText}
                        style={{color: '#007FDD'}}
                    >
                        {t('generics.login_button')}
                    </Link>
                </section>

            </section>
        </main>
    )
}

export default SendEmail;
