import React, { useState, RefObject, useEffect } from 'react';
import {
    matchFileType,
    matchFileTypeByExtension
} from '../../helpers';
import { FILE_TYPES } from '../../constants';
import ImageAnnotations from '../ImageAnnotations';
import {
    PictureAsPdf,
    Description,
} from '@material-ui/icons';

import {
    TDocumentName,
    IAnnotation,
    TAnnotationId,
    TApplyImageAnnotationsProp,
    TAnnotationData,
} from '../../interfaces';
import ContentViewer from '../ContentViewer';
import { Document, Page, pdfjs } from 'react-pdf';
import styles from './styles.module.scss';

// Sets the URL for the PDF.js worker script, which handles rendering PDF files in the background for better performance.
// This code uses a CDN to load the appropriate version of pdf.worker.js based on the current PDF.js version.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


interface IDocumentMedia {
    index: number;
    fileName: string;
    filePath: string;
    currentDocument: TDocumentName;
    documentRef: RefObject<HTMLDivElement> | null;
    annotations: IAnnotation[][];
    activeAnnotations: TAnnotationId[];
    applyImageAnnotations: TApplyImageAnnotationsProp;
    handleSelectDocument: (fileName: string) => void;
    deleteImageAnnotation: (documentIndex: number, data: TAnnotationData) => void;
    activeAnnotationComparator: (a: IAnnotation, b: TAnnotationId) => boolean;
}

const DocumentMedia: React.FC<IDocumentMedia> = ({
    index,
    fileName,
    filePath,
    currentDocument,
    documentRef,
    annotations,
    activeAnnotations,
    applyImageAnnotations,
    deleteImageAnnotation,
    handleSelectDocument,
    activeAnnotationComparator,
}) => {
    const [isOpen, setOpen] = useState(false);

    const handleOpen = (type: string) => {
        if (matchFileType(type) === FILE_TYPES.IMAGE) {
            return;
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <ContentViewer
                filePreview={filePath}
                fileType={matchFileTypeByExtension(fileName)}
                open={isOpen}
                currentFile={fileName}
                handleClose={handleClose}
            />

            <div
                className={styles.media_container}
                ref={documentRef && fileName === currentDocument ? documentRef : null}
                onClick={() => {
                    if (currentDocument !== fileName) {
                        handleSelectDocument(fileName);
                    }
                    handleOpen(matchFileTypeByExtension(fileName));
                }}
            >
                {matchFileType(matchFileTypeByExtension(fileName)) === FILE_TYPES.IMAGE && (
                    <ImageAnnotations
                        annotations={annotations}
                        img={filePath}
                        applyImageAnnotations={applyImageAnnotations}
                        deleteImageAnnotation={deleteImageAnnotation}
                        mode='edit'
                        documentIndex={index}
                        activeAnnotations={activeAnnotations}
                        activeAnnotationComparator={activeAnnotationComparator}
                    />)}

                {matchFileType(matchFileTypeByExtension(fileName)) === FILE_TYPES.VIDEO && (
                    <video className={styles.video}>
                        <source src={filePath} type={matchFileTypeByExtension(fileName)} />
                    </video>)}

                {matchFileType(matchFileTypeByExtension(fileName)) === FILE_TYPES.PDF &&
                    <Document file={filePath} className={styles.pdf}>
                        <Page pageNumber={1} />
                    </Document>}
                   
                {matchFileType(matchFileTypeByExtension(fileName)) === FILE_TYPES.DOC &&
                    <Description className={styles.media_icon} />}
            </div>
        </>
    );
};

export default DocumentMedia;
