import jwtDecode, { JwtPayload as JwtInitialPayload } from 'jwt-decode';
import { TOKEN } from '../constants';

type getAuthHeaderObj = {
    Authorization: string;
};
interface JwtPayload extends JwtInitialPayload {
    roles?: Array<string>;
}

export const setToken = (token: string): void => {
    localStorage.setItem(TOKEN, token);
};

export const getToken = (): string | null => localStorage.getItem(TOKEN);

export const removeToken = (): void => {
    localStorage.removeItem(TOKEN);
};

export const getAuthHeader = (): getAuthHeaderObj => ({
    Authorization: `Bearer ${getToken()}`,
});

export const isAuthenticated = (): boolean => !!getToken();

export const getUserRoles = (): Array<string> => {
    const token = getToken() || '';

    if (!token) {
        return [];
    }

    const decoded = jwtDecode<JwtPayload>(token);

    return decoded.roles || [];
};

// export const getHomeScreenRoute = (): string => {
//     const roles = getUserRoles();

//     const firstRole = roles[0];

//     const homeScreenRoute = ROUTES.find(
//         ({ canviewList, isHomeScreen }) =>
//             canviewList?.includes(firstRole) && isHomeScreen
//     )?.pathname as string;

//     return homeScreenRoute;
// };

export const canView = (canViewRoles?: Array<string>): boolean => {
    if (canViewRoles === undefined) {
        return true;
    }

    const userRoles = getUserRoles();

    return userRoles.some((r: string) => canViewRoles.includes(r));
};

export const setValueToLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
};

export const getValueFromLocalStorage = (key: string) => {
    return localStorage?.getItem(key);
};

export const removeValueFromLocalStorage = (key: string) => {
    localStorage?.removeItem(key);
};

export const convertPathToSlug = (path: string) => {
    switch (path.toLowerCase()) {
        case 'overview':
            return '/overview';
        case 'all users':
            return '/users';
        case 'documents':
            return '/documents?page=1';
        case 'changes':
            return '/documents-change?page=1';
        case 'groups':
            return '/groups';
        case 'settings':
            return '/settings';
        case 'help':
            return '/help';
            default:
            return '';
    }
};

export const extractPathLastSegment = (path: string) => {
    const splittedPath: string[] = path.split('/');
    return splittedPath[splittedPath.length - 1];
};

export const extractPathSegment = (path: string, index: number) => {
    const splittedPath: string[] = path.split('/');
    const adjustedIndex = index < 0 ? splittedPath.length + index : index;

    return splittedPath[adjustedIndex];
};

export const userHasAccess = (userRole: string, userAvailableRoles: string[]) => {
    return !userAvailableRoles || userAvailableRoles.includes(userRole);
}
