import React from 'react';
import { ChangeDocumentFiles } from "../../../../assets_ash/globalTypes";
import RevisionTab from "../RevisionTab";

type RevisionTypeWrapper = {
    document_files: ChangeDocumentFiles[],
    currentDocument: ChangeDocumentFiles | undefined
}

const RevisionTabWrapper: React.FC<RevisionTypeWrapper> = ({document_files, currentDocument}) => {
    return (
        <>
            {!!document_files &&
                typeof currentDocument !== 'undefined' &&
                Object.values(document_files).reduce((result: JSX.Element[], file: any) => {
                    if (file === currentDocument) {
                        const changes = file?.changes;
                        if (changes) {
                            return changes.reduce((res: JSX.Element[], change: any) => {
                                if (typeof change !== 'undefined') {
                                    res.push(
                                        <RevisionTab
                                            key={change.id}
                                            field={change.key}
                                            value={change.value}
                                            old_value={change.old_value}
                                            id={change.id}
                                            status={change.status}
                                        />
                                    );
                                }
                                return res;
                            }, result);
                        }
                    }
                    return result;
                }, [])}
        </>
    )
};

export default RevisionTabWrapper