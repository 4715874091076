import React, { useState, useEffect, ComponentType } from 'react';
import { 
    Redirect, 
    Route, 
    useHistory 
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated, selectUserRole } from '../../store/user/userSlice';
import { getToken, userHasAccess } from '../../helpers/auth';
import routes from '../../routes';

interface ILoginRouteProps {
    path: string;
    exact?: boolean;
    component?: ComponentType;
    userAvailableRoles?: string[];
}

const ProtectedRoute: React.FC<ILoginRouteProps> = ({ path, component, exact = false, children, userAvailableRoles }) => {
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const history = useHistory();
    const userRole = useSelector(selectUserRole);
    
    if (!isAuthenticated) {
        localStorage.setItem('redirectPath', history.location.pathname);
    }
    
    useEffect(() => {
        if (!isAuthenticated && !getToken()) {
            history.push(routes.login.path);
        }
        
    }, [isAuthenticated, getToken()]);

    if (isAuthenticated || getToken()) {
        localStorage.removeItem('redirectPath');
        if(!!userRole && !userHasAccess(userRole as string, userAvailableRoles as string[])) {
            return <Redirect to={routes.home.path} />;
        }

        return <Route exact={exact} path={path} component={component}>{children}</Route>;
    }

    return <Redirect to={routes.login.path} />
};

export default ProtectedRoute;
