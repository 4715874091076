import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { parse, stringify } from "query-string";
import queryString from 'query-string';
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import {
    getUserDocumentsList,
    selectDocuments,
    selectLoading,
    selectPerPage,
    selectSearch,
    selectTabs,
    selectTotal,
    setSearch,
    setTabs,
} from "../../store/documents/documentsSlice";
import { showNotification } from "../../store/notification/notificationSlice";
import { NOTIFICATION_TYPES } from "../../constants";
import { DateRange } from "../../assets_ash/globalTypes";
import { areAllValuesEmpty, areAllValuesUndefined } from "../../helpers";
import { formatDateRange, getLastWeekAndMonthDates } from "../../helpers/dateTime";
import { queryDestruction } from "../../helpers/queryHelpers";
import { hasOnlyOneKeyOrEmpty } from "../../helpers/objectHelpers";
import Tabs from "../../components/Home/PrePostHeader/Tabs";
import DocumentsWrapper from "../../components/Home/DocumentsGrid/DocumentsWrapper";
import Document from "../../components/Home/DocumentsGrid/Document";
import SkeletonGrid from "../../components/Home/DocumentsGrid/SkeletonGrid";
import DocumentView from '../../components/DocumentView';
import NoDataNotification from "../../components/NoDataNotification";

const useStyles = makeStyles({
    mainContainer: {
        padding: "0 50px 10px",
        maxWidth: '100vw',
        '@media(max-width: 900px)': {
            padding: "0 20px 10px",
        }
    },
    tabContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    tabTitle: {
        fontSize: '36px',
        color: '#000000',
        fontWeight: 500
    },
    skeletonGridCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        gridRowEnd: 'span 20',
        margin: '5px',
        padding: 0,
        borderRadius: 22,
        overflow: 'hidden',
        cursor: 'pointer',
    }
});

const HomePage = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const searchResult = localStorage.getItem('searchResult')

    const [totalPages, setTotalPages] = useState<number | null>(null);

    const [isDocumentViewOpen, setIsDocumentViewOpen] = useState<boolean>(false);

    const isLoading = useSelector(selectLoading);
    const total = useSelector(selectTotal);
    const perPage = useSelector(selectPerPage);
    const documents = useSelector(selectDocuments);
    const search = useSelector(selectSearch);
    const tab = useSelector(selectTabs);

    const [query, setQuery] = useQueryParams({
        page: NumberParam,
        type: StringParam,
        group: StringParam,
        created_at_from: StringParam,
        created_at_to: StringParam,
        my_uploads: NumberParam,
        s: StringParam,
        id: StringParam,
    });

    const getDocumentsList = async (page: number | null | undefined, query?: string, perPage: number = 18) => {
        try {
            await dispatch(getUserDocumentsList(page, query, perPage));
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    const handleFilter = (values: Record<string, string>, lastUploads: string, myUploads: string, group: string): void => {
        const { fromYear, fromMonth, toYear, toMonth } = values;
        let createDate: DateRange | undefined;

        if (!!lastUploads) {
            createDate = getLastWeekAndMonthDates(lastUploads);
        } else if (!areAllValuesEmpty(values)) {
            createDate = formatDateRange(fromYear, fromMonth, toYear, toMonth);
        }

        const userUpload = myUploads === 'me' ? 1 : undefined;
        const userGroup = group ? group : undefined;

        setQuery({
            page: 1,
            s: undefined,
            type: query.type,
            created_at_from: createDate?.createdAtFrom,
            created_at_to: createDate?.createdAtTo,
            group: userGroup,
            my_uploads: userUpload,
            id: undefined,
        }, 'push');

        dispatch(setSearch(''));
    };

    const handleOpenDocument = (documentId: string) =>  {
        setQuery({
            ...query,
            id: documentId,
        }, 'push');
    };

    useEffect(() => {
        setQuery({
            ...query,
            page: query.page ?? 1
        }, 'push');

        // Handling document view open if has id in url query params,
        // in cases if url shared or opened manually
        if (query.id) {
            setIsDocumentViewOpen(true);
        }
    }, [query.id]);

    useEffect(() => {
        if (!areAllValuesUndefined(query) && !query.id) {
            getDocumentsList(query.page, stringify(queryDestruction(query, query.s)));
        }
    }, [query]);

    useEffect(() => {
        if (search !== null) {
            setQuery({
                page: 1,
                s: search ? search : undefined,
                type: undefined,
                id: query.id ? query.id : undefined
            }, 'replace');
        }
    }, [search]);

    useEffect(() => {
        if (tab !== null) {
            setQuery({
                page: 1,
                type: tab ? tab : undefined,
                s: undefined
            }, 'replace');
            localStorage.removeItem('searchResult')
        }
    }, [tab]);

    useEffect(() => {
        if (total && perPage) {
            setTotalPages(Math.ceil(total / perPage));
        }
    }, [total, perPage]);

    useEffect(() => {
        history.listen(location => {
            if (!hasOnlyOneKeyOrEmpty(parse(location.search), 'page')) {
                setQuery({
                    type: undefined,
                    s: undefined,
                    group: undefined,
                    created_at_from: undefined,
                    created_at_to: undefined,
                    my_uploads: undefined,
                    id: undefined,
                    page: query.page ?? 1,
                }, 'replaceIn');
                dispatch(setTabs(''))
                dispatch(setSearch(''));
            }
        });
    }, []);

    return (
        <main className={classes.mainContainer}>
            <section className={classes.tabContainer}>
                <p className={classes.tabTitle}>
                    {t('home.title')}
                </p>
                <Tabs handleFilter={handleFilter} />
            </section>
            <section>
                {isLoading ? (
                    <DocumentsWrapper>
                        <SkeletonGrid cells={18}/>
                    </DocumentsWrapper>
                ) : (
                    <DocumentsWrapper>
                        {!!documents && documents.map(document => (
                            <Document
                                key={document.id}
                                document={document}
                                currentPage={query.page as number}
                                handleOpenDocument={handleOpenDocument}
                            />
                        ))}
                    </DocumentsWrapper>
                )}
                {!documents?.length && !isLoading && (
                    <NoDataNotification text={search?.length || searchResult || query.s ? 'not_search_result' : 'not_data'}/>
                )}
            </section>
            {!!documents?.length && totalPages && (totalPages > 1) &&
                <div className="d-flex align-items-center justify-content-center flex-wrap">
                    <Pagination
                        count={totalPages}
                        variant="outlined"
                        shape="rounded"
                        page={query.page as number ?? 1}
                        onChange={(event, value) => {
                            setQuery({
                                ...query,
                                page: value
                            }, 'replace')
                        }}
                        disabled={isLoading}
                    />
                </div>
            }

            {documents.length || queryString.parse(location.search).id ? (
                <DocumentView
                    isDocumentViewOpen={isDocumentViewOpen}
                    documents={documents}
                    setIsDocumentViewOpen={setIsDocumentViewOpen}
                    perPage={perPage}
                    total={total}
                    getDocumentsList={getDocumentsList}
                />
            ) : (
                <></>
            )}
        </main>
    )
};

export default HomePage;