import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { logout, selectUserData } from "../../../store/user/userSlice";
import { showNotification } from "../../../store/notification/notificationSlice";
import { setRevisionDocument } from "../../../store/documents/documentsSlice";
import { getFirstLetters } from "../../../helpers";
import routes from "../../../routes";
import { NOTIFICATION_TYPES } from "../../../constants";
import {
    createStyles,
    ListItemText,
    makeStyles,
    MenuItem,
    Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { StyledMenu } from "../../../components/Select";
import LanguageChange from "../../../components/Header/LanguageChange";

const useStyles = makeStyles(() => createStyles({
    dashboard: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        padding: '10px 10px 20px',
        margin: '40px 10px 0',
        borderBottom: "1px solid black",
    },
    userInfo: {
        display: 'flex',
        flexDirection: "row"
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    backArrow: {
        color: '#007FDD',
    },
    title: {
        fontWeight: 700,
        marginLeft: 15
    },
    avatar: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: 10
    },
    avatarIcon: {
        width: '50px',
        borderRadius: "50%",
        backgroundColor: '#E5A6A6',
        padding: "10px",
        textAlign: 'center',
        fontSize: '20px',
        cursor: 'pointer',
        marginRight: '5px',
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    emailText: {
        fontSize: '10px',
        margin: 0,
    },
    nameText: {
        fontSize: "16px",
        margin: 0,
    },
}));

const Dashboard: React.FC = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentDocumentId = localStorage.getItem('currentDocument') ?? '';

    const documentReview = useRouteMatch({
        path: [routes.documentChanges.path],
        exact: true,
        strict: true,
        sensitive: true,
    });

    const userData = useSelector(selectUserData);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const handleLogout = async () => {
        try {
            dispatch(logout());
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    return (
        <main className={classes.dashboard}>
            <section className={classes.titleContainer}>
                {!!documentReview ? (
                    <>
                        <Link to={`${routes.documentChangesList.path}?page=1${currentDocumentId ? `&id=${currentDocumentId}` : ''}`}>
                            <ArrowBack className={classes.backArrow}/>
                        </Link>
                        <Typography
                            variant='h4'
                            component='h1'
                            className={classes.title}
                        >
                            {t('document_review.sub_title')}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Link to={`${routes.documentsChange.path}?page=1`} >
                            <ArrowBack className={classes.backArrow}/>
                        </Link>
                        <Typography
                            variant='h4'
                            component='h1'
                            className={classes.title}
                        >
                            {t('document_review.title')}
                        </Typography>
                    </>
                )}
            </section>

            <section className={classes.userInfo}>
                {!!userData && (
                    <section className={classes.avatar}>
                        <div
                            className={classes.avatarIcon}
                            onClick={handleClick}
                        >
                            {getFirstLetters(userData.first_name, userData.last_name)}
                        </div>
                        <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem classes={{root: classes.menuItem}} disabled>
                                <p className={classes.nameText}>
                                    {`${userData?.first_name} ${userData?.last_name}`}
                                </p>
                                <p className={classes.emailText}>
                                    {userData?.email}
                                </p>
                            </MenuItem>
                            <MenuItem>
                                <Link to={routes.userDocuments.path}>
                                    <ListItemText primary={t('header.menu_list.my_uploads')}/>
                                </Link>
                            </MenuItem>
                            <MenuItem>
                                <ListItemText
                                    primary={t('header.menu_list.logout')}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleLogout();
                                    }}
                                />
                            </MenuItem>
                        </StyledMenu>
                        <div>
                            <Typography
                                variant='body1'
                                component='p'
                            >
                                {t('document_review.welcome')}
                            </Typography>
                            <Typography
                                variant='body1'
                                component='p'
                            >
                                {`${userData.first_name} ${userData.last_name}`}
                            </Typography>
                        </div>
                    </section>
                )}
                <LanguageChange/>
            </section>
        </main>
    )
};

export default Dashboard;