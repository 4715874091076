export type TRoute = {
    path: string;
    name: string;
};

type TRoutes = {
    [key in string]: TRoute;
};

const routes: TRoutes = {
    home: {
        path: '/gallery',
        name: 'home',
    },
    landing: {
        path: '/',
        name: 'welcome',
    },
    registration: {
        path: '/registration',
        name: 'registration',
    },
    sendEmail: {
        path: '/send-email',
        name: 'send-email',
    },
    emailVerificationSuccess: {
        path: '/verify-email/:token',
        name: 'verify-email-success',
    },
    successfullyRegistered: {
        path: '/successfully-registered',
        name: 'successfully-registered',
    },
    login: {
        path: '/login',
        name: 'login',
    },
    forgotPassword: {
        path: '/forgot-password',
        name: 'forgot-password',
    },
    resetPassword: {
        path: '/reset-password/:token',
        name: 'reset-password',
    },
    resetPasswordSuccess: {
        path: '/reset-password-success',
        name: 'reset-password-success'
    },
    adminDashboard: {
        path: '/admin-dashboard',
        name: 'admin-dashboard'
    },
    adminDashboardOverview: {
        path: '/admin-dashboard/overview',
        name: 'admin-dashboard-overview'  
    },
    adminDashboardUsers: {
        path: '/admin-dashboard/users',
        name: 'admin-dashboard-users'
    },
    adminDashboardGroups: {
        path: '/admin-dashboard/groups',
        name: 'admin-dashboard-groups'
    },
    adminDashboardDocuments: {
        path: '/admin-dashboard/documents',
        name: 'admin-dashboard-documents'
    },
    documentsUpload: {
        path: '/documents-upload',
        name: 'documents-upload'
    },
    documentsUploadFormFirst: {
        path: '/documents-upload/upload-form-first',
        name: 'documents-upload-form-first'
    },
    documentsUploadFormSecond: {
        path: '/documents-upload/upload-form-second',
        name: 'documents-upload-form-second'
    },
    documentsEdit: {
        path: '/document-edit/:id',
        name: 'document-edit'
    },
    documentsList: {
        path: '/documents',
        name: 'documents'
    },
    documentViewBasic: {
        path: '/documents/:id',
        name: 'documents'
    },
    documentViewAdmin: {
        path: '/admin-dashboard/documents/:id',
        name: 'admin-dashboard/documents'
    },
    userDocuments: {
        path: '/my-documents',
        name: 'my-documents'
    },
    userDocument: {
        path: '/my-documents/:id',
        name: 'my-document'
    },
    documentsChange: {
        path: '/admin-dashboard/documents-change',
        name: 'admin-dashboard/documents-change'
    },
    documentChangeReview: {
        path: '/document-review',
        name: 'document-review'
    },
    documentChanges: {
        path: '/document-changes',
        name: 'document-changes'
    },
    documentChangesList: {
        path: '/document-changes-list',
        name: 'document-changes-list'
    },
    documentEditAdmin: {
        path: '/document-edit-admin/:id',
        name: 'document-edit-admin'
    },
    reports: {
        path: '/admin-dashboard/reports',
        name: 'admin-dashboard/reports'
    },
    faq: {
        path: '/faq',
        name: 'faq'
    },
    termsAndConditions: {
        path: '/terms-conditions',
        name: 'terms-conditions'
    },
    privacyPolicy: {
        path: '/privacy-policy',
        name: 'privacy-policy'
    },
    aboutUs: {
        path: '/about-us',
        name: 'about-us'
    },
};

export default routes;
