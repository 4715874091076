import React from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles, Typography } from "@material-ui/core";
import Accordion from "./Accardion";
import background from '../../../assets_ash/images/faq.png'

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexDirection: 'column',
    },
    viewImage: {
        flex: '10%',
        '& > img': {
            width: '100%',
        }
    },
    viewInfo: {
        flex: '90%',
        padding: '25px 200px 0'
    },
    title: {
        fontSize: 48,
        fontWeight: 400,
        marginBottom: '5px'
    }
});

const FAQ: React.FC = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();

    const titles: Record<string, string>[] = [
        {
            title: 'Can I view pictures, documents, and videos without registeration?',
            text: 'Yes, you can but you cannot upload any new file or comment, add or correct information of any of the pictures, documents, or videos.'
        },
        {
            title: 'What is the meaning of the duplicate sign with a number on the top right-hand side of the pictures on home page?',
            text: 'Indication of number of photos in the file. Open the picture by clicking on it you will see the first picture.  Keep your pointer on the picture and you will see two arrows on the left and the right of the photo. By clicking on arrows navigate for other pictures.'
        },
        {
            title: 'How to use filter?',
            text: 'Click on the Filter sign. Select your required searching attributes. Then click, Apply.'
        },
        {
            title: 'Can I correct or add missing information to the information provided on the attributes next to a picture, like the name of participants in a picture?',
            text: 'Yes, you can. Click on the “Suggest Edit” at bottom of the picture.  Then on Document Info dialog box add your correction or addition. Then click on Submit on the top of page.'
        },
        {
            title: 'Can I see my corrections or additions as soon as I submit them on “Suggest Edit” function?',
            text: 'No, the corrections and additions need to be reviewed by administrator/group owner and approved before can be available to public.'
        },
        {
            title: 'How can I navigate the pictures?',
            text: 'Click on the picture on home page and view the large size picture. Click on arrows on extreme right and left to navigate to other pictures.'
        },
        {
            title: 'Why can’t I sign in?',
            text: 'Make sure the email address you are using is the same as you have used to register. Make sure you have verified your email address from the email sent out during registration. Make sure your password is correct. If you are still having problems logging in please contact us directly.'
        },
        {
            title: 'Do I have to Register to use the site?',
            text: 'To browse documents and photos on the site you do not need to register. You would only register to upload/download photos and documents and to edit any information.'
        },
        {
            title: 'Why does it ask for my birthdate during Registration?',
            text: 'We ask for your birthday during Registration to allow our database to organize information chronologically.'
        },
        {
            title: 'Do I need to be a member of Homenetmen to use the site?',
            text: 'No, you do not have to be part of any organization to register and use the site.'
        },
        {
            title: 'Can I make corrections on information already uploaded? How?',
            text: 'Yes, you can update or correct all the information provided by other visitors. The correction will be reviewed before it is updated. To make corrections to any photo/document click on the File>Suggest Edit>make the corrections and submit.'
        },
        {
            title: 'How do I verify my email after Registration?',
            text: 'A confirmation email will be sent to the registered email. From the email, you will follow directions provided and verify your email.'
        },
        {
            title: 'Why is my correction pending?',
            text: 'All corrections must be approved by an Administrator for quality control. Once the correction is reviewed and approved you will see the correction on the document.'
        },
        {
            title: 'Who can see my uploads?',
            text: 'All documents/photos are public unless specified otherwise during upload. You can make the documents/photos private, and it will be seen only by registered users.'
        },
        {
            title: 'How do I switch between English and Armenian on the site?',
            text: 'On the top right side of the website, you will find the flags for each language provided. Click on the flag to switch between the languages.'
        },
        {
            title: 'How can I see my uploads?',
            text: 'Under the filter button (top right) you can use the drop-down menu to choose “My Uploads”'
        },
        {
            title: 'How can I only see the last (new) uploads?',
            text: 'Under the filter button (top right) you can use the drop-down menu to choose “Last Uploads”'
        },
        {
            title: 'If I have a question, who do I contact?',
            text: 'Please email us directly WE NEED AN EMAIL HERE'
        },
        {
            title: 'Why do you need my Country location at the time of Registration?',
            text: 'We ask for your Country location during Registration to allow our database to organize information.'
        },
        {
            title: 'Can I use and register on the site if I was never a member of Homenetmen?',
            text: 'Yes, it is open to anyone.'
        },
        {
            title: 'How do I upload a picture, document, or video?',
            text: 'Login to the site, click Upload on the top of home screen, drag and drop, or browse image(s), click on ...............................................'
        },
        {
            title: 'Do I need to complete all the attributes to upload?',
            text: 'No, documents can be uploaded with no attributes. However, we encourage you to complete the attributes as much as possible. It will make your upload much more valuable.'
        },
        {
            title: 'Can I use and register on the site if I was never a member of Homenetmen?',
            text: 'Yes, it is open to anyone.'
        },
    ]

    return (
        <section className={classes.main}>
            <section className={classes.viewImage}>
                <img src={background} alt="backgroundImage"/>
            </section>
            <section className={classes.viewInfo}>
                <Typography className={classes.title}>
                    {t('faq.title')}
                </Typography>

                {titles && titles.map((paragraph) => (
                    <Accordion title={paragraph.title} text={paragraph.text}/>
                ))}
            </section>
        </section>
    )
};

export default FAQ;