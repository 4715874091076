import {Dispatch, SetStateAction} from "react";
import { IAnnotation } from "../interfaces";

//Global Enums
export enum IsMember {
    Yes = 'Yes',
    No = "No"
}

export enum Tab {
    photo = 'photo',
    document = 'document',
    video = 'video'
}

export enum RadioEnum {
    BY_LAST = 'last',
    BY_GROUP = 'group',
    BY_DATE = 'date',
    BY_ME = 'me',
    BY_STATUS = 'status'
}

export enum StatusEnum {
    PENDING = 'pending',
    APPROVED = 'approved',
    DECLINED = 'declined'
}

export enum CurrentStatus {
    Approved = 'approved',
    Declined = 'declined',
    Pending = 'pending'
}

export enum RevisionStatus {
    Done = 'done',
    Pending = 'pending'
}

export enum DisableEnum {
    CREATE_ASC = 'create_asc',
    CREATE_DESC = 'create_desc',
    UPDATE_ASC = 'update_asc',
    UPDATE_DESC = 'update_desc',
    TYPE_ASC = 'type_asc',
    TYPE_DESC = 'type_desc',
    STATUS_ASC = 'status_asc',
    STATUS_DESC = 'status_desc',
    PRIVACY_ASC = 'privacy_asc',
    PRIVACY_DESC = 'privacy_desc'
}

export enum NotificationType {
    DOCUMENT = 'document',
    REVISION = 'revision'
}

export enum UserRoles {
    ADMIN = 'Admin',
    BASIC = 'Basic',
    GROUP_OWNER = 'Group owner',
    PRIVATE_VIEWER = 'Private viewer'
}

//Global Types
export type TTab = 'photo' | 'document' | 'video' | ''

export type TRadio = 'last' | 'group' | 'date' | 'me' | 'status' | ''

export type TStatus = 'pending' | 'approved' | 'declined' | ''

export type TDisable =
    'create_asc'
    | 'create_desc'
    | 'update_asc'
    | 'update_desc'
    | 'type_asc'
    | 'type_desc'
    | 'status_asc'
    | 'status_desc'
    | 'privacy_asc'
    | 'privacy_desc'
    | ''

export type TSort = {
    name: string,
    sortDir: string,
    disable: string
}

export type Filter = Omit<Tabs, 'types' | 'setTypes'>

export type Tabs = {
    handleFilter: any
}

export type DateRange = {
    createdAtFrom: string,
    createdAtTo: string
}

export type RegisterFormValues = {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    confirm_password: string;
    date_of_birth: string;
    street: string;
    state_province: string;
    country: string;
    date_from: string;
    date_to: string;
    group: Record<string, any>[];
    otherGroup: string,
    city: string;
    isMember: string,
};

export type ChangeDocumentFiles = {
    [key: string]: {
        changes: ChangeFile[],
        file_path: string
    },
};

export type ChangeFile = {
    id: string,
    key: string,
    old_value: string,
    value: string,
    status: string,
    created_at: string
}

export type DocumentRevision = {
    key: string | number | symbol,
    value: string,
}

export type DocumentFileRevision = {
    file_id: string,
    changes: DocumentRevision[],
}

export type CompareDocument = {
    document: DocumentRevision[],
    document_files: DocumentFileRevision[],
}

export type RevisionDocument = {
    id: string,
    name: string,
    date: string,
    approx_date?: {
        from: string,
        to: string,
        period: string
    },
    date_range?: {
        from: string,
        to: string
    },
    place: string,
    group_id: string,
    people: string,
    privacy_level: string,
    document_source: string,
    created_at: string,
    updated_at: string,
    occasion: string,
    files: RevisionFile[],
}

export type RevisionFile = {
    id: string,
    file_path: string,
    information: string,
    narratives: string,
    people: string,
    document_source: string,
    face_tags: IAnnotation[],
}
