import React from 'react';
import { useTranslation } from "react-i18next";
import { IPerDocument } from '../../../interfaces';
import { matchFileType, matchFileTypeByExtension } from '../../../helpers';
import { TAnnotationId } from '../../../interfaces';
import { DATE_OPTIONS, FILE_TYPES } from '../../../constants';
import StaticTags from '../../StaticTags';
import styles from '../styles.module.scss';

interface ICurrentDocument {
    currentDocument: IPerDocument;
    currentFile: any; // TODO: change type to proper
    onMouseOver: (id: TAnnotationId) => void,
    onMouseOut: () => void,
};

const DocumentDataFields: React.FC<ICurrentDocument> = ({
    currentDocument,
    currentFile,
    onMouseOver,
    onMouseOut,
}) => {
    const { t } = useTranslation('common');

    const getPeopleField = () => {
        if (matchFileType(matchFileTypeByExtension(currentFile.file_path)) === FILE_TYPES.IMAGE) {
            return currentFile.face_tags.length ? (
                <div className={styles.info_section}>
                    <p className={styles.info_label}>{t('sdv.fields.people')}</p>
                    <StaticTags
                        faceTags={currentFile.face_tags}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                    />
                </div>
            ) : (
                <></>
            )
        }
        return currentFile.people ? (
            <div className={styles.info_section}>
                <p className={styles.info_label}>{t('sdv.fields.people')}</p>
                <p className={styles.info_data}>{currentFile.people}</p>
            </div>
        ) : (
            <></>
        )
    }

    const formatDocumentViewDates = (date: Record<string, string | number>): string => {
        const currentLanguage = localStorage.getItem('lang') ?? 'hy'

        if('period' in date) {
            const matchingNames = DATE_OPTIONS.period[currentLanguage as keyof typeof DATE_OPTIONS.period]
                .filter((period: any) => period.value === date?.period)
                .map((period: any) => period.name);
            const formattedFrom = !!date.from ? `${date.from}` : '';
            const formattedTo = !!date.to ? `- ${date.to} ` : ''

            return formattedFrom === formattedTo ? `${formattedFrom} ${matchingNames}` : `${formattedFrom} ${formattedTo} ${matchingNames}`
        } else {
            const formattedFrom = !!date.from ? `${date.from}` : '';
            const formattedTo = !!date.to ? `- ${date.to} ` : ''

            return `${formattedFrom} ${formattedTo}`
        }
    }

    return (
        <section className={styles.document_info}>
            {!!currentDocument.approx_date ? (
                <div className={styles.info_section}>
                    <p className={styles.info_label}>{t('sdv.fields.date')}</p>
                    <p className={styles.info_data}>{formatDocumentViewDates(currentDocument.approx_date)}</p>
                </div>
            ) : !!currentDocument.date_range ? (
                <div className={styles.info_section}>
                    <p className={styles.info_label}>{t('sdv.fields.date')}</p>
                    <p className={styles.info_data}>{formatDocumentViewDates(currentDocument.date_range)}</p>
                </div>
            ) : !!currentDocument.date && <div className={styles.info_section}>
                <p className={styles.info_label}>{t('sdv.fields.date')}</p>
                <p className={styles.info_data}>{currentDocument.date}</p>
            </div>}
            {!!currentDocument.place && <div className={styles.info_section}>
                <p className={styles.info_label}>{t('sdv.fields.location')}</p>
                <p className={styles.info_data}>{currentDocument.place}</p>
            </div>}
            {!!currentDocument.group_id && !!currentDocument.group && <div className={styles.info_section}>
                <p className={styles.info_label}>{t('sdv.fields.group')}</p>
                <p className={styles.info_data}>{currentDocument.group}</p>
            </div>}
            {!!currentDocument.occasion && <div className={styles.info_section}>
                <p className={styles.info_label}>{t('sdv.fields.occasion')}</p>
                <p className={styles.info_data}>{currentDocument.occasion}</p>
            </div>}
            {currentFile && (
                <>
                    {getPeopleField()}
                    {!!currentFile.information && <div className={styles.info_section}>
                        <p className={styles.info_label}>{t('sdv.fields.information')}</p>
                        <p className={styles.info_data}>{currentFile.information}</p>
                    </div>}
                    {!!currentFile.document_source && <div className={styles.info_section}>
                        <p className={styles.info_label}>{t('sdv.fields.document_source')}</p>
                        <p className={styles.info_data}>{currentFile.document_source}</p>
                    </div>}
                    {!!currentFile.narratives && <div className={styles.info_section}>
                        <p className={styles.info_label}>{t('sdv.fields.memory')}</p>
                        <p className={styles.info_data}>{currentFile.narratives}</p>
                    </div>}
                </>
            )}
        </section>
    );
};

export default DocumentDataFields;