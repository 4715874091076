import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import { selectIsLoading, resetPassword } from '../../store/user/userSlice';
import { showNotification } from '../../store/notification/notificationSlice';
import { NOTIFICATION_TYPES } from '../../constants';
import { IRouteParams } from '../../interfaces';
import routes from '../../routes';
import resetPassSchema from "../../schemas/resetPassSchema";
import SubmitButton from "../../components/Button";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import KeyIcon from '../../assets_ash/icons/key_icon.svg'

const useStyles = makeStyles({
    container: {
        margin: '30px auto'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    keyIcon: {
        margin: '30px 0'
    },
    title: {
        textAlign: 'center',
        fontSize: 28,
        fontWeight: 500,
        color: '#000000',
        marginBottom: 20
    },
    subTitle: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 500,
        color: '#000000',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: 'center',
        maxWidth: '500px',
        margin: '30px auto'
    },
    field: {
        width: '100%',
        margin: '10px 0'
    }
});

const ResetPassword = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const isLoading = useSelector(selectIsLoading);
    const { token } = useParams<IRouteParams>();

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfPassword, setShowConfPassword] = useState<boolean>(false);

    const handleSubmitPassword = async (
        email: string,
        password: string,
        confirm_password: string
    ) => {
        try {
            await dispatch(resetPassword(token, email, password, confirm_password));
            dispatch(showNotification({
                type: NOTIFICATION_TYPES.INFO,
                message: t('notifications.reset_password_success')
            }))
            history.push(routes.resetPasswordSuccess.path);
        } catch (e) {
            dispatch(showNotification({
                message: `${t(`notifications.${e.message as Error}`)}`,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirm_password: ''
        },
        validationSchema: resetPassSchema,
        onSubmit: (values) => {
            handleSubmitPassword(values.email, values.password, values.confirm_password)
        }
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit
    } = formik;

    return (
        <main className={classes.container}>
            <section className={classes.titleContainer}>
                <img
                    src={KeyIcon}
                    alt='key icon'
                    className={classes.keyIcon}
                />

                <Typography className={classes.title} >
                    {t('reset_password.title')}
                </Typography>

                <Typography className={classes.subTitle}>
                    {t('reset_password.sub_title')}
                </Typography>

            </section>

            <form
                onSubmit={handleSubmit}
                className={classes.form}
            >
                <TextField
                    id="email"
                    name="email"
                    className={classes.field}
                    label={t('reset_password.fields.email')}
                    type="text"
                    autoComplete="off"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                />

                <TextField
                    id="password"
                    name="password"
                    className={classes.field}
                    label={t('reset_password.fields.password')}
                    type={showPassword ? 'text' : "password"}
                    autoComplete="off"
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                onClick={() => setShowPassword(!showPassword)}
                                style={{cursor: 'pointer'}}
                            >
                                {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                            </InputAdornment>
                        )
                    }}
                />

                <TextField
                    id="confirm_password"
                    name="confirm_password"
                    className={classes.field}
                    label={t('reset_password.fields.confirm_password')}
                    type={showConfPassword ? 'text' : "password"}
                    autoComplete="off"
                    variant="outlined"
                    value={values.confirm_password}
                    onChange={handleChange}
                    error={touched.confirm_password && Boolean(errors.confirm_password)}
                    helperText={touched.confirm_password && errors.confirm_password}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                onClick={() => setShowConfPassword(!showConfPassword)}
                                style={{cursor: 'pointer'}}
                            >
                                {showConfPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                            </InputAdornment>
                        )
                    }}
                />

                <SubmitButton
                    type='submit'
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={25} color='inherit'/> : t('generics.reset_password')}
                </SubmitButton>
            </form>
        </main>
    )
}

export default ResetPassword;