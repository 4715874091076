import React from 'react';
import DashboardLayout from './DashboardLayout';

import styles from './styles.module.scss';

const AdminDashboard = () => {
    return (
        <div className={styles.dashboard}>
            <DashboardLayout />
        </div>
    );
};

export default AdminDashboard;
