import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { showNotification } from "../../store/notification/notificationSlice";
import { getDocumentById, selectDocument } from "../../store/documents/documentsSlice";
import { NOTIFICATION_TYPES } from "../../constants";
import { IDocumentIdParams } from "../../interfaces";
import { makeStyles } from "@material-ui/core";
import UploadBar from "../DocumentsUpload/UploadFormSecond/UploadBar";
import Sidebar from "./Sidebar";
import EditBoard from "./EditBoard";
import { 
    applyAnnotations, 
    deleteAnnotation,
    activeAnnotationComparator,
} from '../../helpers/annotationHelpers';
import { 
    IAnnotation, 
    TAnnotationId, 
    IDocumentFile, 
    TAnnotationData, 
    TAnnotationGeometry 
} from '../../interfaces';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        paddingLeft: '7%',
        paddingRight: '2%',
    },
    layout: {
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
    }
});

const DocumentsEdit = () => {
    const classes = useStyles();
    const document = useSelector(selectDocument);
    const dispatch = useDispatch();

    const { id } = useParams<IDocumentIdParams>();

    const [currentFile, setCurrentFile] = useState(document?.files[0]);
    const [annotations, setAnnotations] = useState<IAnnotation[][]>([]);
    const [activeAnnotations, setActiveAnnotations] = useState<TAnnotationId[]>([]);

    const applyImageAnnotations = (documentIndex: number, geometry: TAnnotationGeometry, data: TAnnotationData) => {
        applyAnnotations(documentIndex, geometry, data, annotations, setAnnotations);
    };

    const deleteImageAnnotation = (documentIndex: number, data: TAnnotationData) => {
        deleteAnnotation(documentIndex, data, annotations, setAnnotations);
    };

    const onMouseOver = (id: TAnnotationId) => {
        if (!activeAnnotations.length) {
            setActiveAnnotations([id]);
        }
    };

    const onMouseOut = () => {
        setActiveAnnotations([]);
    };

    const handleGetDocument = async (id: string) => {
        try {
            await dispatch(getDocumentById(id));
        } catch (e) {
            dispatch(showNotification({
                message: e.message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    useEffect(() => {
        handleGetDocument(id);
    }, [id])

    useEffect(() => {
        if(!!document?.files) {
            setCurrentFile(document?.files[0]);
        }

        if (document && !annotations.length) {
            // Init annotations
            const annotationsFromFiles: IAnnotation[][] = document.files.map((file: any) => {
                return file.face_tags;
            })

            setAnnotations(annotationsFromFiles);
        }
    }, [document]);

    return (
        <main className={classes.container}>
            <section className={classes.layout}>
                <UploadBar/>

                <EditBoard
                    document={document}
                    currentFile={currentFile}
                    annotations={annotations}
                    activeAnnotations={activeAnnotations}
                    applyImageAnnotations={applyImageAnnotations}
                    deleteImageAnnotation={deleteImageAnnotation}
                    setCurrentFile={setCurrentFile}
                />
            </section>

            <Sidebar
                document={document}
                currentFile={currentFile}
                annotations={annotations}
                deleteImageAnnotation={deleteImageAnnotation}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
            />
        </main>
    )
}

export default DocumentsEdit;