import React from 'react';
import {
    FormikValues,
} from 'formik';
import {
    Button,
    makeStyles,
} from '@material-ui/core';
import { IFormikDocument } from '../../../types';


import styles from '../../styles.module.scss';
import {useTranslation} from "react-i18next";

interface DocumentFormikValues extends FormikValues {
    docs: any[];
};

interface IFieldActions {
    values: DocumentFormikValues;
    copiedFormValues: IFormikDocument;
    setCopiedFormValues: (value: any) => void;
    copyAllFormFields: (value: any) => void;
    assignCopiedValuesToFields: () => void;
};

const useStyles = makeStyles({
    copyAllBtn: {
        paddingLeft: 0,
        paddingRight: 0,
    },
});

const FieldActions: React.FC<IFieldActions> = ({
    values,
    copiedFormValues,
    setCopiedFormValues,
    copyAllFormFields,
    assignCopiedValuesToFields,
}) => {
    const { t } = useTranslation('common');
    const classes = useStyles();

    return (
        <section className={styles.title}>
            <h3>{t('upload.second_form.field_actions.title')}</h3>

            {copiedFormValues ? (
                <div className={styles.copy_paste_section}>
                    <Button
                        className={classes.copyAllBtn}
                        onClick={assignCopiedValuesToFields}
                    >
                        <span className={styles.copy_paste_btn}>{t('upload.second_form.field_actions.assign_copied_fields')}</span>
                    </Button>
                    <Button
                        className={classes.copyAllBtn}
                        onClick={() => setCopiedFormValues(null)}
                    >
                        <span className={styles.copy_paste_btn}>{t('upload.second_form.field_actions.clear_copied_fields')}</span>
                    </Button>
                </div>
            ) : (
                <Button
                    className={classes.copyAllBtn}
                    onClick={() => copyAllFormFields(values.docs)}
                >
                    <span className={styles.copy_paste_btn}>{t('upload.second_form.field_actions.copy_all_fields')}</span>
                </Button>
            )}
        </section>
    );
};

export default FieldActions;
