import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectSearch } from "../../store/documents/documentsSlice";

type NoDataNotification = {
    text: string
}

const NoDataNotification: React.FC<NoDataNotification> = ({text}) => {
    const { t } = useTranslation('common');
    const searchResult = localStorage.getItem('searchResult')

    const search = useSelector(selectSearch);

    const currentNotification = useMemo(() => {
        if (text === 'not_search_result') {
            return <div
                dangerouslySetInnerHTML={{__html: `${t('notifications.not_search_result', {search: search ? search : searchResult})}`.replace(/\n/g, "<br>")}}/>
        }
        return `${t(`notifications.${text}`)}`
    }, [text])

    return (
        <>
            {currentNotification}
        </>
    )
};

export default NoDataNotification;