import React from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles, Typography } from "@material-ui/core";
import background from "../../../assets_ash/images/faq.png";

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexDirection: 'column',
    },
    viewImage: {
        flex: '10%',
        '& > img': {
            width: '100%',
        }
    },
    viewInfo: {
        flex: '90%',
        padding: '25px 200px 0'
    },
    title: {
        fontSize: 48,
        fontWeight: 400,
        marginBottom: '15px'
    },
    subTitle : {
        fontSize: 16,
        fontWeight: 400,
        color: '#707070',
        marginBottom: '15px'
    },
    infoText: {
        fontSize: 16,
        color: '#000000',
        '&:last-child': {
            marginTop: 10
        }
    }
});

const PrivacyPolicy: React.FC = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();

    return (
        <section className={classes.main}>
            <section className={classes.viewImage}>
                <img src={background} alt="backgroundImage"/>
            </section>
            <section className={classes.viewInfo}>
                <Typography className={classes.title}>
                    {t('privacy_policy.title')}
                </Typography>
                <Typography className={classes.subTitle}>
                    {t('terms_and_conditions.sub_title')}
                </Typography>

                <Typography className={classes.infoText}>
                    We know how important it is for you to understand how we use your personal information. This Privacy Policy sets out how and why HOMENETMEN GLENDALE ARARAT, collect, store, process, and share your personal information. We strive to be transparent with you about what we do with your personal information.
                </Typography>
                <Typography className={classes.infoText}>
                    We believe that privacy is a fundamental human right and take our responsibility very seriously. We are committed to protecting your privacy. Our customers entrust us as data stewards for their users' information.
                </Typography>
            </section>
        </section>
    )
};

export default PrivacyPolicy;