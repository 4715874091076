// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_logo__22loG {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  max-width: 160px;\n  width: 100%; }\n  .styles_logo_icon__2dayE {\n    display: block;\n    width: 100%; }\n", ""]);
// Exports
exports.locals = {
	"logo": "styles_logo__22loG",
	"logo_icon": "styles_logo_icon__2dayE"
};
module.exports = exports;
