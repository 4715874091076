import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { parse } from "query-string";
import { makeStyles } from "@material-ui/core";
import { getRevisionsDocument, selectRevisionDocument } from "../../store/documents/documentsSlice";
import { showNotification } from "../../store/notification/notificationSlice";
import { NOTIFICATION_TYPES } from "../../constants";
import { ChangeDocumentFiles } from "../../assets_ash/globalTypes";
import Sidebar from "./Sidebar";
import Dashboard from "./Dashboard";
import ChangeBoard from "./ChangeBoard";

const useStyles = makeStyles({
    main: {
        display: 'flex', flexDirection: 'row'
    },
    sidebar: {
        flex: '20%'
    },
    changeBoard: {
        flex: '80%',
        display: 'flex',
        flexDirection: 'column'
    }
});

const DocumentChangeReview: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const revision = useSelector(selectRevisionDocument);

    const [currentFile, setCurrentFile] = useState<ChangeDocumentFiles>();

    const handleGetRevisionDocument = async (id: string) => {
        try {
            await dispatch(getRevisionsDocument(id));
        } catch (e) {
            dispatch(showNotification({
                message: e.message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    useEffect(() => {
        handleGetRevisionDocument(parse(location.search)?.id as string);
    }, []);

    useEffect(() => {
        if (!!revision) {
            const firstIndexObject = Object
                .values(revision.document_files)
                .flatMap((file: any) => file ? file : [])
                .find((_, index) => index === 0);
            const currentFile = Object.entries(revision.document_files).filter(([key, file]) => !!revision.document_files && !!file && file === firstIndexObject);
            if(currentFile?.length !== 0 && typeof currentFile !== undefined && !!currentFile) {
                setCurrentFile(currentFile[0][1])
            } else {
                setCurrentFile(revision.main_file)
            }
        }
    }, [revision]);

    return (
        <>
            {!!revision && (
                <main className={classes.main}>
                    <section className={classes.sidebar}>
                        <Sidebar
                            document_files={revision.document_files}
                            current={currentFile}
                            setCurrent={setCurrentFile}
                        />
                    </section>
                    <section className={classes.changeBoard}>
                        <Dashboard/>
                        <ChangeBoard
                            document_files={revision.document_files}
                            document={revision.document}
                            currentDocument={currentFile}
                            user={revision.user}
                            id={revision.id}
                            created_at={revision.created_at}
                        />
                    </section>
                </main>
            )}
        </>
    )
}

export default DocumentChangeReview;