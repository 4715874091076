import React from 'react';
import { Pagination } from '@material-ui/lab';

interface IElementsPagination {
    page: number;
    total: number;
    isLoading: boolean;
    setPage: (page: number) => void;
};

const ElementsPagination: React.FC<IElementsPagination> = ({ page, total, isLoading, setPage }) => {
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <div className="d-flex align-items-center justify-content-center flex-wrap">
            <Pagination
                count={total}
                variant="outlined"
                shape="rounded"
                page={page}
                onChange={handleChange}
                disabled={isLoading}
            />
        </div>
    );
};

export default ElementsPagination;
