import * as Yup from 'yup';
import i18n from "../i18n";

const monthToNumber = (monthName: string): number => {
    const monthMap: Record<string, number> = {
        Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6,
        Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12
    };
    return monthMap[monthName];
};

export const filterValidationSchema = Yup.object().shape({
    fromYear: Yup.string()
        .required(i18n.t('common:admin.group_table.validations.year_from_required'))
        .test({
            name: 'fromYear',
            message: i18n.t('common:admin.group_table.validations.from_less_or_equal'),
            test: function (fromYear) {
                const { toYear } = this.parent;
                if (!fromYear || !toYear) {
                    return true;
                }
                return parseInt(fromYear) <= parseInt(toYear);
            },
        }),
    toYear: Yup.string()
        .required(i18n.t('common:admin.group_table.validations.year_to_required')),
    fromMonth: Yup.string()
        .when(['fromYear', 'toYear'], {
            is: (fromYear, toYear) => fromYear === toYear,
            then: Yup.string()
                .test({
                    name: 'fromMonth',
                    message: i18n.t('common:admin.group_table.validations.from_less_or_equal'),
                    test: function (fromMonth) {
                        const { toMonth } = this.parent;
                        if (!fromMonth || !toMonth) {
                            return true;
                        }

                        const fromMonthNum = monthToNumber(fromMonth);
                        const toMonthNum = monthToNumber(toMonth);

                        return fromMonthNum <= toMonthNum;
                    },
                })
                .required(i18n.t('common:admin.group_table.validations.year_from_required')),
            otherwise: Yup.string().required(i18n.t('common:admin.group_table.validations.year_from_required')),
        }),
    toMonth: Yup.string()
        .required(i18n.t('common:admin.group_table.validations.year_to_required')),
});