import { INotificationTypes, INotificationMessages } from "../interfaces";
import { DisableEnum, NotificationType } from "../assets_ash/globalTypes";
import { faGripHorizontal, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faFile, faListAlt } from "@fortawesome/free-regular-svg-icons";
import { localizeRouth } from "../helpers/localizationHelpers";
import routes from "../routes";
import i18n from "../i18n";
import fileChange from '../assets_ash/icons/document_change.svg'
import reportIcon from '../assets_ash/icons/report_icon.svg'
import landingLogin from "../assets_ash/icons/landingLogin.svg";
import landingGallery from "../assets_ash/icons/landingGallery.svg";
import landingUpload from "../assets_ash/icons/landingUpload.svg";
import landingRegister from "../assets_ash/icons/landingRegister.svg";

export const DEFAULT_PAGE_SIZE = 10;

export const TOKEN = 'token';

export const LANG = 'i18nextLng';

export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';

export const MATERIAL_UI_DATE_FORMAT = 'YYYY-MM-DDThh:mm';

export const DATE_FORMAT = 'yyyy-MM-dd';

export const ISO_9601_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const DEFAULT_DATE_TIME_FORMAT = 'DD-MM-YYYY, HH:mm';

export const DATE_TIME_FORMAT = 'HH:mm';

export const DATABLE_DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss';

export const LANGUAGES = {
    HY: 'hy',
    EN: 'en',
};

export const MODEL_NOT_FOUND_ERROR_CODE = 4004;

export const USER_ROLES = {
    ADMIN: 'admin',
    BASIC: 'basic',
    PRIVATE_VIEWER: 'private_viewer',
    GROUP_OWNER: 'group_owner',
};

export const TEXTAREA = {
    ROWS: 5,
    MAX_LENGTH: 600,
};

export const UNAUTHORIZED = 'Unauthorized';

export const PAGINTAION_PER_PAGE = 10;

export const NOTIFICATION_TYPES: INotificationTypes = {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
};

export const NOTIFICATION_MESSAGES: INotificationMessages = {
    SUCCESS: 'Success',
    INFO: 'Email has been sent',
    WARNING: 'Warning',
    ERROR: 'Something went wrong',
};

export const DRAWER_WIDTH = 270;
export const DOCUMENT_DRAWER_WIDTH = 290;

export const BACK_STEP = {
    BACK_TO_HOME: 'Back To Home',
    BACK_TO_LOGIN: 'Back To Login'
}

export const NO_BACK_MATCHES = [
    localizeRouth(routes.home.path),
    localizeRouth(routes.documentsUpload.path),
    localizeRouth(routes.documentsUploadFormFirst.path),
    localizeRouth(routes.documentsUploadFormSecond.path),
    localizeRouth(routes.documentsEdit.path),
    localizeRouth(routes.userDocuments.path),
    localizeRouth(routes.userDocument.path),
    localizeRouth(routes.documentViewBasic.path),
    localizeRouth(routes.documentChangesList.path),
    localizeRouth(routes.faq.path),
    localizeRouth(routes.termsAndConditions.path),
    localizeRouth(routes.privacyPolicy.path),
    localizeRouth(routes.aboutUs.path),
    localizeRouth(routes.landing.path),
    localizeRouth(routes.documentEditAdmin.path),
];

export const NO_SEARCH_MATCHES = [
    localizeRouth(routes.registration.path),
    localizeRouth(routes.forgotPassword.path),
    localizeRouth(routes.resetPassword.path),
    localizeRouth(routes.sendEmail.path),
    localizeRouth(routes.successfullyRegistered.path),
    localizeRouth(routes.emailVerificationSuccess.path),
    localizeRouth(routes.resetPasswordSuccess.path),
    localizeRouth(routes.documentChangeReview.path),
    localizeRouth(routes.landing.path),
];

export const NO_HEADER_MATCHES = [
    localizeRouth(routes.login.path),
    localizeRouth(routes.adminDashboardOverview.path),
    localizeRouth(routes.adminDashboardUsers.path),
    localizeRouth(routes.adminDashboardGroups.path),
    localizeRouth(routes.adminDashboardDocuments.path),
    localizeRouth(routes.documentsUploadFormSecond.path),
    localizeRouth(routes.documentViewAdmin.path),
    localizeRouth(routes.documentsEdit.path),
    localizeRouth(routes.documentsChange.path),
    localizeRouth(routes.documentChangeReview.path),
    localizeRouth(routes.documentChanges.path),
    localizeRouth(routes.reports.path),
    localizeRouth(routes.landing.path),
    localizeRouth(routes.documentEditAdmin.path),
];

export const LANDING_PAGE_WIDGETS = [
    {
        icon: landingLogin,
        text: i18n.t('common:widget.login'),
        link: routes.login.path,
        isAuth: true
    },
    {
        icon: landingGallery,
        text: i18n.t('common:widget.gallery'),
        link: routes.home.path,
        isAuth: false
    },
    {
        icon: landingUpload,
        text: i18n.t('common:widget.upload'),
        link: routes.documentsUpload.path,
        isAuth: false
    },
    {
        icon: landingRegister,
        text: i18n.t('common:widget.register'),
        link: routes.registration.path,
        isAuth: true
    }
]

export const NO_FOOTER_MATCH = [
    localizeRouth(routes.adminDashboardOverview.path),
    localizeRouth(routes.adminDashboardUsers.path),
    localizeRouth(routes.adminDashboardGroups.path),
    localizeRouth(routes.adminDashboardDocuments.path),
    localizeRouth(routes.documentViewAdmin.path),
    localizeRouth(routes.documentChangesList.path),
    localizeRouth(routes.documentChangeReview.path),
    localizeRouth(routes.documentsChange.path),
    localizeRouth(routes.documentChanges.path),
    localizeRouth(routes.reports.path),
];

export interface Options {
    [key: string]: string[];
}

export const DATE_OPTIONS = {
    months: <Options>{
        en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        hy: ['Հուն', 'Փետ', 'Մար', 'Ապր', 'Մայ', 'Հուն', 'Հուլ', 'Օգս', 'Սեպ', 'Հոկ', 'Նոյ', 'Դեկ']
    },
    last: <Options>{
        en: ['Last week', 'Last month'],
        hy: ['Անցած շաբաթ','Անցած ամիս']
    },
    period : {
        en: [
            { name: 'Early spring', value: 'early_spring' },
            { name: 'Mid spring', value: 'mid_spring' },
            { name: 'End of spring', value: 'end_spring' },
            { name: 'Early summer', value: 'early_summer' },
            { name: 'Mid summer', value: 'mid_summer' },
            { name: 'End of summer', value: 'end_summer' },
            { name: 'Early autumn', value: 'early_autumn' },
            { name: 'Mid autumn', value: 'mid_autumn' },
            { name: 'End of autumn', value: 'end_autumn' },
            { name: 'Early winter', value: 'early_winter' },
            { name: 'Mid winter', value: 'mid_winter' },
            { name: 'End of winter', value: 'end_winter' },
        ],
        hy: [
            { name: 'Գարնան սկիզբ', value: 'early_spring' },
            { name: 'Գարնան կես', value: 'mid_spring' },
            { name: 'Գարնան վերջ', value: 'end_spring' },
            { name: 'Ամառվա սկիզբ', value: 'early_summer' },
            { name: 'Ամառվա կես', value: 'mid_summer' },
            { name: 'Ամառվա վերջ', value: 'end_summer' },
            { name: 'Աշնան սկիզբ', value: 'early_autumn' },
            { name: 'Աշնան կես', value: 'mid_autumn' },
            { name: 'Աշնան վերջ', value: 'end_autumn' },
            { name: 'Ձմռան սկիզբ', value: 'early_winter' },
            { name: 'Ձմռան կես', value: 'mid_winter' },
            { name: 'Ձմռան վերջ', value: 'end_winter' },
        ],
    },
    statuses: {
        en: [
            {
                name: 'Pending',
                status: 'pending'
            },
            {
                name: 'Done',
                status: 'done'
            }
        ],
        hy: [
            {
                name: 'Ընթացքում',
                status: 'pending'
            },
            {
                name: 'Կատարված',
                status: 'done'
            }
        ]
    },
    document_statues : {
        en: [
            {
                name: 'Pending',
                status: 'pending'
            },
            {
                name: 'Approved',
                status: 'approved'
            },
            {
                name: 'Declined',
                status: 'declined'
            }
        ],
        hy: [
            {
                name: 'Ընթացքում',
                status: 'pending'
            },
            {
                name: 'Հաստատված',
                status: 'approved'
            },
            {
                name: 'Մերժված',
                status: 'declined'
            }
        ]
    }
};

export const ACTION_TYPES = {
    CREATE: 'create',
    UPDATE: 'update',
};

export const SIDEBAR_TABS = [
    {icon: faGripHorizontal, text: 'Overview', notification: false, path: '/overview', roles: ['Admin']},
    {icon: faUsers, text: 'Users', notification: false, path: '/users', roles: ['Admin']},
    {icon: faFile, text: 'Documents', notification: true, notificationType: NotificationType.DOCUMENT, path: '/documents?page=1', roles: ['Admin', 'Group owner']},
    {icon: fileChange, text: 'Documents Change', notification: true, notificationType: NotificationType.REVISION, path: '/documents-change?page=1', roles: ['Admin', 'Group owner']},
    {icon: faListAlt, text: 'Groups', notification: false, path: "/groups", roles: ['Admin']},
    {icon: reportIcon, text: 'Reports', notification: false, path: "/reports", roles: ['Admin']},
];

export const DOCUMENTS_TYPES = {
    en: [
        {
            name: 'Image',
            key: 'Image',
            formats: ['png', 'jpg', 'jpeg', 'svg', 'webp']
        },
        {
            name: 'Video',
            key: 'Video',
            formats: ['mp4', 'avi', 'mov', 'wmv', 'mkv', 'gif', 'webm']
        },
        {
            name: 'Word',
            key: 'Word',
            formats: ['doc', 'docx', 'odt', 'rtf', 'txt']
        },
        {
            name: 'PDF',
            key: 'PDF',
            formats: ['pdf', 'pptx', 'xlsx']
        },
    ],
    hy: [
        {
            name: 'Նկար',
            key: 'Image',
            formats: ['png', 'jpg', 'jpeg', 'svg', 'webp']
        },
        {
            name: 'Տեսանյութ',
            key: 'Video',
            formats: ['mp4', 'avi', 'mov', 'wmv', 'mkv', 'gif', 'webm']
        },
        {
            name: 'Word',
            key: 'Word',
            formats: ['doc', 'docx', 'odt', 'rtf', 'txt']
        },
        {
            name: 'PDF',
            key: 'PDF',
            formats: ['pdf', 'pptx', 'xlsx']
        },
    ],
};

export const FILE_TYPES = {
    IMAGE: 'image',
    VIDEO: 'video',
    PDF: 'application/pdf',
    DOC: 'application/msword',
};

export const DOC_FILE_TYPES = [
    'application/msword',
    '.doc',
    '.docx',
    '.pdf',
    '.xls',
    '.xlsx',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const DOCUMENT_PRIVACY_LEVELS = {
    PUBLIC: 'public',
    REGISTERED: 'registered',
    PRIVATE: 'private',
}

export const SORT_CREDENTIALS = {
    created_at : {
        desc: {
            name: 'created_at',
            sortDir: 'DESC',
            disable: 'create_desc'
        },
        asc: {
            name: 'created_at',
            sortDir: 'ASC',
            disable: 'create_asc'
        }
    },
    updated_at : {
        desc: {
            name: 'updated_at',
            sortDir: 'DESC',
            disable: 'update_asc'
        },
        asc: {
            name: 'updated_at',
            sortDir: 'ASC',
            disable: 'update_desc'
        }
    },
    type : {
        desc : {
            name: 'type',
            sortDir: 'DESC',
            disable: 'type_asc'
        },
        asc : {
            name: 'type',
            sortDir: 'ASC',
            disable: 'type_desc'
        }
    },
    status : {
        desc : {
            name: 'status',
            sortDir: 'DESC',
            disable: 'status_asc'
        },
        asc : {
            name: 'status',
            sortDir: 'ASC',
            disable: 'status_desc'
        }
    },
    privacy_level : {
        desc : {
            name: 'privacy_level',
            sortDir: 'DESC',
            disable: 'privacy_asc'
        },
        asc : {
            name: 'privacy_level',
            sortDir: 'ASC',
            disable: 'privacy_desc'
        }
    }
}

export const SORT_ENUMS = {
    created_at : {
        desc: DisableEnum.CREATE_DESC,
        asc: DisableEnum.CREATE_ASC
    },
    updated_at : {
        desc: DisableEnum.UPDATE_ASC,
        asc: DisableEnum.UPDATE_DESC
    },
    type : {
        desc: DisableEnum.TYPE_ASC,
        asc: DisableEnum.TYPE_DESC
    },
    status : {
        desc: DisableEnum.STATUS_ASC,
        asc: DisableEnum.STATUS_DESC
    },
    privacy_level : {
        desc: DisableEnum.PRIVACY_ASC,
        asc: DisableEnum.PRIVACY_DESC
    }
}