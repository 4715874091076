import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import api from '../../api';
import { setValueToLocalStorage, setToken, getToken, removeToken, removeValueFromLocalStorage } from '../../helpers/auth';
import { IError, IUser } from '../../interfaces';
import { AppDispatch, AppThunk, RootState } from '..';
import { API_URL } from '../../config/app';
import { NOTIFICATION_MESSAGES } from '../../constants';
import routes from "../../routes";

export interface IGroup {
    name: string;
    from: string | number;
    to: string | number;
}

type TGroup = {
    email: string,
    password: string,
    first_name: string,
    last_name: string,
    password_confirmation: string,
    dob: string,
    address: Record<string, any>,
    group: any
}

interface IUserState {
    userData: IUser | null;
    loading: boolean;
    isAuthenticated: boolean;
    error: IError | null;
    userRole: string | null
};

const initialState: IUserState = {
    userData: null,
    loading: false,
    isAuthenticated: false,
    error: null,
    userRole: null
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<IUser | null>) => {
            state.userData = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<IError | null>) => {
            state.error = action.payload;
        },
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
        setUserRole: (state, action: PayloadAction<string | null>) => {
            state.userRole = action.payload;
        }
    },
});

export const {
    setUserData,
    setLoading,
    setError,
    setIsAuthenticated,
    setUserRole
} = userSlice.actions;

export const register = ( userCredentials: Record<string, any> ): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await axios.post(`${API_URL}/register`, userCredentials);

        dispatch(setUserData(response.data));
        dispatch(setError(null));
        dispatch(setLoading(false));

        setValueToLocalStorage('unVerifyEmail', userCredentials.email);

        return response.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const verifyEmail = (token: string): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.get(`/verify-email/${token}`);

        dispatch(setUserData(response.data));
        dispatch(setError(null));
        dispatch(setLoading(false));

        return response.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const forgotPassword = (email: string): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.post(`/forgot-password`, {email});

        dispatch(setError(null));
        dispatch(setLoading(false));

        removeValueFromLocalStorage('unVerifyEmail');
        setValueToLocalStorage('verifyEmail', email);

        return response.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const resetPassword = (
    token: string, 
    email: string, 
    password: string, 
    password_confirmation: string
    ): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.post('reset-password', { token, email, password, password_confirmation });

        dispatch(setError(null));
        dispatch(setLoading(false));

        removeValueFromLocalStorage('verifyEmail');
        removeValueFromLocalStorage('unVerifyEmail');

        return response;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;
        
        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};


export const login = (email: string, password: string): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.post('/login', {
            email,
            password,
        });

        setToken(response.data.token);

        removeValueFromLocalStorage('verifyEmail');
        removeValueFromLocalStorage('unVerifyEmail');

        dispatch(setIsAuthenticated(true))
        dispatch(setError(null));
        dispatch(setLoading(false));

    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;
        
        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const getUser = (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.get('/me');

        dispatch(setUserData(response.data.data));
        dispatch(setUserRole(response.data.data.role));
        dispatch(setError(null));
        dispatch(setLoading(false));
    } catch (error) {
        const errorMessage = error || NOTIFICATION_MESSAGES.ERROR;
    
        dispatch(setError(errorMessage as IError));
        dispatch(setLoading(false));
        dispatch(setIsAuthenticated(false));

        throw new Error(errorMessage as string);
    }
};

export const logout = (): AppThunk => async (dispatch: AppDispatch) => {
    await dispatch(setUserData(null));
    await dispatch(setIsAuthenticated(false));
    if (getToken()) {
        removeToken();
    }

    const currentLang = localStorage.getItem('lang');
    if(currentLang === 'en') {
        window.location.replace(`/en${routes.landing.path}`)
    } else {
        window.location.replace(`/hy${routes.landing.path}`)
    }
};

export const emailResend = (email: string): AppThunk => async (dispatch: AppDispatch) => {
    try {
        await api.post('/resend-email-verification/', { email });

        dispatch(setError(null));
        dispatch(setLoading(false));
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const selectIsLoading = (state: RootState) => state.user.loading;
export const selectIsAuthenticated = (state: RootState) => state.user.isAuthenticated;
export const selectAuthError = (state: RootState) => state.user.error;
export const selectUserData = (state: RootState) => state.user.userData;
export const selectUserRole = (state: RootState) => state.user.userRole;

export default userSlice;
