import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import StatisticCard from '../StatisticCard';
import {
    AccountCircle,
    ListAltOutlined,
    ErrorRounded,
    HighlightOffRounded,
} from '@material-ui/icons';

import styles from './styles.module.scss';
import {
    getTabNotifications, getWindgetNotificatios,
    selectTabsNotifications,
    selectWidgetNotifications
} from "../../../store/documents/documentsSlice";
import {showNotification} from "../../../store/notification/notificationSlice";
import {NOTIFICATION_TYPES} from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    icon: {
        fontSize: '35px',
    },
    count: {
        fontSize: '35px',
        fontWeight: 700,
    },
    title: {
        fontSize: '18px',
        textAlign: 'end',
    },
});

const StatisticOverview = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const dispatch = useDispatch();

    const count = useSelector(selectWidgetNotifications)

    const getDocumentsCounts = async () => {
        try {
            await dispatch(getWindgetNotificatios());
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    useEffect(() => {
        getDocumentsCounts();
    }, [])

    return (
        <section className={styles.container}>
            <div className={styles.statistics}>
                <StatisticCard
                    bgColor={'bg-neutral-first'}
                    dataColor={'color-neutral-first'}
                    statisticCount={count ? count.total_users_count : 0}
                    statisticTitle={t('admin.widget.all_users')}
                    icon={<AccountCircle className={clsx(classes.icon, 'color-neutral-first')} />}
                />
                <StatisticCard
                    bgColor={'bg-neutral-second'}
                    dataColor={'color-neutral-second'}
                    statisticCount={count ? count.total_documents_count : 0}
                    statisticTitle={t('admin.widget.all_documents')}
                    icon={<ListAltOutlined className={clsx(classes.icon, 'color-neutral-second')} />}
                />
                <StatisticCard
                    bgColor={'bg-neutral-danger'}
                    dataColor={'color-neutral-danger'}
                    statisticCount={count ? count.pending_documents_count : 0}
                    statisticTitle={t('admin.widget.pending_documents')}
                    icon={<ErrorRounded className={clsx(classes.icon, 'color-neutral-danger')} />}
                />
                <StatisticCard
                    bgColor={'bg-neutral-danger'}
                    dataColor={'color-neutral-danger'}
                    statisticCount={count ? count.pending_changes_count : 0}
                    statisticTitle={t('admin.widget.pending_changes')}
                    icon={<ErrorRounded className={clsx(classes.icon, 'color-neutral-danger')} />}
                />
            </div>
            <div className={styles.divider}></div>
        </section>
    );
};

export default StatisticOverview;
