import { 
    createSlice, 
    PayloadAction 
} from '@reduxjs/toolkit';
import { 
    AppDispatch, 
    AppThunk, 
    RootState 
} from '..';

interface IDocumentViewOpen {
    userRole: string | null;
    currentPage: number;
    documentViewSelected: boolean;
};

interface IDocumentOpenPayload {
    role: string;
    page: number;
    selected: boolean;
};

const initialState: IDocumentViewOpen = {
    userRole: null,
    currentPage: 1,
    documentViewSelected: false,
};

const documentViewSlice = createSlice({
    name: 'documentView',
    initialState,
    reducers: {
        toggleDocumentView: (state, action: PayloadAction<IDocumentOpenPayload>) => {
            state.currentPage = action.payload.page;
            state.userRole = action.payload.role;
            state.documentViewSelected = action.payload.selected;
        },
    },
});

export const {
    toggleDocumentView,
} = documentViewSlice.actions;

export const selectUserRole = (state: RootState) => state.documentView.userRole;
export const selectCurrentPage = (state: RootState) => state.documentView.currentPage;
export const selectDocumentViewSelected = (state: RootState) => state.documentView.documentViewSelected;

export default documentViewSlice;
