import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../api';
import { IError, IGroup, IGroupOwner } from '../../interfaces';
import { AppDispatch, AppThunk, RootState } from '..';
import { AxiosError } from "axios";
import { NOTIFICATION_MESSAGES } from "../../constants";

interface IInitialState {
    groups: IGroup[],
    group: IGroup | null,
    loading: boolean,
    actionLoading: boolean,
    error: IError | null,
    total: number | null,
    perPage: number | null,
    noAuthGroup: IGroup[],
}

const initialState: IInitialState = {
    groups: [],
    group: null,
    loading: false,
    actionLoading: false,
    error: null,
    total: null,
    perPage: null,
    noAuthGroup: []
};

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<IError | null>) => {
            state.error = action.payload;
        },
        setGroups: (state, action: PayloadAction<IGroup[]>) => {
            state.groups = action.payload;
        },
        setGroup: (state, action: PayloadAction<IGroup | null>) => {
            state.group = action.payload;
        },
        setPaginationData: (state, action: PayloadAction<any>) => {
            state.total = action.payload.total;
            state.perPage = action.payload.per_page;
        },
        setActionLoading: (state, action: PayloadAction<boolean>) => {
            state.actionLoading = action.payload;
        },
        setNoAuthGroup: (state, action: PayloadAction<IGroup[]>) => {
            state.noAuthGroup = action.payload;
        }
    }
});

export const {
    setLoading,
    setError,
    setGroups,
    setGroup,
    setPaginationData,
    setActionLoading,
    setNoAuthGroup
} = groupSlice.actions;

export const getGroupsList = (page: number, isAuth: boolean = false): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.get(`${isAuth ? '/admin' : ''}/groups?page=${page}`);

        dispatch(setLoading(false));
        dispatch(setError(null));
        dispatch(setGroups(response.data.data));
        dispatch(setPaginationData(response.data.meta));

        return response.data.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
};

export const getNoAuthGroupList = (page: number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.get(`/groups/list?page=${page}`);

        dispatch(setLoading(false));
        dispatch(setError(null));
        dispatch(setNoAuthGroup(response.data.data));
        dispatch(setPaginationData(response.data.meta));

        return response.data.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }

}

export const getGroupById = (id: number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setLoading(true));

    try {
        const response = await api.get(`admin/groups/${id}`);

        dispatch(setLoading(false));
        dispatch(setError(null));
        dispatch(setGroup(response.data));

        return response.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setLoading(false));

        throw new Error(errorMessage);
    }
}

export const createGroup = (
    name: string,
    owners: IGroupOwner[]
): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setActionLoading(true));
    
    if (owners.length === 1 && !owners[0].user_id) {
        owners = [];
    }
    
    try {
        const response = await api.post(`admin/groups`, { name, owners });

        dispatch(setActionLoading(false));
        dispatch(setError(null));

        return response.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setActionLoading(false));
        dispatch(setError(errorMessage));

        throw new Error(errorMessage);
    }
};

export const updateGroup = (
    name: string,
    groupId: number,
    owners: IGroupOwner[]
): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setActionLoading(true));

    try {
        const response = await api.put(`/admin/groups/${groupId}`, { name, owners });

        dispatch(setActionLoading(false));
        dispatch(setError(null));

        return response.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setActionLoading(false));
        dispatch(setError(errorMessage));

        throw new Error(errorMessage);
    }
};

export const deleteGroup = (id: number): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(setActionLoading(true));

    try {
        const response = await api.delete(`admin/groups/${id}`);

        dispatch(setActionLoading(false));
        dispatch(setError(null));

        return response.data;
    } catch (error) {
        const errorMessage =
            (error as AxiosError)?.response?.data?.message || NOTIFICATION_MESSAGES.ERROR;

        dispatch(setError(errorMessage));
        dispatch(setActionLoading(false));

        throw new Error(errorMessage);
    }
};

export const selectGroups = (state: RootState) => state.group.groups;
export const selectGroupsLoading = (state: RootState) => state.group.loading;
export const selectGroupsActionLoading = (state: RootState) => state.group.actionLoading;
export const selectGroup = (state: RootState) => state.group.group;
export const selectGroupTotal = (state: RootState) => state.group.total;
export const selectGroupPerPage = (state: RootState) => state.group.perPage;
export const selectNoAuthGroups = (state: RootState) => state.group.noAuthGroup

export default groupSlice;




