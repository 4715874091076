import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import queryString, { stringify } from 'query-string';
import { BeatLoader } from "react-spinners";
import clsx from "clsx";
import {
    Button, Card,
    CardContent, createStyles,
    makeStyles, Table,
    Theme, Typography,
} from "@material-ui/core";
import { getAdminDocumentsList, selectNotificationCount } from "../../../store/documents/documentsSlice";
import { selectAdminDocuments, selectLoading, selectPerPage, selectTotal } from "../../../store/documents/documentsSlice";
import { showNotification } from "../../../store/notification/notificationSlice";
import { formatDate, formatDateRange, getLastWeekAndMonthDates } from "../../../helpers/dateTime";
import { areAllValuesEmpty, areAllValuesUndefined, findMatchingFileType } from "../../../helpers";
import routes from '../../../routes';
import { NOTIFICATION_TYPES, SORT_CREDENTIALS, SORT_ENUMS } from "../../../constants";
import { RemoveRedEyeOutlined, SettingsOutlined, AttachFile } from '@material-ui/icons';
import { ISort } from '../../../interfaces';
import { CurrentStatus, DateRange, TDisable } from "../../../assets_ash/globalTypes";
import { COLORS } from "../../../constants/styles";
import { Pagination } from "@material-ui/lab";
import TableSkeleton from "../DataTable/TableSkeleton";
import ActionsPopover from "../ActionsPopover";
import DocumentImage from "../../../components/DocumentImage";
import DocumentView from '../../../components/DocumentView';
import AdminFilter from "../../../components/Admin/Filter";
import TableSortArrows from "../../../components/Admin/TableSortArrows";
import NoDataNotification from "../../../components/NoDataNotification";
import TableActions from "../../../components/Admin/TableActions";

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        height: '100%',
        marginBottom: 0,
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginBottom: '20px',
    },
    tableTitleContainer: {
        fontSize: '30px',
        fontWeight: 700,
        color: '#000000',
        display: 'flex',
        justifyContent: 'space-between',
    },
    tableTitle: {
        marginRight: '15px',
    },
    totalInfo: {
        fontWeight: 'normal',
    },
    heading: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '15px',
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    cardContent: {
        minHeight: '500px',
    },
    button: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        borderBottom: '1px solid #DEDEDE',
        padding: '10px'
    },
    icons: {
        marginRight: '10px',
        width: '20px'
    },
    iconText: {
        fontSize: '15px',
        color: "#707070"
    },
    filterIcon: {
        fontSize: '20px',
    },
    filterButton: {
        display: 'flex',
        alignItems: 'center',
        height: '50px',
        borderRadius: '7px',
        marginLeft: '15px',
        padding: '20px 25px'
    },
    formGroup: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        width: '300px'
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'column',
    },
    selectIcon: {
        color: COLORS.PURE_BLUE,
        marginRight: '10px'
    },
    submitButton: {
        width: "250px",
        marginBottom: '10px',
        '& .MuiButton-label': {
            fontSize: '18px',
        },
        '&.MuiButton-containedPrimary': {
            backgroundColor: '#007FDD',
        },
        '&.MuiButton-containedPrimary:hover': {
            backgroundColor: '#007FDD',
        },
    },
    cancelButton: {
        width: "250px",
        border: 'none',
        borderRadius: '8px',
        '& .MuiButton-label': {
            fontSize: '18px',
            color: "#007FDD",
        },
    },
    sortArrows: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        '& img': {
            width: '12px'
        }
    },
}));

const DocumentsTable = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const [totalPages, setTotalPages] = useState<number | null>(null);
    const [sortTypes, setSortTypes] = useState<ISort>();
    const [isDocumentViewOpen, setIsDocumentViewOpen] = useState<boolean>(false);

    const isLoading = useSelector(selectLoading);
    const total = useSelector(selectTotal);
    const perPage = useSelector(selectPerPage);
    const documents = useSelector(selectAdminDocuments);
    const notificationCount = useSelector(selectNotificationCount);

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [query, setQuery] = useQueryParams({
        id: StringParam,
        page: NumberParam,
        my_uploads: NumberParam,
        status: StringParam,
        created_at_from: StringParam,
        created_at_to: StringParam,
        sort_dir: StringParam,
        sort_by: StringParam
    });

    const handleFilter = async (values: Record<string, string>, lastUploads: string, status: string, my_uploads: string) => {
        const {fromYear, fromMonth, toYear, toMonth} = values;
        let createDate: DateRange | undefined;

        if (!!lastUploads) {
            createDate = getLastWeekAndMonthDates(lastUploads);
        } else if (!areAllValuesEmpty(values)) {
            createDate = formatDateRange(fromYear, fromMonth, toYear, toMonth);
        }

        const userStatus = status ? status : undefined;
        const userUploads = my_uploads ? 1 : undefined;

        setQuery({
            id: undefined,
            page: 1,
            my_uploads: userUploads,
            status: userStatus,
            created_at_from: createDate?.createdAtFrom,
            created_at_to: createDate?.createdAtTo,
            sort_dir: undefined,
            sort_by: undefined
        }, 'push')

    };

    const handleSort = (sortObject: Record<string, string | any>): void => {
        const {name, sortDir, disable} = sortObject;

        setSortTypes({
            name,
            sortDir,
            disable
        })
        setQuery({
            ...query,
            page: 1,
            sort_dir: sortDir,
            sort_by: name
        }, 'replace')
    }

    const getAllDocumentsList = async (
        page: number | null | undefined,
        query?: string
    ) => {
        try {
            await dispatch(getAdminDocumentsList(page, query));
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    const handleOpenDocument = (documentId: string): void => {
        setQuery({
            ...query,
            id: documentId,
        }, 'replace');
    };

    const handleOpenDocumentChanges = (documentId: string) => {
        localStorage.setItem('currentDocument', documentId);
        history.push(`/${routes.documentChangesList.name}?id=${documentId}&page=1`)
    };

    const destructureObject = (query: Record<string, any>): Record<string, any> => {
        const { status, created_at_from, created_at_to, sort_dir, sort_by, group, my_uploads } = query;

        return {
            ...(!!status && { status }),
            ...(!!created_at_from && !!created_at_to && { created_at_from, created_at_to }),
            ...(!!sort_dir && !!sort_by && { sort_dir, sort_by }),
            ...(!!group && { group }),
            ...(!!my_uploads && { my_uploads })
        };
    };

    useEffect(() => {
        const queryParams = queryString.parse(location.search);
        if (queryParams.id) {
            setIsDocumentViewOpen(true);
        }
    }, [location]);

    useEffect(() => {
        if (!areAllValuesUndefined(query) && !query.id) {
            getAllDocumentsList(query.page, stringify(destructureObject(query)));
        }
    }, [query]);

    useEffect(() => {
        if (total && perPage) {
            setTotalPages(Math.ceil(total / perPage));
        }
    }, [total, perPage]);

    return (
        <>
            <Card className={clsx(classes.card, 'card-box mb-spacing-6-x2 bl-0 br-0 shadow-none')}>
                <CardContent className={classes.cardContent}>
                    <div className="table-responsive-md">
                        <section className={classes.tableHeader}>
                            <section className={classes.heading}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <Typography className={classes.tableTitleContainer}>
                                        <span className={classes.tableTitle}>{t('admin.document_table.title')}</span>
                                        {!isLoading && notificationCount ?
                                            <span className={classes.totalInfo}>{` (${notificationCount}) `}</span> :
                                            <span className={classes.totalInfo}>(0)</span>}
                                    </Typography>
                                    {isLoading && <BeatLoader/>}
                                </div>
                                <AdminFilter handler={handleFilter} type='document'/>
                            </section>
                        </section>

                        {isLoading ? (
                            <TableSkeleton rowsCount={10}/>
                        ) : (
                            <Table className="table table-borderless table-hover text-nowrap mb-5">
                                <thead>
                                <tr>
                                    <th className="text-left">
                                        {t('table_columns.documents')}
                                    </th>
                                    <th className="text-center">
                                        <div className={classes.sortArrows}>
                                            {t('table_columns.created_at')}
                                            <TableSortArrows
                                                setSort={handleSort}
                                                sort={SORT_CREDENTIALS.created_at}
                                                disable={sortTypes?.disable as TDisable}
                                                disableStatus={SORT_ENUMS.created_at}
                                            />
                                        </div>
                                    </th>
                                    <th className="text-center">
                                        <div className={classes.sortArrows}>
                                            {t('table_columns.updated_at')}
                                            <TableSortArrows
                                                setSort={handleSort}
                                                sort={SORT_CREDENTIALS.updated_at}
                                                disable={sortTypes?.disable as TDisable}
                                                disableStatus={SORT_ENUMS.updated_at}
                                            />
                                        </div>
                                    </th>
                                    <th className="text-center">
                                        <div className={classes.sortArrows}>
                                            {t('table_columns.group')}
                                        </div>
                                    </th>
                                    <th className="text-center">
                                        <div className={classes.sortArrows}>
                                            {t('table_columns.type')}
                                            <TableSortArrows
                                                setSort={handleSort}
                                                sort={SORT_CREDENTIALS.type}
                                                disable={sortTypes?.disable as TDisable}
                                                disableStatus={SORT_ENUMS.type}
                                            />
                                        </div>
                                    </th>
                                    <th className="text-center">
                                        <div className={classes.sortArrows}>
                                            {t('table_columns.current_status')}
                                            <TableSortArrows
                                                setSort={handleSort}
                                                sort={SORT_CREDENTIALS.status}
                                                disable={sortTypes?.disable as TDisable}
                                                disableStatus={SORT_ENUMS.status}
                                            />
                                        </div>
                                    </th>
                                    <th className="text-center">
                                        {t('table_columns.action')}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {documents && documents.map((document, index) => {
                                    return (
                                        <tr key={index} onClick={(e) => {
                                            e.stopPropagation();
                                            handleOpenDocument(document.id);
                                        }}>
                                            <td>
                                                <DocumentImage
                                                    filePath={document.file_path}
                                                    isNew={document.is_new}
                                                />
                                            </td>
                                            <td className="text-center">
                                                {formatDate(document.created_at)}
                                            </td>
                                            <td className="text-center">
                                                {formatDate(document.updated_at)}
                                            </td>
                                            <td className="text-center">
                                                {document.group}
                                            </td>
                                            <td className="text-center">
                                                {findMatchingFileType(document.file_path)}
                                            </td>
                                            <td className="text-center">
                                                <TableActions status={document.status}/>
                                            </td>
                                            <td className="text-center">
                                                <ActionsPopover
                                                    isColumn={true}
                                                    icon={<SettingsOutlined style={{color: '#8D8D8D'}}/>}
                                                >
                                                    <Button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpenDocument(document.id);
                                                        }}
                                                        color="secondary"
                                                        className={classes.button}
                                                    >
                                                        <RemoveRedEyeOutlined
                                                            className={classes.icons}
                                                            style={{color: '#707070'}}
                                                        />
                                                        <Typography className={classes.iconText}>
                                                            {t('table_columns.view_file')}
                                                        </Typography>
                                                    </Button>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleOpenDocumentChanges(document.id)
                                                        }}
                                                        color="secondary"
                                                        className={classes.button}
                                                    >
                                                        <AttachFile
                                                            className={classes.icons}
                                                            style={{color: '#707070'}}
                                                        />
                                                        <Typography className={classes.iconText}>
                                                            {t('table_columns.document_changes')}
                                                        </Typography>
                                                    </Button>
                                                </ActionsPopover>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </Table>
                        )}

                        {!documents?.length && !isLoading && (
                            <NoDataNotification text={'not_data'}/>
                        )}

                        {!!documents?.length && totalPages && (totalPages > 1) &&
                            <div className="d-flex align-items-center justify-content-center flex-wrap">
                                <Pagination
                                    count={totalPages}
                                    variant="outlined"
                                    shape="rounded"
                                    page={query.page as number ?? 1}
                                    onChange={(event, value) => {
                                        setQuery({
                                            ...query,
                                            page: value
                                        }, 'replace')
                                    }}
                                    disabled={isLoading}
                                />
                            </div>
                        }
                    </div>
                </CardContent>
            </Card>

            {documents.length || queryString.parse(location.search).id ? (
                <DocumentView
                    isDocumentViewOpen={isDocumentViewOpen}
                    documents={documents}
                    setIsDocumentViewOpen={setIsDocumentViewOpen}
                    perPage={perPage}
                    total={total}
                    sort={sortTypes}
                    getDocumentsList={getAllDocumentsList}
                />
            ) : (
                <></>
            )}
        </>
    )
};

export default DocumentsTable;