import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    getDocumentChangesNotificationCount,
    getNotificationCount,
    selectNotificationCount,
    selectRevisionDocumentNotification
} from "../../store/documents/documentsSlice";
import { NotificationType } from "../../assets_ash/globalTypes";
import { showNotification } from "../../store/notification/notificationSlice";
import { NOTIFICATION_TYPES } from "../../constants";

import styles from './styles.module.scss'

type NotificationBadge = {
    type?: NotificationType
}

const NotificationBadge: React.FC<NotificationBadge> = ({type}) => {
    const dispatch = useDispatch();
    const notificationCount = useSelector(selectNotificationCount);
    const revisionNotificationCount = useSelector(selectRevisionDocumentNotification);

    const handelGetNotificationCount = async () => {
        try {
            await dispatch(getNotificationCount());
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    const handleGetRevisionNotificationCount = async () => {
        try {
            await dispatch(getDocumentChangesNotificationCount());
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    }

    const notificationHandlers: Record<string, () => void> = {
        [NotificationType.DOCUMENT]: handelGetNotificationCount,
        [NotificationType.REVISION]: handleGetRevisionNotificationCount,
    };

    useEffect(() => {
        const handler = notificationHandlers[type || ''];
        if (handler) {
            handler();
        }
    }, [type]);

    return (
        <div className={styles.badge_container}>
            {type === NotificationType.DOCUMENT ? notificationCount : type === NotificationType.REVISION ? revisionNotificationCount : 0}
        </div>
    )
};

export default NotificationBadge;