import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
    getGroupsList,
    selectGroupsLoading,
    selectGroups,
    selectGroupTotal,
    selectGroupPerPage,
} from '../../../store/groups/groupSlice';
import { showNotification } from '../../../store/notification/notificationSlice';
import { NOTIFICATION_TYPES, ACTION_TYPES } from '../../../constants';
import { formatDate } from '../../../helpers/dateTime';
import { getTotalPageCount } from '../../../helpers';
import {
    createStyles,
    makeStyles,
    Theme,
    Table,
    Card,
    CardContent,
    Button,
    Typography,
} from '@material-ui/core';
import ElementsPagination from '../../../components/ElementsPagination';
import SearchBar from '../../../components/SearchBar';
import CreateGroup from '../FormModal';
import TableSkeleton from '../DataTable/TableSkeleton';
import ActionsPopover from '../ActionsPopover';
import { BeatLoader } from 'react-spinners';
import { ListAltRounded } from '@material-ui/icons';
import { FilterListOutlined } from '@material-ui/icons';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            height: '100%',
            marginBottom: 0,
        },
        tableHeader: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginBottom: '20px',
        },
        tableTitleContainer: {
            fontSize: '30px',
            fontWeight: 700,
            color: '#000000',
            display: 'flex',
            justifyContent: 'space-between',
        },
        tableTitle: {
            marginRight: '15px',
        },
        totalInfo: {
            fontWeight: 'normal',
        },
        heading: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '15px',
        },
        actions: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        iconContainer: {
            width: '50px',
            height: '50px',
            backgroundColor: '#777777',
            borderRadius: '50%',
            padding: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '15px',
        },
        listIcon: {
            fontSize: '35px',
            color: '#ffffff',
        },
        filterIcon: {
            fontSize: '18px',
        },
        filterButton: {
            marginLeft: '15px',
            display: 'flex',
            alignItems: 'center',
            height: '40px',
        },
    }),
);

const GroupsTable = () => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [actionType, setActionType] = React.useState('create');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState<number | null>(null);
    const [group, setGroup] = useState<any>(null);

    const groups = useSelector(selectGroups);

    const handleModalClose = () => {
        setIsModalOpen(false);
        setGroup(null);
    };

    const isLoading = useSelector(selectGroupsLoading);
    const total = useSelector(selectGroupTotal);
    const perPage = useSelector(selectGroupPerPage);
    const dispatch = useDispatch();

    const getGroups = async (page: number) => {
        try {
            //TODO add userRole === USER_ROLES.ADMIN instead of true
            await dispatch(getGroupsList(page, true));
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    const handleCreateGroup = () => {
        setActionType(ACTION_TYPES.CREATE);
        setIsModalOpen(true);
        setGroup(null);
    };

    const handleUpdateGroup = () => {
        setActionType(ACTION_TYPES.UPDATE);
        setIsModalOpen(true);
    };

    useEffect(() => {
        getGroups(currentPage);
    }, [currentPage]);

    useEffect(() => {
        if (total && perPage) {
            setTotalPages(getTotalPageCount(total, perPage));
        }
    }, [total, perPage]);

    return (
        <>
            <CreateGroup
                open={isModalOpen}
                actionType={actionType}
                group={group}
                handleClose={handleModalClose}
            />
            <Card className={clsx(classes.card, 'card-box mb-spacing-6-x2 bl-0 br-0 shadow-none')}>
                <CardContent>
                    <div className="table-responsive-md">
                        <section className={classes.tableHeader}>
                            <div className={classes.heading}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className={classes.iconContainer}>
                                        <ListAltRounded className={classes.listIcon} />
                                    </div>
                                    <Typography className={classes.tableTitleContainer}>
                                        <span className={classes.tableTitle}>{t('admin.group_table.title')}</span>
                                        {!isLoading && total && <span className={classes.totalInfo}>{` (${total})`}</span>}
                                    </Typography>
                                    {isLoading && <BeatLoader />}
                                </div>

                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={handleCreateGroup}
                                >
                                    <Typography>
                                        {t('admin.group_table.create_group.create_button')}
                                    </Typography>
                                </Button>
                            </div>
                        </section>
                        {isLoading ? (
                            <TableSkeleton rowsCount={10} />
                        ) : (
                            <Table className="table table-borderless table-hover text-nowrap mb-5">
                                <thead>
                                    <tr>
                                        <th className="text-left">{t('table_columns.name')}</th>
                                        <th className="text-center">{t('table_columns.group')}</th>
                                        <th className="text-center">{t('table_columns.action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groups && groups.map((group, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <a
                                                                href="#/"
                                                                onClick={(e) => e.preventDefault()}
                                                                className="font-weight-bold text-black"
                                                                title="...">
                                                                {group.name}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">{formatDate(group.created_at as string)}</td>

                                                <td className="text-center">
                                                    <ActionsPopover>
                                                        <Button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleUpdateGroup();
                                                                setGroup(group);
                                                            }}
                                                            color="secondary"
                                                        >
                                                            <Typography>{t('admin.group_table.update')}</Typography>
                                                        </Button>
                                                    </ActionsPopover>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        )}
                        {totalPages &&
                            <ElementsPagination
                                page={currentPage}
                                total={totalPages}
                                isLoading={isLoading}
                                setPage={setCurrentPage}
                            />
                        }
                    </div>
                </CardContent>
            </Card>
        </>

    );
}

export default GroupsTable;
