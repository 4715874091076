import React from 'react';
import { useSelector } from "react-redux";
import { Switch, Redirect } from 'react-router-dom';
import { selectUserRole } from "../../../store/user/userSlice";
import routes from '../../../routes';
import { localizeRouth } from "../../../helpers/localizationHelpers";
import { UserRoles } from "../../../assets_ash/globalTypes";
import CssBaseline from '@material-ui/core/CssBaseline';
import ProtectedRoute from '../../../components/ProtectedRoute';
import Sidebar from '../Sidebar';
import UsersTable from '../UsersTable';
import GroupsTable from '../GroupsTable';
import StatisticOverview from '../StatisticOverview';
import DashboardBar from '../DashboardBar';
import DocumentsTable from "../DocumentsTable";
import Footer from '../../../components/Footer/index';
import DocumentsChange from "../DocumentsChange";
import Reports from "../Reports";

import styles from './styles.module.scss';

const DashboardLayout = () => {
    const adminAndGroupAvailableRoutes: string[] = ['Admin', 'Group owner'];
    const adminAvailableRoutes: string[] = ['Admin'];

    const userRole = useSelector(selectUserRole)

    const route: string = userRole === UserRoles.ADMIN ? localizeRouth(routes.adminDashboardOverview.path) : userRole === UserRoles.GROUP_OWNER ? localizeRouth(routes.adminDashboardDocuments.path) : ''

    return (
        <div className={styles.root}>
            <CssBaseline />
            <Sidebar />

            <main className={styles.content}>
                <DashboardBar />
                <div className={styles.divider}></div>

                <Switch>
                    <Redirect exact from={routes.adminDashboard.path} to={route} />
                    <ProtectedRoute exact={true} path={localizeRouth(routes.adminDashboardOverview.path)} userAvailableRoles={adminAvailableRoutes}>
                        <StatisticOverview />
                        <UsersTable />
                    </ProtectedRoute>
                    <ProtectedRoute exact={true} path={localizeRouth(routes.adminDashboardUsers.path)} userAvailableRoles={adminAvailableRoutes}>
                        <UsersTable />
                    </ProtectedRoute>
                    <ProtectedRoute exact={true} path={localizeRouth(routes.adminDashboardGroups.path)} userAvailableRoles={adminAvailableRoutes}>
                        <GroupsTable />
                    </ProtectedRoute>
                    <ProtectedRoute exact={true} path={localizeRouth(routes.adminDashboardDocuments.path)} userAvailableRoles={adminAndGroupAvailableRoutes}>
                        <DocumentsTable />
                    </ProtectedRoute>
                    <ProtectedRoute exact={true} path={localizeRouth(routes.documentsChange.path)} userAvailableRoles={adminAndGroupAvailableRoutes}>
                        <DocumentsChange />
                    </ProtectedRoute>
                    <ProtectedRoute exact={true} path={localizeRouth(routes.reports.path)} userAvailableRoles={adminAvailableRoutes}>
                        <Reports />
                    </ProtectedRoute>
                </Switch>

                <Footer />
            </main>
        </div>
    );
}

export default DashboardLayout;
