import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from "formik";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import {
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText, InputAdornment,
    makeStyles,
    MenuItem,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import { register, selectIsAuthenticated, selectIsLoading } from '../../store/user/userSlice';
import { getNoAuthGroupList, selectNoAuthGroups } from "../../store/groups/groupSlice";
import { showNotification } from '../../store/notification/notificationSlice';
import registerSchema from "../../schemas/registerSchema";
import { getYearsToNow } from "../../helpers/dateTime";
import { extractUserCredentials } from "../../helpers";
import { COLORS } from "../../constants/styles";
import { NOTIFICATION_TYPES } from "../../constants";
import routes from '../../routes';
import { IsMember, RegisterFormValues } from "../../assets_ash/globalTypes";
import { BlueRadio, CustomSelect } from "../../components/Select";
import SubmitButton from "../../components/Button";
import SignUpIcon from '../../assets_ash/icons/sign_up_icon.svg';
import countries from '../../assets_ash/countries/countries.json';
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles({
    datePicker: {
        border: "1px solid #BEBEBE",
        width: '100%',
        padding: '10px',
        margin: '10px 0 0',
        '& .MuiInput-underline:before': {
            border: 'none'
        },
        '& .MuiInput-underline:after': {
            border: 'none'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none'
        },
        '& .MuiInput-underline.Mui-error:after': {
            border: 'none'
        }
    },
    errorMessage: {
        color: '#f83245',
        fontSize: '0.75rem',
        margin: '3px 14px 0',
    },
    input: {
        position: 'relative',
        '&:before': {
            content: 'attr(placeholder)',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: '#fff',
            color: 'rgba(0, 0, 0, 0.65)',
            lineHeight: 1.5,
            padding: '5px',
        },
        '&:focus:before, &:not([value=""]):before': {
            display: 'none',
        },
    },
    selectIcon: {
        color: COLORS.PURE_BLUE,
        marginRight: '10px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 700,
        margin: '0 auto'
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        margin: '10px 0'
    },
    title: {
        fontSize: 28,
        fontWeight: 800,
        color: '#000000'
    },
    subTitle: {
        fontSize: 20,
        color: '#BEBEBE'
    },
    authActionContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px 0',
        '& :last-child': {
            marginLeft: 5
        }
    },
    authText: {
        fontSize: 18,
        fontWeight: 500
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        maxWidth: '500px',
        margin: '30px auto'
    },
    mainFields: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    field: {
        width: '100%',
        margin: '10px 0'
    },
    addressText: {
        fontSize: 14,
        margin: '25px 0 0'
    },
    splitFields: {
        display: "flex",
        alignItems: 'baseline',
        justifyContent: 'flex-start',
        marginBottom: 10,
        '& :first-child': {
            flex: "50%",
            marginRight: 5
        },
        "& :last-child": {
            flex: "50%",
        }
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: '10px 0'
    },
    passwordInfo: {
        fontSize: 14,
        color: '#757575'
    },
    passwordInfoContainer: {
        width: '100%'
    }
});

const Register: React.FC = () => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const [chosenState, setChosenState] = useState<any>([]);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfPassword, setShowConfPassword] = useState<boolean>(false)

    const isLoading = useSelector(selectIsLoading);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const groups = useSelector(selectNoAuthGroups);
    const allCountries = countries.countries.map(country => country.country);

    const getGroups = async () => {
        try {
            await dispatch(getNoAuthGroupList(1));
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    const handleCountryChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: Function
    ) => {
        const selectedCountry = event.target.value;
        const countryState = countries.countries.filter(obj => obj.country === selectedCountry);
        setChosenState(countryState[0].states);
        setFieldValue("country", selectedCountry);
    };

    const groupDate = useMemo(getYearsToNow, []);

    const onSubmit = async ({
                                first_name,
                                last_name,
                                email,
                                password,
                                confirm_password,
                                date_of_birth,
                                street,
                                state_province,
                                country,
                                date_from,
                                date_to,
                                group,
                                otherGroup,
                                city,
                            }: {
        first_name: string,
        last_name: string,
        email: string,
        password: string,
        confirm_password: string,
        date_of_birth: any,
        street: string,
        state_province: string,
        country: string,
        date_from: number | string,
        date_to: number | string,
        group: Record<string, any>[],
        otherGroup: string,
        city: string,
    }) => {
        try {
            const userCredentials = extractUserCredentials(
                first_name,
                last_name,
                email,
                password,
                confirm_password,
                date_of_birth,
                street,
                state_province,
                country,
                date_from,
                date_to,
                group,
                otherGroup,
                city
            );

            await dispatch(register(userCredentials));
            await dispatch(showNotification({
                message: t('register.notification.success'),
                type: NOTIFICATION_TYPES.SUCCESS
            }));
            history.push(routes.sendEmail.path);
        } catch (e) {
            dispatch(showNotification({
                message: (e as Error).message,
                type: NOTIFICATION_TYPES.ERROR
            }));
        }
    };

    const initialValues: RegisterFormValues = {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirm_password: '',
        date_of_birth: '',
        street: '',
        state_province: '',
        country: '',
        date_from: '',
        date_to: '',
        group: [],
        otherGroup: '',
        city: '',
        isMember: '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema: registerSchema,
        onSubmit: (values) => {
            onSubmit(values);
        }
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit
    } = formik;

    useEffect(() => {
        if (values.isMember === IsMember.Yes) {
            getGroups();
        }
    }, [values.isMember]);

    if (isAuthenticated) {
        return (
            <Redirect to={routes.home.path}/>
        )
    }

    return (
        <main className={classes.container}>
            <section className={classes.titleContainer}>
                <img
                    src={SignUpIcon}
                    alt="sign-up"
                    className={classes.icon}
                />

                <Typography className={classes.title}>
                    {t('register.title')}
                </Typography>
                <Typography className={classes.subTitle}>
                    {t('register.sub_title')}
                </Typography>

            </section>

            <form
                onSubmit={handleSubmit}
                className={classes.form}
            >
                <section className={classes.mainFields}>
                    <TextField
                        id="first_name"
                        name="first_name"
                        className={classes.field}
                        label={t('register.fields.first_name')}
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        value={values.first_name}
                        onChange={handleChange}
                        error={touched.first_name && Boolean(errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                    />
                    <TextField
                        id="last_name"
                        name="last_name"
                        className={classes.field}
                        label={t('register.fields.last_name')}
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        value={values.last_name}
                        onChange={handleChange}
                        error={touched.last_name && Boolean(errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                    />
                    <TextField
                        id="email"
                        name="email"
                        className={classes.field}
                        label={t('register.fields.email')}
                        type="email"
                        autoComplete="off"
                        variant="outlined"
                        value={values.email}
                        onChange={handleChange}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                    />
                    <TextField
                        id="password"
                        name="password"
                        className={classes.field}
                        label={t('register.fields.password')}
                        type={showPassword ? 'text' : "password"}
                        autoComplete="off"
                        variant="outlined"
                        value={values.password}
                        onChange={handleChange}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    onClick={() => setShowPassword(!showPassword)}
                                    style={{cursor: 'pointer'}}
                                >
                                    {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                </InputAdornment>
                            )
                        }}
                    />
                    {(!values.password) && (
                        <section className={classes.passwordInfoContainer}>
                            <Typography className={classes.passwordInfo}>
                                {t('register.validation.password_min')}
                            </Typography>
                        </section>
                    )}
                    <TextField
                        id="confirm_password"
                        name="confirm_password"
                        className={classes.field}
                        label={t('register.fields.confirm_password')}
                        type={showConfPassword ? 'text' : "password"}
                        autoComplete="off"
                        variant="outlined"
                        value={values.confirm_password}
                        onChange={handleChange}
                        error={touched.confirm_password && Boolean(errors.confirm_password)}
                        helperText={touched.confirm_password && errors.confirm_password}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    onClick={() => setShowConfPassword(!showConfPassword)}
                                    style={{cursor: 'pointer'}}
                                >
                                    {showConfPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                </InputAdornment>
                            )
                        }}
                    />
                    {(!values.confirm_password) && (
                        <section className={classes.passwordInfoContainer}>
                            <Typography className={classes.passwordInfo}>
                                {t('register.validation.confirm_password_min')}
                            </Typography>
                        </section>
                    )}

                    <section style={{width: '100%'}}>
                        <TextField
                            name='date_of_birth'
                            type='date'
                            value={values.date_of_birth}
                            error={!!errors.date_of_birth && !!touched.date_of_birth}
                            className={classes.datePicker}
                            placeholder={t('register.fields.dob')}
                            onChange={handleChange}
                            InputProps={{
                                classes: {
                                    input: classes.input
                                }
                            }}
                        />
                        {!!errors.date_of_birth && !!touched.date_of_birth ?
                            <Typography className={classes.errorMessage}>
                                {errors.date_of_birth}
                            </Typography>
                            :
                            <></>
                        }
                    </section>
                </section>

                <Typography className={classes.addressText}>
                    {t('register.fields.location_title')}
                </Typography>

                <section>
                    <TextField
                        id="street"
                        name="street"
                        className={classes.field}
                        label={t('register.fields.location_street')}
                        type="text"
                        autoComplete="off"
                        variant="outlined"
                        value={values.street}
                        onChange={handleChange}
                        error={touched.street && Boolean(errors.street)}
                        helperText={touched.street && errors.street}
                    />

                    <section className={classes.splitFields}>
                        <FormControl>
                            <Select
                                id='country'
                                name='country'
                                value={values.country}
                                onChange={(e) => {
                                    handleCountryChange(e as React.ChangeEvent<HTMLInputElement>, setFieldValue);
                                }}
                                error={!!errors.country && touched.country}
                                input={<CustomSelect/>}
                                classes={{icon: classes.selectIcon}}
                                fullWidth
                                displayEmpty
                                renderValue={() => (
                                    values.country === '' ? (
                                        <Typography>
                                            {t('register.fields.location_country')}
                                        </Typography>
                                    ) : (
                                        <Typography>
                                            {values.country}
                                        </Typography>
                                    )
                                )}
                            >
                                {allCountries && allCountries?.map((option: any) => (
                                    <MenuItem
                                        value={option}
                                        key={option}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.country && touched.country && (
                                <FormHelperText error>{errors.country}</FormHelperText>
                            )}
                        </FormControl>
                        <TextField
                            id="city"
                            name="city"
                            className={classes.field}
                            label={t('register.fields.location_city')}
                            type="text"
                            autoComplete="off"
                            variant="outlined"
                            value={values.city}
                            onChange={handleChange}
                            error={touched.city && Boolean(errors.city)}
                            helperText={touched.city && errors.city}
                        />
                    </section>

                    <Select
                        id='state_province'
                        name='state_province'
                        value={values.state_province}
                        onChange={handleChange}
                        error={!!errors.state_province && touched.state_province}
                        input={<CustomSelect/>}
                        classes={{icon: classes.selectIcon}}
                        disabled={!values.country}
                        fullWidth
                        displayEmpty
                        renderValue={() => (
                            values.state_province === '' ? (
                                <Typography>
                                    {t('register.fields.location_state')}
                                </Typography>
                            ) : (
                                <Typography>
                                    {values.state_province}
                                </Typography>
                            )
                        )}
                    >
                        {chosenState && chosenState?.map((option: any) => (
                            <MenuItem
                                value={option}
                                key={option}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.state_province && touched.state_province && (
                        <FormHelperText error>{errors.state_province}</FormHelperText>
                    )}
                </section>

                <Typography className={classes.addressText}>
                    {t('register.fields.member_subtitle')}
                </Typography>

                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="isMember"
                        name='isMember'
                        className={classes.radioGroup}
                        onChange={handleChange}
                        value={values.isMember}
                    >
                        <FormControlLabel
                            value={IsMember.Yes}
                            control={<BlueRadio/>}
                            label={t('register.fields.member_yes')}
                        />
                        <FormControlLabel
                            value={IsMember.No}
                            control={<BlueRadio/>}
                            label={t('register.fields.member_no')}
                        />
                    </RadioGroup>
                </FormControl>

                {values.isMember === IsMember.Yes && (
                    <>
                        <section className={classes.splitFields}>
                            <FormControl>
                                <Select
                                    id='date_from'
                                    name='date_from'
                                    value={values.date_from}
                                    onChange={handleChange}
                                    error={!!errors.date_from && touched.date_from}
                                    input={<CustomSelect/>}
                                    classes={{icon: classes.selectIcon}}
                                    fullWidth
                                    displayEmpty
                                    renderValue={() => (
                                        values.date_from === '' ? (
                                            <Typography>
                                                {t('register.fields.member_date_from')}
                                            </Typography>
                                        ) : (
                                            <Typography>
                                                {values.date_from}
                                            </Typography>
                                        )
                                    )}
                                >
                                    {groupDate && groupDate?.map((option: any) => (
                                        <MenuItem
                                            value={option}
                                            key={option}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.date_from && touched.date_from && (
                                    <FormHelperText error>{errors.date_from}</FormHelperText>
                                )}
                            </FormControl>

                            <FormControl>
                                <Select
                                    id='date_to'
                                    name='date_to'
                                    value={values.date_to}
                                    onChange={handleChange}
                                    error={!!errors.date_to && touched.date_to}
                                    input={<CustomSelect/>}
                                    classes={{icon: classes.selectIcon}}
                                    fullWidth
                                    displayEmpty
                                    renderValue={() => (
                                        values.date_to === '' ? (
                                            <Typography>
                                                {t('register.fields.member_date_to')}
                                            </Typography>
                                        ) : (
                                            <Typography>
                                                {values.date_to}
                                            </Typography>
                                        )
                                    )}
                                >
                                    {groupDate && groupDate?.map((option: any) => (
                                        <MenuItem
                                            value={option}
                                            key={option}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.date_to && touched.date_to && (
                                    <FormHelperText error>{errors.date_to}</FormHelperText>
                                )}
                            </FormControl>
                        </section>

                        <FormControl>
                            <Select
                                multiple
                                name='group'
                                input={<CustomSelect/>}
                                classes={{icon: classes.selectIcon}}
                                value={values.group}
                                error={Boolean(!!errors.group && touched.group)}
                                onChange={handleChange}
                                renderValue={(selected: any) => selected.map((select: any) => select.name).join(', ')}
                            >
                                {groups && [...groups]
                                    .sort((a, b) => (a.name === 'Other' ? 1 : b.name === 'Other' ? -1 : 0))
                                    .map((option: any) => (
                                        <MenuItem key={option.id} value={option}>
                                            <FormControlLabel
                                                control={<Checkbox checked={values.group.includes(option)}/>}
                                                label={option.name}
                                            />
                                        </MenuItem>
                                    ))}
                            </Select>

                            {errors.group && touched ? (
                                <FormHelperText error>{errors.group}</FormHelperText>
                            ) : (
                                <></>
                            )}

                            {values.group.some((option) => option.name === 'Other') && (
                                <TextField
                                    id="otherGroup"
                                    name="otherGroup"
                                    className={classes.field}
                                    label={t('register.fields.other')}
                                    type="text"
                                    autoComplete="off"
                                    variant="outlined"
                                    value={values.otherGroup}
                                    onChange={handleChange}
                                    error={touched.otherGroup && Boolean(errors.otherGroup)}
                                    helperText={touched.otherGroup && errors.otherGroup}
                                />
                            )}

                        </FormControl>
                    </>
                )}

                <SubmitButton
                    type='submit'
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={25} color='inherit'/> : t('register.signup_button')}
                </SubmitButton>
            </form>

            <section className={classes.authActionContainer}>
                <Typography className={classes.authText}>
                    {t('register.have_account')}
                </Typography>
                <Link
                    to={routes.login.path}
                    className={classes.authText}
                    style={{color: '#007FDD'}}
                >
                    {t('register.login_button')}
                </Link>
            </section>
        </main>
    )
};

export default Register;



